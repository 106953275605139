import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full md:px-5" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_breadcrumb, { separator: "/" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: $setup.props.referActionUrl
            }, _toDisplayString($setup.props.referActionName), 9 /* TEXT, PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.props.currentActionName), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}