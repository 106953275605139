<template>
  <div class="max-w-[1000px] mx-auto">
    <!-- 文档头部 -->
    <div class="mb-8 pb-6 border-b border-gray-200">
      <div class="flex items-start justify-between gap-4">
        <div class="flex-1 min-w-0">
          <h1 class="text-3xl font-bold text-gray-900 break-words tracking-tight">{{ doc.title }}</h1>
          <div class="mt-3 flex items-center flex-wrap gap-3 text-sm text-gray-500">
            <span>{{ formatDate(doc.updated_at) }} 更新</span>
            <span class="w-1 h-1 rounded-full bg-gray-300"></span>
            <span>{{ docTypes[doc.doc_type] || '其他' }}</span>
            <span class="w-1 h-1 rounded-full bg-gray-300"></span>
            <div class="flex items-center gap-4">
              <span class="flex items-center gap-1">
                <el-icon><View /></el-icon>
                {{ doc.views_count || 0 }}
              </span>
              <span class="flex items-center gap-1">
                <el-icon><ChatLineSquare /></el-icon>
                {{ doc.comments_count || 0 }}
              </span>
            </div>
            <span 
              v-if="doc.status !== 'published'" 
              class="px-2 py-0.5 rounded text-xs bg-gray-100"
            >草稿</span>
          </div>
        </div>
        
        <!-- 操作按钮 -->
        <div v-if="showActions" class="flex-shrink-0">
          <el-dropdown trigger="click" @visible-change="(visible) => isDropdownVisible = visible">
            <el-button
              class="!w-[36px] !h-[36px] !p-0"
              :class="[
                isDropdownVisible ? '!bg-[#FFF5F0] !border-[#FF5005] !text-[#FF5005]' : '!bg-gray-50 !border-gray-200',
                'hover:!bg-[#FFF5F0] hover:!border-[#FF5005] hover:!text-[#FF5005]'
              ]"
            >
              <el-icon :class="[isDropdownVisible ? 'text-[#FF5005]' : 'text-gray-600']">
                <MoreFilled />
              </el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="canEdit" @click="handleEdit">
                  <div class="flex items-center gap-2 py-1">
                    <el-icon><EditPen /></el-icon>
                    <span>编辑文档</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item @click="handleShare">
                  <div class="flex items-center gap-2 py-1">
                    <el-icon><Share /></el-icon>
                    <span>分享文档</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 文档内容 -->
    <div class="prose max-w-none pb-20" v-html="doc.content"></div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { MoreFilled, EditPen, Share, View, ChatLineSquare } from '@element-plus/icons-vue'
import { docTypes } from '../constants/docTypes'
import { docView } from '@api/csrf/doc'

export default {
  name: 'DocContent',
  components: {
    MoreFilled,
    EditPen,
    Share,
    View,
    ChatLineSquare
  },
  props: {
    doc: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const isDropdownVisible = ref(false)

    const updateViewCount = async () => {
      try {
        await docView(props.doc.uuid)
      } catch (error) {
        console.error('Failed to update view count:', error)
      }
    }

    onMounted(() => {
      updateViewCount()
    })

    return { 
      isDropdownVisible,
    }
  },
  data() {
    return {
      docTypes
    }
  },
  methods: {
    handleEdit() {
      window.location.href = `/docs/${this.doc.uuid}/edit`
    },
    handleShare() {
      const url = window.location.href
      navigator.clipboard.writeText(url).then(() => {
        ElMessage({
          message: '链接已复制到剪贴板',
          type: 'success',
          duration: 2000
        })
      }).catch(() => {
        ElMessage({
          message: '复制失败，请手动复制链接',
          type: 'error'
        })
      })
    },
    formatDate(dateString) {
      if (!dateString) return ''
      
      try {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
          return ''
        }
        
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        
        return `${year}-${month}-${day}`
      } catch (error) {
        console.error('Date parsing error:', error)
        return ''
      }
    }
  }
}
</script>

<style scoped>
:deep(.prose) {
  max-width: none;
  color: #374151;
}

:deep(.prose h1) {
  color: #111827;
  margin-top: 2em;
  margin-bottom: 1em;
  font-weight: 600;
}

:deep(.prose h2) {
  color: #111827;
  margin-top: 1.75em;
  margin-bottom: 0.75em;
  font-weight: 600;
}

:deep(.prose h3) {
  color: #111827;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
  font-weight: 600;
}

:deep(.prose img) {
  margin: 2em auto;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 100% !important;
  height: auto !important;
  display: block;
}

:deep(.prose figure) {
  margin: 2em 0;
  max-width: 100% !important;
}

:deep(.prose figure img) {
  margin: 0 auto;
}

:deep(.prose figure figcaption) {
  text-align: center;
  color: #6B7280;
  font-size: 0.875em;
  margin-top: 0.5em;
}

:deep(.prose p) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  line-height: 1.8;
}

:deep(.prose ul) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

:deep(.prose li) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

:deep(.prose blockquote) {
  margin: 1.5em 0;
  padding: 0.5em 1em;
  border-left: 4px solid #E5E7EB;
  background-color: #F9FAFB;
  color: #4B5563;
}

:deep(.prose code) {
  background-color: #F3F4F6;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.875em;
}

:deep(.prose pre) {
  background-color: #1F2937;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
}

:deep(.el-button) {
  transition: all 0.2s ease-in-out !important;
}

:deep(.el-button:hover) {
  background-color: #fff !important;
}

:deep(.el-button:active) {
  transform: scale(0.98);
}

:deep(.el-dropdown-menu__item:not(.is-disabled):hover) {
  background-color: #FFF5F0 !important;
  color: #FF5005 !important;
}

:deep(.el-dropdown-menu__item:not(.is-disabled):hover .el-icon) {
  color: #FF5005 !important;
}

:deep(.el-dropdown-menu) {
  padding: 4px !important;
  min-width: 120px !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1) !important;
}

:deep(.el-dropdown-menu__item) {
  padding: 8px 12px !important;
  border-radius: 4px !important;
  line-height: 1.4 !important;
}
</style> 