import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_3 = { class: "flex w-[1080px] md:w-full justify-center gap-[8px] flex-wrap" }
const _hoisted_4 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[28px] font-[600] text-center leading-[28px] text-white mb-[50px]" }, " 发起成员 ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (index) => {
          return _createElementVNode("img", {
            src: `/images/xty_home/partner_${index}.png`,
            class: "h-[64px] md:h-[35px]",
            alt: "",
            key: index
          }, null, 8 /* PROPS */, _hoisted_4)
        }), 64 /* STABLE_FRAGMENT */))
      ])
    ])
  ]))
}