import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "m-auto w-[583px] md:w-full md:px-[20px]" }
const _hoisted_3 = { class: "py-[96px] text-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_CKEditor5 = _resolveComponent("CKEditor5")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-[20px] font-[500] leading-[30px]" }, " 投递案例 ", -1 /* HOISTED */)),
        _createVNode(_component_el_form, {
          ref: "dataForm",
          model: $data.dataForm,
          rules: $data.rules,
          "label-position": "top",
          class: "text-left"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "组织名称",
              prop: "org_path"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.org_path,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.org_path) = $event)),
                  placeholder: "选择组织"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.organizations, (org) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: org.path,
                        label: org.name,
                        value: org.path
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "所属行业",
              prop: "industry_list"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.industry_list,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.dataForm.industry_list) = $event)),
                  multiple: "",
                  filterable: "",
                  "reserve-keyword": false,
                  "default-first-option": "",
                  placeholder: _ctx.$t('all.pleaseSelect', { value: '行业' }),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.industries, (value) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: value,
                        label: value,
                        value: value
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "案例类型",
              prop: "kind"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.kind,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.kind) = $event)),
                  filterable: "",
                  "reserve-keyword": false,
                  "default-first-option": "",
                  placeholder: _ctx.$t('all.pleaseSelect', { value: '案例类型' }),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.kinds, (value) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: value,
                        label: value,
                        value: value
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "案例名称",
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.title,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.dataForm.title) = $event)),
                  placeholder: "请输入案例名称"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "简介",
              prop: "description"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  rows: "3",
                  modelValue: $data.dataForm.description,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.dataForm.description) = $event)),
                  placeholder: "请输入简介"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "地点",
              prop: "address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.address,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.dataForm.address) = $event)),
                  placeholder: "请输入地点"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "备案进度",
              prop: "report_status"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.report_status,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.dataForm.report_status) = $event)),
                  placeholder: "请选择备案进度"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.report_status_map, (label, value) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: value,
                        label: label,
                        value: value
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "建设进展",
              prop: "build_status"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.build_status,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.dataForm.build_status) = $event)),
                  placeholder: "请选择建设进展"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.build_status_map, (label, value) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: value,
                        label: label,
                        value: value
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "模型类型",
              prop: "model_kind"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.dataForm.model_kind,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.dataForm.model_kind) = $event)),
                  placeholder: "请选择模型类型"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.model_kind_map, (label, value) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: value,
                        label: label,
                        value: value
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "底座模型",
              prop: "base_model"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.base_model,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.dataForm.base_model) = $event)),
                  placeholder: "请输入底座模型"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "模型参数",
              prop: "model_params"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.model_params,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.dataForm.model_params) = $event)),
                  placeholder: "请输入模型参数"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "案例综述",
              prop: "content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CKEditor5, {
                  content: $data.dataForm.content,
                  "onUpdate:content": _cache[11] || (_cache[11] = $event => (($data.dataForm.content) = $event))
                }, null, 8 /* PROPS */, ["content"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]",
                  onClick: $options.handleSubmit
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("提交审核")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ])
  ]))
}