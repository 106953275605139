import request from './request'

/** 文档 开始 */
// 搜索文档
export function docList(query) {
  return request({
    url: `/internal_api/docs`,
    method: 'get',
    params: query
  })
}

// 查看文档
export function docDetail(uuid) {
  return request({
    url: `/internal_api/docs/${uuid}`,
    method: 'get',
  })
}

export function docUpdate(uuid, data) {
  return request({
    url: `/internal_api/docs/${uuid}`,
    method: 'patch',
    data
  })
}

// 删除文档
export function docDelete(uuid) {
  return request({
    url: `/internal_api/docs/${uuid}`,
    method: 'delete',
  })
}

// 创建文档
export function docCreate(data) {
  return request({
    url: `/internal_api/docs`,
    method: 'post',
    data
  })
}

export function docView(uuid) {
  return request({
    url: `/internal_api/docs/${uuid}/view`,
    method: 'put',
  })
}
/** 文档 结束 */
