import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createBlock(_component_el_pagination, {
    background: "",
    "hide-on-single-page": true,
    "current-page": $setup.theCurrentPage,
    "onUpdate:currentPage": [
      _cache[0] || (_cache[0] = $event => (($setup.theCurrentPage) = $event)),
      $setup.currentChange
    ],
    "prev-icon": $setup.prevIcon,
    "next-icon": $setup.nextIcon,
    "default-current-page": 1,
    total: $props.total,
    "page-size": $props.perPage,
    layout: "prev, pager, next",
    class: "my-[52px] flex justify-center"
  }, null, 8 /* PROPS */, ["current-page", "prev-icon", "next-icon", "total", "page-size"]))
}