import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[480px]" }
const _hoisted_2 = { class: "flex justify-between mb-4" }
const _hoisted_3 = { class: "flex justify-between mb-4" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "flex justify-between mb-4" }
const _hoisted_6 = { class: "flex justify-between mb-4" }
const _hoisted_7 = { class: "flex justify-between mb-4" }
const _hoisted_8 = { class: "flex justify-between mb-4" }
const _hoisted_9 = { class: "flex justify-between mb-4" }
const _hoisted_10 = { class: "mb-[16px]" }
const _hoisted_11 = { class: "text-[#303133] text-[14px] mb-[8px]" }
const _hoisted_12 = { class: "dialog-footer" }
const _hoisted_13 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, null, {
      header: _withCtx(() => _cache[6] || (_cache[6] = [
        _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("span", null, "User Detail")
        ], -1 /* HOISTED */)
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogFormVisible = true))
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode("Edit")
          ])),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("label", null, "Uuid", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.uuid), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("label", null, "Name", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.username), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_4, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "Nickname", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.nickname), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("label", null, "Avatar", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.avatar), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_6, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Email", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.email), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_7, [
            _cache[12] || (_cache[12] = _createElementVNode("label", null, "Phone", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.phone), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_8, [
            _cache[13] || (_cache[13] = _createElementVNode("label", null, "Roles", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.user.roles), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_9, [
            _cache[14] || (_cache[14] = _createElementVNode("label", null, "Created At", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.dayjs($setup.user.created_at).format('YYYY-MM-DD HH:mm:ss')), 1 /* TEXT */)
          ]),
          _createCommentVNode(" <li class=\"flex justify-between mb-4\">\r\n          <label>Credit</label>\r\n          <div class=\"flex items-center\">\r\n            <p class=\"mr-3\">{{ credit }} 元</p>\r\n            <div\r\n              @click=\"refreshDialogVisible = true\"\r\n              class=\"border px-[16px] rounded-lg border-amber-50 bg-orange-500 cursor-pointer\"\r\n            >\r\n              {{ $t('new_admin.user.recharge') }}\r\n            </div>\r\n          </div>\r\n        </li> ")
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" recharge dialog "),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.refreshDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.refreshDialogVisible) = $event)),
      title: _ctx.$t('new_admin.user.recharge'),
      width: "20%",
      class: "dialogWidth",
      style: {"border-radius":"0.5rem"},
      left: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_12, [
          _createVNode(_component_el_button, { onClick: $setup.clickCancel }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('new_admin.user.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.fetchRecharge
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('new_admin.user.confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.$t('new_admin.user.inputTitle')) + " ", 1 /* TEXT */),
            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-red-400" }, "*", -1 /* HOISTED */))
          ]),
          _createVNode(_component_el_input, {
            modelValue: $setup.rechargeAmount,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.rechargeAmount) = $event)),
            type: "number",
            placeholder: _ctx.$t('new_admin.user.inputPlaceholder'),
            maxlength: "20"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"]),
    _createCommentVNode(" edit user infos dialog "),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogFormVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.dialogFormVisible) = $event)),
      title: `${$setup.user.username}`,
      width: "500"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = $event => ($setup.dialogFormVisible = false))
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("Cancel")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.submitUserForm
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode(" Confirm ")
            ])),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, { model: $setup.form }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Roles" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.form.roles,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.form.roles) = $event)),
                  multiple: "",
                  placeholder: "Please select the roles"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "Super User",
                      value: "super_user"
                    }),
                    _createVNode(_component_el_option, {
                      label: "Admin",
                      value: "admin"
                    }),
                    _createVNode(_component_el_option, {
                      label: "Personal User",
                      value: "personal_user"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ]))
}