import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex max-w-[1440px] m-auto" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "px-[16px] search-model-input md:border-0 border-b py-[12px] border-[#F2F2F2] css-custom-input" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex w-full flex-wrap justify-between md:gap-[12px] gap-[16px] px-[16px] py-[12px] md:bg-[#F7F7F7] bg-unset" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.mobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["TagSidebar"], {
            taskTags: $setup.props.taskTags,
            frameworkTags: $setup.props.frameworkTags,
            licenseTags: $setup.props.licenseTags,
            onResetTags: $setup.resetTags,
            type: "dataset"
          }, null, 8 /* PROPS */, ["taskTags", "frameworkTags", "licenseTags"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["ElInput"], {
          modelValue: $setup.nameFilterInput,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.nameFilterInput) = $event)),
          size: "large",
          placeholder: "数据集名字",
          "prefix-icon": $setup.Search,
          onChange: $setup.reloadDatasets
        }, null, 8 /* PROPS */, ["modelValue", "prefix-icon"])
      ]),
      ($setup.mobileScreen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode($setup["MobileTagSidebar"], {
              taskTags: $setup.props.taskTags,
              frameworkTags: $setup.props.frameworkTags,
              licenseTags: $setup.props.licenseTags,
              onResetTags: $setup.resetTags,
              type: "dataset"
            }, null, 8 /* PROPS */, ["taskTags", "frameworkTags", "licenseTags"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.datasetsData, (dataset) => {
          return (_openBlock(), _createBlock($setup["DatasetItem"], { dataset: dataset }, null, 8 /* PROPS */, ["dataset"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_el_pagination, {
          background: "",
          "current-page": $setup.currentPage,
          "onUpdate:currentPage": [
            _cache[1] || (_cache[1] = $event => (($setup.currentPage) = $event)),
            $setup.reloadDatasets
          ],
          "default-current-page": 1,
          total: $setup.totalDatasets,
          "page-size": 16,
          layout: "prev, pager, next",
          class: "my-[52px] flex justify-center"
        }, null, 8 /* PROPS */, ["current-page", "total"])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.totalDatasets > $setup.perPage]
      ])
    ])
  ]))
}