import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center pr-6" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1 border-b" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", {
      class: "mx-2 flex h-8 flex-none items-center cursor-pointer rounded-lg px-2 hover:bg-gray-100",
      onClick: $setup.viewMore
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("svg", {
        width: "12",
        height: "8",
        viewBox: "0 0 12 8",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1 1.5L6 6.5L11 1.5",
          stroke: "#475467",
          "stroke-width": "1.66667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        })
      ], -1 /* HOISTED */),
      _createElementVNode("div", { class: "text-[14px] leading-[20px] text-[#475467] font-medium ml-2" }, "展示更多", -1 /* HOISTED */)
    ])),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-1 border-b" }, null, -1 /* HOISTED */))
  ]))
}