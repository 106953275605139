import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0 ml-[32px] md:ml-0 mb-[60px]" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-[328px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full pb-[20px]"
}
const _hoisted_3 = { class: "flex gap-[12px] flex-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex gap-[12px] flex-wrap" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex gap-[12px] flex-wrap" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex gap-[12px] flex-wrap" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "w-full"
}
const _hoisted_12 = { class: "border-b border-[#F2F2F2] px-[16px] py-[12px] text-[#000000E0]" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 1,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 2,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 3,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "pr-[20px] pt-[32px] md:px-[20px] md:pt-[0px] w-full" }
const _hoisted_22 = { class: "flex md:flex-col justify-between items-center px-[16px]" }
const _hoisted_23 = { class: "text-lg font-normal text-[#101828] flex items-center gap-2" }
const _hoisted_24 = { class: "text-gray-400 text-[16px] italic" }
const _hoisted_25 = { class: "flex xl:flex-col justify-end md:gap-[8px]" }
const _hoisted_26 = { class: "xl:mt-[16px] md:w-full mr-[16px] md:mr-0" }
const _hoisted_27 = {
  key: 0,
  href: `/issues/new`,
  class: "btn btn-orange flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]"
}
const _hoisted_28 = { class: "grid grid-cols-2 md:grid-cols-1 gap-[16px] px-[16px] py-[12px] md:px-[0px]" }
const _hoisted_29 = ["href"]
const _hoisted_30 = { class: "flex flex-col gap-[10px] border-[#E6E6E6] border-[1px] rounded-[4px] p-[16px] shadow-[0px_0px_0px_0px_rgba(0,0,0,0.05)] cursor-pointer xty-model-card" }
const _hoisted_31 = { class: "flex gap-[16px] justify-between text-[18px] font-[500] leading-[28px]" }
const _hoisted_32 = { class: "text-[rgba(0, 0, 0, 0.88)] overflow-ellipsis line-clamp line-clamp-1 max-w-[70%]" }
const _hoisted_33 = { class: "text-[#FF8035]" }
const _hoisted_34 = {
  key: 0,
  class: ""
}
const _hoisted_35 = {
  key: 1,
  class: ""
}
const _hoisted_36 = { class: "flex items-center gap-[8px] text-[#667085] text-[12px] leading-[20px] font-[400]" }
const _hoisted_37 = { class: "flex items-center gap-[4px]" }
const _hoisted_38 = { class: "flex items-center gap-[4px]" }
const _hoisted_39 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_40 = { class: "text-[#667085]" }
const _hoisted_41 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_42 = { class: "text-[#667085]" }
const _hoisted_43 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_44 = { class: "text-[#667085]" }
const _hoisted_45 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-2" }
const _hoisted_46 = { class: "text-[#667085]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_View = _resolveComponent("View")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Clock = _resolveComponent("Clock")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$data.mobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createCommentVNode(" 组织类型 "),
          _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "组织类型", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.org_types, (org_type) => {
              return (_openBlock(), _createElementBlock("span", {
                key: org_type,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${$data.activeOrgType === org_type ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleOrgTypeClick(org_type))
              }, _toDisplayString(org_type), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" 行业 "),
          _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "行业", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tags, (tag) => {
              return (_openBlock(), _createElementBlock("span", {
                key: tag,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${$data.activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleTagClick(tag))
              }, _toDisplayString(tag), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" 需求类型 "),
          _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "需求类型", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kinds, (kind) => {
              return (_openBlock(), _createElementBlock("span", {
                key: kind.value,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${$data.activeKind === kind.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleKindClick(kind.value))
              }, _toDisplayString(kind.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_8))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" 供需状态 "),
          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "供需状态", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statuses, (status) => {
              return (_openBlock(), _createElementBlock("span", {
                key: status.value,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${$data.activeStatus === status.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleStatusClick(status.value))
              }, _toDisplayString(status.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_10))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'OrgType' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[0] || (_cache[0] = $event => {this.drawer = true; this.activeNavItem = 'OrgType'})
            }, " 组织类型 ", 2 /* CLASS */),
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'Industry' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[1] || (_cache[1] = $event => {this.drawer = true; this.activeNavItem = 'Industry'})
            }, " 行业 ", 2 /* CLASS */),
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'Kind' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[2] || (_cache[2] = $event => {this.drawer = true; this.activeNavItem = 'Kind'})
            }, " 需求类型 ", 2 /* CLASS */),
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'Status' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[3] || (_cache[3] = $event => {this.drawer = true; this.activeNavItem = 'Status'})
            }, " 供需状态 ", 2 /* CLASS */)
          ]),
          _createVNode(_component_el_drawer, {
            modelValue: $data.drawer,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.drawer) = $event)),
            direction: "btt",
            "with-header": false
          }, {
            default: _withCtx(() => [
              ($data.activeNavItem === 'OrgType')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.org_types, (org_type) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: org_type,
                        class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${$data.activeOrgType === org_type ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                        onClick: $event => ($options.handleOrgTypeClick(org_type))
                      }, _toDisplayString(org_type), 11 /* TEXT, CLASS, PROPS */, _hoisted_14))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              ($data.activeNavItem === 'Industry')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tags, (tag) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: tag,
                        class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${$data.activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                        onClick: $event => ($options.handleTagClick(tag))
                      }, _toDisplayString(tag), 11 /* TEXT, CLASS, PROPS */, _hoisted_16))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : ($data.activeNavItem === 'Kind')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kinds, (kind) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: kind.value,
                          class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${$data.activeKind === kind.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                          onClick: $event => ($options.handleKindClick(kind.value))
                        }, _toDisplayString(kind.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_18))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  : ($data.activeNavItem === 'Status')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statuses, (status) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: status.value,
                            class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${$data.activeStatus === status.value ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                            onClick: $event => ($options.handleStatusClick(status.value))
                          }, _toDisplayString(status.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_20))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ])),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("h3", _hoisted_23, [
          _createVNode(_component_SvgIcon, {
            name: "issue",
            width: "18",
            height: "18"
          }),
          _createElementVNode("span", null, [
            _cache[10] || (_cache[10] = _createTextVNode(" 需求广场 ")),
            _createElementVNode("span", _hoisted_24, _toDisplayString($data.total), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_el_input, {
              modelValue: $data.keywords,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.keywords) = $event)),
              class: "w-[320px] md:w-full",
              size: "large",
              placeholder: "搜索关键字",
              "prefix-icon": $setup.Search,
              onChange: $options.fetchIssues
            }, null, 8 /* PROPS */, ["modelValue", "prefix-icon", "onChange"])
          ]),
          ($data.canCreateIssue)
            ? (_openBlock(), _createElementBlock("a", _hoisted_27, " 发布需求 "))
            : (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 1,
                class: "box-item",
                effect: "dark",
                content: "请先登录并注册组织后再发布需求",
                placement: "top-start"
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("a", {
                    href: `javascript:;`,
                    class: "btn bg-[var(--Gray-4)] text-[--Base-White] flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]"
                  }, " 发布需求 ", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }))
        ])
      ]),
      _createElementVNode("div", _hoisted_28, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.issues, (issue) => {
          return (_openBlock(), _createElementBlock("a", {
            key: issue.id,
            href: `/issues/${issue.id}`
          }, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, _toDisplayString(issue.project_name), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_33, [
                  (issue.budget > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(issue.budget) + "万", 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_35, "面议"))
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_View)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createElementVNode("div", null, _toDisplayString(issue.view_count), 1 /* TEXT */)
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "" }, " | ", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_38, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Clock)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createElementVNode("div", null, "截止时间：" + _toDisplayString(issue.delivery_at ? $setup.dayjs(issue.delivery_at).format("YYYY-MM-DD") : '面议'), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-[#475467]" }, "企业名称：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_40, _toDisplayString(issue.org_name), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-[#475467]" }, "产业链：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_42, _toDisplayString(issue.industry_list.join('、')), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_43, [
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-[#475467]" }, "发布时间：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_44, _toDisplayString($setup.dayjs(issue.created_at).format("YYYY-MM-DD")), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_45, [
                _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-[#475467]" }, "需求介绍：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_46, _toDisplayString(issue.description), 1 /* TEXT */)
              ])
            ])
          ], 8 /* PROPS */, _hoisted_29))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createVNode($setup["CsgPagination"], {
        perPage: $data.perPage,
        currentPage: $data.currentPage,
        onCurrentChange: $options.fetchIssues,
        total: $data.total
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "onCurrentChange", "total"])
    ])
  ]))
}