<template>
  <div class="max-w-[1440px] m-auto flex">
    <div class="px-[68px] md:px-[16px] pt-[60px] w-full lg:px-[0px]">
      <div class="px-[32px] md:px-0">
        <div class="flex flex-col gap-[32px]">
          <div class="text-[24px] font-[500] leading-[32px] text-[#101828]">近期热门活动</div>
          <div class="flex justify-between gap-[32px] md:flex-col md:gap-[32px]">
            <div class="flex flex-col items-start gap-[20px] flex-[1_0_0] self-stretch">
              <a
                :href="hot_salons[0].link"
                target="_blank"
              >
                <img :src="hot_salons[0].cover" class="lg:h-[191px] h-[240px] lg:w-[347px] w-[435px] md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover">
              </a>
              <div class="w-[435px] md:w-full lg:w-[240px]">
                <TextDetail :salon="hot_salons[0]" />
              </div>
            </div>

            <div class="flex flex-col gap-[32px]">
              <template :key="salon.id" v-for="salon in hot_salons.slice(-2)">
                <div class="flex md:flex-col gap-[20px]">
                  <a
                    :href="salon.link"
                    target="_blank"
                  >
                    <img :src="salon.cover" class="lg:h-[190px] h-[200px] lg:w-[304px] w-[320px]  md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover">
                  </a>
                  <div class="w-[320px] lg:w-[256px] md:w-full">
                    <TextDetail :salon="salon" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="mt-[96px] mb-[64px] md:mt-[32px]">
          <div class="flex flex-col gap-[32px]">
            <div class="text-[24px] font-[500] leading-[32px] text-[#101828]">全部活动</div>
            <div class="flex gap-[16px] md:justify-between sm:flex-col">
              <!-- search input -->
              <div class="">
                <el-input
                  class="!md:w-full !w-[320px]"
                  size="large"
                  v-model="query.keyword"
                  placeholder="搜索活动"
                  clearable
                  suffix-icon="el-icon-search"
                  @keyup.enter="fetchItems"
                >
                  <template #prefix>
                    <el-icon @click="fetchItems" class="el-input__icon"><search /></el-icon>
                  </template>
                </el-input>
              </div>
              <div class="">
                <el-select
                  v-model="query.city"
                  placeholder="选择城市"
                  class="!md:w-full !w-[320px]"
                  @change="fetchItems"
                >
                  <el-option
                    :label="'全部城市'"
                    value=""
                  />
                  <el-option
                    v-for="city in cities"
                    :key="city"
                    :label="city"
                    :value="city"
                  />
                </el-select>
              </div>
            </div>
            <div class="grid grid-cols-3 md:grid-cols-1 gap-x-[32px] gap-y-[46px]">
              <template :key="salon.id" v-for="salon in salons">
                <div class="flex flex-col gap-[20px]">
                  <a
                    :href="salon.link"
                    target="_blank"
                  >
                    <img :src="salon.cover" class="h-[200px] w-[320px] md:w-full md:h-[unset] flex-[1_0_0] rounded-[16px] object-cover">
                  </a>
                  <div class="w-[320px]">
                    <TextDetail :salon="salon" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="pt-[20px] border-t border-[#EAECF0] flex justify-center">
          <el-pagination
            background
            hide-on-single-page
            v-model:current-page="query.page"
            :default-current-page="1"
            :page-size="6"
            layout="prev, pager, next"
            :total="Number(total_count)"
            @current-page="fetchItems"
            @update:current-page="fetchItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TextDetail from "./TextDetail.vue";
  import csrfFetch from "../../../../packs/csrfFetch.js"

  export default {
    components: {
      TextDetail
    },
    props: {
    },
    data() {
      return {
        hot_salons: [{}],
        salons: [{}],
        cities: [],
        salon_tags: [],
        total_count: 0,
        query: {
          keyword: "",
          page: 1,
          city: "",
        },
      }
    },
    mounted() {
      this.fetchItems();
    },
    methods: {
      handleIndustryChange(industry) {
        if (this.query.industry == industry) {
          this.query.industry = "";
        } else {
          this.query.industry = industry;
        }
        this.fetchItems();
      },
      handleOrgTypeChange(org_type) {
        if (this.query.org_type == org_type) {
          this.query.org_type = "";
        } else {
          this.query.org_type = org_type;
        }
        this.fetchItems();
      },
      handleKeywordChange() {
        this.fetchItems();
      },
      fetchItems() {
        console.log("this.query", this.query);
        url = `/salons/index_json?keyword=${this.query.keyword}&city=${this.query.city}&page=${this.query.page}`
        csrfFetch(url, {
          method: "GET",
        }).then((res) => {
          return res.json();
        }).then((res) => {
          this.hot_salons = res.hot_salons;
          this.salons = res.salons;
          this.cities = res.cities;
          this.salon_tags = res.salon_tags;
          this.total_count = res.total_count;
        });
      }
    }
  }
</script>
<style scoped>
  :deep(.el-input__inner) {
    height: 44px;
  }
  :deep(.el-select__wrapper) {
    height: 46px;

  }
</style>