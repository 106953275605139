<template>
  <div class="flex items-center absolute top-0 right-0 md:relative md:pb-4 z-10 md:hidden">
    <div v-show="false" class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]" @click="dialogLibraryVisible = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M4.6665 3.5L1.1665 7L4.6665 10.5M9.33317 3.5L12.8332 7L9.33317 10.5" stroke="#606266" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="ml-2 text-[#606266] text-sm font-medium">Use in {{resource}} library</div>
    </div>
    <el-tooltip
      effect="dark"
      content="请不要使用迅雷下载，推荐使用BT下载器，如：Deluge、qBittorrent、Vuze等"
      placement="top"
    >
      <a
        class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]"
        @click="downloadPtLink"
        v-if="!!ptMetadata?.metadata?.data?.stuts == 1">
        <img class="w-[16px] h-[16px]" src="/images/xty_images/common/magnet.svg" alt="">
        <div class="ml-2 text-[#606266] text-sm font-medium">BT+ 下载</div>
      </a>
      <a
        class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]"
        @click="downloadBtLink"
        target="_blank"
        v-if="!!btLink">
        <img class="w-[16px] h-[16px]" src="/images/xty_images/common/magnet.svg" alt="">
        <div class="ml-2 text-[#606266] text-sm font-medium">BT 下载</div>
      </a>
    </el-tooltip>
    <el-tooltip
      v-if="!!ptMetadata?.metadata?.data?.stuts == 1 || !!btLink"
      effect="dark"
      content="请在【文件和版本】中检查是否存在权重文件，如不存在，请尝试BT/BT+下载方式"
      placement="top"
    >
      <div class="rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]" @click="cloneRepositoryVisible = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <rect opacity="0.12" x="1.3335" y="1.3335" width="13.3333" height="13.3333" rx="3.33333" fill="#2F384C"/>
          <path d="M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z" stroke="#2F384C" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="ml-2 text-[#606266] text-sm font-medium">Git 下载</div>
      </div>
    </el-tooltip>
    <el-dialog v-model="dialogLibraryVisible" title="Dataset library is unknown" class="md:!w-[80%]">
      <div class="text-[#a0a1a7] p-2"># Type of {{resource}}/library unknown.</div>
      <div class="text-[#a0a1a7] p-2"># Feel free to open a Pull request </div>
      <div class="text-[#a0a1a7] p-2"># for integration of the {{resource}} hub</div>
      <div class="text-[#a0a1a7] p-2"># into the corresponding library =)</div>
    </el-dialog>
    <el-dialog v-model="cloneRepositoryVisible" title="" class="md:!w-[80%]">
      <div class="rounded-t border-t border-x border-[#EBEEF5] mt-4">
        <div class="flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]">
          <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect opacity="0.12" x="1.3335" y="1.3335" width="13.3333" height="13.3333" rx="3.33333" fill="#2F384C"/>
            <path d="M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z" stroke="#2F384C" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          下载{{resourceMap[resource]}}
        </div>
      </div>
      <el-tabs v-model="activeCloneType" class="border border-[#EBEEF5] mb-8 clone-tabs">
        <el-tab-pane label="HTTPS" name="https">
          <div class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all">
            <div class="text-[#909399]"># 在下载前请确认您安装了git-lfs  (https://git-lfs.com)</div>
            <div>git lfs install</div>
            <div>git clone {{ httpCloneUrl }}</div>
            <div class="text-[#909399]"># 如果您不想下载LFS文件的内容，请在环境变量中添加</div>
            <div>GIT_LFS_SKIP_SMUDGE=1</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="SSH" name="ssh">
          <div class="flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all">
            <div class="text-[#909399]"># 在下载前请确认您安装了git-lfs  (https://git-lfs.com)</div>
            <div>git lfs install</div>
            <div>git clone {{ sshCloneUrl }}</div>
            <div class="text-[#909399]"># 如果您不想下载LFS文件的内容，请在环境变量中添加</div>
            <div>GIT_LFS_SKIP_SMUDGE=1</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import csrfFetch from "../../../../packs/csrfFetch"
import { onMounted } from "vue";

const props = defineProps({
  httpCloneUrl: String,
  sshCloneUrl: String,
  resource: String,
  btLink: String,
});

const activeCloneType = ref('https')
const dialogLibraryVisible = ref(false)
const cloneRepositoryVisible = ref(false)
const ptMetadata = ref({})

const resourceMap = {
  model: '模型',
  dataset: '数据集',
};

const downloadBtLink =() => {
  let repoType = ""
  if (props.resource == "model") {
    repoType = "models"
  }
  if (props.resource == "dataset") {
    repoType = "datasets"
  }
  const path = window.location.pathname.split('/').slice(2).join('/')
  const url = `/internal_api/${repoType}/${path}/incr_downloads`
  const option = {method: 'PUT'}
  csrfFetch(url, option)
  window.open(props.btLink, '_blank');
};

const downloadPtLink =() => {
  let repoType = ""
  if (props.resource == "model") {
    repoType = "models"
  }
  if (props.resource == "dataset") {
    repoType = "datasets"
  }
  const path = window.location.pathname.split('/').slice(2).join('/')

  const incr_downloads_url = `/internal_api/${repoType}/${path}/incr_downloads`
  const option = {method: 'PUT'}
  csrfFetch(incr_downloads_url, option)

  const download_url = `/${repoType}/${path}/pt_download`
  window.open(download_url);
};

const fetchMetadata = async () => {
  let repoType = ""
  if (props.resource == "model") {
    repoType = "models"
  }
  if (props.resource == "dataset") {
    repoType = "datasets"
  }
  const path = window.location.pathname.split('/').slice(2).join('/')
  const url = `/internal_api/${repoType}/${path}/pt_metadata`
  const option = {method: 'GET'}
  await csrfFetch(url, option)
    .then(response => response.json())
    .then(data => {
      ptMetadata.value = data
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

// check pt link is present?
onMounted(() => {
  fetchMetadata()
})

</script>
