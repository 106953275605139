export const solution = {
  "banner": {
    "headline": "大模型重新定义软件开发和工程交付新范式",
    "solution": "CAICT 解决方案"
  },
  "knowledge": {
    "headline": "CAICT EKB AI 智能企业知识库",
    "desc": "EKB AI 将文档结构化并转换为数字资产，实现信息数字化存储和管理，夯实企业资产的数字化转型基础。",
    "demoVideo": "产品演示",
    "demo": "立即体验",
    "remarks": "暂不开放注册，请联系 {contact} 获取试用权限",
  },
  "starAIO": {
    "headline": "CAICT StarAIO 大模型一体机",
    "desc": "为开发者们提供了优秀的开源工具和平台，助力企业定制私有化大模型。",
    "part1": "持续迭代、随意更换的底座大模型",
    "part1-": "大模型可以不断更新和升级，以适应不同的应用场景和需求。",
    "part2": "开源框架、 便捷的大模型应用开发",
    "part2-": "采用开源框架，方便开发人员构建和定制大模型的应用程序。",
    "part3": "本地部署，安全合规",
    "part3-": "自有数据中心内部署大模型服务，以保障数据隐私及安全合规性。",
    "part4": "容器部署，无缝迁移",
    "part4-": "支持开发符合标准API接口规范的插件，扩展产品功能和应用场景。",
    "subpart1": "解锁无限创意，实现个性化 AI 体验",
    "subpart1-": "大模型一体机提供了持续迭代和随意更换的大模型服务，让您尽情释放创造力，定制属于自己的独特AI体验。无论是聊天、中文写作还是其他应用场景，我们的产品将为您打开无限可能的大门。",
    "subpart2": "数据安全保障，掌握敏感信息的安心选择",
    "subpart2-": "在崇尚数据安全的时代，我们深知您对于隐私保护的重视。大模型一体机支持本地化部署，确保您的大模型和数据始终在安全可控的环境下运行。选择我们的产品，让您的敏感信息得到最可靠的保护。",
    "subpart3": "轻松部署，高效迁移至云服务或其他硬件",
    "subpart3-": "借助容器化部署技术，大模型一体机可以轻松迁移到其他硬件设备或云服务平台。无论是需要弹性扩展能力还是使用云计算资源，我们为您提供简单快捷的解决方案，确保您的大模型始终处于最佳状态。",
    "subpart4": "开源框架，助力开发者构建自定义应用程序",
    "subpart4-": "大模型一体机基于开源框架，为开发者们提供了优秀的工具和平台，助力他们构建和定制化大模型的应用程序。无论您是寻找创新突破点还是希望提供个性化服务，我们都会与您携手共进，实现共同的价值目标。",
    "subpart5": "满足国产化需求，引领行业数字化转型",
    "subpart5-": "大模型一体机兼容国产化硬件，适配信创体系，为金融、政府、运营商等传统企业提供国产化的Al解决方案。通过与信创生态的全面适配，我们致力于推动行业的数字化转型，让国产CPU的算力与大模型的兼容性完美结合。"
  },
  "AIImg": {
    "headline": "CAICT Imagen AI 智能图像助手",
    "desc": "CAICT Imagen AI 智能图像助手是一款在 Stable Diffusion 2.1开源模型基础上研发的，致力于增强人类智能的视觉工具集，旨在为创作者提供更好用的工具，催化灵感的萌芽，并实现创意和生产力的指数级放大。CAICT Imagen AI 智能图像助手适应专业设计工作流程，提供多种功能。",
    "demo": "快速体验",
    "part1": "文生图绘图模式",
    "part1-": "无需专业绘画技能，用户只需简单描述即可在几秒内生成高质量的画作。",
    "part2": "图生图绘图模式",
    "part2-": "选择多种绘画风格和艺术家参考，通过借鉴不同风格的作品进行创作，提升设计质量和出图效率。",
    "part3": "基于文字描述生成图片",
    "part3-": "根据用户提供的文字描述生成对应的图片，拓展创作可能性。",
    "part4": "基于文字引导修改图片",
    "part4-": "用户可以使用文字引导来修改已有的图片，使其符合自己的创作需求。",
    "part5": "艺术家作品风格迁移",
    "part5-": "通过学习不同艺术家或风格的作品并将其融合，生成具有特定艺术风格的图像，帮助创作者探索全新表达方式。",
    "part6": "图片超分辨率",
    "part6-": "CAICT Image AI 图像助手提高画面分辨率，获得更清晰、细节更丰富的图像"
  },
  "programming": {
    "headline": "CAICT StarCode AI 智能编程助手",
    "desc": "StarCode AI 是一款全能的智能编程助手，通过基于大模型技术的创新功能，帮助开发者提高工作效率。它融合了其它辅助编程助手的优点，并为开发者提供更多强大的功能。",
    "demo": "快速体验",
    "part1": "代码自动生成和补全",
    "part1-": "支持根据自然语言注释自动生成代码，以及根据已有的代码生成后续代码，使得编码过程更加快速和准确。CAICT StarCode AI 还进一步提供了针对当前行或生成后续若干行代码的补全功能，帮助开发者轻松完成编程任务。",
    "part2": "自动生成单元测试",
    "part2-": "支持根据常见单元测试框架生成单元测试以及单元测试相关代码解释。 StarCode AI 还进一步提供了针对当前行或生成后续若干行代码的补全功能，帮助开发者轻松完成编程任务。",
    "part3": "研发领域知识问答",
    "part3-": "支持智能问答功能，开发者可以直接向助手提出技术问题，无需离开集成开发环境 (IDE) 去搜索引擎寻找答案。这种无缝集成的体验让开发者能够更专注地解决问题，提高工作效率。 StarCode AI 还进一步提供了针对当前行或生成后续若干行代码的补全功能，帮助开发者轻松完成编程任务。",
    "part4": "代码翻译",
    "part4-": "不仅支持多种主流编程语言之间的互译，还借鉴了 CoPilot 的功能，基于人工智能大模型实现了更准确、高质量的代码翻译。 StarCode AI 还进一步提供了针对当前行或生成后续若干行代码的补全功能，帮助开发者轻松完成编程任务。"
  },
}
