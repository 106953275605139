import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center flex-wrap gap-2 md:px-5" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm text-[#909399]"
}
const _hoisted_3 = {
  key: 2,
  class: "flex items-center text-sm text-[#909399]"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 4,
  class: "text-sm text-[#909399]"
}
const _hoisted_6 = { class: "text-sm text-[#303133] px-[8px] py-[4px] rounded flex items-center border gap-1" }
const _hoisted_7 = {
  key: 6,
  class: "text-sm text-[#909399]"
}
const _hoisted_8 = { class: "text-sm text-[#303133] px-[8px] py-[4px] rounded flex items-center border gap-1" }
const _hoisted_9 = {
  key: 8,
  class: "text-sm text-[#909399]"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "text-[#344054]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Task "),
    ($setup.taskTags.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('all.tasks')) + ": ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theTaskTags.theTags, (tag) => {
      return (_openBlock(), _createBlock($setup["TagItem"], {
        tag: tag,
        onHandleTagClick: $setup.searchByTag
      }, null, 8 /* PROPS */, ["tag"]))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($setup.theTaskTags.moreTags)
      ? (_openBlock(), _createBlock($setup["MoreTags"], {
          key: 1,
          num: $setup.taskTags.length - 3,
          target: "task",
          onViewMoreTargets: $setup.viewMoreTargets
        }, null, 8 /* PROPS */, ["num"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" Framework "),
    _createCommentVNode(" <div\r\n      v-if=\"frameworkTags.length\"\r\n      class=\"text-sm text-[#909399]\"\r\n    >\r\n      {{ $t('all.framework') }}:\r\n    </div>\r\n    <a\r\n      v-for=\"tag in theFrameworkTags.theTags\"\r\n      :href=\"`/${prefix}?tag=${tag.name}&tag_type=Framework`\"\r\n    >\r\n      <PyTorch v-if=\"tag.name.toLowerCase() === 'pytorch'\" />\r\n      <TensorFlow v-if=\"tag.name.toLowerCase() === 'tensorflow'\" />\r\n      <Safetensors v-if=\"tag.name.toLowerCase() === 'safetensors'\" />\r\n      <ONNX v-if=\"tag.name.toLowerCase() === 'onnx'\" />\r\n      <JAX v-if=\"tag.name.toLowerCase() === 'jax'\" />\r\n      <PaddlePaddle v-if=\"tag.name.toLowerCase() === 'paddlepaddle'\" />\r\n      <GGUF v-if=\"tag.name.toLowerCase() === 'gguf'\" />\r\n      <Joblib v-if=\"tag.name.toLowerCase() === 'joblib'\" />\r\n    </a>\r\n    <MoreTags\r\n      v-if=\"theFrameworkTags.moreTags\"\r\n      :num=\"frameworkTags.length - 3\"\r\n      target=\"framework\"\r\n      @view-more-targets=\"viewMoreTargets\"\r\n    /> "),
    _createCommentVNode(" Language "),
    ($setup.languageTags.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('all.languages')) + ": ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theLanguageTags.theTags, (tag) => {
      return (_openBlock(), _createElementBlock("a", {
        href: `/${$props.prefix}?tag=${tag.label}&tag_type=Language`,
        style: _normalizeStyle(`color: ${tag.color}`),
        class: "text-sm text-[#087443] px-[8px] py-[4px] rounded cursor-pointer flex items-center gap-1 bg-[#F6FEF9]"
      }, [
        _createVNode(_component_SvgIcon, { name: "language_tag" }),
        _createTextVNode(" " + _toDisplayString(this.$i18n.locale === 'zh' ? tag.show_name || tag.name : tag.name), 1 /* TEXT */)
      ], 12 /* STYLE, PROPS */, _hoisted_4))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($setup.theLanguageTags.moreTags)
      ? (_openBlock(), _createBlock($setup["MoreTags"], {
          key: 3,
          num: $setup.languageTags.length - 3,
          target: "language",
          onViewMoreTargets: $setup.viewMoreTargets
        }, null, 8 /* PROPS */, ["num"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" industryTags "),
    ($setup.industryTags.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('all.industry')) + ": ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theIndustryTags.theTags, (tag) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(this.$i18n.locale === 'zh' ? tag.show_name || tag.name : tag.name), 1 /* TEXT */))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($setup.theIndustryTags.moreTags)
      ? (_openBlock(), _createBlock($setup["MoreTags"], {
          key: 5,
          num: $setup.industryTags.length - 3,
          target: "industry",
          onViewMoreTargets: $setup.viewMoreTargets
        }, null, 8 /* PROPS */, ["num"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" Other "),
    ($setup.otherTags.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('all.others')) + ": ", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theOtherTags.theTags, (tag) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(tag.name), 1 /* TEXT */))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($setup.theOtherTags.moreTags)
      ? (_openBlock(), _createBlock($setup["MoreTags"], {
          key: 7,
          num: $setup.otherTags.length - 3,
          target: "other",
          onViewMoreTargets: $setup.viewMoreTargets
        }, null, 8 /* PROPS */, ["num"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" License "),
    ($setup.licenseTags.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, " License: "))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theLicenseTags.theTags, (tag) => {
      return (_openBlock(), _createElementBlock("a", {
        href: `/${$props.prefix}?tag=${tag.name}&tag_type=License`,
        class: "text-[14px] text-[#909399] px-[8px] py-[3px] rounded-[16px] cursor-pointer flex items-center border border-[#D0D5DD] gap-1"
      }, [
        _createVNode(_component_SvgIcon, { name: "repo_header_license_icon" }),
        _cache[0] || (_cache[0] = _createTextVNode(" License: ")),
        _createElementVNode("p", _hoisted_11, _toDisplayString(tag.name), 1 /* TEXT */)
      ], 8 /* PROPS */, _hoisted_10))
    }), 256 /* UNKEYED_FRAGMENT */)),
    ($setup.theLicenseTags.moreTags)
      ? (_openBlock(), _createBlock($setup["MoreTags"], {
          key: 9,
          num: $setup.licenseTags.length - 3,
          target: "license",
          onViewMoreTargets: $setup.viewMoreTargets
        }, null, 8 /* PROPS */, ["num"]))
      : _createCommentVNode("v-if", true)
  ]))
}