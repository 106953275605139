import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "min-h-[300px] py-8 md:px-5" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center flex-wrap gap-4" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex items-center text-sm text-[#606266]" }
const _hoisted_7 = { class: "flex items-center py-[1px] md:hidden" }
const _hoisted_8 = {
  href: "#",
  class: "ml-4 items-center px-4 py-[5px] border border-[#DCDFE6] rounded-[100px] hidden"
}
const _hoisted_9 = {
  key: 0,
  class: "flex items-center justify-between mt-4 px-3 py-2 border border-[#DCDFE6] bg-[#F5F7FA] rounded-t-[4px]"
}
const _hoisted_10 = { class: "flex items-center text-sm overflow-hidden mr-2" }
const _hoisted_11 = { class: "flex items-center mr-2" }
const _hoisted_12 = {
  href: "#",
  class: "text-[#303133] hover:underline"
}
const _hoisted_13 = {
  href: "#",
  class: "mr-2 text-[#606266] truncate hover:underline"
}
const _hoisted_14 = {
  href: "#",
  class: "rounded border border-[#DCDFE6] text-xs text-[#606266] px-3 py-[2px] hover:underline"
}
const _hoisted_15 = { class: "text-[#909399] text-sm cursor-pointer flex-shrink-0 md:hidden" }
const _hoisted_16 = {
  key: 1,
  class: "border border-t-0 border-[#DCDFE6] rounded-b"
}
const _hoisted_17 = { class: "text-xs text-[#303133] px-4 py-2 flex items-center justify-between border-b border-[#DCDFE6]" }
const _hoisted_18 = { class: "flex items-center gap-4" }
const _hoisted_19 = { class: "bg-[#F0F2F5] px-3 py-[2px] flex items-center justify-center rounded" }
const _hoisted_20 = {
  key: 0,
  class: "flex items-center gap-1"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "p-4" }
const _hoisted_23 = {
  key: 2,
  class: "border border-t-0 border-[#DCDFE6] rounded-b"
}
const _hoisted_24 = { class: "text-xs text-[#303133] px-4 py-2 flex items-center justify-end border-b border-[#DCDFE6]" }
const _hoisted_25 = { class: "flex items-center justify-center px-4 py-10 border-b border-[#DCDFE6] font-medium text-[#667085] text-[14px] whitespace-pre-wrap" }
const _hoisted_26 = ["href"]
const _hoisted_27 = { class: "p-4 flex flex-col gap-[4px] text-sm font-medium" }
const _hoisted_28 = { class: "mb-[4px]" }
const _hoisted_29 = { class: "flex" }
const _hoisted_30 = { class: "text-[12px] font-light" }
const _hoisted_31 = { class: "flex" }
const _hoisted_32 = { class: "" }
const _hoisted_33 = { class: "text-[12px] font-light" }
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = { class: "text-[12px] font-light" }
const _hoisted_36 = { class: "flex items-center mt-[4px] mr-[4px]" }
const _hoisted_37 = ["href"]
const _hoisted_38 = { class: "flex items-center mt-[4px] text-[#667085] text-[12px] font-light" }
const _hoisted_39 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["BranchDropdown"], {
          onChangeBranch: $setup.changeBranch,
          "current-branch": $props.currentBranch,
          branches: $props.branches
        }, null, 8 /* PROPS */, ["current-branch", "branches"]),
        _createVNode(_component_el_breadcrumb, { separator: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_breadcrumb_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: `/${$setup.prefixPath}/${$props.namespacePath}/files/${$props.currentBranch}`
                }, _toDisplayString($props.namespacePath.split('/')[1]), 9 /* TEXT, PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumb, (path, index) => {
              return (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: path }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: `/${$setup.prefixPath}/${$props.namespacePath}/${
                index === $setup.breadcrumb.length - 1 ? 'blob' : 'files'
              }/${$props.currentBranch}${path}`
                  }, _toDisplayString($setup.extractNameFromPath(path)), 9 /* TEXT, PROPS */, _hoisted_5)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_avatar, {
            size: 24,
            class: "mr-1",
            src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          }),
          _cache[0] || (_cache[0] = _createTextVNode(" 1 contributors "))
        ]),
        _createCommentVNode(" Todo 暂时先隐藏 "),
        _createElementVNode("a", _hoisted_8, [
          _createVNode(_component_SvgIcon, {
            name: "commits",
            class: "mr-2"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.commits')), 1 /* TEXT */)
        ])
      ])
    ]),
    ($setup.lastCommit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_avatar, {
                size: 24,
                class: "mr-2",
                src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              }),
              _createElementVNode("a", _hoisted_12, _toDisplayString($setup.lastCommit.author_name), 1 /* TEXT */)
            ]),
            _createElementVNode("a", _hoisted_13, _toDisplayString($setup.lastCommit.message), 1 /* TEXT */),
            _createElementVNode("a", _hoisted_14, _toDisplayString($setup.lastCommit.id?.substring(0, 7)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_el_popover, {
              width: "158",
              placement: "top",
              effect: "dark",
              trigger: "hover",
              content: $setup.lastCommit.committer_date
            }, {
              reference: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.format($setup.lastCommit.committer_date, 'zh_CN')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["content"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.lfs || $setup.isImage())
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('shared.preview')), 1 /* TEXT */),
              ($props.canWrite && !$setup.isImage())
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_component_SvgIcon, { name: "edit" }),
                    _createElementVNode("a", {
                      href: `/${$setup.prefixPath}/${$props.namespacePath}/edit/main/${$props.currentPath}`
                    }, _toDisplayString(_ctx.$t('shared.edit')), 9 /* TEXT, PROPS */, _hoisted_21)
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", null, _toDisplayString($setup.formatBytes($setup.size)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_22, [
            (['jpg', 'png', 'jpeg', 'gif', 'svg', 'md'].includes($setup.fileType))
              ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
                  key: 0,
                  content: $setup.content
                }, null, 8 /* PROPS */, ["content"]))
              : (_openBlock(), _createBlock($setup["CodeViewer"], {
                  key: 1,
                  extension: $setup.fileType,
                  content: $setup.content
                }, null, 8 /* PROPS */, ["extension", "content"]))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", null, _toDisplayString($setup.formatBytes($setup.size)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('shared.lfs1')) + " ", 1 /* TEXT */),
              _createElementVNode("a", {
                href: $setup.version,
                target: "_blank",
                class: "underline"
              }, _toDisplayString(_ctx.$t('shared.lfs2')), 9 /* TEXT, PROPS */, _hoisted_26),
              _createTextVNode(_toDisplayString(_ctx.$t('shared.lfs3')) + " ", 1 /* TEXT */),
              _createElementVNode("span", {
                class: "underline cursor-pointer",
                onClick: $setup.lfsDownload
              }, _toDisplayString(_ctx.$t('shared.lfs4')), 1 /* TEXT */),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('shared.lfs5')), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t('shared.lfs6')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_29, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "" }, "SHA256: ", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_30, _toDisplayString($setup.sha), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t('shared.lfs7')) + ": ", 1 /* TEXT */),
              _createElementVNode("p", _hoisted_33, _toDisplayString($setup.formatBytes($setup.lfsPointerSize)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('shared.lfs8')) + ": ", 1 /* TEXT */),
              _createElementVNode("p", _hoisted_35, _toDisplayString($setup.formatBytes($setup.size)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_36, [
              _createVNode(_component_SvgIcon, {
                name: "file",
                class: "mr-1.5"
              }),
              _createElementVNode("a", {
                href: `/${$setup.prefixPath}/${$props.namespacePath}/resolve/${$props.currentBranch}/${$props.currentPath}`,
                target: "_blank",
                class: "underline text-[12px]"
              }, _toDisplayString(_ctx.$t('shared.lfs9')), 9 /* TEXT, PROPS */, _hoisted_37)
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('shared.lfs10')), 1 /* TEXT */),
              _createElementVNode("a", {
                href: $setup.version,
                target: "_blank",
                class: "underline"
              }, _toDisplayString(_ctx.$t('shared.lfs11')), 9 /* TEXT, PROPS */, _hoisted_39),
              _cache[2] || (_cache[2] = _createTextVNode(". "))
            ])
          ])
        ]))
  ]))
}