export const all = {
  defaultText: "```\nREADME file is empty, please download the file and add description content.\n```",
  tip: "tip",
  summary: "Summary",
  files: "Files",
  community: "Community",
  logs: "logs",
  settings: "Settings",
  tasks: "Tasks",
  framework: "Framework",
  industry:"Industry",
  others: "Others",
  contributors: "Contributors",
  historyCommits: "History commits",
  addFile: "Add file",
  fileName: "File name",
  filterTags: "Filter tags",
  provideMoreDesc: "Provide more description",
  submitNewFile: "Submit new file",
  submitChange: "Submit changes",
  createNewFile: "Create new file",
  createFile: "Create File",
  uploadFile: "Upload file",
  loadError: "Load Error",
  notSupportPreview: "Preview is not supported at the moment, please download via git clone",
  syncNotSupportPreview: "Preview is not supported at the moment",
  changeVis: "Change visibility to ",
  enterPls: "Please type",
  sureDel: "to confirm deletion",
  canNot: "Cannot",
  role: "Role",
  userName: "Username",
  updateUserName: "Update Username",
  nickName: "Nickname",
  homepage:"homepage",
  bio:"bio",
  phone: "Phone Number",
  email: "Email",
  confirm: "Confirm",
  cancel: "Cancel",
  add: "Add",
  update: "Update",
  changeSuccess: "Switch successful",
  changeCancel: "Operation canceled",
  addSuccess: "Added successfully",
  delSuccess: "Del successfully",
  updateSuccess: "Update successfully",
  lastTime: "Last Updated",
  downloadCount: "Download",
  public: "Public",
  private: "Private",
  recentlyUpdate: "Recently Updated",
  trending: "Trending",
  mostDownload: "Most Downloads",
  mostFavorite: "Most Favorites",
  noData: "No Data",
  save: "Save",
  remove: "Remove",
  saveSetting: "Save Setting",
  toBeUploaded: "to be uploaded",
  upLoadSuccess: "Upload successful",
  upLoadError: "Upload error",
  clickUpload: "Click Upload",
  uploadAvatar: "Upload Avatar",
  uploadPic1Mb: "Please upload image files not exceeding 1MB.",
  select: "Select",
  selectProject: "Please select Project",
  selectFilePls: "Please select a file",
  fileTooLarge: "File size is too large",
  inputPls: "Please enter",
  inputNickNamePlc: "Enter nickname",
  inputDescPlc: "Enter description",
  lfsTips: "Before downloading, please make sure you have installed git-lfs (https://git-lfs.com).",
  lfsTips2: "If you do not want to download the content of LFS files, please add it to the environment variables.",
  pleaseSelect: "Please select {value}",
  pleaseInput: "Please input {value}",
  languages: "Languages",
  previous:"Previous",
  next:"Next",
  deleteSuccess: "Successfully delete",
  commits: "commit history",
  fetchError: "Failed to fetch data",
  deploy: "Deploy",
  more:"More",
  baseModel:"Base Model",
  moreDesc: "Expand more",
  sdkTips: "Please register first and then obtain the Token from CAICT's account page",
  free: "Free",
  minutePay: "Pay-as-you-go",
  yearMonthPay: "Yearly Subscription",
  hourUnit:'¥/hour',
  monthUnit:'¥/month',
  yearUnit:'¥/year'
}