<template>
  <div class="flex border border-[#E4E7ED] rounded-[4px] cursor-pointer">
    <div class="px-[12px] py-[12px] bg-[#F0F3FF]"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><path opacity="0.12" d="M13.5 19.8C13.5 18.1198 13.5 17.2798 13.827 16.638C14.1146 16.0735 14.5735 15.6146 15.138 15.327C15.7798 15 16.6198 15 18.3 15H28.2C29.8802 15 30.7202 15 31.362 15.327C31.9265 15.6146 32.3854 16.0735 32.673 16.638C33 17.2798 33 18.1198 33 19.8V30.5455C33 32.0355 31.2154 32.8004 30.1364 31.7727C29.0859 30.7723 27.6909 30.2143 26.2403 30.2143H18.3C16.6198 30.2143 15.7798 30.2143 15.138 29.8873C14.5735 29.5997 14.1146 29.1407 13.827 28.5763C13.5 27.9345 13.5 27.0944 13.5 25.4143V19.8Z" fill="#4D6AD6"/><path d="M13.5 21H10.875C10.5043 21 10.3189 21 10.1384 21.0146C9.4133 21.073 8.71316 21.3064 8.09799 21.6947C7.94487 21.7914 7.79658 21.9026 7.5 22.125V22.125C6.73286 22.7004 6.34929 22.988 6.05674 23.1004C4.82225 23.5746 3.44701 22.8869 3.08564 21.6148C3 21.3134 3 20.8339 3 19.875V10.2C3 7.67976 3 6.41965 3.49047 5.45704C3.9219 4.61031 4.61031 3.9219 5.45704 3.49047C6.41965 3 7.67976 3 10.2 3H19.8C22.3202 3 23.5804 3 24.543 3.49047C25.3897 3.9219 26.0781 4.61031 26.5095 5.45704C27 6.41965 27 7.67976 27 10.2V10.5V11.25M30.1364 31.7727V31.7727C31.2154 32.8004 33 32.0355 33 30.5455V19.8C33 18.1198 33 17.2798 32.673 16.638C32.3854 16.0735 31.9265 15.6146 31.362 15.327C30.7202 15 29.8802 15 28.2 15H18.3C16.6198 15 15.7798 15 15.138 15.327C14.5735 15.6146 14.1146 16.0735 13.827 16.638C13.5 17.2798 13.5 18.1198 13.5 19.8V25.4143C13.5 27.0944 13.5 27.9345 13.827 28.5763C14.1146 29.1407 14.5735 29.5997 15.138 29.8873C15.7798 30.2143 16.6198 30.2143 18.3 30.2143H26.2403C27.6909 30.2143 29.0859 30.7723 30.1364 31.7727Z" stroke="#4D6AD6" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
    <div class="px-[16px] py-[5px] w-full">
      <div class="flex justify-between items-center text-[#303133] text-[16px] leading-[24px] font-semibold">
        <div class="hover:underline">{{title}}</div>
        <div class="flex items-center gap-[4px] text-[#606266] text-[14px] leading-[22px] font-normal" v-show="commentNum >=0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M1.33325 6.66634C1.33325 4.7995 1.33325 3.86608 1.69656 3.15304C2.01614 2.52583 2.52608 2.0159 3.15328 1.69632C3.86632 1.33301 4.79974 1.33301 6.66659 1.33301H9.33325C11.2001 1.33301 12.1335 1.33301 12.8466 1.69632C13.4738 2.0159 13.9837 2.52583 14.3033 3.15304C14.6666 3.86608 14.6666 4.7995 14.6666 6.66634V12.7802C14.6666 13.4643 14.6666 13.8063 14.5581 14.0198C14.376 14.3781 13.9972 14.5926 13.5964 14.5643C13.3575 14.5475 13.0642 14.3716 12.4776 14.0196V14.0196C12.1277 13.8097 11.9528 13.7047 11.7694 13.6228C11.4648 13.4867 11.1414 13.3972 10.8102 13.3571C10.6108 13.333 10.4068 13.333 9.99876 13.333H6.66659C4.79974 13.333 3.86632 13.333 3.15328 12.9697C2.52608 12.6501 2.01614 12.1402 1.69656 11.513C1.33325 10.7999 1.33325 9.86652 1.33325 7.99967V6.66634Z" stroke="#606266" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{commentNum}}
        </div>
      </div>
      <div class="flex gap-[8px] text-[#606266] text-[14px] leading-[22px]">
        <div>#{{num}}</div>
        <div>{{time}}</div>
        <div>by {{user}}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { format } from 'timeago.js';
export default {
  props: {
    num:String,
    title:String,
    time:String,
    user:String,
    commentNum:String
  },
  components: {},
  data() {
    return {
      // theTime:format(this.time,'zh_CN')
    };
  },
  mounted() {},
  methods: {},
};
</script>
