import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M12.5 15L7.5 10L12.5 5",
      stroke: "#475467",
      "stroke-width": "1.66667",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1 /* HOISTED */)
  ])))
}