import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "text-[#FF5005] cursor-pointer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", null, [
      _cache[0] || (_cache[0] = _createTextVNode("由 ")),
      ($setup.ownerUrl)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: $setup.ownerUrl,
            class: "text-[#FF5005] cursor-pointer"
          }, _toDisplayString($setup.ownerName), 9 /* TEXT, PROPS */, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.ownerName), 1 /* TEXT */)),
      _cache[1] || (_cache[1] = _createTextVNode(" 提供 "))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("span", null, [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1",
        height: "8",
        viewBox: "0 0 1 8",
        fill: "none"
      }, [
        _createElementVNode("path", {
          d: "M0.5 0V8",
          stroke: "#DCDFE6"
        })
      ])
    ], -1 /* HOISTED */)),
    _createElementVNode("span", null, "更新时间：" + _toDisplayString($props.repo.updated_at && $props.repo.updated_at.substring(0, 10)), 1 /* TEXT */),
    _cache[3] || (_cache[3] = _createElementVNode("span", null, [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1",
        height: "8",
        viewBox: "0 0 1 8",
        fill: "none"
      }, [
        _createElementVNode("path", {
          d: "M0.5 0V8",
          stroke: "#DCDFE6"
        })
      ])
    ], -1 /* HOISTED */)),
    _createElementVNode("span", null, "下载量：" + _toDisplayString($props.repo.downloads), 1 /* TEXT */)
  ], 64 /* STABLE_FRAGMENT */))
}