import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-[30px]" }
const _hoisted_2 = { class: "text-[#606266]" }
const _hoisted_3 = { class: "my-[30px]" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "my-[30px]" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "my-[30px]" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "my-[30px]" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "my-[30px]" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "my-[30px]" }
const _hoisted_14 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "Application ENV", -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_2, _toDisplayString($setup.systemConfigs.application_env), 1 /* TEXT */)
    ]),
    _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" feature flags "),
    _createElementVNode("div", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "Feature Flags", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "featureFlagsRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.feature_flags),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_4)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateFeatureFlags
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" general configs "),
    _createElementVNode("div", _hoisted_5, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "General Configs", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "generalConfigsRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.general_configs),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_6)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateGeneralConfigs
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" S3 configs "),
    _createElementVNode("div", _hoisted_7, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "S3 Configs", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "s3ConfigsRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.s3_configs),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_8)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateS3Configs
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" Oidc configs "),
    _createElementVNode("div", _hoisted_9, [
      _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "OIDC Configs", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "oidcConfigsRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.oidc_configs),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_10)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateOidcConfigs
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" Starhub configs "),
    _createElementVNode("div", _hoisted_11, [
      _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "Starhub Configs", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "starhubConfigsRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.starhub_configs),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_12)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateStarhubConfigs
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createCommentVNode(" License info "),
    _createElementVNode("div", _hoisted_13, [
      _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "License info", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createElementVNode("textarea", {
            ref: "licenseInfoRef",
            class: "system-config-obj-box",
            value: $setup.stringifyObject($setup.systemConfigs.license_info),
            rows: "5"
          }, null, 8 /* PROPS */, _hoisted_14)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: $setup.updateLicenseInfo
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ], 64 /* STABLE_FRAGMENT */))
}