import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["FileList"], {
    branches: $props.branches,
    "current-branch": $props.currentBranch,
    "current-path": $props.currentPath,
    "namespace-path": $props.namespacePath,
    "can-write": $props.canWrite,
    onChangeBranch: $setup.changeBranch
  }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write"]))
}