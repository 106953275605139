<template>
  <div>
    <button 
      v-show="!isOpen"
      @click="openAnnouncement"
      class="fixed bottom-6 right-6 z-50 flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 hover:scale-105 animate-bounce-gentle group"
    >
      <svg class="w-5 h-5 animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
      </svg>
      系统公告
      <span class="absolute -top-10 right-0 bg-gray-800 text-white text-sm px-3 py-1 rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        遇到页面问题？点击这里查看解决方案
      </span>
    </button>

    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-200 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
        <!-- 背景遮罩 -->
        <transition
          enter-active-class="transition-opacity duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div 
            class="absolute inset-0 bg-black bg-opacity-50"
            @click="closeAnnouncement"
          ></div>
        </transition>

        <div class="relative bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <div class="text-xl font-medium mb-4">系统公告</div>
          <div class="text-gray-600">
            <p class="mb-4">尊敬的用户，欢迎来到鲸智社区。如遇到页面无法正常显示，请尝试清除本站Cookie：</p>
            
            <div class="bg-gray-50 p-4 rounded-lg space-y-4">
              <div class="flex items-start gap-2">
                <div class="bg-blue-100 rounded-full p-2 flex-shrink-0">
                  <span class="text-blue-600 font-bold">1</span>
                </div>
                <div>
                  按键盘快捷键：
                  <div class="mt-1 p-2 bg-white rounded border border-gray-200">
                    <span class="font-medium">Windows:</span> Ctrl + Shift + Delete<br/>
                    <span class="font-medium">Mac:</span> Command + Shift + Delete
                  </div>
                </div>
              </div>

              <div class="flex items-center gap-2">
                <div class="bg-blue-100 rounded-full p-2 flex-shrink-0">
                  <span class="text-blue-600 font-bold">2</span>
                </div>
                <div>
                  在弹出的清除浏览数据窗口中：
                  <ul class="mt-1 ml-4 list-disc text-gray-500">
                    <li>时间范围选择"过去1小时"</li>
                    <li>只勾选"Cookie及其他网站数据"</li>
                  </ul>
                </div>
              </div>

              <div class="flex items-center gap-2">
                <div class="bg-blue-100 rounded-full p-2 flex-shrink-0">
                  <span class="text-blue-600 font-bold">3</span>
                </div>
                <div>
                  点击"清除数据"按钮
                </div>
              </div>

              <div class="flex items-center gap-2">
                <div class="bg-blue-100 rounded-full p-2 flex-shrink-0">
                  <span class="text-blue-600 font-bold">4</span>
                </div>
                <div>
                  刷新页面即可
                </div>
              </div>
            </div>
          </div>
          
          <div class="flex justify-end mt-6">
            <button
              @click="closeAnnouncement"
              class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              我知道了
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openAnnouncement() {
      this.isOpen = true
    },
    closeAnnouncement() {
      this.isOpen = false
    }
  }
}
</script>

<style>
.animate-bounce-gentle {
  animation: bounce-gentle 2s infinite;
}

@keyframes bounce-gentle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}
</style>
