import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex gap-[32px] flex-col pt-[60px]" }
const _hoisted_2 = { class: "flex md:flex-col gap-[32px] px-[64px] rounded-[16px] justify-between" }
const _hoisted_3 = { class: "text-[36px] font-[400] text-center" }
const _hoisted_4 = { class: "text-[14px] font-[400] text-center" }
const _hoisted_5 = { class: "flex md:flex-col gap-[32px] px-[64px] rounded-[16px] justify-between" }
const _hoisted_6 = { class: "text-[36px] font-[400] text-center" }
const _hoisted_7 = { class: "text-[14px] font-[400] text-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[28px] font-[600] leading-[28px] text-center" }, " 平台最新数据 ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tech_stats.slice(0, 4), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-[248px] pb-[27px] border-b border-[#7caef8] md:w-full flex flex-col gap-[4px]",
            key: item.name
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.value), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.label), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tech_stats.slice(-3), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-[340px] pb-[27px] border-b border-[#7caef8] md:w-full flex flex-col gap-[4px]",
            key: item.name
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.value), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.label), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}