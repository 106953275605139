import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "m-auto w-[583px] md:w-full md:px-[20px]" }
const _hoisted_3 = { class: "py-[96px] text-center" }
const _hoisted_4 = { class: "el-upload__text py-[16px] px-[24px]" }
const _hoisted_5 = { class: "w-full flex gap-[4px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_CKEditor5 = _resolveComponent("CKEditor5")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-[20px] font-[500] leading-[30px]" }, " 投递方案 ", -1 /* HOISTED */)),
        ($data.dataForm.issue_id)
          ? (_openBlock(), _createBlock(_component_el_form, {
              key: 0,
              ref: "dataForm",
              model: $data.dataForm,
              rules: $data.rules,
              "label-position": "top",
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "联系人姓名",
                  prop: "contact_name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $data.dataForm.contact_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.contact_name) = $event)),
                      placeholder: "请输入联系人姓名"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" contact_phone "),
                _createVNode(_component_el_form_item, {
                  label: "联系电话",
                  prop: "contact_phone"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $data.dataForm.contact_phone,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.dataForm.contact_phone) = $event)),
                      placeholder: "请输入联系电话"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" title "),
                _createVNode(_component_el_form_item, {
                  label: "方案名称",
                  prop: "title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $data.dataForm.title,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.title) = $event)),
                      placeholder: "请输入方案名称"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" description "),
                _createVNode(_component_el_form_item, {
                  label: "方案描述",
                  prop: "description"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      rows: 5,
                      type: "textarea",
                      modelValue: $data.dataForm.description,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.dataForm.description) = $event)),
                      placeholder: "请输入方案描述"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" file_urls "),
                _createVNode(_component_el_form_item, {
                  label: "方案附件",
                  prop: "file_urls"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_upload, {
                      "file-list": $data.filesList,
                      "onUpdate:fileList": _cache[4] || (_cache[4] = $event => (($data.filesList) = $event)),
                      drag: "",
                      multiple: "",
                      limit: 5,
                      headers: { 'X-CSRF-TOKEN': $data.csrf_token },
                      data: {namespace: 'system-issue-plan'},
                      action: "/internal_api/upload",
                      "on-remove": _ctx.handleRemoveImage,
                      "on-success": _ctx.handleUploadSuccess,
                      style: {"width":"100%"}
                    }, {
                      tip: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("div", { class: "el-upload__tip" }, " 请上传 大小不超过5MB 格式为 doc/xls/pdf/docx/xlsx/rar/zip/png/jpg/jpeg 的文件或 图片最多上传5个 ", -1 /* HOISTED */)
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createElementVNode("svg", {
                              width: "20",
                              height: "18",
                              viewBox: "0 0 20 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }, [
                              _createElementVNode("path", {
                                d: "M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613",
                                stroke: "#475467",
                                "stroke-width": "1.66667",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              })
                            ], -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createElementVNode("div", _hoisted_4, [
                          _createTextVNode(_toDisplayString(_ctx.$t('shared.dragOr')) + " ", 1 /* TEXT */),
                          _createElementVNode("em", null, _toDisplayString(_ctx.$t('all.clickUpload')), 1 /* TEXT */),
                          _createTextVNode("（" + _toDisplayString(_ctx.$t('shared.onlyFiveFiles')) + "） ", 1 /* TEXT */)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["file-list", "headers", "on-remove", "on-success"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" content "),
                _createVNode(_component_el_form_item, {
                  label: "详细介绍",
                  prop: "content"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CKEditor5, {
                      content: $data.dataForm.content,
                      "onUpdate:content": _cache[5] || (_cache[5] = $event => (($data.dataForm.content) = $event))
                    }, null, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_el_button, {
                        class: "w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]",
                        onClick: $options.handleSubmit
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("提交")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model", "rules"]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}