import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[16px] flex-wrap mb-[8px] text-lg text-[#606266] font-semibold md:px-5" }
const _hoisted_2 = {
  key: 0,
  class: "flex gap-1 items-center"
}
const _hoisted_3 = {
  class: "max-w-full break-words text-[24px] text-[#000000e0] flex-wrap mb-[8px] font-[500] mt-[4px]",
  href: "#"
}
const _hoisted_4 = {
  key: 1,
  class: "flex gap-[8px] items-center"
}
const _hoisted_5 = { class: "md:ml-0 text-[#344054] font-normal hover:text-[#344054]" }
const _hoisted_6 = { class: "text-[#344054] font-normal" }
const _hoisted_7 = {
  key: 2,
  class: "flex items-center text-[#344054] text-base font-normal"
}
const _hoisted_8 = {
  key: 0,
  class: "leading-[24px] pb-[16px] text-[#344054] md:px-5"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode(" dataset repo "),
      _createCommentVNode(" <div\r\n      v-if=\"repoType === 'dataset'\"\r\n      class=\"w-full flex flex-wrap gap-2 items-center md:w-full md:mb-1\"\r\n    >\r\n      <SvgIcon\r\n        name=\"repoheader_dataset\"\r\n        width=\"16\"\r\n        height=\"16\"\r\n      />\r\n      <span class=\"text-[#A8ABB2] text-[18px] font-medium\"\r\n        >Dataset:</span\r\n      >\r\n      <el-avatar\r\n        :size=\"24\"\r\n        :src=\"avatar\"\r\n        class=\"flex-shrink-0\"\r\n      ></el-avatar>\r\n      <span class=\"max-w-full break-words text-[#344053] font-medium\">{{\r\n        nickname.trim() === '' ? name : nickname\r\n      }}</span>\r\n      <div\r\n        class=\"border border-[#DCDFE6] px-3 py-[2px] text-center text-xs text-[#606266] font-medium rounded\"\r\n      >\r\n        {{ repoDetailStore.isPrivate ? $t('all.private') : $t('all.public') }}\r\n      </div>\r\n      <div\r\n        class=\"flex cursor-pointer gap-[4px] border border-[#DCDFE6] pl-3 pr-1 py-[2px] text-center text-xs text-[#606266] font-medium rounded hover:bg-gray-50 active:ring-4 active:ring-gray-400 active:ring-opacity-25 active:bg-white\"\r\n        :class=\"userLiked === true ? 'text-gray-400 border-gray-200' : ''\"\r\n        @click=\"clickLike\"\r\n      >\r\n        {{ userLiked === false ? $t('shared.likes') : $t('shared.hasLikes') }}\r\n        <div class=\"min-h-[16px] min-w-[16px] bg-gray-100 px-1\">\r\n          {{ likesNumberDisplayName }}\r\n        </div>\r\n      </div>\r\n    </div> "),
      _createCommentVNode(" endpoint "),
      _createCommentVNode(" <div\r\n      v-else-if=\"repoType === 'endpoint'\"\r\n      class=\"flex flex-wrap w-full gap-2 items-center\"\r\n    >\r\n      <el-avatar\r\n        :size=\"24\"\r\n        :src=\"avatar\"\r\n        class=\"flex-shrink-0\"\r\n      ></el-avatar>\r\n      <span class=\"max-w-full break-words text-[#344053] font-medium\">{{\r\n        nickname.trim() === '' ? name : nickname\r\n      }}</span>\r\n      <div\r\n        class=\"border border-[#DCDFE6] px-3 py-[2px] text-center text-xs text-[#606266] font-medium rounded\"\r\n      >\r\n        {{ repoDetailStore.isPrivate ? $t('all.private') : $t('all.public') }}\r\n      </div>\r\n      <AppStatus\r\n        v-if=\"appStatus\"\r\n        :appStatus=\"appStatus\"\r\n        :spaceResource=\"spaceResource\"\r\n      />\r\n    </div> "),
      _createCommentVNode(" finetune "),
      _createCommentVNode(" <div\r\n      v-else-if=\"repoType === 'finetune'\"\r\n      class=\"flex flex-wrap w-full gap-2 items-center\"\r\n    >\r\n      <SvgIcon\r\n        name=\"model_finetune_create\"\r\n        width=\"16\"\r\n        height=\"16\"\r\n        class=\"flex-shrink-0\"\r\n      />\r\n      <span class=\"max-w-full break-words text-[#344053] font-medium\">{{\r\n        nickname.trim() === '' ? name : nickname\r\n      }}</span>\r\n      <AppStatus\r\n        v-if=\"appStatus\"\r\n        :appStatus=\"appStatus\"\r\n        :spaceResource=\"spaceResource\"\r\n      />\r\n    </div> "),
      _createCommentVNode(" other repo "),
      _createCommentVNode(" <div\r\n      v-else\r\n      class=\"flex flex-wrap w-full gap-2 items-center\"\r\n    >\r\n      <el-avatar\r\n        :size=\"24\"\r\n        :src=\"avatar\"\r\n        class=\"flex-shrink-0\"\r\n      ></el-avatar>\r\n      <span class=\"max-w-full break-words text-[#344053] font-medium\">{{\r\n        nickname.trim() === '' ? name : nickname\r\n      }}</span>\r\n      <div\r\n        class=\"border border-[#DCDFE6] px-3 py-[2px] text-center text-xs text-[#606266] font-medium rounded\"\r\n      >\r\n        {{ repoDetailStore.isPrivate ? $t('all.private') : $t('all.public') }}\r\n      </div>\r\n      <div\r\n        class=\"flex cursor-pointer gap-[4px] border border-[#DCDFE6] pl-3 pr-1 py-[2px] text-center text-xs text-[#606266] font-medium rounded hover:bg-gray-50 active:ring-4 active:ring-gray-400 active:ring-opacity-25 active:bg-white\"\r\n        :class=\"userLiked === true ? 'text-gray-400 border-gray-200' : ''\"\r\n        v-show=\"repoType !== 'collections'\"\r\n        @click=\"clickLike\"\r\n      >\r\n        {{ userLiked === false ? $t('shared.likes') : $t('shared.hasLikes') }}\r\n        <div class=\"min-h-[16px] min-w-[16px] bg-gray-100 px-1\">\r\n          {{ likesNumberDisplayName }}\r\n        </div>\r\n      </div>\r\n      <AppStatus\r\n        v-if=\"appStatus\"\r\n        :appStatus=\"appStatus\"\r\n        :spaceResource=\"spaceResource\"\r\n      />\r\n      <p\r\n        v-if=\"canWrite\"\r\n        class=\"cursor-pointer\"\r\n        @click=\"showSpaceLogs\"\r\n      >\r\n        <SvgIcon\r\n          name=\"instance_logs\"\r\n          width=\"16\"\r\n          height=\"16\"\r\n        />\r\n      </p>\r\n    </div> "),
      ($props.repoType !== 'finetune')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createCommentVNode(" <a\r\n        class=\"md:ml-0 hover:text-[#223B99] text-[#667084] font-normal\"\r\n        :href=\"ownerUrl\"\r\n      >\r\n        {{ path?.split('/')[0] }}\r\n      </a>\r\n      <div>/</div> "),
            _createElementVNode("a", _hoisted_3, _toDisplayString($props.repoType === 'endpoint' ? $props.name : $props.path?.split('/')[1]), 1 /* TEXT */),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: $setup.copyName
            }, [
              _createVNode(_component_SvgIcon, {
                name: "copy",
                width: "16",
                height: "16"
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SvgIcon, {
              name: "finetune_name_icon",
              width: "16",
              height: "16",
              class: "flex-shrink-0"
            }),
            _createElementVNode("span", _hoisted_5, _toDisplayString($props.path), 1 /* TEXT */),
            _createVNode(_component_SvgIcon, {
              name: "finetune_cpu_icon",
              width: "16",
              height: "16",
              class: "flex-shrink-0 ml-6 md:ml-0"
            }),
            _createElementVNode("span", _hoisted_6, _toDisplayString($props.resourceName), 1 /* TEXT */)
          ])),
      ($props.repoType === 'model' && $props.baseModel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t('all.baseModel')) + ": ", 1 /* TEXT */),
            _createElementVNode("div", {
              onClick: $setup.redirectBaseModel,
              class: "cursor-pointer text-[#475467] hover:text-[#344054] focus:text-[#475467] ml-[8px]"
            }, _toDisplayString($props.baseModel), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (!!$props.desc)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.desc), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" repo tags "),
    ($props.repoType === 'model' || $props.repoType === 'dataset')
      ? (_openBlock(), _createBlock($setup["HeaderTags"], {
          key: 1,
          tags: $props.tags,
          prefix: `${$props.repoType}s/`
        }, null, 8 /* PROPS */, ["tags", "prefix"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}