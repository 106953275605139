import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.5 3.33331H17.5V4.99998H2.5V3.33331ZM2.5 9.16665H17.5V10.8333H2.5V9.16665ZM2.5 15H17.5V16.6666H2.5V15Z",
      fill: "black",
      "fill-opacity": "0.88"
    }, null, -1 /* HOISTED */)
  ])))
}