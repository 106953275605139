<template>
  <div class="bg-white">
    <div class="m-auto w-[583px] md:w-full md:px-[20px]">
      <div class="py-[96px] text-center">
        <div class="text-[20px] font-[500] leading-[30px]">
          投递案例
        </div>
        <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top" class="text-left">
          <el-form-item label="组织名称" prop="org_path">
            <el-select v-model="dataForm.org_path" placeholder="选择组织">
              <el-option v-for="org in organizations" :key="org.path" :label="org.name" :value="org.path" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry_list">
            <el-select v-model="dataForm.industry_list" multiple filterable :reserve-keyword="false"
              default-first-option :placeholder="$t('all.pleaseSelect', { value: '行业' })" style="width: 100%;">
              <el-option v-for="value in this.industries" :key="value" :label="value" :value="value" />
            </el-select>
          </el-form-item>
          <el-form-item label="案例类型" prop="kind">
            <el-select v-model="dataForm.kind" filterable :reserve-keyword="false" default-first-option
              :placeholder="$t('all.pleaseSelect', { value: '案例类型' })" style="width: 100%;">
              <el-option v-for="value in this.kinds" :key="value" :label="value" :value="value" />
            </el-select>
          </el-form-item>
          <el-form-item label="案例名称" prop="title">
            <el-input v-model="dataForm.title" placeholder="请输入案例名称"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="description">
            <el-input type="textarea" rows="3" v-model="dataForm.description" placeholder="请输入简介"></el-input>
          </el-form-item>
          <el-form-item label="地点" prop="address">
            <el-input v-model="dataForm.address" placeholder="请输入地点"></el-input>
          </el-form-item>
          <el-form-item label="备案进度" prop="report_status">
            <el-select v-model="dataForm.report_status" placeholder="请选择备案进度">
              <el-option v-for="(label, value) in this.report_status_map" :key="value" :label="label" :value="value" />
            </el-select>
          </el-form-item>
          <el-form-item label="建设进展" prop="build_status">
            <el-select v-model="dataForm.build_status" placeholder="请选择建设进展">
              <el-option v-for="(label, value) in this.build_status_map" :key="value" :label="label" :value="value" />
            </el-select>
          </el-form-item>
          <el-form-item label="模型类型" prop="model_kind">
            <el-select v-model="dataForm.model_kind" placeholder="请选择模型类型">
              <el-option v-for="(label, value) in this.model_kind_map" :key="value" :label="label" :value="value" />
            </el-select>
          </el-form-item>
          <el-form-item label="底座模型" prop="base_model">
            <el-input v-model="dataForm.base_model" placeholder="请输入底座模型"></el-input>
          </el-form-item>
          <el-form-item label="模型参数" prop="model_params">
            <el-input v-model="dataForm.model_params" placeholder="请输入模型参数"></el-input>
          </el-form-item>
          <el-form-item label="案例综述" prop="content">
            <CKEditor5 v-model:content="dataForm.content" />
          </el-form-item>
          <el-form-item>
            <el-button
              class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]"
              @click="handleSubmit">提交审核</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { caseCreate } from '@api/csrf/case'
import { currentUserDetail } from '@api/jwt/user'
import CKEditor5 from '../shared/form/CKEditor5.vue'
import { useCookies } from 'vue3-cookies'
import { ElMessage } from 'element-plus'
const { cookies } = useCookies()
import { industryList } from "../helpers/utils";

export default {
  props: {
  },
  data() {
    return {
      step: 1,
      dataForm: {},
      // kinds: ['方案展示', '产品展示', '先锋案例', '技术底座', '新型工业化', '行业应用', '政务民生'],
      kinds: ['技术底座', '新型工业化', '行业应用', '政务民生'],
      industries: industryList,
      model_kind_map: {
        general: '通用模型',
        industry: '行业模型',
        vertical: '垂直模型',
        other: '其他'
      },
      report_status_map: {
        not_start: '暂未备案',
        in_progress: '正在备案',
        completed: '已备案'
      },
      build_status_map: {
        not_start: '规划中',
        in_progress: '建设中',
        completed: '已应用'
      },
      organizations: [],
      currentUserName: cookies.get('current_user'),
      rules: {
        org_name: [{ required: true, message: '请牵头单位组织名称', trigger: 'blur' }],
        industry_list: [{ required: true, message: '请选择所属行业', trigger: 'blur' }],
        kind: [{ required: true, message: '请选择案例类型', trigger: 'blur' }],
        title: [{ required: true, message: '请输入案例名称', trigger: 'blur' }],
        content: [{ required: true, message: '请输入案例综述', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地点', trigger: 'blur' }],
        description: [{ required: true, message: '请输入简介', trigger: 'blur' }],
        model_kind: [{ required: true, message: '请选择模型类型', trigger: 'blur' }],
        report_status: [{ required: true, message: '请选择备案进度', trigger: 'blur' }],
        build_status: [{ required: true, message: '请选择建设进展', trigger: 'blur' }],
      }
    }
  },
  components: {
    CKEditor5
  },
  methods: {
    handleSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm)
          const selectedOrg = this.organizations.find(org => org.path === params.org_path)
          params.org_name = selectedOrg.name
          params.org_type = selectedOrg.org_type
          caseCreate(params).then(res => {
            this.$message({
              message: '案例提交成功待审核。',
              type: 'success'
            });
            setTimeout(() => {
              window.location.href = `/cases`
            }, 1000)
          }).catch(err => {
            console.log(err)
            this.$message({
              message: '提交失败',
              type: 'error'
            });
          })
        } else {
          return false
        }
      })
    },
  },
  mounted() {
    currentUserDetail(this.currentUserName).then(res => {
      this.organizations = res.data.orgs || []
      if (this.organizations.length <= 0) {
        ElMessage.warning('请先创建组织')
        setTimeout(() => {
          window.open('/organizations/new', '_blank')
        }, 1500)
      }
    })
  }
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 24px;
}

.el-form-item__label {
  margin-bottom: 6px;
}

:deep(.el-input__wrapper) {
  border-radius: 8px;
}

:deep(.append-input .el-input__wrapper) {
  border-radius: 8px 0 0 8px;
}

:deep(.el-input-group__append, .el-input-group__prepend) {
  background: transparent;
  color: #344054;
}

:deep(.el-textarea__inner) {
  border-radius: 8px;
}

:deep(.el-upload--picture-card) {
  border: 0px;
}

:deep(.hide .el-upload.el-upload--picture-card) {
  display: none;
}
</style>