import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-[20x] font-[500]" }, "Users", -1 /* HOISTED */)),
          _createVNode(_component_el_input, {
            modelValue: $setup.keyword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.keyword) = $event)),
            style: {"width":"240px"},
            placeholder: "Name, Email, Phone",
            "prefix-icon": $setup.Search,
            onInput: $setup.searchUsers
          }, null, 8 /* PROPS */, ["modelValue", "prefix-icon"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_table, {
          stripe: true,
          data: $setup.users,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "username",
              label: "Name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "nickname",
              label: "Nickname"
            }),
            _createVNode(_component_el_table_column, {
              prop: "email",
              label: "Email"
            }),
            _createVNode(_component_el_table_column, {
              prop: "phone",
              label: "Phone"
            }),
            _createVNode(_component_el_table_column, { label: "Operations" }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  size: "small",
                  onClick: $event => ($setup.showDetail(scope.row))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 详情 ")
                  ])),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]),
        _createVNode(_component_el_pagination, {
          "current-page": $setup.page,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => (($setup.page) = $event)),
          "page-size": $setup.per,
          layout: "prev, pager, next",
          total: $setup.total,
          onCurrentChange: $setup.fetchUsers,
          class: "my-[52px] flex justify-center"
        }, null, 8 /* PROPS */, ["current-page", "page-size", "total"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}