import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 my-[30px] md:px-5" }
const _hoisted_2 = { class: "flex items-center gap-[10px]" }
const _hoisted_3 = { class: "whitespace-nowrap" }
const _hoisted_4 = { class: "mb-2 text-sm" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.repoName), 1 /* TEXT */),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-[#909399]" }, "/", -1 /* HOISTED */)),
      _createVNode(_component_el_input, {
        modelValue: $setup.fileName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.fileName) = $event)),
        maxLength: 200,
        "show-word-limit": "",
        clearable: "",
        placeholder: _ctx.$t('repo.edit.fileName'),
        onInput: $setup.handleFileNameChange,
        class: "w-full h-[40px] text-[#606266]"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createVNode($setup["CodeEditor"], {
      modelValue: $setup.codeContent,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.codeContent) = $event)),
      fileName: $setup.fileName,
      originalCodeContent: $setup.originalCodeContent
    }, null, 8 /* PROPS */, ["modelValue", "fileName", "originalCodeContent"]),
    _createVNode(_component_el_radio_group, {
      modelValue: $setup.new_branch,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.new_branch) = $event)),
      class: "my-4 py-4 border border-[#DCDFE6] border-l-0 border-r-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio, {
          label: "main",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('repo.edit.main')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('repo.edit.submit')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        modelValue: $setup.commitTitle,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.commitTitle) = $event)),
        maxLength: 200,
        "show-word-limit": "",
        clearable: "",
        placeholder: $setup.commitTitlePlaceholder,
        class: "w-full h-[40px] text-[#606266]"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createVNode($setup["CommunityMDTextarea"], {
      desc: "",
      placeholder: _ctx.$t('repo.edit.desc'),
      onInputChange: $setup.handleCommentInputChange
    }, null, 8 /* PROPS */, ["placeholder"]),
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: $setup.updateFile,
        disabled: !$setup.commitValid || $setup.submiting
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('repo.edit.update')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_el_button, { onClick: $setup.cancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('repo.edit.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}