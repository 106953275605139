import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[8px] w-[260px] p-[20px] border rounded-[10px] bg-[#F4F4F6]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: "/new_admin/",
      class: "underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.title')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <RouterLink\r\n      to=\"/new_admin/system_config\"\r\n      class=\"underline\"\r\n    >\r\n      System Config\r\n    </RouterLink> "),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/users",
      class: "underline"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Users ")
      ])),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/models",
      class: "underline"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Models ")
      ])),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/datasets",
      class: "underline"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Datasets ")
      ])),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_RouterLink, {
      to: "/new_admin/codes",
      class: "underline"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" Codes ")
      ])),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <RouterLink\r\n      to=\"/new_admin/mirrors\"\r\n      class=\"underline\"\r\n    >\r\n      Mirrors\r\n    </RouterLink> "),
    ($setup.onPremise === 'true')
      ? (_openBlock(), _createBlock(_component_RouterLink, {
          key: 0,
          to: "/new_admin/sync",
          class: "underline"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Sync Setting ")
          ])),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <RouterLink\r\n      v-if=\"onPremise === 'false'\"\r\n      to=\"/new_admin/email_sending\"\r\n      class=\"underline\"\r\n    >\r\n      Email Sending\r\n    </RouterLink>\r\n\r\n    <RouterLink\r\n      v-if=\"onPremise === 'false'\"\r\n      to=\"/new_admin/email_group\"\r\n      class=\"underline\"\r\n    >\r\n      Email Group\r\n    </RouterLink> "),
    _createCommentVNode(" <RouterLink\r\n      to=\"/new_admin/eai_articles\"\r\n      class=\"underline\"\r\n    >\r\n      EAI Articles\r\n    </RouterLink>\r\n    <RouterLink\r\n      to=\"/new_admin/eai_devices\"\r\n      class=\"underline\"\r\n    >\r\n      EAI Devices\r\n    </RouterLink> ")
  ]))
}