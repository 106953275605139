<template>
  <a
    :href="`/models/${model.path}`"
    class="basis-1/2 md:basis-full max-w-[532px] md:max-w-full md:max-w-full flex flex-col xty-model-card md:bg-white bg-unset"
  >
    <div class="flex items-center mb-[5px] sm:w-auto gap-[8px]">
      <div
        :title="model.path"
        class="model-path max-w-[60%] text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ model.name }}
      </div>
      <div v-if="model.repository?.tags?.length > 0" class="flex items-center gap-[8px]">
        <span :class="`text-[12px] px-[8px] py-[3px] flex gap-[4px] font-[400] rounded-[4px] bg-[${colorMap(displayTag().category)}30] text-[${colorMap(displayTag().category)}]`">{{ displayTag().name }}</span>
      </div>
    </div>
    <div class="flex items-center gap-[8px] text-xs text-[#00000061]">
      <short-info :supplier="props.model.path.split('/')[0]" :updated_at="model.updated_at" :downloads="model.downloads" />
    </div>
  </a>
</template>

<script setup>
import { computed } from "vue";
import ShortInfo from "../shared/ShortInfo.vue";
import { colorMap } from "../helpers/utils";

const props = defineProps({
  model: Object,
});

const displayTag = () => {
  let tag
  if (props.model.repository.tags?.length > 0) {
    tag = props.model.repository.tags.find((tag) => tag.category === "task")
  }
  if (tag == undefined || tag == null) {
    tag = {}
  }
  return tag
}


const visibility = computed(() => {
  return props.model.private ? "私有" : "公开";
});
</script>

<style scoped>
.model-card:hover .model-path {
  color: var(--theme-dark-red-t-red-500-ad-4-a-3-b, #ad4a3b);
}
</style>
