export const ekb = {
  "title": "Era of Large Models",
  "desc": "Comprehensive Enterprise Knowledgebase Management Foundation",
  "tips": "Versatile Assistant, Large Models Help You Quickly Complete Complex Tasks",
  "askTitle": "Quick Q&A",
  "askTitle2": "Smooth and Powerful",
  "askDesc": "One-click Traceability, Direct to the Source of Knowledge.",
  "askSTitle": "All-in-One Enterprise Document Management Platform",
  "askSText": "Greatly enhance knowledge and data query processing efficiency",
  "askSTitle2": "Focus on Enterprise Information Security",
  "askStText2": "Hybrid cloud architecture, continuously protect enterprise assets and customer privacy data",
  "moreP": "More Features",
  "morePTitle": "Learn More About the Powerful Features of EKB AI",
  "morePText": "EKB AI, as the infrastructure for comprehensive enterprise knowledge lifecycle management, assists users in realizing the 'extraction, storage, circulation, and utilization' of internal corporate knowledge. Like the human brain, EKB AI assists in storing, understanding, and creating knowledge, opening up a new mode of knowledge management and significantly reducing the threshold for using unstructured internal corporate data.",
  "moreDataTitle": "Comprehensive Multi-Modal, Multilingual Integration and Output",
  "moreDataDesc": "The system supports various formats, including PDF, Word, Excel, and online web links, greatly facilitating the collection and organization of enterprise information.",
  "moreDataTitle2": "Intelligent Document Recognition and Accurate Search",
  "moreDataDesc2": "Utilizing automatic recognition technology and OCR for high-precision extraction, effectively identifying documents in different formats and accurately conducting content retrieval.",
  "moreDataTitle3": "Establishing API Interface Support",
  "moreDataDesc3": "Providing a selection of multiple AI models for easy integration with third-party platforms such as DingTalk and WeChat.",
  "moreDataTitle4": "One-Click Deployment of Q&A Chatbots",
  "moreDataDesc4": "By utilizing intelligent AI documentation and data analysis capabilities, we help you achieve more efficient workflows while reducing labor costs.",
  "adv": "Our Advantages",
  "advTitle": "More Intelligent Large Language Model Knowledge Assistant",
  "advText": "Help you solve diverse product usage needs for individuals and teams, support various secure and convenient deployment methods, and possess powerful data analysis capabilities",
  "advDataTitle": "Comprehensive Enterprise Knowledge Management Platform",
  "advDataDesc": "A powerful platform for managing enterprise knowledge, documents, and data assets. It comprehensively manages the enterprise knowledge base and provides a one-stop information inquiry and solution. With advanced algorithms combined with LLM, it can understand and organize large amounts of unstructured data.",
  "advDataTitle2": "Omniscient AI Assistant",
  "advDataDesc2": "A powerful and intelligent AI assistant, like a portable expert assistant, able to understand user queries and provide information at any time, simplifying the synchronization process. It serves as a bridge between the user and the enterprise knowledge base, providing convenient access to key information through natural interaction.",
  "advDataTitle3": "Flexible Deployment Options",
  "advDataDesc3": "Supporting multiple deployment options, including online SaaS, private cloud, public cloud, and full privatization. Combined with the CAICT integrated solution, it can be deployed on customer-selected compatible hardware, significantly reducing customer usage costs.",
  "advDataTitle4": "Intelligent AI Document and Data Analysis",
  "advDataDesc4": "Intelligent AI document and efficient data analysis models, quickly resolving enterprise issues for intelligent customer service and report analysis. Conducting data mining and pattern analysis, producing easy-to-understand reports, and providing data visualization capabilities."
}