<template>
  <div class="weight-adjuster">
    <el-card class="mb-4">
      <template #header>
        <div class="flex justify-between items-center">
          <span class="font-bold">请滑动滑块修改权重</span>
          <div class="flex items-center gap-4">
            <span :class="{'text-red-500': totalWeight > 100, 'text-green-500': totalWeight === 100}">
              总权重: {{ totalWeight }}%
            </span>
            <el-button type="primary" @click="applyWeights" :disabled="totalWeight !== 100">
              应用权重
            </el-button>
          </div>
        </div>
      </template>
      
      <div v-if="totalWeight !== 100" class="mb-4">
        <el-alert
          :type="totalWeight > 100 ? 'warning' : 'info'"
          :title="weightAlertMessage"
          show-icon
        />
      </div>
      
      <div class="grid gap-4">
        <div v-for="(weight, field) in currentWeights" :key="field" class="flex items-center gap-4">
          <span class="w-32">{{ getFieldLabel(field) }}</span>
          <el-slider
            v-model="currentWeights[field]"
            :min="0"
            :max="100"
            :step="0.5"
            class="flex-1"
            @change="handleWeightChange"
          />
          <span class="w-12 text-right">{{ weight }}%</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  name: 'WeightAdjuster',
  
  props: {
    initialWeights: {
      type: Object,
      required: true
    }
  },

  emits: ['weightsChanged', 'apply'],
  
  setup(props, { emit }) {
    // 当前权重状态
    const currentWeights = ref({ ...props.initialWeights })

    // 字段标签映射
    const FIELD_LABELS = {
      baidu_index: '百度指数',
      wechat_index: '微信指数',
      huggingface_downloads: 'Huggingface下载量',
      github_forks: 'Github Fork数',
      parameter_scales_count: '参数规模种类数',
      input_modals: '输入模态',
      output_modals: '输出模态',
      deployment_methods: '部署方式'
    }

    // 获取字段显示标签
    const getFieldLabel = (field) => {
      return FIELD_LABELS[field] || field
    }

    // 计算总权重
    const totalWeight = computed(() => {
      return Object.values(currentWeights.value).reduce((sum, weight) => sum + weight, 0)
    })

    // 添加提醒消息计算属性
    const weightAlertMessage = computed(() => {
      if (totalWeight.value > 100) {
        return `当前总权重超出100%，需要减少${totalWeight.value - 100}%`
      } else if (totalWeight.value < 100) {
        return `当前总权重不足100%，还需要增加${100 - totalWeight.value}%`
      }
      return ''
    })

    // 处理权重变化
    const handleWeightChange = () => {
      // 发送权重变化事件
      emit('weightsChanged', { ...currentWeights.value })
    }

    // 应用权重
    const applyWeights = () => {
      if (totalWeight.value !== 100) {
        return
      }
      emit('apply')
    }

    return {
      currentWeights,
      totalWeight,
      getFieldLabel,
      handleWeightChange,
      applyWeights,
      weightAlertMessage,
    }
  }
}
</script>

<style scoped>
.weight-adjuster {
  max-width: 800px;
  margin: 0 auto;
}
</style> 