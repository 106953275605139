import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-[14px] font-[400] gap-[8px] leading-[20px] text-[#00000061]" }
const _hoisted_2 = { class: "flex items-center gap-[8px] items-center text-[24px] text-[#000000e0] flex-wrap mb-[8px]" }
const _hoisted_3 = { class: "break-words line-clamp font-[500] md:max-w-[85%]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["ShortInfo"], {
        ownerUrl: $props.ownerUrl,
        supplier: $props.path.split('/')[0],
        updated_at: $setup.props.resource.updated_at,
        downloads: $setup.props.resource.downloads
      }, null, 8 /* PROPS */, ["ownerUrl", "supplier", "updated_at", "downloads"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.path.split('/')[1]), 1 /* TEXT */),
      _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: $setup.copyName
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M10.7779 5.3335V5.3335C11.9162 5.3335 12.4854 5.3335 12.9395 5.50441C13.6582 5.77492 14.2254 6.34212 14.4959 7.06083C14.6668 7.51495 14.6668 8.08409 14.6668 9.22239V10.4002C14.6668 11.8936 14.6668 12.6404 14.3762 13.2108C14.1205 13.7126 13.7126 14.1205 13.2108 14.3762C12.6404 14.6668 11.8936 14.6668 10.4002 14.6668H9.22239C8.08409 14.6668 7.51495 14.6668 7.06083 14.4959C6.34212 14.2254 5.77492 13.6582 5.50441 12.9395C5.3335 12.4854 5.3335 11.9162 5.3335 10.7779V10.7779M5.60016 10.6668H6.40016C7.89364 10.6668 8.64037 10.6668 9.2108 10.3762C9.71257 10.1205 10.1205 9.71257 10.3762 9.2108C10.6668 8.64037 10.6668 7.89364 10.6668 6.40016V5.60016C10.6668 4.10669 10.6668 3.35995 10.3762 2.78952C10.1205 2.28776 9.71257 1.87981 9.2108 1.62415C8.64037 1.3335 7.89364 1.3335 6.40016 1.3335H5.60016C4.10669 1.3335 3.35995 1.3335 2.78952 1.62415C2.28776 1.87981 1.87981 2.28776 1.62415 2.78952C1.3335 3.35995 1.3335 4.10669 1.3335 5.60016V6.40016C1.3335 7.89364 1.3335 8.64037 1.62415 9.2108C1.87981 9.71257 2.28776 10.1205 2.78952 10.3762C3.35995 10.6668 4.10669 10.6668 5.60016 10.6668Z",
            stroke: "#606266",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          })
        ], -1 /* HOISTED */)
      ])),
      _createCommentVNode(" <div class=\"border border-[#DCDFE6] px-3 py-[2px] text-center text-xs text-[#606266] font-medium rounded\">{{private?'私有':'公开'}}</div> ")
    ]),
    _createVNode($setup["HeaderTags"], {
      "task-tags": $props.tags.task_tags,
      "framework-tags": $props.tags.framework_tags,
      "license-tags": $props.tags.license_tags,
      "other-tags": $props.tags.other_tags
    }, null, 8 /* PROPS */, ["task-tags", "framework-tags", "license-tags", "other-tags"])
  ], 64 /* STABLE_FRAGMENT */))
}