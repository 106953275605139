import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SecondLevel = _resolveComponent("SecondLevel")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`rounded-[16px] border-[2px] px-[36px] py-[60px] relative flex flex-col gap-[32px] ${$options.getTheme($props.firstItem.label).bg} ${$options.getTheme($props.firstItem.label).borderColor}`),
    style: {"width":"auto"}
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`absolute bg-white ${$options.getTheme($props.firstItem.label).borderColor} left-[24px] top-[-16px] rounded-[8px] px-[16px] text-[20px] font-[400] leading-[30px] border-[2px]`)
    }, _toDisplayString($props.firstItem.label), 3 /* TEXT, CLASS */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.range, (times) => {
      return (_openBlock(), _createElementBlock("div", {
        key: times,
        class: "flex flex-nowrap gap-[32px]"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.firstItem.subs, (secondItem, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: secondItem.label
          }, [
            (index % this.secondLevelRowCount === times)
              ? (_openBlock(), _createBlock(_component_SecondLevel, {
                  key: 0,
                  firstItem: $props.firstItem,
                  secondItem: secondItem,
                  thirdLevelRowCount: this.thirdLevelRowCount
                }, null, 8 /* PROPS */, ["firstItem", "secondItem", "thirdLevelRowCount"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}