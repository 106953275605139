import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] py-[36px] my-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu")
  const _component_ProfileEdit = _resolveComponent("ProfileEdit")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu, {
      class: "max-w-[411px] md:mb-[24px]",
      name: $data.profileName,
      displayName: $data.profileDisplayName,
      userInfo: $props.userInfo,
      avatar: $data.profileAvatar
    }, null, 8 /* PROPS */, ["name", "displayName", "userInfo", "avatar"]),
    _createVNode(_component_ProfileEdit, {
      class: "grow py-[24px]",
      name: $props.name,
      nickname: $props.nickname,
      avatar: $props.avatar,
      phone: $props.phone,
      email: $props.email,
      displayName: $props.displayName,
      userInfo: $props.userInfo,
      onUpdateUserInfo: $options.updateUserInfo
    }, null, 8 /* PROPS */, ["name", "nickname", "avatar", "phone", "email", "displayName", "userInfo", "onUpdateUserInfo"])
  ]))
}