import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-[640px] m-auto flex flex-col items-center md:w-full md:p-5" }
const _hoisted_2 = { class: "text-[#303133] text-xl font-semibold mt-6 mb-3" }
const _hoisted_3 = { class: "text-[#606266] text-base font-medium md:text-center" }
const _hoisted_4 = { class: "mt-9" }
const _hoisted_5 = { class: "w-full flex md:flex-col gap-[16px] items-center" }
const _hoisted_6 = { class: "w-full flex md:flex-col gap-[16px] items-center justify-between" }
const _hoisted_7 = { class: "mb-[18px] rounded bg-[#FFA80130] text-[#FFA801] text-[13px] py-[9px] px-4" }
const _hoisted_8 = { class: "flex justify-end" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_Warning = _resolveComponent("Warning")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_SvgIcon, {
        name: "models",
        height: "36",
        width: "37"
      })
    ]),
    _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.t('models.newModel.title')), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString($setup.t('models.newModel.titleDesc')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_form, {
        ref: "dataFormRef",
        model: $setup.dataForm,
        rules: $setup.rules,
        class: "w-full flex flex-col gap-[14px]",
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_form_item, {
              label: $setup.t('models.newModel.owner'),
              prop: "owner",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.dataForm.owner,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dataForm.owner) = $event)),
                  placeholder: $setup.t('all.select'),
                  size: "large",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.namespaces(), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item,
                        label: item,
                        value: item
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              class: "w-full",
              label: $setup.t('models.newModel.modelEnName'),
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.dataForm.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dataForm.name) = $event)),
                  placeholder: 
                $setup.t('all.pleaseInput', {
                  value: $setup.t('models.newModel.modelEnName')
                })
              ,
                  "input-style": "width: 100%"
                }, {
                  suffix: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      class: "item",
                      effect: "dark",
                      "raw-content": "",
                      content: `
                <p>${$setup.t('models.modelNameTips')}</p>
                <ul style='margin-left: 18px; list-style: disc; margin-top: 12px;'>
                  <li>${$setup.t('rule.lengthLimit', { min: 2, max: 64 })}</li>
                  <li>${$setup.t('rule.startWithLetter')}</li>
                  <li>${$setup.t('rule.endWithLetterOrNumber')}</li>
                  <li>${$setup.t('rule.onlyLetterNumberAndSpecialStr')}</li>
                  <li>${$setup.t('rule.specialStrNotTogether')}</li>
                </ul>
                `,
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Warning)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_form_item, {
              class: "w-full",
              label: $setup.t('models.newModel.modelNickName'),
              prop: "nickname"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.dataForm.nickname,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.dataForm.nickname) = $event)),
                  placeholder: 
                $setup.t('all.pleaseInput', {
                  value: $setup.t('models.newModel.modelNickName')
                })
              
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: "License",
              prop: "license",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.dataForm.license,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.dataForm.license) = $event)),
                  placeholder: $setup.t('all.select'),
                  size: "large",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.licenseTags, (tag) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: tag.name,
                        label: tag.show_name,
                        value: tag.name
                      }, null, 8 /* PROPS */, ["label", "value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createVNode(_component_el_form_item, {
            class: "w-full",
            label: $setup.t('models.newModel.modelDesc'),
            prop: "desc"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.dataForm.desc,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.dataForm.desc) = $event)),
                rows: 6,
                type: "textarea",
                placeholder: 
              $setup.t('all.pleaseInput', { value: $setup.t('models.newModel.modelDesc') })
            
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_el_form_item, { class: "w-full" }, {
            default: _withCtx(() => [
              _createVNode($setup["PublicAndPrivateRadioGroup"], {
                modelValue: $setup.dataForm.visibility,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.dataForm.visibility) = $event)),
                publicDesc: $setup.t('models.newModel.publicDesc'),
                privateDesc: $setup.t('models.newModel.privateDesc')
              }, null, 8 /* PROPS */, ["modelValue", "publicDesc", "privateDesc"])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("p", _hoisted_7, _toDisplayString($setup.t('models.newModel.tips')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  loading: $setup.loading,
                  class: "!text-center !h-9 !text-[16px] !text-white !bg-[#FF5005] !rounded-[8px] !border-[1px] !border-[#FF5005]",
                  onClick: $setup.handleSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.t('models.newModel.createModel')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["loading"])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ])
  ]))
}