<template>
  <el-upload
    class="w-full"
    drag
    :headers="{ 'X-CSRF-TOKEN': csrf_token }"
    multiple
    :data="{
      namespace: 'system-logo',
      file_max_size: 1024*1024
    }"
    action="/internal_api/upload"
    :on-success="(res) => handleUploadSuccess(res)"
  >
    <div>
      <div class="el-upload__text">
        <p>
          <span class="text-[#FF5005] font-[500]">
            {{ $t('components.upload.click_to_upload') }}
          </span>
          {{ $t('components.upload.or_drag_file') }}
        </p>
      </div>
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    </div>
  </el-upload>
  <div class="flex gap-[20px] px-[120px]">
    <div v-for="(url, index) in files" :key="index">
      <img @click="copyUrl(url)" :src="url" class="w-[300px] h-[100px] object-cover border rounded-[8px] cursor-pointer hover:opacity-80">
    </div>
  </div>
</template>

<script>
  import { copyToClipboard } from '../../../../packs/clipboard'

  export default {
    data() {
      return {
        csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        files: [],
      }
    },
    methods: {
      handleUploadSuccess(res) {
        const files = this.files
        files.push(res.url)
        this.files = files
      },
      copyUrl(url) {
        copyToClipboard(url)
      }
    }
  }
</script>
<style scoped>
  :deep(.el-upload-list) {
    display: none; 
  }
</style>