import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-[294px] rounded-[8px] mx-[24px]" }
const _hoisted_2 = { class: "flex p-[16px]" }
const _hoisted_3 = { class: "ml-[10px]" }
const _hoisted_4 = { class: "text-[18px] leading-[28px] font-semibold" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-[16px] text-[#909399] leading-[24px] font-light" }
const _hoisted_7 = { class: "flex flex-col" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_avatar, {
          size: 60,
          src: $setup.userStore?.avatar
        }, null, 8 /* PROPS */, ["src"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              href: '/profile/' + $setup.userStore?.username
            }, _toDisplayString($setup.userStore?.nickname || $setup.userStore?.username), 9 /* TEXT, PROPS */, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, "@" + _toDisplayString($setup.userStore?.username), 1 /* TEXT */)
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[20px] font-[600] leading-[28px] p-[16px]" }, " 设置 ", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", {
          href: "/settings/profile",
          class: _normalizeClass(["p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer", $setup.menuClass('/settings/profile')])
        }, " 个人资料 ", 2 /* CLASS */),
        _createCommentVNode(" <div class=\"p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40\"\r\n              :class=\"menuClass('/settings/account')\"\r\n        >\r\n          账户信息\r\n        </div>\r\n        <div class=\"p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40\"\r\n              :class=\"menuClass('/settings/accessTokens')\"\r\n        >\r\n          Access Token\r\n        </div> "),
        _createElementVNode("a", {
          href: "/settings/git-token",
          class: _normalizeClass(["p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer", $setup.menuClass('/settings/git-token')])
        }, " Git Token ", 2 /* CLASS */),
        _createElementVNode("a", {
          href: "/settings/ssh-keys",
          class: _normalizeClass(["p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer", $setup.menuClass('/settings/ssh-keys')])
        }, " SSH Keys ", 2 /* CLASS */),
        _createCommentVNode(" <div class=\"p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40\"\r\n              :class=\"menuClass('/settings/billing')\"\r\n        >\r\n          账单\r\n        </div> ")
      ])
    ])
  ]))
}