import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "min-h-[300px] py-8 md:px-5" }
const _hoisted_2 = { class: "rounded-lg border border-[#e5e7eb] min-h-[100px] mb-4" }
const _hoisted_3 = { class: "flex justify-between gap-[4px] border-b border-[#e5e7eb] items-center px-[12px] py-[9px] bg-[#F5F7FA] rounded-t-lg" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "flex items-center mr-2" }
const _hoisted_6 = { class: "text-[#606266] text-sm" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center text-sm text-[#344054]"
}
const _hoisted_8 = { class: "p-4 flex justify-between" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_button = _resolveComponent("el-button")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_button_group = _resolveComponent("el-button-group")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_avatar, {
              icon: $setup.UserFilled,
              size: 24,
              class: "mr-2"
            }, null, 8 /* PROPS */, ["icon"]),
            _createElementVNode("span", null, _toDisplayString($setup.commit.committer_name), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString($setup.format($setup.beiJingTimeParser($setup.commit.committer_date), _ctx.locale == "en" ? "en_US" : "zh_CN")), 1 /* TEXT */)
        ]),
        ($setup.commit.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_el_button_group, { class: "ml-2 min-w-[107px]" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "default",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.commit.id.slice(0, 7)), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_button, {
                    type: "default",
                    size: "small",
                    onClick: _cache[0] || (_cache[0] = $event => ($setup.copyCommitId($setup.commit.id)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SvgIcon, {
                        name: "copy",
                        class: "w-3 h-3"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        ($setup.commit.message)
          ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
              key: 0,
              content: $setup.commit.message
            }, null, 8 /* PROPS */, ["content"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", { innerHTML: $setup.diffContent }, null, 8 /* PROPS */, _hoisted_9)
  ]))
}