import request from './request'

export function getDocComments(docUuid, config = {}) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments`,
        method: 'get',
        ...config
    })
}

export function createDocComment(docUuid, data) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments`,
        method: 'post',
        data
    })
}

export function deleteDocComment(docUuid, commentId) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments/${commentId}`,
        method: 'delete'
    })
}

export function updateDocComment(docUuid, commentId, data) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments/${commentId}`,
        method: 'put',
        data
    })
}

export function voteDocComment(docUuid, commentId, data) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments/${commentId}/vote`,
        method: 'post',
        data
    })
}

export function getDocCommentReplies(docUuid, config = {}) {
    return request({
        url: `/internal_api/docs/${docUuid}/doc_comments/replies`,
        method: 'get',
        ...config
    })
}
