export const community = {
  "discussion": {
    "back": "回到话题列表",
    "new": "新建话题"
  },
  "discussionDetail": {
    "reply": "回复",
    "comment": "评论",
    "cancel": "取消",
    "updateSuccess": "标题更新成功！",
    "warn": "内容不能为空",
    "addSuccess": "添加评论成功"
  },
  "MD": {
    "edit": "编辑",
    "preview": "预览",
    "desc": "通过拖拽上传图片，音频，视频，或者",
    "click": "点击这里上传",
    "default": "留下评论"
  },
  "newDiscussion": {
    "new": "新建话题",
    "title": "标题",
    "content": "内容",
    "create": "创建话题",
    "cancel": "取消",
    "warn": "标题和内容不能为空",
    "success": "添加话题成功",
    "comment": "评论中： "
  },
  "empty": {
    "welcome": "欢迎来讨论",
    "desc": "这里是与CAICT讨论和合作的地方！",
    "new": "新建话题"
  },
}
