<template>
  <!-- visibility -->
  <div class="my-[30px]">
    <h3 class="text-[18px] font-[500] mb-[8px]">Visibility</h3>
    <el-form-item>
      <el-select 
        v-model="dataset.private"
        placeholder="Select"
        size="large"
        class="w-full"
      >
        <el-option label="Private" :value="true" />
        <el-option label="Public" :value="false" />    
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="info"
        size="small"
        @click="updateDataset({ private: dataset.private })"
        >Update</el-button
      >
    </el-form-item>
  </div>

  <hr />

</template>


<script setup>
  import useFetchApi from '../../../packs/useFetchApi'
  import { ref, onMounted, inject } from 'vue'
  import { useRoute } from 'vue-router'
  import { ElMessage } from 'element-plus'
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  const route = useRoute()
  const dataset = ref({
    private: null,
    base_dataset: ""
  })
  
  const updateDataset = async (payload) => {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    const { data, error } = await useFetchApi(`/datasets/${route.params.namespace}/${route.params.name}`, options).json()
    
    if (data.value) {
      ElMessage({
        message: '更新成功！',
        type: 'success'
      })
    } else {
      ElMessage({
        message: error.value.msg,
        type: 'error'
      })
    }
  }

  const fetchDataset = async () => {
    const { data } = await useFetchApi(
      `/datasets/${route.params.namespace}/${route.params.name}`
    ).json()
    if (data.value) {
      const res_json = data.value
      dataset.value.private = res_json.data.private
      // dataset.value.base_dataset = res_json.data.base_dataset
    } else {
      ElMessage.error('Failed to fetch dataset')
    }
  }

  // const fetchBaseDatasets = async (query, cb) => {
  //   const { data, error } = await useFetchApi(`/datasets?search=${query}`).json();
  //   if (error.value) {
  //     res.json().then((error) => {
  //       ElMessage({ message: error.msg, type: 'warning' })
  //     })
  //   } else {
  //     const body = data.value
  //     const paths = body.data?.map((dataset) => {
  //       return { key: dataset.path, value: dataset.path };
  //     });
  //     cb(paths);
  //   }
  // };

  onMounted(() => {
    fetchDataset()
  })
</script>