export const codes = {
  title: "工具集仓库",
  list: "工具集列表",
  detail: "工具集详情",
  tips:"创建工具集库托管您的工具集",
  placeholder: "工具集仓库名字",
  downloadCode: "下载工具集仓库",
  codeName: "工具集仓库名称",
  codeNameTips: "应用于工具集仓库路径，创建后不可更改",
  namespaceCodeName: "命名空间/工具集仓库名称",
  codeNickName: "工具集仓库别名",
  codeDesc: "工具集仓库简介",
  codesUsedToTrain: '关联工具集',
  newCode: {
    title: "新建工具集仓库",
    titleDesc: "仓库包含所有的工具集文件和修订的历史记录",
    owner: "所有者",
    codeName: "工具集仓库名称",
    codeNickName: "工具集仓库别名",
    codeDesc: "工具集仓库简介",
    public: "公开",
    publicDesc:
      "任何互联网上的人都可以看到这个仓库。只有你（个人）或你所在组织的成员可以提交。",
    private: "私有",
    privateDesc: "只有你（个人）或你所在组织的成员可以看到并提交到这个仓库。",
    tips: "创建工具集仓库后，你可以使用网页或 Git 上传你的文件。",
    createCode: "创建工具集仓库",
    createSuccess: "工具集仓库创建成功",
    tip: "应用于工具集库路径，创建后不可更改",
    license: "许可证",
    createFail: "工具集仓库创建失败",
    codeEnName: "工具集仓库英文名称",
  },
  edit: {
    tips: "将会显示在工具集列表页面中，选填",
    tips2: "一段关于工具集的介绍，选填",
    changeVisibility: "修改工具集可见性",
    statusText: "当前工具集目前是",
    status: "状态。",
    codeVisibility: "工具集可见性",
    delCode: "删除工具集",
    canNot: "无法",
    delTips: "此操作",
    delTips2: "撤销，这将永久删除",
    delTips3: "工具集仓库及其所有文件。",
    enterPls: "请输入",
    sureDel: "确认删除",
    privateVis: "只有创建者或组织成员可见",
    publicVis: "任何人都可以看到此工具集",
    confirmDel: "我已知晓,确认删除",
    privateInfo: "，仅创建者或者组织管理员可进行提交",
    publicInfo: "， 任何互联网上的人都可以看到此工具集。",
    needCodeName: "请先提供工具集别名",
    needCodelDesc: "请先提供工具集介绍",
  },
}
