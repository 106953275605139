<template>
  <div class="flex min-h-[300px] md:flex-col-reverse">
    <div class="max-w-[80%] sm:max-w-[100%] pt-4 pb-10 pr-5 sm:pr-0 break-words flex-1">
      <MarkdownViewer
        :content="readmeContent"
        :setDefaultText="true"
        v-if="!loading"
      >
      </MarkdownViewer>
    </div>
    <div class="p-4 w-[20%] border-l border-[#EBEEF5] md:border-l-0 md:w-full md:px-0">
      <div class="text-[#606266] text-base font-medium leading-[22px]">下载量</div>
      <div class="text-[#303133] text-base font-semibold leading-6 mt-1">{{ downloadCount }}</div>
    </div>
  </div>
</template>

<script setup>
  import MarkdownViewer from './viewers/MarkdownViewer.vue'
  import { ref, onMounted } from 'vue'

  const props = defineProps({
    downloadCount: Number,
    namespacePath: String,
    resource: String,
  })

  const loading = ref(true)
  const readmeContent = ref('')
  
  const fetchData = async () => {
    const url = `/internal_api/${props.resource}/${props.namespacePath}/readme`

    fetch(url).then((response) => {
      response.json().then((data) => {
        readmeContent.value = data.readme
      }).catch((error) => {
        console.error(error)
      }).then(() => {
        loading.value = false
      })
    })
  }

  onMounted(() => {
    fetchData()
  })
</script>
