import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[16px] rounded-lg bg-[#F5F7FA] p-[12px] w-[480px] lg:w-full" }
const _hoisted_2 = { class: "flex items-center pb-[16px] pt-[2px] border-b-2 md:block relative" }
const _hoisted_3 = { class: "font-medium text-base break-words mr-[8px]" }
const _hoisted_4 = { class: "text-[#606266] text-[13px] md:pl-0 md:mt-[4px]" }
const _hoisted_5 = { class: "pt-[16px]" }
const _hoisted_6 = { class: "break-all text-[#606266] text-sm" }
const _hoisted_7 = { class: "flex items-center justify-center h-[108px]" }
const _hoisted_8 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString($data.theSshKeyName), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_4, _toDisplayString($options.passedTime), 1 /* TEXT */),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = $event => ($data.deleteDialogVisible = true)),
          class: "flex items-center justify-center absolute top-0 right-0 w-[46px] h-[32px] bg-white rounded border-2 text-right"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("svg", {
            width: "14",
            height: "14",
            viewBox: "0 0 14 14",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              d: "M2.33366 2.91699L2.73981 9.82157C2.80217 10.8818 2.83336 11.4119 3.05963 11.8141C3.25882 12.1683 3.56115 12.4533 3.92637 12.6314C4.34125 12.8337 4.87226 12.8337 5.93429 12.8337H8.06636C9.12839 12.8337 9.6594 12.8337 10.0743 12.6314C10.4395 12.4533 10.7418 12.1683 10.941 11.8141C11.1673 11.4119 11.1985 10.8818 11.2608 9.82157L11.667 2.91699M2.33366 2.91699H1.16699M2.33366 2.91699H11.667M11.667 2.91699H12.8337M9.33366 2.91699L9.30372 2.82719C9.21047 2.54744 9.16385 2.40756 9.10498 2.28735C8.8112 1.68746 8.23732 1.27383 7.57531 1.18483C7.44265 1.16699 7.29521 1.16699 7.00033 1.16699V1.16699C6.70544 1.16699 6.558 1.16699 6.42535 1.18483C5.76333 1.27383 5.18946 1.68746 4.89567 2.28735C4.8368 2.40756 4.79018 2.54744 4.69693 2.82719L4.66699 2.91699M5.83366 5.83366V9.91699M8.16699 5.83366V8.16699",
              stroke: "#606266",
              "stroke-width": "0.857143",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1 /* HOISTED */)
        ]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString($data.theSshKey), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $data.deleteDialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.deleteDialogVisible) = $event)),
      title: _ctx.$t('sshKey.delKeyName', {value: $props.sshKeyName}),
      width: "30%",
      class: "dialogWidth",
      style: {"border-radius":"0.5rem"},
      left: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = $event => ($data.deleteDialogVisible = false))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Cancel")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = $event => ($options.confirmDeleteSshKey($data.theSshKeyName)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sshKey.sureDelKey')), 1 /* TEXT */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ], 64 /* STABLE_FRAGMENT */))
}