import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[480px]" }
const _hoisted_2 = { class: "flex justify-between mb-4" }
const _hoisted_3 = { class: "flex justify-between mb-4" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "flex justify-between mb-4" }
const _hoisted_6 = { class: "flex justify-between mb-4" }
const _hoisted_7 = { class: "flex gap-[16px]" }
const _hoisted_8 = { class: "card-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, null, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("span", null, "Code Detail")
        ], -1 /* HOISTED */)
      ])),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_button, {
              onClick: $setup.redirectCodeEdit,
              class: "w-[100px]"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("编辑")
              ])),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("label", null, "Name", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.code.name), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("label", null, "Owner", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.route.params.namespace), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "Creator", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.code.user?.username || ''), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", null, "Visibility", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.code.private == false ? 'public' : 'private'), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("label", null, "Created At", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.dayjs($setup.code.created_at).format('YYYY-MM-DD HH:mm:ss')), 1 /* TEXT */)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}