<template>
  <div class="flex items-center gap-2 mb-1">
    <span 
      class="font-medium text-[14px] cursor-pointer hover:text-[#FF5005]"
      @click="$emit('profile-click', comment.user_name)"
    >
      {{ userDetails[comment.user_name]?.nickname || comment.user_name }}
    </span>
    <span 
      v-if="comment.parent_id"
      class="text-[12px] text-[#86909C]"
    >
      回复
      <span 
        class="text-[#1D2129] cursor-pointer hover:text-[#FF5005]"
        @click="$emit('parent-click', comment.parent_id)"
      >
        {{ getParentPreview() }}
      </span>
    </span>
    <span class="text-[12px] text-[#86909C]">{{ formatDate(comment.created_at) }}</span>
    <span v-if="isEdited" class="text-[12px] text-[#86909C]">(已编辑)</span>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CommentHeader',
  props: {
    comment: {
      type: Object,
      required: true
    },
    userDetails: {
      type: Object,
      required: true
    },
    parentPreview: {
      type: String,
      default: '原评论'
    }
  },
  computed: {
    isEdited() {
      return this.comment.updated_at !== this.comment.created_at
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },
    getParentPreview() {
      if (!this.parentPreview || this.parentPreview === '原评论') return '原评论'
      return this.parentPreview.length > 10 
        ? this.parentPreview.slice(0, 10) + '...' 
        : this.parentPreview
    }
  }
}
</script>
