import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "bg-white py-[36px]" }
const _hoisted_2 = { class: "flex md:flex-col max-w-[1280px] px-[20px] m-auto" }
const _hoisted_3 = { class: "w-[752px] md:w-full md:border-none pr-[16px] border-r-[1px] border-[#EAECF0]" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "w-[20px] h-[20px]" }
const _hoisted_6 = { class: "text-[48px] font-[500] leading-[60px] mt-[16px] text-[#101828]" }
const _hoisted_7 = { class: "mt-[24px] text-[#475467] text-[20px] font-[300] leading-[30px] pb-[30px]" }
const _hoisted_8 = { class: "mt-[20px] text-[#475467] text-[18px] font-[300] leading-[28px]" }
const _hoisted_9 = { class: "mt-[20px] text-[#475467] text-[18px] font-[300] leading-[28px]" }
const _hoisted_10 = { class: "my-[48px]" }
const _hoisted_11 = { class: "flex gap-[12px]" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "flex flex-col gap-[24px] md:hidden" }
const _hoisted_15 = { class: "flex justify-between gap-[4px] border-b border-[#EBEEF5] items-center px-[12px] py-[9px] bg-[#F5F7FA]" }
const _hoisted_16 = { class: "flex items-center gap-[8px]" }
const _hoisted_17 = { class: "p-[16px]" }
const _hoisted_18 = { class: "flex gap-[8px] items-center mb-[16px]" }
const _hoisted_19 = { class: "text-[16px] font-[400] leading-[24px] text-[#909399]" }
const _hoisted_20 = { class: "flex flex-col gap-[16px]" }
const _hoisted_21 = { class: "flex gap-[8px] items-center mt-[48px] mb-[16px]" }
const _hoisted_22 = { class: "text-[16px] font-[400] leading-[24px] text-[#909399]" }
const _hoisted_23 = { class: "flex flex-col gap-[16px]" }
const _hoisted_24 = {
  key: 0,
  class: "flex gap-[8px] items-center mt-[48px] mb-[16px]"
}
const _hoisted_25 = {
  key: 1,
  class: "max-w-[480px] flex flex-col gap-[16px]"
}
const _hoisted_26 = {
  ref: "videoPlayer",
  style: {"width":"100%"},
  controls: "",
  muted: ""
}
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "flex flex-col gap-[24px] hidden mt-[32px] md:block" }
const _hoisted_29 = { class: "flex justify-between gap-[4px] border-b border-[#EBEEF5] items-center px-[12px] py-[9px] bg-[#F5F7FA]" }
const _hoisted_30 = { class: "flex items-center gap-[8px]" }
const _hoisted_31 = { class: "mt-[24px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopRight = _resolveComponent("TopRight")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: $data.backUrl,
          class: "flex gap-[5px] text-[#475467] cursor-pointer hover-filter-primary px-[12px] py-[8px]"
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode($setup["ChevronLeft"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[14px] font-[500] leading-[20px]" }, "返回文章列表", -1 /* HOISTED */))
        ], 8 /* PROPS */, _hoisted_4),
        _createElementVNode("div", _hoisted_6, _toDisplayString($data.data.title), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_7, " 原文发布于 " + _toDisplayString($setup.dayjs($data.data.published_at).format("MM-DD")) + " - " + _toDisplayString($setup.dayjs($data.data.created_at).format("MM-DD")) + " 刊登于《社区文章》 ", 1 /* TEXT */),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mt-[24px] text-[30px] font-[500] leading-[38px] text-[#101828]" }, "推荐语", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_8, _toDisplayString($data.data.recommendation), 1 /* TEXT */),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "mt-[48px] text-[30px] font-[500] leading-[38px] text-[#101828]" }, " 摘要 ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_9, _toDisplayString($data.data.description), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            ($data.data.article_link)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: $data.data.article_link,
                  class: "flex gap-[4px] px-[14px] py-[10px] border-[1px] border-[#D0D5DD] rounded-[8px] cursor-pointer hover-filter-primary",
                  target: "_blank"
                }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_TopRight)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-[14px] font-[500] leading-[20px]" }, " 查看 arXiv 页面 ", -1 /* HOISTED */))
                ], 8 /* PROPS */, _hoisted_12))
              : _createCommentVNode("v-if", true),
            ($data.data.pdf_link)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: $data.data.pdf_link,
                  class: "flex gap-[4px] px-[14px] py-[10px] border-[1px] border-[#D0D5DD] rounded-[8px] cursor-pointer hover-filter-primary",
                  target: "_blank"
                }, [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_TopRight)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-[14px] font-[500] leading-[20px]" }, " 查看 PDF ", -1 /* HOISTED */))
                ], 8 /* PROPS */, _hoisted_13))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"flex gap-[8px] items-center\"><div class=\"px-[8px] py-[8px] bg-[#F0F3FF] rounded-[8px]\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path opacity=\"0.12\" d=\"M9 13.2C9 12.0799 9 11.5198 9.21799 11.092C9.40973 10.7157 9.71569 10.4097 10.092 10.218C10.5198 10 11.0799 10 12.2 10H18.8C19.9201 10 20.4802 10 20.908 10.218C21.2843 10.4097 21.5903 10.7157 21.782 11.092C22 11.5198 22 12.0799 22 13.2V20.3636C22 21.357 20.8103 21.8669 20.0909 21.1818C19.3906 20.5149 18.4606 20.1429 17.4935 20.1429H12.2C11.0799 20.1429 10.5198 20.1429 10.092 19.9249C9.71569 19.7331 9.40973 19.4272 9.21799 19.0508C9 18.623 9 18.063 9 16.9429V13.2Z\" fill=\"#4D6AD6\"></path><path d=\"M9 14H7.25C7.00285 14 6.87928 14 6.75895 14.0097C6.27553 14.0487 5.80877 14.2043 5.39866 14.4631C5.29658 14.5276 5.19772 14.6017 5 14.75V14.75C4.48857 15.1336 4.23286 15.3254 4.03782 15.4003C3.21483 15.7164 2.29801 15.258 2.05709 14.4099C2 14.2089 2 13.8893 2 13.25V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H13.2C14.8802 2 15.7202 2 16.362 2.32698C16.9265 2.6146 17.3854 3.07354 17.673 3.63803C18 4.27976 18 5.11984 18 6.8V7V7.5M20.0909 21.1818V21.1818C20.8103 21.8669 22 21.357 22 20.3636V13.2C22 12.0799 22 11.5198 21.782 11.092C21.5903 10.7157 21.2843 10.4097 20.908 10.218C20.4802 10 19.9201 10 18.8 10H12.2C11.0799 10 10.5198 10 10.092 10.218C9.71569 10.4097 9.40973 10.7157 9.21799 11.092C9 11.5198 9 12.0799 9 13.2V16.9429C9 18.063 9 18.623 9.21799 19.0508C9.40973 19.4272 9.71569 19.7331 10.092 19.9249C10.5198 20.1429 11.0799 20.1429 12.2 20.1429H17.4935C18.4606 20.1429 19.3906 20.5149 20.0909 21.1818Z\" stroke=\"#4D6AD6\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg></div> 讨论 </div>", 1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.commentData, (comment) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "border border-[#EBEEF5] border-b-[2px] rounded-[4px]",
              key: comment.id
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_el_avatar, {
                    size: 24,
                    src: comment.user.avatar
                  }, null, 8 /* PROPS */, ["src"]),
                  _createTextVNode(" " + _toDisplayString(comment.user.name) + " " + _toDisplayString(comment.time), 1 /* TEXT */)
                ]),
                _createVNode(_component_el_dropdown, null, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_dropdown_item, {
                          onClick: $event => (_ctx.quote(comment.content))
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("svg", {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none"
                            }, [
                              _createElementVNode("g", { opacity: "0.12" }, [
                                _createElementVNode("path", {
                                  d: "M6.66658 11.2C6.66658 11.9467 6.66658 12.3201 6.52126 12.6053C6.39343 12.8562 6.18946 13.0602 5.93857 13.188C5.65336 13.3333 5.27999 13.3333 4.53325 13.3333H3.46659C2.71985 13.3333 2.34648 13.3333 2.06126 13.188C1.81038 13.0602 1.60641 12.8562 1.47858 12.6053C1.33325 12.3201 1.33325 11.9467 1.33325 11.2L1.33325 10.1333C1.33325 9.3866 1.33325 9.01323 1.47858 8.72801C1.60641 8.47713 1.81038 8.27316 2.06126 8.14532C2.34648 8 2.71985 8 3.46659 8H4.53325C5.27999 8 5.65336 8 5.93857 8.14532C6.18946 8.27316 6.39343 8.47713 6.52126 8.72801C6.66658 9.01323 6.66658 9.3866 6.66658 10.1333V11.2Z",
                                  fill: "#2F384C"
                                }),
                                _createElementVNode("path", {
                                  d: "M14.6666 11.2C14.6666 11.9467 14.6666 12.3201 14.5213 12.6053C14.3934 12.8562 14.1895 13.0602 13.9386 13.188C13.6534 13.3333 13.28 13.3333 12.5333 13.3333L11.4666 13.3333C10.7198 13.3333 10.3465 13.3333 10.0613 13.188C9.81038 13.0602 9.60641 12.8562 9.47858 12.6053C9.33325 12.3201 9.33325 11.9467 9.33325 11.2V10.1333C9.33325 9.3866 9.33325 9.01323 9.47858 8.72801C9.60641 8.47713 9.81038 8.27316 10.0613 8.14532C10.3465 8 10.7198 8 11.4666 8L12.5333 8C13.28 8 13.6534 8 13.9386 8.14532C14.1895 8.27316 14.3934 8.47713 14.5213 8.72801C14.6666 9.01323 14.6666 9.3866 14.6666 10.1333V11.2Z",
                                  fill: "#2F384C"
                                })
                              ]),
                              _createElementVNode("path", {
                                d: "M1.33325 10.6665L1.33325 7.98414C1.33325 7.00577 1.33325 6.51659 1.44377 6.05624C1.54176 5.6481 1.70338 5.25792 1.92269 4.90003C2.17006 4.49636 2.51596 4.15046 3.20777 3.45865L3.99992 2.6665M9.33325 10.6665V7.98414C9.33325 7.00577 9.33325 6.51659 9.44377 6.05624C9.54176 5.6481 9.70338 5.25792 9.92269 4.90003C10.1701 4.49636 10.516 4.15046 11.2078 3.45865L11.9999 2.6665M12.5333 13.3332H11.4666C10.7198 13.3332 10.3465 13.3332 10.0613 13.1878C9.81038 13.06 9.60641 12.856 9.47858 12.6052C9.33325 12.3199 9.33325 11.9466 9.33325 11.1998V10.1332C9.33325 9.38643 9.33325 9.01307 9.47858 8.72785C9.60641 8.47697 9.81038 8.27299 10.0613 8.14516C10.3465 7.99984 10.7198 7.99984 11.4666 7.99984L12.5332 7.99984C13.28 7.99984 13.6534 7.99984 13.9386 8.14516C14.1895 8.27299 14.3934 8.47697 14.5213 8.72785C14.6666 9.01307 14.6666 9.38643 14.6666 10.1332V11.1998C14.6666 11.9466 14.6666 12.3199 14.5213 12.6052C14.3934 12.856 14.1895 13.06 13.9386 13.1878C13.6534 13.3332 13.28 13.3332 12.5333 13.3332ZM4.53325 7.99984H3.46659C2.71985 7.99984 2.34648 7.99984 2.06126 8.14516C1.81038 8.27299 1.60641 8.47697 1.47858 8.72785C1.33325 9.01307 1.33325 9.38643 1.33325 10.1332L1.33325 11.1998C1.33325 11.9466 1.33325 12.3199 1.47858 12.6052C1.60641 12.856 1.81038 13.06 2.06126 13.1878C2.34648 13.3332 2.71985 13.3332 3.46659 13.3332H4.53325C5.27999 13.3332 5.65336 13.3332 5.93857 13.1878C6.18946 13.06 6.39343 12.856 6.52126 12.6052C6.66658 12.3199 6.66658 11.9466 6.66658 11.1998V10.1332C6.66658 9.38643 6.66658 9.01307 6.52126 8.72785C6.39343 8.47697 6.18946 8.27299 5.93857 8.14516C5.65336 7.99984 5.27999 7.99984 4.53325 7.99984Z",
                                stroke: "#2F384C",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              })
                            ], -1 /* HOISTED */),
                            _createElementVNode("span", { class: "ml-[4px]" }, "回复", -1 /* HOISTED */)
                          ])),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("div", null, [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        fill: "none"
                      }, [
                        _createElementVNode("path", {
                          d: "M7.66667 4.66634C8.58333 4.66634 9.33333 3.91634 9.33333 2.99967C9.33333 2.08301 8.58333 1.33301 7.66667 1.33301C6.75 1.33301 6 2.08301 6 2.99967C6 3.91634 6.75 4.66634 7.66667 4.66634Z",
                          fill: "#606266"
                        }),
                        _createElementVNode("path", {
                          d: "M7.66667 6.33301C6.75 6.33301 6 7.08301 6 7.99967C6 8.91634 6.75 9.66634 7.66667 9.66634C8.58333 9.66634 9.33333 8.91634 9.33333 7.99967C9.33333 7.08301 8.58333 6.33301 7.66667 6.33301Z",
                          fill: "#606266"
                        }),
                        _createElementVNode("path", {
                          d: "M6 12.9997C6 12.083 6.75 11.333 7.66667 11.333C8.58333 11.333 9.33333 12.083 9.33333 12.9997C9.33333 13.9163 8.58333 14.6663 7.66667 14.6663C6.75 14.6663 6 13.9163 6 12.9997Z",
                          fill: "#606266"
                        })
                      ])
                    ], -1 /* HOISTED */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _createVNode($setup["MarkdownViewer"], {
                class: "px-[12px] py-[12px]",
                content: comment.content,
                setDefaultText: true
              }, null, 8 /* PROPS */, ["content"])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createVNode($setup["CommunityMDTextarea"], {
            ref: "mdTextarea",
            desc: $data.desc,
            onInputChange: $options.handleInputChange
          }, null, 8 /* PROPS */, ["desc", "onInputChange"]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $options.create
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("创建")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_el_button, { onClick: $options.cancel }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("取消")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode($setup["ModelIcon"], {
            width: "18px",
            height: "18px"
          }),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-[16px] font-[500] leading-[24px] text-[#303133]" }, "关联模型", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_19, _toDisplayString($data.data.model_links.length), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_20, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.modelList, (model) => {
            return (_openBlock(), _createBlock($setup["ModelItem"], {
              key: model.path,
              model: model
            }, null, 8 /* PROPS */, ["model"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode($setup["DatasetIcon"], {
            width: "18px",
            height: "18px"
          }),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-[16px] font-[500] leading-[24px] text-[#303133]" }, "关联数据集", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_22, _toDisplayString($data.data.dataset_links.length), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_23, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataSetList, (dataset) => {
            return (_openBlock(), _createBlock($setup["DatasetItem"], {
              key: dataset.path,
              dataset: dataset
            }, null, 8 /* PROPS */, ["dataset"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        ($data.data.video_link!=''&&$data.data.video_link!=_ctx.nil)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[12] || (_cache[12] = [
              _createElementVNode("div", { class: "text-[16px] font-[500] leading-[24px] text-[#303133]" }, "关联视频", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true),
        ($data.data.video_link!=''&&$data.data.video_link!=_ctx.nil)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("video", _hoisted_26, [
                _createElementVNode("source", {
                  src: $data.data.video_link,
                  type: "video/mp4"
                }, null, 8 /* PROPS */, _hoisted_27)
              ], 512 /* NEED_PATCH */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_28, [
          _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"flex gap-[8px] items-center mb-[24px]\"><div class=\"px-[8px] py-[8px] bg-[#F0F3FF] rounded-[8px]\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path opacity=\"0.12\" d=\"M9 13.2C9 12.0799 9 11.5198 9.21799 11.092C9.40973 10.7157 9.71569 10.4097 10.092 10.218C10.5198 10 11.0799 10 12.2 10H18.8C19.9201 10 20.4802 10 20.908 10.218C21.2843 10.4097 21.5903 10.7157 21.782 11.092C22 11.5198 22 12.0799 22 13.2V20.3636C22 21.357 20.8103 21.8669 20.0909 21.1818C19.3906 20.5149 18.4606 20.1429 17.4935 20.1429H12.2C11.0799 20.1429 10.5198 20.1429 10.092 19.9249C9.71569 19.7331 9.40973 19.4272 9.21799 19.0508C9 18.623 9 18.063 9 16.9429V13.2Z\" fill=\"#4D6AD6\"></path><path d=\"M9 14H7.25C7.00285 14 6.87928 14 6.75895 14.0097C6.27553 14.0487 5.80877 14.2043 5.39866 14.4631C5.29658 14.5276 5.19772 14.6017 5 14.75V14.75C4.48857 15.1336 4.23286 15.3254 4.03782 15.4003C3.21483 15.7164 2.29801 15.258 2.05709 14.4099C2 14.2089 2 13.8893 2 13.25V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H13.2C14.8802 2 15.7202 2 16.362 2.32698C16.9265 2.6146 17.3854 3.07354 17.673 3.63803C18 4.27976 18 5.11984 18 6.8V7V7.5M20.0909 21.1818V21.1818C20.8103 21.8669 22 21.357 22 20.3636V13.2C22 12.0799 22 11.5198 21.782 11.092C21.5903 10.7157 21.2843 10.4097 20.908 10.218C20.4802 10 19.9201 10 18.8 10H12.2C11.0799 10 10.5198 10 10.092 10.218C9.71569 10.4097 9.40973 10.7157 9.21799 11.092C9 11.5198 9 12.0799 9 13.2V16.9429C9 18.063 9 18.623 9.21799 19.0508C9.40973 19.4272 9.71569 19.7331 10.092 19.9249C10.5198 20.1429 11.0799 20.1429 12.2 20.1429H17.4935C18.4606 20.1429 19.3906 20.5149 20.0909 21.1818Z\" stroke=\"#4D6AD6\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg></div> 讨论 </div>", 1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.commentData, (comment) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "border border-[#EBEEF5] border-b-[2px] rounded-[4px] mb-[24px]",
              key: comment.id
            }, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_el_avatar, {
                    size: 24,
                    src: comment.user.avatar
                  }, null, 8 /* PROPS */, ["src"]),
                  _createTextVNode(" " + _toDisplayString(comment.user.name) + " " + _toDisplayString(comment.time), 1 /* TEXT */)
                ]),
                _createVNode(_component_el_dropdown, null, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_dropdown_item, {
                          onClick: $event => (_ctx.quote(comment.content))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("svg", {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              viewBox: "0 0 16 16",
                              fill: "none"
                            }, [
                              _createElementVNode("g", { opacity: "0.12" }, [
                                _createElementVNode("path", {
                                  d: "M6.66658 11.2C6.66658 11.9467 6.66658 12.3201 6.52126 12.6053C6.39343 12.8562 6.18946 13.0602 5.93857 13.188C5.65336 13.3333 5.27999 13.3333 4.53325 13.3333H3.46659C2.71985 13.3333 2.34648 13.3333 2.06126 13.188C1.81038 13.0602 1.60641 12.8562 1.47858 12.6053C1.33325 12.3201 1.33325 11.9467 1.33325 11.2L1.33325 10.1333C1.33325 9.3866 1.33325 9.01323 1.47858 8.72801C1.60641 8.47713 1.81038 8.27316 2.06126 8.14532C2.34648 8 2.71985 8 3.46659 8H4.53325C5.27999 8 5.65336 8 5.93857 8.14532C6.18946 8.27316 6.39343 8.47713 6.52126 8.72801C6.66658 9.01323 6.66658 9.3866 6.66658 10.1333V11.2Z",
                                  fill: "#2F384C"
                                }),
                                _createElementVNode("path", {
                                  d: "M14.6666 11.2C14.6666 11.9467 14.6666 12.3201 14.5213 12.6053C14.3934 12.8562 14.1895 13.0602 13.9386 13.188C13.6534 13.3333 13.28 13.3333 12.5333 13.3333L11.4666 13.3333C10.7198 13.3333 10.3465 13.3333 10.0613 13.188C9.81038 13.0602 9.60641 12.8562 9.47858 12.6053C9.33325 12.3201 9.33325 11.9467 9.33325 11.2V10.1333C9.33325 9.3866 9.33325 9.01323 9.47858 8.72801C9.60641 8.47713 9.81038 8.27316 10.0613 8.14532C10.3465 8 10.7198 8 11.4666 8L12.5333 8C13.28 8 13.6534 8 13.9386 8.14532C14.1895 8.27316 14.3934 8.47713 14.5213 8.72801C14.6666 9.01323 14.6666 9.3866 14.6666 10.1333V11.2Z",
                                  fill: "#2F384C"
                                })
                              ]),
                              _createElementVNode("path", {
                                d: "M1.33325 10.6665L1.33325 7.98414C1.33325 7.00577 1.33325 6.51659 1.44377 6.05624C1.54176 5.6481 1.70338 5.25792 1.92269 4.90003C2.17006 4.49636 2.51596 4.15046 3.20777 3.45865L3.99992 2.6665M9.33325 10.6665V7.98414C9.33325 7.00577 9.33325 6.51659 9.44377 6.05624C9.54176 5.6481 9.70338 5.25792 9.92269 4.90003C10.1701 4.49636 10.516 4.15046 11.2078 3.45865L11.9999 2.6665M12.5333 13.3332H11.4666C10.7198 13.3332 10.3465 13.3332 10.0613 13.1878C9.81038 13.06 9.60641 12.856 9.47858 12.6052C9.33325 12.3199 9.33325 11.9466 9.33325 11.1998V10.1332C9.33325 9.38643 9.33325 9.01307 9.47858 8.72785C9.60641 8.47697 9.81038 8.27299 10.0613 8.14516C10.3465 7.99984 10.7198 7.99984 11.4666 7.99984L12.5332 7.99984C13.28 7.99984 13.6534 7.99984 13.9386 8.14516C14.1895 8.27299 14.3934 8.47697 14.5213 8.72785C14.6666 9.01307 14.6666 9.38643 14.6666 10.1332V11.1998C14.6666 11.9466 14.6666 12.3199 14.5213 12.6052C14.3934 12.856 14.1895 13.06 13.9386 13.1878C13.6534 13.3332 13.28 13.3332 12.5333 13.3332ZM4.53325 7.99984H3.46659C2.71985 7.99984 2.34648 7.99984 2.06126 8.14516C1.81038 8.27299 1.60641 8.47697 1.47858 8.72785C1.33325 9.01307 1.33325 9.38643 1.33325 10.1332L1.33325 11.1998C1.33325 11.9466 1.33325 12.3199 1.47858 12.6052C1.60641 12.856 1.81038 13.06 2.06126 13.1878C2.34648 13.3332 2.71985 13.3332 3.46659 13.3332H4.53325C5.27999 13.3332 5.65336 13.3332 5.93857 13.1878C6.18946 13.06 6.39343 12.856 6.52126 12.6052C6.66658 12.3199 6.66658 11.9466 6.66658 11.1998V10.1332C6.66658 9.38643 6.66658 9.01307 6.52126 8.72785C6.39343 8.47697 6.18946 8.27299 5.93857 8.14516C5.65336 7.99984 5.27999 7.99984 4.53325 7.99984Z",
                                stroke: "#2F384C",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              })
                            ], -1 /* HOISTED */),
                            _createElementVNode("span", { class: "ml-[4px]" }, "回复", -1 /* HOISTED */)
                          ])),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createElementVNode("div", null, [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        fill: "none"
                      }, [
                        _createElementVNode("path", {
                          d: "M7.66667 4.66634C8.58333 4.66634 9.33333 3.91634 9.33333 2.99967C9.33333 2.08301 8.58333 1.33301 7.66667 1.33301C6.75 1.33301 6 2.08301 6 2.99967C6 3.91634 6.75 4.66634 7.66667 4.66634Z",
                          fill: "#606266"
                        }),
                        _createElementVNode("path", {
                          d: "M7.66667 6.33301C6.75 6.33301 6 7.08301 6 7.99967C6 8.91634 6.75 9.66634 7.66667 9.66634C8.58333 9.66634 9.33333 8.91634 9.33333 7.99967C9.33333 7.08301 8.58333 6.33301 7.66667 6.33301Z",
                          fill: "#606266"
                        }),
                        _createElementVNode("path", {
                          d: "M6 12.9997C6 12.083 6.75 11.333 7.66667 11.333C8.58333 11.333 9.33333 12.083 9.33333 12.9997C9.33333 13.9163 8.58333 14.6663 7.66667 14.6663C6.75 14.6663 6 13.9163 6 12.9997Z",
                          fill: "#606266"
                        })
                      ])
                    ], -1 /* HOISTED */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _createVNode($setup["MarkdownViewer"], {
                class: "px-[12px] py-[12px]",
                content: comment.content,
                setDefaultText: true
              }, null, 8 /* PROPS */, ["content"])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createVNode($setup["CommunityMDTextarea"], {
            ref: "mdTextarea",
            desc: $data.desc,
            onInputChange: $options.handleInputChange
          }, null, 8 /* PROPS */, ["desc", "onInputChange"]),
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $options.create
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("创建")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_el_button, { onClick: $options.cancel }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("取消")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ])
      ])
    ])
  ]))
}