export const ekb = {
  "title": "大模型时代",
  "desc": "企业知识库全生命周期管理基座",
  "tips": "千面助理，大模型帮您快速完成繁杂任务",
  "askTitle": "快问快答",
  "askTitle2": "流畅强大",
  "askDesc": "一键溯源，直达知识出处。",
  "askSTitle": "一站式企业文档管理平台",
  "askSText": "极大提升知识、数据查询处理效率",
  "askSTitle2": "注重企业信息安全性",
  "askStText2": "混合云架构，持续保护企业资产及客户隐私数据",
  "moreP": "更多功能",
  "morePTitle": "了解更多 EKB AI 强大功能",
  "morePText": "EKB AI 作为企业知识全生命周期管理的基础设施，辅助用户实现企业内部知识的“提取、存储、流转、利用”。像人类大脑一样，EKB AI 辅助存储、理解和创造知识，开启知识管理的新模式，大幅降低使用企业内部非结构化数据的门槛。",
  "moreDataTitle": "多模态、多语言 全方位汇总输出",
  "moreDataDesc": "系统支持多种格式，包括PDF、Word、Excel以及在线网页链接，极大便利了企业资料归集与整理。",
  "moreDataTitle2": "智能文档识别与精确搜索",
  "moreDataDesc2": "利用自动识别技术及OCR高精度提取，有效辨识不同格式文档并准确进行内容检索。",
  "moreDataTitle3": "创建 API 接口支持",
  "moreDataDesc3": "提供多AI模型选择，轻松对接钉钉、微信等第三方平台。",
  "moreDataTitle4": "问答机器人一键部署",
  "moreDataDesc4": "支持用户快速设置智能客服、聊天机器人等，提升服务效率。",
  "adv": "我们的优势",
  "advTitle": "更加智能的大语言模型知识助手",
  "advText": "帮助您解决个人及团队多样的产品使用需求，支持多种安全便捷的部署方式以及具备强大的数据分析能力",
  "advDataTitle": "大模型知识管理基座",
  "advDataDesc": "强大的企业知识、文档和数据资产的管理平台，全面管理企业知识库并提供一站式信息查询及解决方案。借助于与LLM结合的先进算法，可以理解和组织大量非结构化数据。",
  "advDataTitle2": "无所不知的 AI 专家",
  "advDataDesc2": "功能强大且智能的AI助理，如同一个随身的专家助手，能够理解用户的查询请求，随时提供信息，简化同步过程。是用户与企业知识库的桥梁，通过自然交互，便捷获取关键信息。",
  "advDataTitle3": "灵活的部署选项",
  "advDataDesc3": "支持多种部署方式，包括在线SaaS、私有云和公有云，也可完全私有化。结合CAICT一体机解决方案，可在客户自选兼容硬件上部署，大幅降低客户的使用成本。",
  "advDataTitle4": "智能 AI 文档与数据分析",
  "advDataDesc4": "文档和高效数据分析模型，快速解决企业问题，用于智能客服和报表分析等领域。进行数据挖掘和模式分析，输出易于理解的报告，并提供数据可视化功能。"
}