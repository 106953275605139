import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "InviteMember" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "px-[12px] py-[12px] rounded-[10px] border-[2px] border-[#EAECF0]" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "text-[18px] leading-[28px] text-[#101828]" }
const _hoisted_6 = { class: "text-[14px] leading-[20px] text-[#475467] font-light" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "mt-[20px]" }
const _hoisted_9 = { class: "mb-[20px]" }
const _hoisted_10 = { class: "text-[#344054] text-[14px] mb-[6px]" }
const _hoisted_11 = { class: "text-[#344054] text-[14px] mb-[6px]" }
const _hoisted_12 = { class: "flex gap-[4px] flex-wrap items-center w-full border rounded-[4px] border-gray-300 min-h-[40px] p-[6px]" }
const _hoisted_13 = { class: "scroll-container flex gap-[4px] flex-wrap max-h-[120px] overflow-y-auto" }
const _hoisted_14 = { class: "flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "md:max-h-[110px] max-h-[210px] overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "flex justify-between" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_Close = _resolveComponent("Close")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" org detail invite button "),
    ($props.role === 'admin')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogVisible = true)),
          class: "px-[12px] py-[8px] flex gap-[4px] items-center rounded-md border border-gray-300 text-[14px]"
        }, [
          _createVNode(_component_SvgIcon, { name: "invite_org_member" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('organization.invite.title')), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" invite dialog "),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.dialogVisible) = $event)),
      top: "10vh",
      style: { borderRadius: '10px' },
      width: "450",
      class: "invite_dialog"
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SvgIcon, { name: "invite_org_member" })
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: "/images/invite_bg.png",
            class: "w-[200px] absolute top-0 left-0"
          }, null, -1 /* HOISTED */))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_19, [
          _createVNode(_component_el_button, {
            class: "w-[50%]",
            size: "large",
            onClick: _cache[3] || (_cache[3] = $event => ($setup.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            class: "w-[50%]",
            size: "large",
            type: "primary",
            onClick: $setup.confirmInviteNewMember
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('organization.invite.inviteNew')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.$t('organization.invite.inviteDesc')) + " ", 1 /* TEXT */),
            _createElementVNode("span", _hoisted_7, _toDisplayString($props.orgName), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('all.role')), 1 /* TEXT */),
                _createVNode(_component_el_select, {
                  modelValue: $setup.userRoleInput,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.userRoleInput) = $event)),
                  placeholder: this.$t('all.select'),
                  size: "large",
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.roleMappings, (item) => {
                      return _createVNode(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8 /* PROPS */, ["label", "value"])
                    }), 64 /* STABLE_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('all.userName')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectedUsers, (user) => {
                    return (_openBlock(), _createElementBlock("span", _hoisted_14, [
                      _createElementVNode("img", {
                        src: user.avatar,
                        height: "16",
                        width: "16"
                      }, null, 8 /* PROPS */, _hoisted_15),
                      _createTextVNode(" " + _toDisplayString(user.username) + " ", 1 /* TEXT */),
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Close, {
                            onClick: $event => ($setup.removeUser(user.username))
                          }, null, 8 /* PROPS */, ["onClick"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full max-h-[36px] outline-none",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.userNameInput) = $event)),
                  onInput: $setup.showUserList
                }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                  [_vModelText, $setup.userNameInput]
                ])
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userList, (user) => {
                  return (_openBlock(), _createElementBlock("p", {
                    onClick: $event => ($setup.selectUser(user)),
                    class: _normalizeClass(["flex gap-[8px] items-center p-[10px]", user.invited ? 'cursor-not-allowed' : 'cursor-pointer'])
                  }, [
                    _createElementVNode("img", {
                      src: user.avatar || 'https://cdn.casbin.org/img/casbin.svg',
                      height: "16",
                      width: "16"
                    }, null, 8 /* PROPS */, _hoisted_18),
                    _createTextVNode(" " + _toDisplayString(user.username) + " ", 1 /* TEXT */),
                    _createElementVNode("span", null, _toDisplayString(user.invited ? _ctx.$t('organization.invite.invited') : ''), 1 /* TEXT */)
                  ], 10 /* CLASS, PROPS */, _hoisted_17))
                }), 256 /* UNKEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */), [
                [_vShow, $setup.shouldShowUserList]
              ])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}