export const shared = {
  commitToMain: "Commit directly to the main branch",
  dragOr: "Drag and drop files here or",
  uploadFile5Mb: "Support uploading files up to 5MB in size",
  onlyFiveFiles: "Supports up to 5 files",
  lfs1: "This file is stored with",
  lfs2: "Git LFS",
  lfs3: ". It's too big to display, but you can still",
  lfs4: "download",
  lfs5: "it.",
  lfs6: "Git LFS Details",
  lfs7: "Pointer size",
  lfs8: "Size of remote file",
  lfs9: "Raw pointer file",
  lfs10: "Git Large File Storage (LFS) replaces large files with text pointers inside Git, while storing the file contents on a remote server.",
  lfs11: "More info",
  preview: "Preview",
  edit: "Edit",
  likes: "Likes",
  hasLikes: "Liked"
}