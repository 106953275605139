import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col min-h-screen" }
const _hoisted_2 = { class: "border-b border-[#DCDFE6] flex-shrink-0" }
const _hoisted_3 = { class: "max-w-[1400px] mx-auto px-4 py-2 flex items-center justify-between" }
const _hoisted_4 = { class: "flex items-center gap-3" }
const _hoisted_5 = { class: "flex items-center justify-center gap-1" }
const _hoisted_6 = { class: "flex items-center gap-3" }
const _hoisted_7 = { class: "flex-1 py-6" }
const _hoisted_8 = { class: "w-[1200px] mx-auto" }
const _hoisted_9 = { class: "flex gap-4 mb-6" }
const _hoisted_10 = { class: "editor-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_CKEditor5 = _resolveComponent("CKEditor5")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 顶部操作区 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_button, {
            class: "!h-[40px] !text-[14px] !text-[#666] !bg-white !rounded-[6px] !border-[#DCDFE6]",
            onClick: $options.goBack
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_el_icon, { class: "!text-[#666]" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowLeft)
                  ]),
                  _: 1 /* STABLE */
                }),
                _cache[4] || (_cache[4] = _createTextVNode(" 返回 "))
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-[18px] font-[500] text-[#1D2129]" }, "创建文档", -1 /* HOISTED */))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_radio_group, {
            modelValue: $data.dataForm.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.status) = $event)),
            class: "!mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, { label: 0 }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("草稿")
                ])),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_radio, { label: 1 }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("发布")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_el_button, {
            class: "!min-w-[100px] !h-[40px] !text-[14px] !text-white !bg-[#FF5005] !rounded-[6px] !border-[#FF5005]",
            onClick: $options.handleSubmit
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("提交文档")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _createCommentVNode(" 主要内容区 "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_el_form, {
          ref: "dataForm",
          model: $data.dataForm,
          rules: $data.rules,
          "label-position": "top"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" 标题和分类行 "),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_el_form_item, {
                label: "文档标题",
                prop: "title",
                class: "flex-[3]"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $data.dataForm.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.dataForm.title) = $event)),
                    placeholder: "请输入文档标题",
                    class: "!text-[16px]",
                    maxlength: 100,
                    "show-word-limit": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, {
                label: "文档分类",
                prop: "doc_type",
                class: "flex-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $data.dataForm.doc_type,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.doc_type) = $event)),
                    placeholder: "请选择文档分类",
                    class: "!w-full"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.docTypes, (label, value) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: value,
                          label: label,
                          value: value
                        }, null, 8 /* PROPS */, ["label", "value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode(" 编辑器区域 "),
            _createVNode(_component_el_form_item, {
              label: "文档内容",
              prop: "content"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_CKEditor5, {
                    content: $data.dataForm.content,
                    "onUpdate:content": _cache[3] || (_cache[3] = $event => (($data.dataForm.content) = $event))
                  }, null, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ])
  ]))
}