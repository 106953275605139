import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_arrow_down = _resolveComponent("arrow-down")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dropdown, null, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.branches, (branch) => {
              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: branch.name,
                onClick: $event => ($setup.handleClick(branch.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(branch.name), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_button, { type: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_SvgIcon, {
              name: "branch",
              class: "mr-1"
            }),
            _createTextVNode(" " + _toDisplayString($props.currentBranch) + " ", 1 /* TEXT */),
            _createVNode(_component_el_icon, { class: "ml-1 el-icon--right" }, {
              default: _withCtx(() => [
                _createVNode(_component_arrow_down)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}