import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[16px] min-h-[300px] py-[32px] md:px-5" }
const _hoisted_2 = { class: "test-[14px]" }
const _hoisted_3 = { class: "border border-[#DCDFE6] rounded-[4px] bg-[#F5F7FA]" }
const _hoisted_4 = { class: "flex text-[14px] text-[#4D6AD6] leading-[22px]" }
const _hoisted_5 = { class: "px-[20px] py-[9px] border-r bg-white w-[140px]" }
const _hoisted_6 = { class: "px-[16px] py-[16px] bg-white cursor-pointer" }
const _hoisted_7 = { class: "el-upload__text py-[16px] px-[24px]" }
const _hoisted_8 = { class: "el-upload__tip" }
const _hoisted_9 = { class: "text-[14px] mb-[8px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString($props.repoName) + "/", 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('all.uploadFile')), 1 /* TEXT */),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "border-b w-full" }, null, -1 /* HOISTED */))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_upload, {
          ref: "uploadRef",
          class: "upload-demo",
          drag: "",
          multiple: "",
          limit: 5,
          headers: { 'X-CSRF-TOKEN': $setup.csrf_token },
          data: { commit_title: $setup.commitTitle, commit_desc: $setup.commitDesc},
          "auto-upload": false,
          onChange: $setup.handleFileChange,
          "on-remove": $setup.handleRemove,
          "on-error": _ctx.handleError
        }, {
          tip: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('shared.uploadFile5Mb')), 1 /* TEXT */)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("svg", {
                  width: "20",
                  height: "18",
                  viewBox: "0 0 20 18",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613",
                    stroke: "#475467",
                    "stroke-width": "1.66667",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  })
                ], -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t('shared.dragOr')) + " ", 1 /* TEXT */),
              _createElementVNode("em", null, _toDisplayString(_ctx.$t('all.clickUpload')), 1 /* TEXT */),
              _createTextVNode("（" + _toDisplayString(_ctx.$t('shared.onlyFiveFiles')) + "） ", 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["headers", "data", "on-error"])
      ])
    ]),
    _createVNode(_component_el_radio_group, {
      modelValue: $setup.new_branch,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.new_branch) = $event)),
      class: "my-4 py-4 border border-[#DCDFE6] border-l-0 border-r-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio, {
          label: "main",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('shared.commitToMain')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('all.submitChange')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        modelValue: $setup.commitTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.commitTitle) = $event)),
        maxLength: 200,
        "show-word-limit": "",
        clearable: "",
        placeholder: $setup.commitTitlePlaceholder
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createVNode($setup["CommunityMDTextarea"], {
      desc: "",
      placeholder: _ctx.$t('all.provideMoreDesc'),
      onInputChange: $setup.handleCommentInputChange
    }, null, 8 /* PROPS */, ["placeholder"]),
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: $setup.submitUpload,
        disabled: $setup.filesList.length === 0
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('all.uploadFile')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_el_button, { onClick: $setup.cancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('all.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}