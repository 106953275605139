import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.canReply)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('reply')))
        }, " 回复 "))
      : _createCommentVNode("v-if", true),
    ($options.isOwner)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", {
            class: "text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('edit')))
          }, " 编辑 "),
          _createElementVNode("span", {
            class: "text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]",
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.handleDelete && $options.handleDelete(...args)))
          }, " 删除 ")
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}