<template>
  <div class="flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0 ml-[32px] md:ml-0">
    <div v-if="!mobileScreen"
      class="flex w-[334px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full pb-[20px]">
      <!-- 案例类型 -->
      <h3 class="text-[#00000099] font-[500] text-[12px] py-[16px]">案例类型</h3>
      <div class="flex gap-[8px] flex-wrap">
        <template v-for="kind in kinds" :key="kind">
          <span
            :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${activeKind === kind ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
            @click="handleKindClick(kind)">
            {{ kind }}
          </span>
        </template>
      </div>
      <!-- 组织类型 -->
      <h3 class="text-[#00000099] font-[500] text-[12px] py-[16px]">组织类型</h3>
      <div class="flex gap-[8px] flex-wrap">
        <template v-for="org in org_types" :key="org">
          <span
            :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${activeOrgType === org ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
            @click="handleOrgTypeClick(org)">
            {{ org }}
          </span>
        </template>
      </div>
      <!-- 行业 -->
      <h3 class="text-[#00000099] font-[500] text-[12px] py-[16px]">行业</h3>
      <div class="flex gap-[8px] flex-wrap">
        <template v-for="tag in tags" :key="tag">
          <span
            :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
            @click="handleTagClick(tag)">
            {{ tag }}
          </span>
        </template>
      </div>
    </div>

    <div v-else class="w-full">
      <div class="border-b border-[#F2F2F2] px-[16px] py-[12px] text-[#000000E0]">
        <span
          :class="`${activeNavItem === 'Kind' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
          @click="this.drawer = true; this.activeNavItem = 'Kind'">
          案例类型
        </span>
        <span
          :class="`${activeNavItem === 'OrgType' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
          @click="this.drawer = true; this.activeNavItem = 'OrgType'">
          组织类型
        </span>
        <span
          :class="`${activeNavItem === 'Industry' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
          @click="this.drawer = true; this.activeNavItem = 'Industry'">
          行业
        </span>
      </div>

      <el-drawer v-model="drawer" direction="btt" :with-header="false">
        <div class="flex flex-wrap gap-[5px]" v-if="activeNavItem === 'Kind'">
          <template v-for="kind in kinds" :key="kind">
            <div
              :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${activeKind === kind ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
              @click="handleKindClick(kind)">
              {{ kind }}
            </div>
          </template>
        </div>
        <div class="flex flex-wrap gap-[5px]" v-else-if="activeNavItem === 'OrgType'">
          <template v-for="org in org_types" :key="org">
            <div
              :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${activeOrgType === org ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
              @click="handleOrgTypeClick(org)">
              {{ org }}
            </div>
          </template>
        </div>
        <div class="flex flex-wrap gap-[5px]" v-if="activeNavItem === 'Industry'">
          <template v-for="tag in tags" :key="tag">
            <div
              :class="`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`"
              @click="handleTagClick(tag)">
              {{ tag }}
            </div>
          </template>
        </div>
      </el-drawer>
    </div>

    <div class="pr-[20px] pt-[32px] md:px-[20px] md:pt-[0px] w-full">
      <div class="flex md:flex-col justify-between items-center px-[16px]">
        <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
          <SvgIcon
            name="case"
            width="18"
            height="18"
          />
          <span>
            案例展示
            <span class="text-gray-400 text-[16px] italic">
              {{ total }}
            </span>
          </span>
        </h3>
        <div class="flex justify-end gap-[16px] items-center mt-[16px] md:flex-col md:pl-0 md:mr-0">
          <div class="md:w-full">
            <el-input v-model="keywords" class="w-[320px] md:w-full" size="large" placeholder="搜索关键字"
              :prefix-icon="Search" @change="fetchCases" />
          </div>
          <div class="md:mt-[16px] md:w-full flex justify-end">
            <template v-if="canCreateCase">
              <a :href="`/cases/new`" class="btn btn-orange flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]">
                上传案例
              </a>
            </template>
            <template v-else>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="请先登录并注册组织后再上传案例"
                placement="top-start"
              >
                <a :href="`/cases/new`" class="btn bg-[var(--Gray-4)] text-[--Base-White] flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]">
                  上传案例
                </a>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 md:grid-cols-1 gap-[16px] px-[16px] py-[12px] md:px-[0px] mb-[60px]">
        <template v-for="item in cases" :key="item.id">
          <a :href="`/cases/${item.id}`">
            <div
              class="flex flex-col gap-[10px] border-[#E6E6E6] border-[1px] rounded-[4px] p-[16px] shadow-[0px_0px_0px_0px_rgba(0,0,0,0.05)] cursor-pointer xty-model-card">
              <div class="flex gap-[16px] justify-between text-[18px] font-[500] leading-[28px]">
                <div class="text-[rgba(0, 0, 0, 0.88)] overflow-ellipsis line-clamp line-clamp-1 max-w-[70%]">{{ item.title }}</div>
                <div v-if="item.build_status" :class="`text-[14px] px-[4px] rounded-[4px] cursor-pointer ${getThemeClass(item.build_status)}`">
                  {{ build_status_map[item.build_status] }}
                </div>
              </div>

              <div class="flex items-center gap-[8px] text-[#667085] text-[12px] leading-[18px] font-[400]">
                <div class="flex items-center gap-[4px]">
                  <template v-if="item.org_type">
                    <div
                      class="text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD]">
                      {{ item.org_type }}
                    </div>
                  </template>
                  <template v-for="tag in item.industry_list" :key="tag">
                    <div
                      class="text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD]">
                      {{ tag }}
                    </div>
                  </template>
                  <div v-if="item.report_status" :class="`text-[14px] px-[8px] py-[3px] rounded-[6px] cursor-pointer ${getThemeClass(item.report_status)}`">
                    {{ report_status_map[item.report_status] }}
                  </div>
                </div>
              </div>

              <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                <span class="text-[#475467]">牵头单位：</span>
                <span class="text-[#667085]">{{ item.org_name }}</span>
              </div>
              <div class="flex gap-[8px]">
                <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                  <span class="text-[#475467]">发布时间：</span>
                  <span class="text-[#667085] w-[135px] inline-block">{{ dayjs(item.updated_at).format("YYYY-MM-DD") }}</span>
                </div>
                <div class="text-[#475467] text-[14px] leading-[22px] font-[400]">
                  <span class="text-[#475467]">案例类型：</span>
                  <span class="text-[#667085]">{{ item.kind }}</span>
                </div>
              </div>

              <div class="flex gap-[8px]">
                <div class="text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1">
                  <span class="text-[#475467]">底座模型：</span>
                  <span class="text-[#667085] w-[135px] inline-block">{{ item.extra.base_model }}</span>
                </div>
                <div class="text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1">
                  <span class="text-[#475467]">模型类型：</span>
                  <span class="text-[#667085]">{{ model_kind_map[item.model_kind] }}</span>
                </div>
              </div>

              <div class="text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1">
                <span class="text-[#475467]">案例介绍：</span>
                <span class="text-[#667085]">{{ item.description }}</span>
              </div>
            </div>
          </a>
        </template>
      </div>

      <CsgPagination :perPage="perPage" :currentPage="currentPage" @currentChange="fetchCases" :total="total" />
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
</script>
<script>
import CsgPagination from '../shared/CsgPagination.vue'
import { caseList } from '@api/csrf/case'
import { Search } from '@element-plus/icons-vue'
import { currentUserDetail } from '../../../../api/jwt/user'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
import { industryList } from "../helpers/utils";

export default {
  data() {
    return {
      tags: industryList,
      // kinds: ['方案展示', '产品展示', '先锋案例', '技术底座', '新型工业化', '行业应用', '政务民生'],
      kinds: ['技术底座', '新型工业化', '行业应用', '政务民生'],
      org_types: ['企业', '高校', '非营利组织', '社区组织', '事业单位', '研究机构'],
      model_kind_map: {
        general: '通用模型',
        industry: '行业模型',
        vertical: '垂直模型',
        other: '其他'
      },
      report_status_map: {
        not_start: '暂未备案',
        in_progress: '正在备案',
        completed: '已备案'
      },
      build_status_map: {
        not_start: '规划中',
        in_progress: '建设中',
        completed: '已应用'
      },
      
      activeTag: '',
      activeIssueType: '',
      keywords: '',
      perPage: 8,
      currentPage: 1,
      canCreateCase: false,
      currentUserName: cookies.get('current_user'),
      total: 0,
      cases: [],
      mobileScreen: window.innerWidth <= 768,
      activeNavItem: '',
      drawer: false
    }
  },
  components: {
    CsgPagination,
    Search
  },
  methods: {
    handleKindClick(kind) {
      if (this.activeKind === kind) {
        this.activeKind = ''
      } else {
        this.activeKind = kind
      }
      this.fetchCases()
    },
    handleTagClick(tag) {
      if (this.activeTag === tag) {
        this.activeTag = ''
      } else {
        this.activeTag = tag
      }
      this.fetchCases()
    },
    handleOrgTypeClick(orgType) {
      if (this.activeOrgType === orgType) {
        this.activeOrgType = ''
      } else {
        this.activeOrgType = orgType
      }
      this.fetchCases()
    },
    fetchCases(page) {
      caseList({
        page: page ? page : this.currentPage,
        per: this.perPage,
        keywords: this.keywords,
        tag: this.activeTag,
        org_type: this.activeOrgType,
        kind: this.activeKind,
      }).then(res => {
        this.cases = res.data
        this.total = res.total
      })
    },
    adapterScreenWidth() {
      this.mobileScreen = window.innerWidth <= 768
    },
    getThemeClass(status) {
      const themesClassMap = {
        not_start: 'text-white bg-[#909399]',
        in_progress: 'text-white bg-[#409eff]',
        completed: 'text-white bg-[#67c23a]'
      }
      return themesClassMap[status]
    }
  },
  mounted() {
    this.fetchCases()
    window.addEventListener("resize", this.adapterScreenWidth)
    currentUserDetail(this.currentUserName).then(res => {
      this.canCreateCase = res.data.orgs.length > 0
    })
  }
}
</script>