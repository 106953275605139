<template>
  <div class="max-w-[1440px] m-auto flex">
    <div class="w-full md:px-[16px]">
      <!-- 筛选栏 -->
      <rank-filter
        :version="searchForm.version"
        :deployment_method_options="deployment_method_options"
        :input_modal_options="input_modal_options"
        :output_modal_options="output_modal_options"
        @filterChange="handleFilterChange"
        @adjustWeight="showWeightAdjuster = true"
      />

      <!-- 权重调整器弹窗 -->
      <el-dialog
        v-model="showWeightAdjuster"
        title="调整权重"
        width="800px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <weight-adjuster 
          :initial-weights="weights"
          @weightsChanged="handleWeightsChange"
          @apply="handleWeightApply"
        />
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showWeightAdjuster = false">取消</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- 排名表格 -->
      <div class="flex custom-table mt-4">
        <el-table
          :data="sortedRankLists"
          :default-sort="{ prop: 'score', order: 'descending' }"
          :show-overflow-tooltip="true"
          v-loading="loading"
          style="width: 100%"
          border
        >
          <el-table-column fixed prop="rank" label="排名" width="70" align="center">
            <template #default="scope">
              <champion-icon class="m-auto" v-if="scope.row.rank == 1" />
              <runner-up-icon class="m-auto" v-else-if="scope.row.rank == 2" />
              <third-place-icon class="m-auto" v-else-if="scope.row.rank == 3" />
              <div
                v-else
                class="text-center opacity-[.26] text-[16px] font-[500] leading-[24px]"
              >
                {{ scope.row.rank }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="模型名称" width="150" fixed show-overflow-tooltip>
            <template #default="scope">
              <span class="font-[500]">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          
          <el-table-column prop="score" width="80" label="得分" align="center" fixed>
            <template #default="scope">
              <el-popover
                :content="scope.row.scoreDetails"
                placement="right"
                trigger="hover"
                :width="400"
                popper-class="score-details-popover"
                :hide-after="0"
                :show-arrow="true"
                :offset="12"
              >
                <template #default>
                  <div class="whitespace-pre-line text-sm p-2 max-h-[400px] overflow-y-auto">
                    {{ scope.row.scoreDetails }}
                  </div>
                </template>
                <template #reference>
                  <span class="font-[700] cursor-help">{{ formatNumber(scope.row.score, 2) }}</span>
                </template>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column prop="baidu_index" width="110" label="百度指数" align="center" sortable>
            <template #default="scope">
              {{ formatNumber(scope.row.baidu_index) }}
            </template>
          </el-table-column>

          <el-table-column prop="wechat_index" width="110" label="微信指数" align="center" sortable>
            <template #default="scope">
              {{ formatNumber(scope.row.wechat_index) }}
            </template>
          </el-table-column>

          <el-table-column prop="huggingface_downloads" width="180" label="Huggingface下载量" align="center" sortable>
            <template #default="scope">
              {{ formatNumber(scope.row.huggingface_downloads) }}
            </template>
          </el-table-column>

          <el-table-column prop="github_forks" width="180" label="Github Fork数" align="center" sortable>
            <template #default="scope">
              {{ formatNumber(scope.row.github_forks) }}
            </template>
          </el-table-column>

          <el-table-column prop="parameter_scales" label="参数规模" min-width="120" align="center">
            <template #default="scope">
              <div class="flex gap-[4px] flex-wrap justify-center">
                <el-tag
                  v-for="scale in (scope.row.parameter_scales || [])"
                  :key="scale"
                  size="small"
                  effect="plain"
                >
                  {{ formatNumber(scale) }}B
                </el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="deployment_methods" label="部署方式" min-width="120" align="center">
            <template #default="scope">
              <div class="flex gap-[4px] flex-wrap justify-center">
                <el-tag
                  v-for="dl in scope.row.deployment_methods"
                  :key="dl"
                  :type="dl === '云端API部署' ? 'success' : 'primary'"
                  size="small"
                  effect="plain"
                >
                  {{ dl === '云端API部署' ? '云端' : '本地' }}
                </el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="input_modals" label="输入模态" min-width="100" align="center">
            <template #default="scope">
              <div class="flex gap-[4px] flex-wrap justify-center">
                <el-tag
                  v-for="im in scope.row.input_modals"
                  :key="im"
                  size="small"
                  effect="plain"
                  :class="modal_type_class_map[im]"
                  :style="getModalStyle(im)"
                >
                  {{ im }}
                </el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="output_modals" label="输出模态" min-width="100" align="center">
            <template #default="scope">
              <div class="flex gap-[4px] flex-wrap justify-center">
                <el-tag
                  v-for="om in scope.row.output_modals"
                  :key="om"
                  size="small"
                  effect="plain"
                  :class="modal_type_class_map[om]"
                  :style="getModalStyle(om)"
                >
                  {{ om }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import ChampionIcon from "../../icons/ChampionIcon.vue"
import RunnerUpIcon from "../../icons/RunnerUpIcon.vue"
import ThirdPlaceIcon from "../../icons/ThirdPlaceIcon.vue"
import WeightAdjuster from './WeightAdjuster.vue'
import RankFilter from './RankFilter.vue'
import { ScoreCalculator } from './ScoreCalculator'

export default {
  name: 'RankConfiguration',
  components: {
    WeightAdjuster,
    ChampionIcon,
    RunnerUpIcon,
    ThirdPlaceIcon,
    RankFilter
  },

  props: {
    rank_lists: {
      type: String,
      required: true
    },
    version: {
      type: String,
      required: true
    },
    import_dynamic_fields_options: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const rankListsData = ref([])
    const weights = ref({
      baidu_index: 12.5,
      wechat_index: 12.5,
      huggingface_downloads: 12.5,
      github_forks: 12.5,
      parameter_scales_count: 12.5,
      input_modals: 12.5,
      output_modals: 12.5,
      deployment_methods: 12.5
    })
    const tempWeights = ref(null)
    const loading = ref(false)
    const mobileScreen = ref(window.innerWidth < 768)
    const showWeightAdjuster = ref(false)
    const scoreCalculator = ref(null)

    const searchForm = reactive({
      version: props.version
    })

    const localSearchForm = reactive({
      deployment_methods: [],
      input_modals: [],
      output_modals: []
    })

    const deployment_method_options = new Set()
    const input_modal_options = new Set()
    const output_modal_options = new Set()

    // 处理数据初始化
    const initializeData = () => {
      try {
        const parsedData = JSON.parse(props.rank_lists)
        rankListsData.value = parsedData.map(item => ({
          ...item,
          baidu_index: Number(item.baidu_index) || 0,
          wechat_index: Number(item.wechat_index) || 0,
          huggingface_downloads: Number(item.huggingface_downloads) || 0,
          github_forks: Number(item.github_forks) || 0,
          parameter_scales: Array.isArray(item.parameter_scales) ? item.parameter_scales.map(Number) : [],
          deployment_methods: Array.isArray(item.deployment_methods) ? item.deployment_methods : [],
          input_modals: Array.isArray(item.input_modals) ? item.input_modals : [],
          output_modals: Array.isArray(item.output_modals) ? item.output_modals : []
        }))

        // 初始化选项集合
        if (rankListsData.value.length > 0) {
          rankListsData.value.forEach((item) => {
            item.deployment_methods?.forEach((dm) => {
              deployment_method_options.add(dm)
            })
            item.input_modals?.forEach((im) => {
              input_modal_options.add(im)
            })
            item.output_modals?.forEach((om) => {
              output_modal_options.add(om)
            })
          })
        }

        // 初始化计算器
        updateScoreCalculator()
      } catch (error) {
        console.error('数据初始化失败:', error)
        ElMessage.error('数据初始化失败')
      }
    }

    // 更新计算器
    const updateScoreCalculator = () => {
      console.log('Updating calculator with weights:', weights.value)
      scoreCalculator.value = new ScoreCalculator(rankListsData.value, weights.value)
    }

    // 监听权重变化
    watch(weights, () => {
      console.log('Weights changed:', weights.value)
      updateScoreCalculator()
    }, { deep: true })

    const adapterScreenWidth = () => {
      mobileScreen.value = window.innerWidth < 768
    }

    onMounted(() => {
      window.addEventListener("resize", adapterScreenWidth)
      initializeData()
    })

    const handleFilterChange = (filters) => {
      localSearchForm.deployment_methods = filters.deployment_methods
      localSearchForm.input_modals = filters.input_modals
      localSearchForm.output_modals = filters.output_modals
      
      // 重新计过滤后的列表
      const filteredData = rankListsData.value.filter(item => {
        // 检部署方式
        if (filters.deployment_methods.length > 0) {
          const hasAllDeploymentMethods = filters.deployment_methods.every(method => 
            item.deployment_methods.includes(method)
          )
          if (!hasAllDeploymentMethods) return false
        }

        // 检查输入模态
        if (filters.input_modals.length > 0) {
          const hasAllInputModals = filters.input_modals.every(modal => 
            item.input_modals.includes(modal)
          )
          if (!hasAllInputModals) return false
        }

        // 检查输出模态
        if (filters.output_modals.length > 0) {
          const hasAllOutputModals = filters.output_modals.every(modal => 
            item.output_modals.includes(modal)
          )
          if (!hasAllOutputModals) return false
        }

        return true
      })

      // 更新计算器使用过滤后的数据
      scoreCalculator.value = new ScoreCalculator(filteredData, weights.value)
    }

    const deployment_method_color_map = {
      本地私有化部署: "tag-primary",
      云端API部署: "tag-success",
    }

    const modal_type_class_map = {
      '文本': 'modal-text',
      '图像': 'modal-image',
      '语音': 'modal-audio',
      '视频': 'modal-video'
    }

    const queryByVersion = () => {
      if (!searchForm.version) return
      window.location.href = `?version=${dayjs(searchForm.version).format("YYYY-MM-DD")}`
    }

    // 处理权重变化（临时存储）
    const handleWeightsChange = (newWeights) => {
      console.log('Weights changing to:', newWeights)
      tempWeights.value = newWeights
    }

    // 处理权重应用
    const handleWeightApply = () => {
      console.log('Applying weights:', tempWeights.value)
      weights.value = { ...tempWeights.value }
      showWeightAdjuster.value = false
    }

    // 格式化数字
    const formatNumber = (value, decimals = 0) => {
      if (value === null || value === undefined) return '-'
      return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
    }

    // 计算排序后的榜单列表
    const sortedRankLists = computed(() => {
      console.log('Recalculating scores with calculator:', scoreCalculator.value)
      if (!scoreCalculator.value) return rankListsData.value
      return scoreCalculator.value.calculateScores()
    })

    const modal_style_map = {
      '文本': {
        color: '#165DFF',
        backgroundColor: '#E8F3FF',
        borderColor: '#E8F3FF'
      },
      '图像': {
        color: '#FF7D00',
        backgroundColor: '#FFF3E8',
        borderColor: '#FFF3E8'
      },
      '语音': {
        color: '#722ED1',
        backgroundColor: '#F5E8FF',
        borderColor: '#F5E8FF'
      },
      '视频': {
        color: '#F5319D',
        backgroundColor: '#FFE8F5',
        borderColor: '#FFE8F5'
      }
    }

    const getModalStyle = (modalType) => {
      return modal_style_map[modalType] || {}
    }

    const parseScoreDetails = (details) => {
      if (!details) return []
      
      const lines = details.split('\n')
      const sections = []
      let currentSection = null

      // 添加调试日志
      console.log('Raw score details:', details)
      console.log('Split lines:', lines)

      lines.forEach(line => {
        // 去除所有特殊字符
        const cleanLine = line.replace(/[│├└┌]/g, '').trim()
        
        if (cleanLine.includes('基础指标')) {
          currentSection = { title: '基础指标', items: [] }
          sections.push(currentSection)
        } else if (cleanLine.includes('参数规模')) {
          currentSection = { title: '参数规模', items: [] }
          sections.push(currentSection)
        } else if (cleanLine.includes('模态评分')) {
          currentSection = { title: '模态评分', items: [] }
          sections.push(currentSection)
        } else if (currentSection && !cleanLine.includes('得分计算明细') && !cleanLine.includes('最终得分') && !cleanLine.includes('─')) {
          // 解析每一行的数据
          if (cleanLine.includes(':')) {
            const [label, ...restParts] = cleanLine.split(':')
            const rest = restParts.join(':').trim()
            
            if (rest.includes('得分')) {
              // 这是得分行
              if (currentSection.items.length > 0) {
                currentSection.items[currentSection.items.length - 1].score = rest
              }
            } else {
              // 这是数值行
              currentSection.items.push({
                label: label.trim(),
                value: rest,
                score: ''
              })
            }
          }
        }
      })

      // 添加调试日志
      console.log('Parsed sections:', sections)
      return sections
    }

    return {
      rankListsData,
      searchForm,
      localSearchForm,
      deployment_method_options,
      input_modal_options,
      output_modal_options,
      mobileScreen,
      deployment_method_color_map,
      modal_type_class_map,
      getModalStyle,
      queryByVersion,
      handleFilterChange,
      handleWeightsChange,
      handleWeightApply,
      formatNumber,
      sortedRankLists,
      loading,
      showWeightAdjuster,
      weights,
      parseScoreDetails
    }
  }
}
</script>
<style scoped>
.rank-configuration {
  padding: 20px;
}

.rank-tag {
  @apply px-[8px] py-[2px] rounded-[4px] text-[12px] leading-[18px] tracking-[0.018px] text-[#1F2329] bg-[#F2F3F5];
}

.tag-primary {
  @apply text-[#165DFF] bg-[#E8F3FF];
}

.tag-success {
  @apply text-[#00B42A] bg-[#E8FFEA];
}

/* 模态类型标签样式 */
.modal-text {
  @apply text-[#165DFF] bg-[#E8F3FF] !important;
}

.modal-image {
  @apply text-[#FF7D00] bg-[#FFF3E8] !important;
}

.modal-audio {
  @apply text-[#722ED1] bg-[#F5E8FF] !important;
}

.modal-video {
  @apply text-[#F5319D] bg-[#FFE8F5] !important;
}

.orange-hover:hover {
  @apply text-[#FF5005] cursor-pointer;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.community-border-b {
  @apply border-b border-[#E5E6E8];
}

.css-custom-input :deep(.el-input__wrapper) {
  @apply !bg-[#F7F8FA];
}

.custom-table :deep(.el-table__cell) {
  padding: 4px 0;
}

/* 使用深度选择器确保样式能覆盖 Element Plus 的默认样式 */
:deep(.modal-text.el-tag) {
  --el-tag-bg-color: #E8F3FF !important;
  --el-tag-border-color: #E8F3FF !important;
  --el-tag-text-color: #165DFF !important;
}

:deep(.modal-image.el-tag) {
  --el-tag-bg-color: #FFF3E8 !important;
  --el-tag-border-color: #FFF3E8 !important;
  --el-tag-text-color: #FF7D00 !important;
}

:deep(.modal-audio.el-tag) {
  --el-tag-bg-color: #F5E8FF !important;
  --el-tag-border-color: #F5E8FF !important;
  --el-tag-text-color: #722ED1 !important;
}

:deep(.modal-video.el-tag) {
  --el-tag-bg-color: #FFE8F5 !important;
  --el-tag-border-color: #FFE8F5 !important;
  --el-tag-text-color: #F5319D !important;
}

.score-details {
  @apply space-y-4 text-sm;
}

.score-section {
  @apply bg-white rounded-lg overflow-hidden border border-gray-100;
}

.section-title {
  @apply text-[14px] font-medium px-4 py-2 bg-gray-50 text-gray-700 border-b border-gray-100;
}

.section-items {
  @apply p-4 space-y-4;
}

.section-item {
  @apply space-y-2 pb-3 border-b border-gray-100 last:border-b-0 last:pb-0;
}

.item-label {
  @apply text-[13px] text-gray-600 font-medium;
}

.item-value {
  @apply text-[14px] font-medium text-gray-900 pl-4;
}

.item-score {
  @apply text-[13px] text-blue-600 pl-4;
}

:deep(.score-details-popover) {
  max-width: 400px !important;
  max-height: 80vh !important;
  overflow: visible !important;
}

:deep(.el-popper.is-light) {
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

:deep(.el-popper__arrow::before) {
  background: white !important;
  border: 1px solid #e4e7ed !important;
}
</style>

