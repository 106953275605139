<template>
  <div class="bg-white flex flex-col min-h-screen">
    <!-- 顶部操作区 -->
    <div class="border-b border-[#DCDFE6] flex-shrink-0">
      <div class="max-w-[1400px] mx-auto px-4 py-2 flex items-center justify-between">
        <div class="flex items-center gap-3">
          <el-button
            class="!h-[40px] !text-[14px] !text-[#666] !bg-white !rounded-[6px] !border-[#DCDFE6]"
            @click="goBack">
            <span class="flex items-center justify-center gap-1">
              <el-icon class="!text-[#666]"><ArrowLeft /></el-icon>
              返回
            </span>
          </el-button>
          <div class="text-[18px] font-[500] text-[#1D2129]">编辑文档</div>
        </div>
        <div class="flex items-center gap-3">
          <el-radio-group v-model="dataForm.status" class="!mr-4">
            <el-radio :label="0">草稿</el-radio>
            <el-radio :label="1">发布</el-radio>
          </el-radio-group>
          <el-button
            class="!min-w-[100px] !h-[40px] !text-[14px] !text-white !bg-[#FF5005] !rounded-[6px] !border-[#FF5005]"
            @click="handleSubmit">保存文档</el-button>
          <el-button
            class="!min-w-[80px] !h-[40px] !text-[14px] !text-[#666] !bg-white !rounded-[6px] !border-[#DCDFE6]"
            @click="handleDelete">
            <span class="flex items-center justify-center gap-1">
              <el-icon class="!text-[#666]"><Delete /></el-icon>
              删除
            </span>
          </el-button>
        </div>
      </div>
    </div>

    <!-- 主要内容区 -->
    <div class="flex-1 py-6">
      <div class="w-[1200px] mx-auto">
        <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top">
          <!-- 标题和分类行 -->
          <div class="flex gap-4 mb-6">
            <el-form-item label="文档标题" prop="title" class="flex-[3]">
              <el-input 
                v-model="dataForm.title" 
                placeholder="请输入文档标题"
                class="!text-[16px]"
                :maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="文档分类" prop="doc_type" class="flex-1">
              <el-select v-model="dataForm.doc_type" placeholder="请选择文档分类" class="!w-full">
                <el-option v-for="(label, value) in docTypes" :key="value" :label="label" :value="value" />
              </el-select>
            </el-form-item>
          </div>

          <!-- 编辑器区域 -->
          <el-form-item label="文档内容" prop="content">
            <div class="editor-wrapper">
              <CKEditor5 v-model:content="dataForm.content" />
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { docUpdate, docDelete } from '@api/csrf/doc'
import CKEditor5 from '../shared/form/CKEditor5.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Delete, ArrowLeft } from '@element-plus/icons-vue'
import { docTypes } from './constants/docTypes'

export default {
  props: {
    doc: {
      type: Object,
      required: true,
      default: () => ({}),
      validator(value) {
        if (typeof value === 'string') {
          try {
            JSON.parse(value)
            return true
          } catch (e) {
            console.error('Invalid JSON string in doc prop:', e)
            return false
          }
        }
        return true
      }
    }
  },
  data() {
    const docData = typeof this.doc === 'string' ? JSON.parse(this.doc) : this.doc
    return {
      dataForm: {
        doc_type: docData.doc_type || '',
        title: docData.title || '',
        content: docData.content || '',
        status: docData.status === 'published' ? 1 : 0
      },
      rules: {
        doc_type: [{ required: true, message: '请选择文档分类', trigger: 'blur' }],
        title: [{ required: true, message: '请输入文档标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入文档内容', trigger: 'blur' }],
        status: [{ required: true, message: '请选择文档状态', trigger: 'blur' }]
      },
      docTypes
    }
  },
  components: {
    CKEditor5,
    Delete,
    ArrowLeft
  },
  methods: {
    handleSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              doc: Object.assign({}, this.dataForm)
            }
            await docUpdate(this.doc.uuid, params)
            ElMessage({
              message: '文档更新成功',
              type: 'success'
            })
            window.location.href = '/docs'
          } catch (err) {
            ElMessage({
              message: err.response?.data?.message || '更新失败',
              type: 'warning'
            })
          }
        }
      })
    },
    async handleDelete() {
      try {
        await ElMessageBox.confirm('确定要删除这篇文档吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        await docDelete(this.doc.uuid)
        ElMessage({
          message: '文档删除成功',
          type: 'success'
        })
        window.location.href = '/docs'
      } catch (err) {
        if (err !== 'cancel') {
          ElMessage({
            message: err.response?.data?.message || '删除失败',
            type: 'error'
          })
        }
      }
    },
    goBack() {
      window.history.back()
    }
  },
  watch: {
    doc: {
      handler(newDoc) {
        const docData = typeof newDoc === 'string' ? JSON.parse(newDoc) : newDoc
        this.dataForm = {
          doc_type: docData.doc_type || '',
          title: docData.title || '',
          content: docData.content || '',
          status: docData.status === 'published' ? 1 : 0
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.el-form-item :deep(.el-form-item__label) {
  padding-bottom: 4px;
  font-weight: 500;
  color: #1D2129;
  font-size: 14px;
  line-height: 1.5;
}

.el-form-item :deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #DCDFE6;
}

.el-form-item :deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px #C0C4CC;
}

.el-form-item :deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #FF5005 !important;
}

/* 编辑器容器样式 */
.editor-wrapper {
  border: 1px solid #DCDFE6;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

:deep(.ck.ck-editor__main) {
  min-height: 500px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  width: 100%;
}

:deep(.ck.ck-editor__editable_inline) {
  min-height: 500px;
  max-height: none;
  width: 100%;
}

:deep(.ck.ck-content) {
  min-height: 500px;
  width: 100%;
}
</style> 