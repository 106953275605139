<template>
  <span class="flex gap-[4px] items-center text-[14px] border px-[8px] py-[4px] rounded-[4px] cursor-pointer"
        :style="setTagColor()"
        @click="toggleActive"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13" viewBox="0 0 21 13" fill="none">
      <g clip-path="url(#clip0_4087_110578)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8485 0.959961C10.6531 0.959961 10.462 1.01638 10.2995 1.12208C10.137 1.22779 10.0104 1.37804 9.93558 1.55382C9.86079 1.7296 9.84121 1.92303 9.87934 2.10964C9.91746 2.29624 10.0116 2.46765 10.1498 2.60219C10.288 2.73673 10.464 2.82835 10.6557 2.86547C10.8474 2.90258 11.0461 2.88354 11.2266 2.81072C11.4072 2.73791 11.5615 2.61461 11.6701 2.45641C11.7787 2.29821 11.8366 2.11222 11.8366 1.92195C11.8366 1.66681 11.7325 1.42214 11.5472 1.24173C11.3619 1.06132 11.1106 0.959961 10.8485 0.959961Z" fill="#2932DF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3849 12.9552H11.8096C11.823 12.9545 11.8359 12.9504 11.8472 12.9433C11.8584 12.9363 11.8677 12.9265 11.8739 12.915L16.1712 3.71563C16.1757 3.70471 16.1774 3.69286 16.1761 3.68114C16.1748 3.66942 16.1706 3.65819 16.1637 3.64845C16.1569 3.63871 16.1477 3.63077 16.137 3.62532C16.1263 3.61987 16.1143 3.61707 16.1022 3.6172H14.6775C14.6639 3.61697 14.6505 3.62068 14.639 3.62785C14.6276 3.63501 14.6186 3.64532 14.6131 3.65746L10.3159 12.8613C10.3114 12.8722 10.3097 12.8841 10.311 12.8958C10.3123 12.9075 10.3165 12.9187 10.3233 12.9285C10.3302 12.9382 10.3393 12.9461 10.3501 12.9516C10.3608 12.957 10.3727 12.9598 10.3849 12.9597" fill="#2932DF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4383 0.959961C19.2429 0.959961 19.0519 1.01638 18.8894 1.12208C18.7269 1.22779 18.6002 1.37804 18.5254 1.55382C18.4506 1.7296 18.4311 1.92303 18.4692 2.10964C18.5073 2.29624 18.6014 2.46765 18.7396 2.60219C18.8778 2.73673 19.0539 2.82835 19.2456 2.86547C19.4372 2.90258 19.6359 2.88354 19.8165 2.81072C19.997 2.73791 20.1513 2.61461 20.2599 2.45641C20.3685 2.29821 20.4264 2.11222 20.4264 1.92195C20.4264 1.66681 20.3223 1.42214 20.137 1.24173C19.9517 1.06132 19.7004 0.959961 19.4383 0.959961Z" fill="#2932DF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54851 3.61748H6.12375C6.10949 3.61696 6.09538 3.62048 6.08314 3.62763C6.07089 3.63478 6.06107 3.64525 6.05483 3.65775L4.3773 7.23726H1.03604C1.014 7.23621 0.992158 7.24163 0.973314 7.2528C0.954471 7.26396 0.939505 7.28037 0.93034 7.29991L0.388021 8.48562C0.384306 8.49508 0.383016 8.50527 0.384234 8.51533C0.385453 8.52539 0.389132 8.535 0.395006 8.54336C0.40088 8.55172 0.408765 8.55856 0.417952 8.56331C0.427139 8.56806 0.437369 8.57058 0.447771 8.57063H3.75686L1.76681 12.8616C1.76239 12.8721 1.76064 12.8836 1.7617 12.895C1.76276 12.9064 1.76659 12.9173 1.77289 12.9269C1.77919 12.9366 1.78778 12.9446 1.79791 12.9503C1.80805 12.9561 1.81944 12.9594 1.83116 12.96H3.25589C3.26938 12.9597 3.28252 12.9558 3.29387 12.9487C3.30522 12.9416 3.31434 12.9315 3.32024 12.9197L7.61744 3.7204C7.62185 3.70982 7.6236 3.69836 7.62254 3.68699C7.62148 3.67561 7.61765 3.66465 7.61135 3.65502C7.60505 3.64539 7.59649 3.63735 7.58636 3.6316C7.57622 3.62585 7.56483 3.62255 7.55311 3.62196" fill="#2932DF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4436 5.80963C12.3797 5.22191 12.0999 4.67642 11.6557 4.27357C11.2116 3.87073 10.6328 3.63763 10.0261 3.61719H9.10694C9.09631 3.61711 9.08589 3.62003 9.07692 3.62559C9.06795 3.63114 9.06082 3.6391 9.05639 3.64851L8.56005 4.72236C8.55618 4.73057 8.5545 4.7396 8.55517 4.74861C8.55584 4.75762 8.55882 4.76633 8.56386 4.77391C8.5689 4.78149 8.57583 4.78772 8.584 4.792C8.59217 4.79629 8.60132 4.7985 8.61059 4.79843H9.48841C9.64226 4.79535 9.79609 4.80583 9.948 4.82974C10.2701 4.86248 10.5673 5.01352 10.7791 5.2521C10.9908 5.49068 11.1013 5.79887 11.0878 6.1139C11.0806 6.41973 10.9504 6.71057 10.7252 6.92393C10.5001 7.13729 10.1979 7.25612 9.88365 7.25487H6.73084C6.70502 7.2554 6.67985 7.26277 6.65799 7.27617C6.63613 7.28957 6.61843 7.3085 6.60677 7.33094L6.08282 8.454C6.07766 8.46666 6.0757 8.48035 6.0771 8.49391C6.07851 8.50747 6.08324 8.52049 6.0909 8.5319C6.09856 8.5433 6.10892 8.55275 6.12111 8.55944C6.13331 8.56613 6.14696 8.56986 6.16095 8.57034H9.8377C10.1989 8.57216 10.5565 8.49995 10.8871 8.35839C11.2178 8.21683 11.5141 8.0091 11.7569 7.7487C11.9996 7.48831 12.1834 7.18108 12.2962 6.847C12.4089 6.51292 12.4482 6.15947 12.4114 5.80963" fill="#2932DF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0007 5.80999C20.9368 5.22226 20.6571 4.67677 20.2129 4.27393C19.7687 3.87108 19.19 3.63799 18.5833 3.61754H17.6641C17.6533 3.61684 17.6426 3.61949 17.6335 3.62512C17.6244 3.63076 17.6174 3.63908 17.6135 3.64887L17.1172 4.72272C17.1133 4.73093 17.1116 4.73995 17.1123 4.74896C17.113 4.75797 17.1159 4.76668 17.121 4.77426C17.126 4.78185 17.133 4.78807 17.1411 4.79236C17.1493 4.79664 17.1584 4.79886 17.1677 4.79879H18.0455C18.1994 4.79586 18.3532 4.80633 18.5051 4.8301C18.8273 4.86284 19.1245 5.01388 19.3362 5.25245C19.548 5.49103 19.6584 5.79922 19.6449 6.11426C19.6377 6.42008 19.5075 6.71093 19.2824 6.92429C19.0572 7.13764 18.755 7.25647 18.4408 7.25522H15.288C15.262 7.25525 15.2366 7.26241 15.2147 7.27587C15.1927 7.28932 15.1751 7.30853 15.1639 7.33129L14.64 8.45435C14.6348 8.46702 14.6328 8.48071 14.6342 8.49427C14.6356 8.50782 14.6404 8.52085 14.648 8.53225C14.6557 8.54366 14.6661 8.55311 14.6782 8.5598C14.6904 8.56649 14.7041 8.57022 14.7181 8.57069H18.3948C18.7562 8.57245 19.1139 8.50023 19.4448 8.35872C19.7757 8.2172 20.0723 8.00955 20.3154 7.74924C20.5585 7.48893 20.7427 7.18176 20.856 6.84768C20.9693 6.5136 21.0092 6.16006 20.9732 5.80999" fill="#2932DF"/>
      </g>
      <defs>
        <clipPath id="clip0_4087_110578">
          <rect width="20.617" height="12" fill="white" transform="translate(0.383789 0.959961)"/>
        </clipPath>
      </defs>
    </svg>
    PaddlePaddle
  </span>
</template>
<script setup lang="ts">
  import { onMounted } from 'vue'
  import { colorMap } from '../../helpers/utils';
  const props = defineProps({
    activeTag: String
  })

  const emit = defineEmits(['setActiveFrameworkTag'])

  const toggleActive = () => {
    emit('setActiveFrameworkTag', 'PaddlePaddle')
  }
  const setTagColor = () => {
    if (props.activeTag === 'PaddlePaddle') {
      return `color: ${colorMap('framework')}; background-color: ${colorMap('framework')}30`
    }
  }
  onMounted(() => {
  })
</script>