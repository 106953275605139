export class ScoreCalculator {
  constructor(rankLists, weights) {
    this.rankLists = rankLists || []
    this.weights = weights || {}
    this.modalWeights = {
      input: {
        '文本': 0.05,
        '视频': 0.15,
        '图像': 0.1,
        '语音': 0.1,
        '文件': 0.1
      },
      output: {
        '文本': 0.05,
        '视频': 0.15,
        '图像': 0.1,
        '语音': 0.1,
        '文件': 0.1
      },
      deployment: {
        '云端API部署': 0.5,
        '本地私有化部署': 0.5
      }
    }
  }

  calculateScores() {
    if (!this.rankLists.length) return []
    
    return this.rankLists.map(item => {
      const { score, details } = this._calculateScore(item)
      return {
        ...item,
        score,
        scoreDetails: details
      }
    }).sort((a, b) => b.score - a.score)
    .map((item, index) => ({
      ...item,
      rank: index + 1
    }))
  }

  _calculateScore(item) {
    let totalScore = 0
    const scoreDetails = []
    
    // 计算基本指标得分
    totalScore += this._calculateNumericScores(item, scoreDetails)
    // 计算参数规模得分
    totalScore += this._calculateParameterScalesScore(item, scoreDetails)
    // 计算模态得分
    totalScore += this._calculateModalScores(item, scoreDetails)
    return {
      score: Math.round(totalScore * 100) / 100,
      details: scoreDetails.join('\n')
    }
  }

  _calculateNumericScores(item, scoreDetails) {
    let score = 0
    const numericFields = ['baidu_index', 'wechat_index', 'huggingface_downloads', 'github_forks']
    const fieldLabels = {
      'baidu_index': '百度指数',
      'wechat_index': '微信指数',
      'huggingface_downloads': 'Huggingface下载量',
      'github_forks': 'Github Fork数'
    }
    
    numericFields.forEach(field => {
      if (this.weights[field]) {
        const value = Number(item[field]) || 0
        const normalizedValue = this._calculateExponentialScore(field, value) * 2.5
        const fieldScore = normalizedValue * this.weights[field] / 100
        score += fieldScore
        scoreDetails.push(
          `${fieldLabels[field]}: ${value}`,
          `得分: ${normalizedValue.toFixed(2)} × ${this.weights[field]}% = ${fieldScore.toFixed(2)}`,
          ''
        )
      }
    })

    return score
  }

  _calculateParameterScalesScore(item, scoreDetails) {
    if (!this.weights.parameter_scales_count) return 0

    const parameterScalesCount = Array.isArray(item.parameter_scales) ? item.parameter_scales.length : 0
    const parameterScalesScore = this._calculateExponentialScore('parameter_scales_count', parameterScalesCount) * 2.5
    const parameterScalesFinalScore = parameterScalesScore * this.weights.parameter_scales_count / 100
    
    scoreDetails.push(
      `参数规模数量: ${parameterScalesCount}`,
      `得分: ${parameterScalesScore.toFixed(2)} × ${this.weights.parameter_scales_count}% = ${parameterScalesFinalScore.toFixed(2)}`,
      ''
    )

    return parameterScalesFinalScore
  }

  _calculateModalScores(item, scoreDetails) {
    let score = 0

    // 输入模态
    if (this.weights.input_modals) {
      const inputModals = Array.isArray(item.input_modals) ? item.input_modals : []
      const inputModalScore = this._calculateModalScore(inputModals, this.modalWeights.input)
      const inputModalFinalScore = inputModalScore * this.weights.input_modals / 100
      score += inputModalFinalScore
      scoreDetails.push(
        `输入模态: ${inputModals.join('、') || '无'}`,
        `得分: ${inputModalScore.toFixed(2)} × ${this.weights.input_modals}% = ${inputModalFinalScore.toFixed(2)}`,
        ''
      )
    }

    // 输出模态
    if (this.weights.output_modals) {
      const outputModals = Array.isArray(item.output_modals) ? item.output_modals : []
      const outputModalScore = this._calculateModalScore(outputModals, this.modalWeights.output)
      const outputModalFinalScore = outputModalScore * this.weights.output_modals / 100
      score += outputModalFinalScore
      scoreDetails.push(
        `输出模态: ${outputModals.join('、') || '无'}`,
        `得分: ${outputModalScore.toFixed(2)} × ${this.weights.output_modals}% = ${outputModalFinalScore.toFixed(2)}`,
        ''
      )
    }

    // 部署方式
    if (this.weights.deployment_methods) {
      const deploymentMethods = Array.isArray(item.deployment_methods) ? item.deployment_methods : []
      const deploymentScore = this._calculateModalScore(deploymentMethods, this.modalWeights.deployment)
      const deploymentFinalScore = deploymentScore * this.weights.deployment_methods / 100
      score += deploymentFinalScore
      scoreDetails.push(
        `部署方式: ${deploymentMethods.join('、') || '无'}`,
        `得分: ${deploymentScore.toFixed(2)} × ${this.weights.deployment_methods}% = ${deploymentFinalScore.toFixed(2)}`,
        ''
      )
    }

    return score
  }

  _calculateModalScore(modalList, weightMap) {
    if (!modalList || !weightMap) return 0
    
    let score = 0
    modalList.forEach(modal => {
      score += weightMap[modal] || 0
    })
    return score
  }

  _calculateExponentialScore(field, value) {
    if (!this.rankLists.length) return 0

    // 获取该字段的所有值并排序
    const allValues = this.rankLists
      .map(item => {
        if (field === 'parameter_scales_count') {
          return Array.isArray(item.parameter_scales) ? item.parameter_scales.length : 0
        }
        return Number(item[field]) || 0
      })
      .sort((a, b) => a - b)
    
    // 如果所有值都相同，返回满分
    if (allValues[0] === allValues[allValues.length - 1]) {
      return 1
    }

    // 找到当前值的排名（从0开始）
    const index = allValues.indexOf(value)
    if (index === -1) return 0
    
    // 计算 a 和 b
    // f(n-1) = 1 且 f(0) = 0.4，其中 n 是模型总数
    // f(x) = e^(ax + b)
    // 1 = e^(a(n-1) + b)
    // 0.4 = e^b
    const n = allValues.length
    const b = Math.log(0.4)
    const a = (Math.log(1) - b) / (n - 1)

    // 计算得分
    return Math.exp(a * index + b)
  }
} 