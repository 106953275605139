<template>
  <div class="bg-white">
    <div class="m-auto w-[583px] md:w-full md:px-[20px]">
      <div class="py-[96px] text-center">
        <div class="text-[20px] font-[500] leading-[30px]">
          投递方案
        </div>
        <el-form
          v-if="dataForm.issue_id"
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
          label-position="top"
          class="text-left">
          <el-form-item label="联系人姓名" prop="contact_name">
            <el-input v-model="dataForm.contact_name" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <!-- contact_phone -->
          <el-form-item label="联系电话" prop="contact_phone">
            <el-input v-model="dataForm.contact_phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <!-- title -->
          <el-form-item label="方案名称" prop="title">
            <el-input v-model="dataForm.title" placeholder="请输入方案名称"></el-input>
          </el-form-item>
          <!-- description -->
          <el-form-item label="方案描述" prop="description">
            <el-input :rows="5" type="textarea" v-model="dataForm.description" placeholder="请输入方案描述"></el-input>
          </el-form-item>
          <!-- file_urls -->
          <el-form-item label="方案附件" prop="file_urls">
            <el-upload
              v-model:file-list="filesList"
              drag
              multiple
              :limit="5"
              :headers="{ 'X-CSRF-TOKEN': csrf_token }"
              :data="{namespace: 'system-issue-plan'}"
              action="/internal_api/upload"
              :on-remove="handleRemoveImage"
              :on-success="handleUploadSuccess"
              style="width: 100%;"
            >
              <el-icon>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </el-icon>
              <div class="el-upload__text py-[16px] px-[24px]">
                {{ $t('shared.dragOr') }} <em>{{ $t('all.clickUpload') }}</em>（{{ $t('shared.onlyFiveFiles') }}）
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  请上传 大小不超过5MB 格式为 doc/xls/pdf/docx/xlsx/rar/zip/png/jpg/jpeg 的文件或 图片最多上传5个
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <!-- content -->
          <el-form-item label="详细介绍" prop="content">
            <CKEditor5 v-model:content="dataForm.content" />
          </el-form-item>
          <el-form-item>
            <div class="w-full flex gap-[4px]">
              <el-button class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]" @click="handleSubmit">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { createIssuePlan, issueDetail } from '../../../../api/csrf/issue'
  import { ElMessage } from 'element-plus'
  import CKEditor5 from '../shared/form/CKEditor5.vue'
  export default {
    components: {
      CKEditor5
    },
    data() {
      return {
        csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        dataForm: {
          file_urls: [],
          content: "",
        },
        filesList: [],
        rules: {
          contact_name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
          contact_phone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/, message: '请输入正确的手机号', trigger: 'blur' }
          ],
          title: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
          description: [{ required: true, message: '请输入方案描述', trigger: 'blur' }],
          content: [{ required: true, message: '请输入详细介绍', trigger: 'blur' }],
        }
      }
    },
    methods: {
      handleSubmit() {
        this.dataForm.file_urls = this.filesList.map(item => item.response.url)
        this.$refs['dataForm'].validate(async (valid) => {
          if (valid) {
            this.createIssuePlan()
          } else {
            return false
          }
        })
      },
      createIssuePlan() {
        const params = Object.assign({}, this.dataForm)
        createIssuePlan(params).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '方案已投递成功，经管理员审核通过后，发起后续的供需匹配对接',
              type: 'success'
            });
            setTimeout(() => {
              window.location.href = `/issues/${this.dataForm.issue_id}`
            }, 1000)
          } else {
            ElMessage.error(res.message)
          }
        })
      },
      fetchIssue() {
        const issue_id = new URLSearchParams(window.location.search).get('issue_id')
        if (!issue_id) {
          ElMessage.error('需求不存在')
          return
        }
        issueDetail(issue_id).then(res => {
          if (res.code === 200) {
            this.dataForm.issue_id = res.data.id
          } else {
            ElMessage.error(res.message)
          }
        })
      }
    },
    mounted() {
      this.fetchIssue()
    }
  }
</script>
<style scoped>
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    margin-bottom: 6px;
  }
  :deep(.el-input__wrapper) {
    border-radius: 8px;
  }
  :deep(.append-input .el-input__wrapper) {
    border-radius: 8px 0 0 8px;
  }
  :deep(.el-input-group__append, .el-input-group__prepend) {
    background: transparent;
    color: #344054;
  }
  :deep(.el-textarea__inner) {
    border-radius: 8px;
  }
  :deep(.el-upload--picture-card) {
    border: 0px;
  }
  :deep(.hide .el-upload.el-upload--picture-card){
    display: none;
  }
</style>