import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sm:w-[100%] sm:mt-[36px]" }
const _hoisted_2 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_5 = { class: "mt-[32px]" }
const _hoisted_6 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_7 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_8 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_9 = { class: "mt-[32px]" }
const _hoisted_10 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_11 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_12 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" collections "),
    _createElementVNode("div", null, [
      _createCommentVNode(" <h3 class=\"text-[20px] text-[#303133] flex items-center gap-[8px]\">\r\n        <SvgIcon name=\"collections\" width=\"18\" height=\"18\" />\r\n        <span>{{ $t(\"collections.collection\") }}</span>\r\n      </h3> "),
      _createCommentVNode(" <div v-if=\"hasCollections\" class=\"mb-4 mt-[16px]\">\r\n        <CollectionCards \r\n          v-if=\"collections.data.length > 0\" \r\n          :collections=\"collections.data\"\r\n        />\r\n      </div> "),
      _createCommentVNode(" <div v-else class=\"flex flex-wrap gap-4 mb-4 mt-[16px]\">\r\n        {{ $t(\"all.noData\") }}\r\n      </div> "),
      ($setup.collections.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 0,
            target: "collections",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.collectionsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 1,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" models "),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, [
        _createVNode(_component_SvgIcon, {
          name: "models",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.model")), 1 /* TEXT */)
      ]),
      ($setup.hasModels)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.models.data, (model) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: model,
                "repo-type": "model"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.models.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "models",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.modelsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" datasets "),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", _hoisted_6, [
        _createVNode(_component_SvgIcon, {
          name: "datasets",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.dataset")), 1 /* TEXT */)
      ]),
      ($setup.hasDatasets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.datasets.data, (dataset) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: dataset,
                "repo-type": "dataset"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.datasets.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "datasets",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.datasetsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" code repo "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("h3", _hoisted_10, [
        _createVNode(_component_SvgIcon, {
          name: "codes",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.code")), 1 /* TEXT */)
      ]),
      ($setup.hasCodes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.codes.data, (code) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: code,
                "repo-type": "code"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.codes.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "codes",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.codeLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" spaces "),
    _createCommentVNode(" <div class=\"mt-[32px]\">\r\n      <h3 class=\"text-[20px] text-[#344054] flex items-center gap-[8px]\">\r\n        <SvgIcon name=\"spaces\" width=\"18\" height=\"18\" />\r\n        <span>{{ $t(\"organization.space\") }}</span>\r\n      </h3>\r\n      <div v-if=\"hasSpaces\" class=\"grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]\">\r\n        <application-space-item v-for=\"repo in spaces.data\" :repo=\"repo\" repo-type=\"space\" />\r\n      </div>\r\n      <div v-else class=\"flex flex-wrap gap-4 mb-4 mt-[16px]\">\r\n        {{ $t(\"all.noData\") }}\r\n      </div>\r\n      <view-more v-if=\"spaces.more\" target=\"spaces\" @view-more-targets=\"viewMoreTargets\"></view-more>\r\n      <el-skeleton class=\"pr-6\" v-if=\"spacesLoading\" :rows=\"2\" animated />\r\n    </div> ")
  ]))
}