<template>
  <div>
    <el-card>
      <template #header>
        <div class="card-header">
          <span>Model Detail</span>
        </div>
      </template>
      <ul class="max-w-[480px]">
        <li class="flex justify-between mb-4">
          <label>Name</label>
          <p>{{ model.name }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Owner</label>
          <p>{{ route.params.namespace }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Creator</label>
          <p>{{ model.user?.username || '' }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Visibility</label>
          <p>{{ model.private == false ? 'public' : 'private' }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Weight</label>
          <p>{{ model.recom_op_weight || 1 }}</p>
        </li>
        <li v-if="sourceUrl" class="flex justify-between mb-4">
          <label>Origin</label>
          <p>{{ sourceUrl }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Created At</label>
          <p>{{ dayjs(model.created_at).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </li>
        <li v-if="lastUpdatedAt" class="flex justify-between mb-4">
          <label>Last Updated At</label>
          <p>{{ dayjs(lastUpdatedAt).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </li>
        <li v-if="lastMessage" class="flex justify-between mb-4">
          <label>Last Message</label>
          <p>{{ lastMessage }}</p>
        </li>                
      </ul>
      <template #footer>
        <div class="flex gap-[16px]">
          <div class="card-footer">
            <el-button @click="redirectModelEdit" class="w-[100px]">编辑</el-button>
            <el-button @click="showWeightDialog = true" class="w-[100px]">设置权重</el-button>
          </div>
          <div class="card-footer">
            <el-button v-if="canSync" @click="syncModelMirror" class="w-[100px]">同步</el-button>
          </div>
        </div>
      </template>
    </el-card>
    <el-dialog 
      v-model="showWeightDialog" 
      title="设置模型权重" 
      width="30%" 
      class="dialogWidth"
      style="border-radius: 0.5rem" 
      left
    >
      <el-input v-model="weight" type="number" placeholder="请输入权重" /> 
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showWeightDialog = false">{{ $t('all.cancel') }}</el-button>
          <el-button type="primary" @click="updateWeight">
            {{ $t('all.update') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import dayjs from 'dayjs'
  import { ElMessage } from 'element-plus'
  import useFetchApi from "../../../packs/useFetchApi"

  const route = useRoute()
  const model = ref({})
  const showWeightDialog = ref(false)
  const weight = ref(1)
  const lastUpdatedAt = ref(null)
  const lastMessage = ref(null)
  const canSync = ref(false)
  const sourceUrl = ref(null)

  const fetchModel = async () => {
    const { data, error } = await useFetchApi(
      `/models/${route.params.namespace}/${route.params.name}?need_op_weight=true`
    ).json()

    if (error.value) {
      ElMessage.error('Failed to fetch model')
    } else {
      const body_data = data.value.data
      model.value = body_data
    }
  }

  const redirectModelEdit = () => {
    window.location.href = `/new_admin/models/${route.params.namespace}/${route.params.name}/edit`
  }

  const updateWeight = async() => {
    const params = {
      repo_id: Number(model.value.repository_id),
      weight: Number(weight.value),
    }
    const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params)
        }
    const { data, error } = await useFetchApi(
      `/recom/opweight`,
      options
    ).json()

    if (error.value) {
      ElMessage({ message: error.value.msg, type: 'warning' })
    } else {
      showWeightDialog.value = false
      location.reload()
    }
  }

  const getModelMirror = async () => {
    const { data, error } = await useFetchApi(
      `/models/${route.params.namespace}/${route.params.name}/mirror`
    ).json()
    if (data) {
      const body_data = data.value.data
      lastUpdatedAt.value = body_data.last_updated_at
      lastMessage.value = body_data.last_message || ' '
      sourceUrl.value = body_data["source_url"]
      canSync.value = body_data["status"] != 'finished' 
    }
  }

  const syncModelMirror = async () => {
    const { data, error } = await useFetchApi(
      `/models/${route.params.namespace}/${route.params.name}/mirror/sync`,
      { method: 'POST', headers: { 'Content-Type': 'application/json' } }
    ).json()

    if (error.value) {
      ElMessage.error('同步失败！')
    } else {
      ElMessage.success('同步成功！')
    }
  }

  onMounted(() => {
    fetchModel()
    getModelMirror()
  })
</script>
