import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "max-w-[1440px] m-auto flex" }
const _hoisted_2 = { class: "w-full md:px-[16px]" }
const _hoisted_3 = { class: "dialog-footer" }
const _hoisted_4 = { class: "flex custom-table mt-4" }
const _hoisted_5 = {
  key: 3,
  class: "text-center opacity-[.26] text-[16px] font-[500] leading-[24px]"
}
const _hoisted_6 = { class: "font-[500]" }
const _hoisted_7 = { class: "whitespace-pre-line text-sm p-2 max-h-[400px] overflow-y-auto" }
const _hoisted_8 = { class: "font-[700] cursor-help" }
const _hoisted_9 = { class: "flex gap-[4px] flex-wrap justify-center" }
const _hoisted_10 = { class: "flex gap-[4px] flex-wrap justify-center" }
const _hoisted_11 = { class: "flex gap-[4px] flex-wrap justify-center" }
const _hoisted_12 = { class: "flex gap-[4px] flex-wrap justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rank_filter = _resolveComponent("rank-filter")
  const _component_weight_adjuster = _resolveComponent("weight-adjuster")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_champion_icon = _resolveComponent("champion-icon")
  const _component_runner_up_icon = _resolveComponent("runner-up-icon")
  const _component_third_place_icon = _resolveComponent("third-place-icon")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_el_tag = _resolveComponent("el-tag")
  const _component_el_table = _resolveComponent("el-table")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" 筛选栏 "),
      _createVNode(_component_rank_filter, {
        version: $setup.searchForm.version,
        deployment_method_options: $setup.deployment_method_options,
        input_modal_options: $setup.input_modal_options,
        output_modal_options: $setup.output_modal_options,
        onFilterChange: $setup.handleFilterChange,
        onAdjustWeight: _cache[0] || (_cache[0] = $event => ($setup.showWeightAdjuster = true))
      }, null, 8 /* PROPS */, ["version", "deployment_method_options", "input_modal_options", "output_modal_options", "onFilterChange"]),
      _createCommentVNode(" 权重调整器弹窗 "),
      _createVNode(_component_el_dialog, {
        modelValue: $setup.showWeightAdjuster,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.showWeightAdjuster) = $event)),
        title: "调整权重",
        width: "800px",
        "close-on-click-modal": false,
        "close-on-press-escape": false
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = $event => ($setup.showWeightAdjuster = false))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("取消")
              ])),
              _: 1 /* STABLE */
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_weight_adjuster, {
            "initial-weights": $setup.weights,
            onWeightsChanged: $setup.handleWeightsChange,
            onApply: $setup.handleWeightApply
          }, null, 8 /* PROPS */, ["initial-weights", "onWeightsChanged", "onApply"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]),
      _createCommentVNode(" 排名表格 "),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: $setup.sortedRankLists,
          "default-sort": { prop: 'score', order: 'descending' },
          "show-overflow-tooltip": true,
          style: {"width":"100%"},
          border: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              fixed: "",
              prop: "rank",
              label: "排名",
              width: "70",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                (scope.row.rank == 1)
                  ? (_openBlock(), _createBlock(_component_champion_icon, {
                      key: 0,
                      class: "m-auto"
                    }))
                  : (scope.row.rank == 2)
                    ? (_openBlock(), _createBlock(_component_runner_up_icon, {
                        key: 1,
                        class: "m-auto"
                      }))
                    : (scope.row.rank == 3)
                      ? (_openBlock(), _createBlock(_component_third_place_icon, {
                          key: 2,
                          class: "m-auto"
                        }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.row.rank), 1 /* TEXT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "name",
              label: "模型名称",
              width: "150",
              fixed: "",
              "show-overflow-tooltip": ""
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(scope.row.name), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "score",
              width: "80",
              label: "得分",
              align: "center",
              fixed: ""
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_popover, {
                  content: scope.row.scoreDetails,
                  placement: "right",
                  trigger: "hover",
                  width: 400,
                  "popper-class": "score-details-popover",
                  "hide-after": 0,
                  "show-arrow": true,
                  offset: 12
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(scope.row.scoreDetails), 1 /* TEXT */)
                  ]),
                  reference: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, _toDisplayString($setup.formatNumber(scope.row.score, 2)), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "baidu_index",
              width: "110",
              label: "百度指数",
              align: "center",
              sortable: ""
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString($setup.formatNumber(scope.row.baidu_index)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "wechat_index",
              width: "110",
              label: "微信指数",
              align: "center",
              sortable: ""
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString($setup.formatNumber(scope.row.wechat_index)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "huggingface_downloads",
              width: "180",
              label: "Huggingface下载量",
              align: "center",
              sortable: ""
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString($setup.formatNumber(scope.row.huggingface_downloads)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "github_forks",
              width: "180",
              label: "Github Fork数",
              align: "center",
              sortable: ""
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString($setup.formatNumber(scope.row.github_forks)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "parameter_scales",
              label: "参数规模",
              "min-width": "120",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((scope.row.parameter_scales || []), (scale) => {
                    return (_openBlock(), _createBlock(_component_el_tag, {
                      key: scale,
                      size: "small",
                      effect: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.formatNumber(scale)) + "B ", 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "deployment_methods",
              label: "部署方式",
              "min-width": "120",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.deployment_methods, (dl) => {
                    return (_openBlock(), _createBlock(_component_el_tag, {
                      key: dl,
                      type: dl === '云端API部署' ? 'success' : 'primary',
                      size: "small",
                      effect: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(dl === '云端API部署' ? '云端' : '本地'), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "input_modals",
              label: "输入模态",
              "min-width": "100",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.input_modals, (im) => {
                    return (_openBlock(), _createBlock(_component_el_tag, {
                      key: im,
                      size: "small",
                      effect: "plain",
                      class: _normalizeClass($setup.modal_type_class_map[im]),
                      style: _normalizeStyle($setup.getModalStyle(im))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(im), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "style"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "output_modals",
              label: "输出模态",
              "min-width": "100",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.output_modals, (om) => {
                    return (_openBlock(), _createBlock(_component_el_tag, {
                      key: om,
                      size: "small",
                      effect: "plain",
                      class: _normalizeClass($setup.modal_type_class_map[om]),
                      style: _normalizeStyle($setup.getModalStyle(om))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(om), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "style"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"])), [
          [_directive_loading, $setup.loading]
        ])
      ])
    ])
  ]))
}