<template>
  <div>
    <doc-content
      :doc="doc"
      :can-edit="canEdit(doc)"
      :show-actions="true"
    />
    <div class="max-w-[1200px] mx-auto mt-8 px-4">
      <Comments 
        :doc-uuid="doc.uuid"
        :current-user="currentUser"
      />
    </div>
  </div>
</template>

<script>
import DocContent from './components/DocContent.vue'
import Comments from './components/Comments.vue'

export default {
  components: {
    DocContent,
    Comments
  },
  props: {
    doc: {
      type: Object,
      required: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  setup(props) {
    const canEdit = (doc) => {
      if (props.admin) return true
      if (!props.currentUser) return false
      return doc.user_id === props.currentUser.id
    }

    return {
      canEdit
    }
  }
}
</script>
