import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[4px] bg-[#F5F7FA]" }
const _hoisted_2 = { class: "flex text-[14px] leading-[22px]" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "flex items-center gap-[8px] text-[#909399] text-[14px] leading-[22px]" }
const _hoisted_5 = {
  for: "fileInput",
  class: "cursor-pointer"
}
const _hoisted_6 = { class: "underline" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["px-[20px] py-[9px] whitespace-nowrap border-r", $setup.activeTab == 'Edit' ? 'bg-white' : 'border-b']),
        onClick: _cache[0] || (_cache[0] = $event => ($setup.activeTab = 'Edit'))
      }, _toDisplayString(_ctx.$t('community.MD.edit')), 3 /* TEXT, CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass(["px-[20px] py-[9px] whitespace-nowrap border-r", $setup.activeTab == 'Preview' ? 'bg-white' : 'border-b']),
        onClick: _cache[1] || (_cache[1] = $event => ($setup.activeTab = 'Preview'))
      }, _toDisplayString(_ctx.$t('community.MD.preview')), 3 /* TEXT, CLASS */),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "border-b w-full" }, null, -1 /* HOISTED */))
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "px-[16px] py-[16px] bg-white",
      onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
      onDrop: $setup.handleDrop
    }, [
      _withDirectives(_createElementVNode("textarea", {
        ref: "commentTextareaRef",
        rows: "4",
        cols: "10",
        onInput: $setup.handleInput,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.theDesc) = $event)),
        class: "w-full text-[#606266] mb-[10px] p-[8px] border border-gray-300 shadow-xs rounded-md focus:outline-none focus:ring-0.5 focus:border-blue-300",
        placeholder: $props.placeholder==='留下评论' ? _ctx.$t('community.MD.default'): $props.placeholder
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
        [_vModelText, $setup.theDesc]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M1.99992 11.9997L4.51355 9.48605C5.03457 8.96503 5.29507 8.70452 5.59611 8.60523C5.86095 8.51788 6.14652 8.51577 6.41263 8.5992C6.7151 8.69403 6.97943 8.95066 7.50809 9.46393L9.65733 11.5506M14.3333 11.7913L13.3834 10.8415C12.8554 10.3135 12.5914 10.0495 12.2869 9.95055C12.0192 9.86354 11.7307 9.86354 11.4629 9.95055C11.1585 10.0495 10.8943 10.3136 10.3661 10.8418C9.96312 11.2448 9.65733 11.5506 9.65733 11.5506M12.3333 14.2265L9.65733 11.5506M11.9999 5.33301C11.9999 6.06939 11.403 6.66634 10.6666 6.66634C9.93021 6.66634 9.33325 6.06939 9.33325 5.33301C9.33325 4.59663 9.93021 3.99967 10.6666 3.99967C11.403 3.99967 11.9999 4.59663 11.9999 5.33301ZM7.73325 14.6663H8.26658C10.5068 14.6663 11.6269 14.6663 12.4825 14.2304C13.2352 13.8469 13.8471 13.235 14.2306 12.4823C14.6666 11.6267 14.6666 10.5066 14.6666 8.26634V7.73301C14.6666 5.4928 14.6666 4.37269 14.2306 3.51705C13.8471 2.7644 13.2352 2.15248 12.4825 1.76898C11.6269 1.33301 10.5068 1.33301 8.26659 1.33301H7.73325C5.49304 1.33301 4.37294 1.33301 3.51729 1.76898C2.76464 2.15248 2.15272 2.7644 1.76923 3.51705C1.33325 4.37269 1.33325 5.4928 1.33325 7.73301V8.26634C1.33325 10.5066 1.33325 11.6267 1.76923 12.4823C2.15272 13.235 2.76464 13.8469 3.51729 14.2304C4.37294 14.6663 5.49304 14.6663 7.73325 14.6663Z",
            stroke: "#606266",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          })
        ], -1 /* HOISTED */)),
        _createElementVNode("label", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('community.MD.desc')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('community.MD.click')), 1 /* TEXT */),
          _createElementVNode("input", {
            id: "fileInput",
            type: "file",
            onChange: $setup.handleFileInput,
            class: "hidden"
          }, null, 32 /* NEED_HYDRATION */)
        ])
      ])
    ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
      [_vShow, $setup.activeTab == 'Edit']
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "px-[16px] markdown-body py-[16px] bg-white",
      innerHTML: $setup.renderMarkdown($setup.theDesc)
    }, null, 8 /* PROPS */, _hoisted_7), [
      [_vShow, $setup.activeTab == 'Preview']
    ])
  ]))
}