import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "max-w-[1440px] m-auto flex" }
const _hoisted_2 = { class: "w-full md:px-[16px] px-[32px] pt-[24px]" }
const _hoisted_3 = { class: "grid grid-cols-2 md:grid-cols-1 gap-[24px]" }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = { class: "text-[14px] font-[500] leading-[20px]" }
const _hoisted_6 = { class: "flex gap-[8px] items-center" }
const _hoisted_7 = { class: "text-[#475467] text-[14px] font-[400] leading-[24px]" }
const _hoisted_8 = { class: "text-[12px] text-[#00000061] font-[400] leading-[18px]" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "text-white text-[20px] font-[500] leading-[28px] z-30" }
const _hoisted_11 = { class: "text-white text-[16px] font-[500] leading-[28px] z-30" }
const _hoisted_12 = { class: "absolute left-0 top-0 h-[147px] w-full z-0" }
const _hoisted_13 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.spaces, (space) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex px-[16px] py-[12px] flex-col justify-center gap-[8px] rounded-[4px] border border-[1px] border-[#E6E6E6] shadow",
            key: space.id
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(space.title), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString($data.statusMap[space.status].text), 1 /* TEXT */),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[12px] w-[12px] rounded-full bg-[#17B26A]" }, null, -1 /* HOISTED */))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString($options.formatTime(space.updated_at)) + " 更新 ", 1 /* TEXT */),
            _createElementVNode("a", {
              href: `/spaces/${space.id}`,
              class: "h-[147px] rounded-[8px] bg-[#000000] w-[full] justify-center items-start flex flex-col px-[40px] gap-[8px] relative z-20"
            }, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(space.title), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_11, _toDisplayString(space.desc), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("img", {
                  src: space.cover_image,
                  alt: "",
                  class: "h-full",
                  style: {"inline-size":"-webkit-fill-available"}
                }, null, 8 /* PROPS */, _hoisted_13)
              ])
            ], 8 /* PROPS */, _hoisted_9)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}