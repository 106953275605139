import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] py-[36px] my-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }
const _hoisted_2 = { class: "grow flex flex-col gap-[24px] px-6 py-10 border-l min-h-[calc(100vh-153px)]" }
const _hoisted_3 = { class: "max-w-[512px]" }
const _hoisted_4 = { class: "mb-[16px]" }
const _hoisted_5 = { class: "mb-[4px] text-[#303133] text-[20px] font-semibold" }
const _hoisted_6 = { class: "text-[#606266] text-[14px]" }
const _hoisted_7 = { class: "bg-[#F5F7FA] p-[12px] rounded-[8px] mt-[16px]" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "my-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu, {
      class: "max-w-[411px] md:mb-[24px]",
      name: $data.profileName,
      displayName: $data.profileDisplayName,
      userInfo: _ctx.userInfo,
      avatar: $data.profileAvatar
    }, null, 8 /* PROPS */, ["name", "displayName", "userInfo", "avatar"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('accessToken.title')), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('accessToken.desc')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "text-[#303133] text-[16px] font-[500] mb-[16px]" }, " access token ", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_input, {
              modelValue: $data.theAccessToken,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.theAccessToken) = $event)),
              type: "password",
              "show-password": "",
              onfocus: "this.blur()"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createElementVNode("div", {
              class: "ml-[8px] px-[16px] py-[8px] border rounded-[4px] bg-[#FFF] cursor-pointer",
              onClick: _cache[1] || (_cache[1] = (...args) => ($options.copyToken && $options.copyToken(...args)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M9.43088 4.66699H9.63366C10.7538 4.66699 11.3138 4.66699 11.7416 4.88498C12.118 5.07673 12.4239 5.38269 12.6157 5.75901C12.8337 6.18683 12.8337 6.74689 12.8337 7.86699V9.63366C12.8337 10.7538 12.8337 11.3138 12.6157 11.7416C12.4239 12.118 12.118 12.4239 11.7416 12.6157C11.3138 12.8337 10.7538 12.8337 9.63366 12.8337H7.86699C6.74689 12.8337 6.18683 12.8337 5.75901 12.6157C5.38269 12.4239 5.07673 12.118 4.88498 11.7416C4.66699 11.3138 4.66699 10.7538 4.66699 9.63366V9.43088M4.36699 9.33366H6.13366C7.25376 9.33366 7.81382 9.33366 8.24164 9.11567C8.61796 8.92393 8.92393 8.61796 9.11567 8.24164C9.33366 7.81382 9.33366 7.25377 9.33366 6.13366V4.36699C9.33366 3.24689 9.33366 2.68683 9.11567 2.25901C8.92393 1.88269 8.61796 1.57673 8.24164 1.38498C7.81382 1.16699 7.25377 1.16699 6.13366 1.16699H4.36699C3.24689 1.16699 2.68683 1.16699 2.25901 1.38498C1.88269 1.57673 1.57673 1.88269 1.38498 2.25901C1.16699 2.68683 1.16699 3.24689 1.16699 4.36699V6.13366C1.16699 7.25376 1.16699 7.81382 1.38498 8.24164C1.57673 8.61796 1.88269 8.92393 2.25901 9.11567C2.68683 9.33366 3.24689 9.33366 4.36699 9.33366Z",
                  stroke: "#606266",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1 /* HOISTED */)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_el_button, {
            type: "default",
            onClick: $options.confirmRefreshAccessToken,
            class: "h-[30px] mb-[16px]"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('accessToken.refresh')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ])
  ]))
}