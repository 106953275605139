import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex min-h-[300px] md:flex-col-reverse" }
const _hoisted_2 = { class: "max-w-[80%] sm:max-w-[100%] pt-4 pb-10 pr-5 sm:pr-0 break-words flex-1" }
const _hoisted_3 = { class: "p-4 w-[20%] border-l border-[#EBEEF5] md:border-l-0 md:w-full md:px-0" }
const _hoisted_4 = { class: "text-[#303133] text-base font-semibold leading-6 mt-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!$setup.loading)
        ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
            key: 0,
            content: $setup.readmeContent,
            setDefaultText: true
          }, null, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[#606266] text-base font-medium leading-[22px]" }, "下载量", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.downloadCount), 1 /* TEXT */)
    ])
  ]))
}