import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[32px] bg-[#F4F5FE] pt-[24px] pb-[40px] px-[32px] md:px-[10px]" }
const _hoisted_2 = { class: "flex flex-col max-w-[1440px] gap-[24px]" }
const _hoisted_3 = { class: "flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]" }
const _hoisted_4 = { class: "flex items-center gap-[8px] text-[14px] font-[400] leading-[20px]" }
const _hoisted_5 = { class: "flex gap-[8px] font-[500] items-center" }
const _hoisted_6 = {
  href: "/issues",
  class: "flex items-center"
}
const _hoisted_7 = { class: "text-[#101828] text-[30px] font-[500] leading-[30px]" }
const _hoisted_8 = { class: "flex gap-[24px] md:flex-col" }
const _hoisted_9 = { class: "w-[503px] flex flex-col gap-[24px] md:w-full" }
const _hoisted_10 = { class: "bg-white p-[32px] rounded-[8px]" }
const _hoisted_11 = { class: "text-[14px] font-[400] leading-[22px] flex flex-col gap-[8px]" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "text-[#667085]" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "ml-[8px] px-[8px] py-[3px] rounded-[4px] bg-white border border-[var(--Gray-300)] text-[12px] leading-[18px] font-[400] text-[var(--Gray-700)]" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "text-[#667085]" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "text-[#667085]" }
const _hoisted_20 = { class: "flex gap-[8px] items-center" }
const _hoisted_21 = { class: "text-[#667085]" }
const _hoisted_22 = { class: "text-[#667085]" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "text-[#667085]" }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "flex md:flex-col gap-[16px]" }
const _hoisted_27 = { class: "flex w-[320px] md:w-full hover:bg-[#fbfafa] rounded-[8px] hover:shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[16px] mt-[16px]" }
const _hoisted_28 = { class: "flex flex-col" }
const _hoisted_29 = { class: "text-[var(--Brand-700)] text-[18px] font-[500] leading-[28px]" }
const _hoisted_30 = {
  key: 0,
  class: ""
}
const _hoisted_31 = {
  key: 1,
  class: ""
}
const _hoisted_32 = { class: "flex w-[320px] md:w-full hover:bg-[#fbfafa] rounded-[8px] hover:shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[16px] mt-[16px]" }
const _hoisted_33 = { class: "flex flex-col" }
const _hoisted_34 = { class: "text-[#182230] text-[16px] font-[500] leading-[24px]" }
const _hoisted_35 = { class: "bg-white p-[32px] rounded-[8px]" }
const _hoisted_36 = { class: "text-[14px] font-[400] leading-[22px] flex flex-col gap-[8px]" }
const _hoisted_37 = { class: "flex my-[16px]" }
const _hoisted_38 = { class: "flex items-center relative left-[57px] md:left-[47px]" }
const _hoisted_39 = {
  key: 0,
  src: "/images/xty_images/issue/checked.svg",
  class: "h-[24px] w-[24px]",
  alt: ""
}
const _hoisted_40 = {
  key: 1,
  src: "/images/xty_images/issue/processing.svg",
  class: "h-[24px] w-[24px] rounded-[100px] shadow-[0px_0px_0px_4px_rgba(77,106,214,0.24)]",
  alt: ""
}
const _hoisted_41 = {
  key: 2,
  src: "/images/xty_images/issue/pending.svg",
  class: "h-[24px] w-[24px]",
  alt: ""
}
const _hoisted_42 = {
  key: 0,
  src: "/images/xty_images/issue/split_primary.svg",
  class: "h-[24px] w-[112px] md:w-[92px]",
  alt: ""
}
const _hoisted_43 = {
  key: 1,
  src: "/images/xty_images/issue/split_gray.svg",
  class: "h-[24px] w-[112px] md:w-[92px]",
  alt: ""
}
const _hoisted_44 = { class: "flex flex-col items-center" }
const _hoisted_45 = ["href"]
const _hoisted_46 = { class: "w-full flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_Back = _resolveComponent("Back")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "xty_home"
          }),
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "slash"
          }),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[#475467]" }, "企业需求", -1 /* HOISTED */)),
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "slash"
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-[#344054]" }, "技术需求", -1 /* HOISTED */))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createVNode(_component_el_icon, { size: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_Back)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString($data.issue.project_name), 1 /* TEXT */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createCommentVNode(" 左侧 "),
      _createElementVNode("div", _hoisted_9, [
        _createCommentVNode(" issue 基础信息 "),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-[#475467]" }, "企业名称：", -1 /* HOISTED */)),
              _createElementVNode("span", _hoisted_13, [
                _createElementVNode("a", {
                  href: `/organizations/${$data.issue.org_path}`,
                  target: "_blank"
                }, _toDisplayString($data.issue.org_name), 9 /* TEXT, PROPS */, _hoisted_14),
                _createElementVNode("span", _hoisted_15, _toDisplayString($data.issue.org_type), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-[#475467]" }, "产业链：", -1 /* HOISTED */)),
              _createElementVNode("span", _hoisted_17, _toDisplayString($data.issue.industry_list.join('、')), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-[#475467]" }, "需求类型：", -1 /* HOISTED */)),
              _createElementVNode("span", _hoisted_19, _toDisplayString($data.kindsMap[$data.issue.kind]), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-[#475467]" }, "联系人：", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_21, _toDisplayString($data.issue.extra?.contact_name), 1 /* TEXT */),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-[#667085]" }, "|", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("a", {
                  href: `tel:${$data.issue.extra?.contact_phone}`
                }, _toDisplayString($data.issue.extra?.contact_phone), 9 /* TEXT, PROPS */, _hoisted_23)
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-[#667085]" }, "|", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("a", {
                  href: `mailto:${$data.issue.extra?.contact_email}`
                }, _toDisplayString($data.issue.extra?.contact_email), 9 /* TEXT, PROPS */, _hoisted_25)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _cache[9] || (_cache[9] = _createElementVNode("img", {
                src: "/images/xty_images/issue/budget.svg",
                class: "h-[52px] px-[24px]",
                alt: ""
              }, null, -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  ($data.issue.budget > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString($data.issue.budget) + "万", 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_31, "面议"))
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-[#667085] text-[14px] font-[400] leading-[20px]" }, " 预计投入金额 ", -1 /* HOISTED */))
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _cache[11] || (_cache[11] = _createElementVNode("img", {
                src: "/images/xty_images/issue/delivery.svg",
                class: "h-[52px] px-[24px]",
                alt: ""
              }, null, -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, _toDisplayString($data.issue.delivery_at ? $setup.dayjs($data.issue.delivery_at).format("YYYY-MM-DD") : '面议'), 1 /* TEXT */),
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-[#667085] text-[14px] font-[400] leading-[20px]" }, " 截止时间 ", -1 /* HOISTED */))
              ])
            ])
          ])
        ]),
        _createCommentVNode(" issue 状态 "),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px]" }, "流程状态", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_37, [
              _createCommentVNode(" 进程状态轴 "),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statusMap, (status, key) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: key,
                  class: "flex flex-col gap-[12px] w-[136px] md:w-[116px]"
                }, [
                  _createElementVNode("div", _hoisted_38, [
                    ($data.statusMap[$data.issue.status]?.value > status.value)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_39))
                      : ($data.statusMap[$data.issue.status]?.value === status.value)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_40))
                        : (_openBlock(), _createElementBlock("img", _hoisted_41)),
                    (key !== 'completed')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          ($data.statusMap[$data.issue.status]?.value > status.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_42))
                            : (_openBlock(), _createElementBlock("img", _hoisted_43))
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`${$data.statusMap[$data.issue.status]?.value === status.value ? 'text-[var(--Brand-700)]' : 'text-[var(--Gray-600)]' } text-[14px] font-[500] leading-[22px]`)
                    }, _toDisplayString(status.label), 3 /* TEXT, CLASS */),
                    ($data.issue[status.time])
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`${$data.statusMap[$data.issue.status]?.value === status.value ? 'text-[var(--Brand-700)]' : 'text-[var(--Gray-600)]' } text-[14px] font-[300] leading-[22px]`)
                        }, _toDisplayString($setup.dayjs($data.issue[status.time]).format("YYYY-MM-DD")), 3 /* TEXT, CLASS */))
                      : _createCommentVNode("v-if", true)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            ($data.issue.status === 'collecting')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: `/issue_plans/new?issue_id=${$data.issue.id}`,
                  class: "w-[106px] btn btn-orange flex justify-center items-center px-[20px] py-[9px] text-[14px] font-[500] leading-[22px]"
                }, "投递方案", 8 /* PROPS */, _hoisted_45))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _createCommentVNode(" 右侧 "),
      _createElementVNode("div", _hoisted_46, [
        _createVNode($setup["IssueInfo"], { issue: $data.issue }, null, 8 /* PROPS */, ["issue"])
      ])
    ])
  ]))
}