<template>
  <div class="comments-section">
    <!-- 评论排序 -->
    <div class="sort-section mb-4 flex items-center gap-4">
      <span class="text-[14px] text-[#86909C]">排序方式：</span>
      <el-radio-group v-model="sortBy" size="small" @change="handleSortChange">
        <el-radio-button label="hot">热门</el-radio-button>
        <el-radio-button label="new">最新</el-radio-button>
        <el-radio-button label="old">最早</el-radio-button>
      </el-radio-group>
    </div>

    <!-- 评论输入框 -->
    <template v-if="canComment">
      <comment-editor
        v-model="newComment"
        :loading="submitting"
        submit-text="发表评论"
        @submit="submitComment"
      />
    </template>
    <template v-else>
      <div class="text-center text-[#86909C] py-4 bg-gray-50 rounded-lg">
        请<a href="/login" class="text-[#FF5005] hover:text-[#ff6a2b]">登录</a>后发表评论
      </div>
    </template>

    <!-- 评论列表 -->
    <div class="comments-list">
      <template v-if="comments.length > 0">
        <div v-for="comment in comments" :key="comment.id">
          <!-- 渲染评论树 -->
          <comment-tree
            :comment="comment"
            :user-details="userDetails"
            :current-user="currentUser"
            :editing-id="editingCommentId"
            :replying-id="replyToId"
            :loading="submitting"
            :loading-replies="loadingReplies"
            :highlighted-id="highlightedCommentId"
            @vote="handleVote"
            @reply="showReplyInput"
            @edit="showEditInput"
            @delete="deleteComment"
            @edit-submit="submitEdit"
            @edit-cancel="cancelEdit"
            @reply-submit="submitReply"
            @reply-cancel="cancelReply"
            @profile-click="goToProfile"
            @parent-click="scrollToComment"
            @load-more="loadMoreReplies"
          />
        </div>
      </template>
      <template v-else>
        <div class="text-center text-[#86909C] py-8">
          暂无评论，快来发表第一条评论吧
        </div>
      </template>

      <!-- 分页 -->
      <div v-if="totalPages > 1" class="flex justify-center mt-6">
        <el-pagination
          v-model:current-page="currentPage"
          :page-size="perPage"
          :total="totalCount"
          @current-change="handlePageChange"
          layout="prev, pager, next"
          background
        />
      </div>
    </div>
  </div>
</template>

<script>
import { 
  createDocComment, 
  deleteDocComment, 
  getDocComments,
  updateDocComment,
  voteDocComment,
  getDocCommentReplies
} from '@api/csrf/doc_comment'
import { userDetail } from '@api/jwt/user'
import { ElMessage } from 'element-plus'
import CommentTree from './comments/CommentTree.vue'
import CommentEditor from './comments/CommentEditor.vue'

export default {
  name: 'Comments',
  components: {
    CommentTree,
    CommentEditor
  },
  props: {
    docUuid: {
      type: String,
      required: true
    },
    currentUser: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      comments: [],
      newComment: '',
      editingCommentId: null,
      replyToId: null,
      submitting: false,
      userDetails: {},
      loading: false,
      currentPage: 1,
      totalPages: 1,
      totalCount: 0,
      perPage: 20,
      sortBy: 'hot',
      allComments: new Map(),
      loadingReplies: {},
      highlightedCommentId: null
    }
  },
  computed: {
    canComment() {
      return !!this.currentUser
    }
  },
  methods: {
    async fetchUserDetails(comments) {
      if (!Array.isArray(comments)) return

      const usernames = new Set()
      comments.forEach(comment => {
        if (comment?.user_name) {
          usernames.add(comment.user_name)
        }
        if (Array.isArray(comment?.replies)) {
          comment.replies.forEach(reply => {
            if (reply?.user_name) {
              usernames.add(reply.user_name)
            }
          })
        }
      })

      const fetchPromises = [...usernames].map(async (username) => {
        try {
          const response = await userDetail(username)
          if (response?.data) {
            this.userDetails[username] = response.data
          }
        } catch (error) {
          console.error(`获取用户 ${username} 信息失败:`, error)
        }
      })
      
      await Promise.all(fetchPromises)
    },

    async fetchComments(page = 1) {
      this.loading = true
      try {
        const response = await getDocComments(this.docUuid, {
          params: {
            page,
            per_page: this.perPage,
            sort_by: this.sortBy
          }
        })
        
        if (response?.data) {
          const { data, meta } = response
          if (Array.isArray(data)) {
            await this.fetchUserDetails(data)
            this.comments = data
            this.currentPage = meta.current_page
            this.totalPages = meta.total_pages
            this.totalCount = meta.total_count

            this.updateCommentsMap(data)
          }
        }
      } catch (error) {
        ElMessage.error('获取评论失败')
      } finally {
        this.loading = false
      }
    },

    updateCommentsMap(comments) {
      const updateMap = (comment) => {
        this.allComments.set(comment.id, comment)
        if (Array.isArray(comment.replies)) {
          comment.replies.forEach(reply => {
            updateMap(reply)
          })
        }
      }

      comments.forEach(comment => {
        updateMap(comment)
      })
    },

    async handleSortChange() {
      await this.fetchComments(1)
    },

    async submitComment(content) {
      if (!this.canComment) {
        ElMessage.warning('请先登录后再发表评论')
        return
      }

      if (!content.trim()) {
        ElMessage.warning('请输入评论内容')
        return
      }

      this.submitting = true
      try {
        await createDocComment(this.docUuid, {
          comment: {
            content: content
          }
        })
        await this.fetchComments(1)
        this.newComment = ''
        ElMessage.success('评论发表成功')
      } catch (error) {
        ElMessage.error('评论发表失败，请检查是否登录')
      } finally {
        this.submitting = false
      }
    },

    showReplyInput(comment) {
      this.replyToId = comment.id
      this.editingCommentId = null
    },

    showEditInput(comment) {
      this.editingCommentId = comment.id
      this.replyToId = null
    },

    cancelReply() {
      this.replyToId = null
    },

    cancelEdit() {
      this.editingCommentId = null
    },

    async submitReply({ parentId, content }) {
      if (!this.canComment) {
        ElMessage.warning('请先登录后再发表评论')
        return
      }

      if (!content.trim()) {
        ElMessage.warning('请输入回复内容')
        return
      }

      this.submitting = true
      try {
        await createDocComment(this.docUuid, {
          comment: {
            content: content,
            parent_id: parentId
          }
        })
        await this.fetchComments(this.currentPage)
        this.cancelReply()
        ElMessage.success('回复发表成功')
      } catch (error) {
        ElMessage.error('回复发表失败')
      } finally {
        this.submitting = false
      }
    },

    async submitEdit({ id, content }) {
      if (!content.trim()) {
        ElMessage.warning('请输入内容')
        return
      }

      this.submitting = true
      try {
        await updateDocComment(this.docUuid, id, {
          comment: {
            content: content
          }
        })
        await this.fetchComments(this.currentPage)
        this.cancelEdit()
        ElMessage.success('编辑成功')
      } catch (error) {
        ElMessage.error('编辑失败')
      } finally {
        this.submitting = false
      }
    },

    async deleteComment(commentId) {
      try {
        await deleteDocComment(this.docUuid, commentId)
        await this.fetchComments(this.currentPage)
        ElMessage.success('评论删除成功')
      } catch (error) {
        ElMessage.error('评论删除失败')
      }
    },

    async handleVote({ id, type }) {
      if (!this.canComment) {
        ElMessage.warning('请先登录后再进行投票')
        return
      }

      if (!id || !type) {
        console.error('Invalid vote parameters:', { id, type })
        ElMessage.error('投票参数无效')
        return
      }

      const comment = this.allComments.get(id)
      if (!comment) {
        console.error('Comment not found:', id)
        ElMessage.error('评论不存在')
        return
      }

      try {
        const response = await voteDocComment(this.docUuid, id, { vote_type: type })
        if (response?.data) {
          comment.votes_count = response.data.votes_count
          comment.user_vote = response.data.user_vote
          // 可选：显示成功消息
          // ElMessage.success(response.message || '投票成功')
        } else {
          console.error('Invalid response format:', response)
          ElMessage.error('服务器响应格式错误')
        }
      } catch (error) {
        console.error('投票失败:', error)
        ElMessage.error('投票失败，请稍后重试')
      }
    },

    goToProfile(username) {
      window.location.href = `/profile/${username}`
    },

    scrollToComment(commentId) {
      this.highlightedCommentId = commentId
      const element = document.getElementById(`comment-${commentId}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },

    async handlePageChange(page) {
      await this.fetchComments(page)
      this.$el.scrollIntoView({ behavior: 'smooth' })
    },

    async loadMoreReplies(commentId) {
      if (this.loadingReplies[commentId]) return

      this.$set(this.loadingReplies, commentId, true)
      try {
        const comment = this.findComment(commentId)
        if (!comment) return

        const currentRepliesCount = comment.replies?.length || 0
        const response = await getDocCommentReplies(this.docUuid, {
          params: {
            comment_id: commentId,
            page: Math.floor(currentRepliesCount / 10) + 1,
            per_page: 10
          }
        })

        if (response?.data?.data) {
          const newReplies = response.data.data
          await this.fetchUserDetails(newReplies)
          
          if (!comment.replies) {
            comment.replies = []
          }
          
          comment.replies.push(...newReplies)
          comment.has_more_replies = response.data.meta.total_count > comment.replies.length

          this.updateCommentsMap(newReplies)
        }
      } catch (error) {
        console.error('加载回复失败:', error)
        ElMessage.error('加载回复失败')
      } finally {
        this.$set(this.loadingReplies, commentId, false)
      }
    },

    findComment(commentId) {
      return this.allComments.get(commentId)
    }
  },
  created() {
    this.fetchComments()
  }
}
</script>

<style scoped>
.highlight-comment {
  animation: highlight 1.5s ease-out;
}

@keyframes highlight {
  0% {
    background-color: rgba(255, 80, 5, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

:deep(.el-radio-button__inner) {
  border-color: #E5E7EB !important;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #FF5005 !important;
  border-color: #FF5005 !important;
  box-shadow: -1px 0 0 0 #FF5005 !important;
}
</style> 