import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-[full] px-[90px] overflow-x-scroll m-auto py-[94px] flex flex-col gap-[24px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FirstLevel = _resolveComponent("FirstLevel")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.range, (times) => {
      return (_openBlock(), _createElementBlock("div", {
        key: times,
        class: "flex flex-nowrap gap-[24px]"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.data.bulletins, (firstItem, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: firstItem.label
          }, [
            (index % this.data.firstLevelRowCount === times)
              ? (_openBlock(), _createBlock(_component_FirstLevel, {
                  key: 0,
                  firstItem: firstItem,
                  secondLevelRowCount: this.data.secondLevelRowCount,
                  thirdLevelRowCount: this.data.thirdLevelRowCount
                }, null, 8 /* PROPS */, ["firstItem", "secondLevelRowCount", "thirdLevelRowCount"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}