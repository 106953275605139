import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex justify-between items-center mb-1" }
const _hoisted_3 = { class: "w-full flex items-center justify-between" }
const _hoisted_4 = { class: "flex-1 text-[#303133] font-semibold leading-6 truncate mr-[8px] text-left" }
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = { class: "px-[8px] py-[3px] flex items-center justify-center border rounded-md text-[#344054] text-[12px]" }
const _hoisted_7 = { class: "text-sm leading-snug text-[#606266] flex justify-between" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "my-2" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "h-[36px]"
}
const _hoisted_12 = { class: "max-w-[402px] xl:max-w-full h-[36px] text-[#606266] text-sm overflow-hidden text-ellipsis line-clamp-2" }
const _hoisted_13 = {
  key: 2,
  class: "flex gap-2 my-2 overflow-x-auto no-scrollbar"
}
const _hoisted_14 = { class: "max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createElementBlock("a", {
    href: `/spaces/${$props.repo.path}`,
    class: "focus:outline focus:outline-4 focus:outline-[#EAECF0] hover:shadow-md border border-gray-200 rounded-xl p-4 w-[409px] lg:w-[409px] mlg:w-full"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString($setup.getComputed.path), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString($setup.getComputed.visibility), 1 /* TEXT */),
          _createVNode($setup["AppStatus"], {
            appStatus: $setup.getComputed.appStatus || 'NoAppFile',
            spaceResource: $props.repo.hardware
          }, null, 8 /* PROPS */, ["appStatus", "spaceResource"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", null, [
        _createElementVNode("span", _hoisted_8, _toDisplayString($props.repo.path.split('/')[0]), 1 /* TEXT */),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mr-2" }, "·", -1 /* HOISTED */)),
        _createElementVNode("span", null, _toDisplayString($props.repo.updated_at.substring(0, 10)), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("img", {
        src: $setup.coverImageUrl,
        class: "w-[375px] xl:w-full h-[144px] object-cover rounded cursor-pointer hover:opacity-50"
      }, null, 8 /* PROPS */, _hoisted_10)
    ]),
    (!$props.repo.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11))
      : (_openBlock(), _createBlock(_component_el_popover, {
          key: 1,
          width: 384,
          trigger: "hover",
          placement: "bottom",
          effect: "dark",
          content: $props.repo.description
        }, {
          reference: _withCtx(() => [
            _createElementVNode("p", _hoisted_12, _toDisplayString($props.repo.description), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"])),
    ($setup.getComputed.taskTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString($setup.getComputed.taskTag), 1 /* TEXT */),
          _cache[1] || (_cache[1] = _createElementVNode("span", null, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "1",
              height: "8",
              viewBox: "0 0 1 8",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M0.5 0V8",
                stroke: "#DCDFE6"
              })
            ])
          ], -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 8 /* PROPS */, _hoisted_1))
}