import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_avatar, {
      size: $data.size,
      src: $props.avatar
    }, null, 8 /* PROPS */, ["size", "src"]),
    ($props.flag == 'blue_v')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: "/images/xty_images/header/flag/blue_v.svg",
          class: _normalizeClass($data.flagClass),
          alt: ""
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}