<template>
  <a
    :href="`/datasets/${dataset.path}`"
    class="basis-1/2 md:basis-full max-w-[532px] md:max-w-full md:max-w-full flex flex-col xty-model-card md:bg-white bg-unset"
  >
    <div class="flex items-center mb-[5px] sm:w-auto gap-[8px]">
      <div
        :title="dataset.path"
        class="dataset-path max-w-[60%] text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {{ dataset.name }}
      </div>
      <div v-if="dataset.repository?.tags?.length > 0" class="flex items-center gap-[8px]">
        <span :class="`text-[12px] px-[8px] py-[3px] flex gap-[4px] font-[400] rounded-[4px] bg-[${colorMap(displayTag().category)}30] text-[${colorMap(displayTag().category)}]`">{{ displayTag().name }}</span>
      </div>
    </div>
    <div class="flex items-center gap-[8px] text-xs text-[#00000061]">
      <short-info :supplier="props.dataset.path.split('/')[0]" :updated_at="dataset.updated_at" :downloads="dataset.downloads" />
    </div>
  </a>
</template>

<script setup>
  import { computed } from 'vue'
  import ShortInfo from '../shared/ShortInfo.vue';
  import { colorMap } from "../helpers/utils";

  const props = defineProps({
    dataset: Object,
  })

  const visibility = computed(() => {
    return props.dataset.private ? '私有' : '公开'
  });

  const displayTag = () => {
    let tag
    if (props.dataset.repository.tags?.length > 0) {
      tag = props.dataset.repository.tags.find((tag) => tag.category === "task")
    }
    if (tag == undefined || tag == null) {
      tag = {}
    }
    return tag
  };
</script>

<style scoped>
  .dataset-card:hover .dataset-path {
    color: var(--blue-blue-5001-f-75-cb, #1F75CB);
  }
</style>
