import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[6px] border border-[#FEDF89] bg-[#FFFAEB] rounded-[6px] py-[2px] px-[8px]" }
const _hoisted_2 = { class: "text-[14px] text-[#B54708] font-[400] leading-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "8",
      height: "8",
      viewBox: "0 0 8 8",
      fill: "none"
    }, [
      _createElementVNode("circle", {
        cx: "4",
        cy: "4",
        r: "3",
        fill: "#F79009"
      })
    ], -1 /* HOISTED */)),
    _createElementVNode("p", _hoisted_2, _toDisplayString($props.statusName), 1 /* TEXT */)
  ]))
}