export const navbar = {
  "allSolution":"Complete solutions",
  "models": "Models",
  "datasets": "Datasets",
  spaces: "Spaces",
  codes: "Codes",
  "product": "Products",
  "starCloud": "StarCloud",
  "starCloudText": "One stop large-scale model development and service operation platform, including computing resource scheduling and algorithm training inference services",
  "starShip": "StarShip",
  "starShipText": "Provide a complete model/data management and application building platform for the large model application development team",
  "starChain":"StarChain",
  "starChainText":"LLM application construction, testing, and sharing platform",
  "solution": "Solutions",
  "EKB": "EKB AI",
  "EKBText": "The whole life cycle management base of enterprise knowledge in the era of large model is a one-stop solution for enterprise customers",
  "starAIO": "StarAIO",
  "starAIOText": "Integrated CAICT software platform and selected open source large model of software and hardware integration solution",
  "imagen": "Starlmagen",
  "imagenText": "Provide creators with better tools to achieve exponential amplification of creativity and productivity",
  "starCode": "StarCode",
  "starCodeText": "Innovative features based on big model technology to help developers improve work efficiency",
  "developer": "Developers",
  "open": "CSGHub",
  "openText": "An open-source and trustworthy large model asset management platform",
  "llm_inference_open": "llm-Inference",
  "llm_inference_open_text": "Efficiently deploy LLM inference services",
  "llm_finetune":"llm-finetune",
  "llm_finetune_text":"Framework of training LLMs",
  "space": "Space",
  "spaceText": "Large model application experience platform",
  "docs": "Documents",
  "docsText": "Product Description and Frequently Asked Questions of Chuanshen Community",
  "computer": "Compute",
  "enterprise": "Company",
  "about": "Company Profile",
  "aboutText": "About CAICT",
  "partner": "Business Partner",
  "partnerText": "Work with partners to build a solution ecology",
  "expert": "Technical Expert",
  "expertText": "Collaborate and share within an open and inclusive ecosystem alongside experts",
  "community": "Community",
  "campaign": "Community Activities",
  "campaignText": "Aggregate top industry and domain conferences and offline events",
  "dailyPaper": "Papers Center",
  "dailyPaperText": "Gather the latest research results and share cutting-edge academic papers",
  "profile": "Your Profile",
  editProfile: "Account Settings",
  "setting": "Settings",
  "contact": "Contact Us",
  "newModel": "New Model",
  "newDataset": "New Dataset",
  newCode: "New Code",
  newApplicationSpace: "New Space",
  "newOrg": "New Organization",
  newOrganization: "New Organization",
  "recommendation": "Paper Recommendation",
  "logout": "Sign Out",
  "register": "Login",
  loginRegister: "Login/Registe",
  "login": "Login",
  "source": "Console",
}
