<template>
  <div class="relative w-[460px] h-[220px] overflow-hidden rounded-[0_0_100px_0] bg-[#1B185FB3]">
    <img :src="image" class="w-[800px] h-[800px] rounded-[24px] absolute left-[-290px] top-[-290px] animation-rotate-60" alt="">
    <img :src="icon" class="w-[100px] h-[100px] rounded-[24px] absolute left-[56px] top-[56px]" alt="">
  </div>

</template>
<script>
  export default {
    props: {
      image: { type: String},
      icon: { type: String},
    },
  }
</script>