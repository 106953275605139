import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "el-upload__text" }
const _hoisted_2 = { class: "text-[#FF5005] font-[500]" }
const _hoisted_3 = { class: "flex gap-[20px] px-[120px]" }
const _hoisted_4 = ["onClick", "src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_upload_filled = _resolveComponent("upload-filled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      class: "w-full",
      drag: "",
      headers: { 'X-CSRF-TOKEN': $data.csrf_token },
      multiple: "",
      data: {
      namespace: 'system-logo',
      file_max_size: 1024*1024
    },
      action: "/internal_api/upload",
      "on-success": (res) => $options.handleUploadSuccess(res)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('components.upload.click_to_upload')), 1 /* TEXT */),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('components.upload.or_drag_file')), 1 /* TEXT */)
            ])
          ]),
          _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
            default: _withCtx(() => [
              _createVNode(_component_upload_filled)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["headers", "on-success"]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.files, (url, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("img", {
            onClick: $event => ($options.copyUrl(url)),
            src: url,
            class: "w-[300px] h-[100px] object-cover border rounded-[8px] cursor-pointer hover:opacity-80"
          }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}