<template>
  <div>
    <div class="text-[#101828] text-[24px] font-[500] leading-[32px] mb-[32px]">需求详情</div>
    <div class="flex flex-col gap-[32px]">
      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">需求描述</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.description || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">需求预期</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.expect_usage || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">数据准备</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.data || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">算法准备</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.algorithm || '-' }}
        </div>
      </div>

      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">算力准备</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.evaluation_data || '-' }}
        </div>
      </div>

      <!-- performance_metrics -->
      <div>
        <div class="text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]">验收要求</div>
        <div class="text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]">
          {{ issue.extra?.performance_metrics || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      issue: {
        type: Object,
        required: true
      }
    }
  }
</script>
