export const footer = {
  "about": "About us",
  "news": "News",
  "docs": "Document",
  "join": "Join us",
  "contact": "Contact us",
  "policy": "User Agreement & Privacy Policy",
  "illegal": "Reporting center",
  "police": "Beijing public network security 11011202004415",
  "company": "Company Introduction",
  "partner": "Business Cooperation",
  "resume": "Resume",
  "pr": "Media Collaboration",
  "ir": "Investment Relationship",
  "cookies": "Cookies Policy",
  "publish": "©Copyright 2023 CAICT.com",
  "ICP": "Beijing ICPB 2023006263-1",
  "wx": "WeChat Official Account",
  "follow":"Follow CAICT",
  "followText":"Follow the CAICT official account to stay updated with real-time information."
}
