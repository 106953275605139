import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2 mb-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-[12px] text-[#86909C]"
}
const _hoisted_3 = { class: "text-[12px] text-[#86909C]" }
const _hoisted_4 = {
  key: 1,
  class: "text-[12px] text-[#86909C]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "font-medium text-[14px] cursor-pointer hover:text-[#FF5005]",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('profile-click', $props.comment.user_name)))
    }, _toDisplayString($props.userDetails[$props.comment.user_name]?.nickname || $props.comment.user_name), 1 /* TEXT */),
    ($props.comment.parent_id)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" 回复 ")),
          _createElementVNode("span", {
            class: "text-[#1D2129] cursor-pointer hover:text-[#FF5005]",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('parent-click', $props.comment.parent_id)))
          }, _toDisplayString($options.getParentPreview()), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString($options.formatDate($props.comment.created_at)), 1 /* TEXT */),
    ($options.isEdited)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(已编辑)"))
      : _createCommentVNode("v-if", true)
  ]))
}