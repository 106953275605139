import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[32px]" }
const _hoisted_2 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }
const _hoisted_3 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }
const _hoisted_4 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }
const _hoisted_5 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }
const _hoisted_6 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }
const _hoisted_7 = { class: "text-[#475467] text-[16px] font-[300] leading-[24px] p-[16px] bg-[#F9FAFB] rounded-[8px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-[#101828] text-[24px] font-[500] leading-[32px] mb-[32px]" }, "需求详情", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "需求描述", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_2, _toDisplayString($props.issue.description || '-'), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "需求预期", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_3, _toDisplayString($props.issue.extra?.expect_usage || '-'), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "数据准备", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.issue.extra?.data || '-'), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "算法准备", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_5, _toDisplayString($props.issue.extra?.algorithm || '-'), 1 /* TEXT */)
      ]),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "算力准备", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_6, _toDisplayString($props.issue.extra?.evaluation_data || '-'), 1 /* TEXT */)
      ]),
      _createCommentVNode(" performance_metrics "),
      _createElementVNode("div", null, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-[#101828] text-[20px] font-[500] leading-[30px] mb-[12px]" }, "验收要求", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_7, _toDisplayString($props.issue.extra?.performance_metrics || '-'), 1 /* TEXT */)
      ])
    ])
  ]))
}