import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-[1000px] mx-auto" }
const _hoisted_2 = { class: "mb-8 pb-6 border-b border-gray-200" }
const _hoisted_3 = { class: "flex items-start justify-between gap-4" }
const _hoisted_4 = { class: "flex-1 min-w-0" }
const _hoisted_5 = { class: "text-3xl font-bold text-gray-900 break-words tracking-tight" }
const _hoisted_6 = { class: "mt-3 flex items-center flex-wrap gap-3 text-sm text-gray-500" }
const _hoisted_7 = { class: "flex items-center gap-4" }
const _hoisted_8 = { class: "flex items-center gap-1" }
const _hoisted_9 = { class: "flex items-center gap-1" }
const _hoisted_10 = {
  key: 0,
  class: "px-2 py-0.5 rounded text-xs bg-gray-100"
}
const _hoisted_11 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_12 = { class: "flex items-center gap-2 py-1" }
const _hoisted_13 = { class: "flex items-center gap-2 py-1" }
const _hoisted_14 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_View = _resolveComponent("View")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_ChatLineSquare = _resolveComponent("ChatLineSquare")
  const _component_MoreFilled = _resolveComponent("MoreFilled")
  const _component_el_button = _resolveComponent("el-button")
  const _component_EditPen = _resolveComponent("EditPen")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_Share = _resolveComponent("Share")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 文档头部 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString($props.doc.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString($options.formatDate($props.doc.updated_at)) + " 更新", 1 /* TEXT */),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "w-1 h-1 rounded-full bg-gray-300" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString($data.docTypes[$props.doc.doc_type] || '其他'), 1 /* TEXT */),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "w-1 h-1 rounded-full bg-gray-300" }, null, -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_View)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createTextVNode(" " + _toDisplayString($props.doc.views_count || 0), 1 /* TEXT */)
              ]),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChatLineSquare)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createTextVNode(" " + _toDisplayString($props.doc.comments_count || 0), 1 /* TEXT */)
              ])
            ]),
            ($props.doc.status !== 'published')
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "草稿"))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createCommentVNode(" 操作按钮 "),
        ($props.showActions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_el_dropdown, {
                trigger: "click",
                onVisibleChange: _cache[0] || (_cache[0] = (visible) => $setup.isDropdownVisible = visible)
              }, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      ($props.canEdit)
                        ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: 0,
                            onClick: $options.handleEdit
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_EditPen)
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _cache[3] || (_cache[3] = _createElementVNode("span", null, "编辑文档", -1 /* HOISTED */))
                              ])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true),
                      _createVNode(_component_el_dropdown_item, { onClick: $options.handleShare }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Share)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _cache[4] || (_cache[4] = _createElementVNode("span", null, "分享文档", -1 /* HOISTED */))
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: _normalizeClass(["!w-[36px] !h-[36px] !p-0", [
                $setup.isDropdownVisible ? '!bg-[#FFF5F0] !border-[#FF5005] !text-[#FF5005]' : '!bg-gray-50 !border-gray-200',
                'hover:!bg-[#FFF5F0] hover:!border-[#FF5005] hover:!text-[#FF5005]'
              ]])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, {
                        class: _normalizeClass([$setup.isDropdownVisible ? 'text-[#FF5005]' : 'text-gray-600'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MoreFilled)
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["class"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["class"])
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createCommentVNode(" 文档内容 "),
    _createElementVNode("div", {
      class: "prose max-w-none pb-20",
      innerHTML: $props.doc.content
    }, null, 8 /* PROPS */, _hoisted_14)
  ]))
}