<template>
  <div class="">
    <div v-for="(banner, index) in items" :key="index">
      <div :class="`w-[1440px] md:w-full px-[112px] h-[545px] md:h-[unset] ${banner.banner_type == 'full_image' ? 'md:!py-0' : 'bg-[#272286] '} md:h-[100%] md:px-[0px] mx-auto text-white flex justify-center gap-[64px] md:gap-[0px] md:flex-col ${index == bannerIndex ? 'show-animation py-[96px] md:py-[64px]' : 'hidden-animation'}`">
        <div class="relative">
          <div :class="`flex flex-col gap-[48px] w-[576px] select-none md:w-full relative  ${banner.banner_type == 'full_image' ? 'hidden' : ''}`">
            <template v-if="banner.banner_type != 'full_image'">
              <div class="p-[16px] rounded-[16px] w-[fit-content] md:hidden">
                <!-- <img :src="banner.icon_url" class="w-[64px] h-[64px]" alt=""> -->
              </div>
              <div class="text-[48px] md:text-[36px] font-[500] leading-[60px] md:leading-[44px]">
                <template v-if="banner.link_to">
                  <a :href="banner.link_to">
                    {{ banner.title }}
                  </a>
                </template>
                <template v-else>
                  {{ banner.title }}
                </template>
              </div>
      
              <div class="flex flex-col gap-[20px]">
                <div class="flex gap-[12px]" v-for="task in banner.tasks" :key="task">
                  <div class="flex items-center">
                    <el-icon :size="28"><CircleCheck /></el-icon>
                  </div>
                  <div class="text-[18px] font-[300] leading-[28px]">
                    {{ task }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          
          <div :class="`absolute md:relative bottom-0 ${banner.banner_type == 'full_image' ? 'right-[-50vw]' : 'right-0'}  z-20`" v-if="items.length >= 2">
            <div class="flex gap-[32px] justify-end md:justify-start">
              <div class="border border-[1px] border-[#EAECF0] rounded-full w-[56px] h-[56px] flex justify-center items-center cursor-pointer hover:bg-[#EAECF0] hover:text-[#272286] transition duration-300" @click="prevBanner">
                <el-icon :size="24"><Back /></el-icon>
              </div>
              <div class="border border-[1px] border-[#EAECF0] rounded-full w-[56px] h-[56px] flex justify-center items-center cursor-pointer hover:bg-[#EAECF0] hover:text-[#272286] transition duration-300" @click="nextBanner">
                <el-icon :size="24"><Right /></el-icon>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 这里要隐藏，否则图片容器的高度可以点击 -->
        <div class="flex justify-between items-center md:w-full" v-if="index == bannerIndex">
          <template v-if="banner.banner_type == 'image'">
            <template v-if="banner.link_to">
              <a :href="banner.link_to">
                <img :src="banner.image_url" class="w-[576px] md:w-full rounded-[24px]" alt="">
              </a>
            </template>
            <template v-else>
              <img :src="banner.image_url" class="w-[576px] md:w-full rounded-[24px]" alt="">
            </template>
          </template>
          <template v-if="banner.banner_type == 'rotate'">
            <RotateImage :image="banner.image_url" :icon="banner.icon_url" />
          </template>
          <template v-if="banner.banner_type == 'full_image'">
            <template v-if="banner.link_to">
              <a :href="banner.link_to" class="max-w-[1440px] w-[1440px] ml-[-64px] md:ml-0 z-10 h-[545px] md:h-full">
                <img :src="banner.image_url" class=" w-full md:w-full md:hidden h-[545px]" alt="">
                <img :src="banner.mobile_image_url" class="w-full hidden md:block" alt="">
              </a>
            </template>
            <template v-else>
              <div class="max-w-[1440px] w-[1440px] ml-[-64px] md:ml-0 z-10 h-[545px] md:h-full">
                <img :src="banner.image_url" class=" w-full md:w-full md:hidden h-[545px]" alt="">
                <img :src="banner.mobile_image_url" class="w-full hidden md:block" alt="">
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RotateImage from './RotateImage.vue'
export default {
  components: {
    RotateImage
  },
  props: {
    banners: {
      type: Object,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      items: this.banners,
      bannerIndex: 0,
      // hiddenClass: 'hidden',
    }
  },
  watch: {
    banners(val) {
      this.items = val
    }
  },
  methods: {
    nextBanner() {
      let nextIndex = this.bannerIndex + 1
      if (nextIndex >= this.items.length) {
        nextIndex = 0
      }
      this.bannerIndex = nextIndex
      // this.hiddenClass = ''
      // setTimeout(() => {
      //   this.hiddenClass = 'hidden'
      // }, 1000)
    },
    prevBanner() {
      let prevIndex = this.bannerIndex - 1
      if (prevIndex < 0) {
        prevIndex = this.items.length - 1
      }
      this.bannerIndex = prevIndex
      // this.hiddenClass = ''
      // setTimeout(() => {
      //   this.hiddenClass = 'hidden'
      // }, 1000)
    }
  }
}
</script>
