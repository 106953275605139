<template>
  <el-card>
    <template #header>
      <div class="flex items-center justify-between mb-[20px]">
        <h3 class="text-[20x] font-[500]">Datasets</h3>
        <el-input
          v-model="keyword"
          style="width: 240px"
          placeholder="Name, Owner"
          :prefix-icon="Search"
          @input="searchDatasets"
        />
      </div>
    </template>
    <el-table
      :stripe="true"
      :data="datasets"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        label="Name"
      />
      <el-table-column label="Owner">
        <template #default="scope">
          {{ scope.row.path.split('/')[0] }}
        </template>
      </el-table-column>
      <el-table-column label="Visibility">
        <template #default="scope">
          {{ scope.row.private === false ? 'public' : 'private' }}
        </template>
      </el-table-column>
      <el-table-column label="Operations">
        <template #default="scope">
          <el-button
            size="small"
            @click="showDetail(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-model:current-page="page"
      :page-size="per"
      layout="prev, pager, next"
      :total="total"
      @current-change="fetchDatasets"
      class="my-[52px] flex justify-center"
    />
  </el-card>
</template>

<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { Search } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  import useFetchApi from '../../../packs/useFetchApi'

  const datasets = ref([])
  const page = ref(1)
  const per = ref(10)
  const total = ref(0)
  const keyword = ref('')

  const fetchDatasets = async () => {
    const { data, error } = await useFetchApi(
      `/datasets?&sort=trending&page=${page.value}&per=${per.value}&search=${keyword.value}`
    ).json()
    if (data.value) {
      const result = data.value
      datasets.value = result.data
      total.value = result.total
    } else {
      ElMessage.error(error.value.msg || 'Failed to fetch datasets')
    }
  }

  const searchDatasets = () => {
    page.value = 1
    fetchDatasets()
  }

  const showDetail = (obj) => {
    window.location.href = `/new_admin/datasets/${obj.path}`
  }

  onMounted(() => {
    fetchDatasets()
  })
</script>
