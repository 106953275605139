<template>
  <div class="bg-white">
    <div class="m-auto w-[583px] md:w-full md:px-[20px]">
      <div class="py-[96px] text-center">
        <div class="text-[20px] font-[500] leading-[30px]">
          发布需求
        </div>
        <div class="flex gap-[8px] w-full my-[16px]">
          <div
            :class="`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${step === 1 ? 'issue-start-active-bar' : 'issue-start-inactive-bar'}`"
            @click="prevStep">
            基本信息
          </div>
          <div
            :class="`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${step === 1 ? 'issue-end-inactive-bar' : 'issue-end-active-bar'}`"
            @click="nextStep">
            预算与要求
          </div>
        </div>
        <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top" class="text-left">
          <template v-if="step === 1">
            <el-form-item label="项目名称" prop="project_name">
              <el-input v-model="dataForm.project_name" placeholder="请输入项目名称"></el-input>
            </el-form-item>

            <el-form-item label="需求类型" prop="kind">
              <el-select v-model="dataForm.kind" placeholder="选择需求类型">
                <el-option v-for="kind in kinds" :key="kind.value" :label="kind.label" :value="kind.value" />
              </el-select>
            </el-form-item>

            <el-form-item label="组织名称" prop="org_path">
              <el-select v-model="dataForm.org_path" placeholder="选择组织">
                <el-option v-for="org in organizations" :key="org.path" :label="org.name" :value="org.path" />
              </el-select>
            </el-form-item>
            <el-form-item label="交付日期" prop="delivery_at">
              <el-date-picker v-model="dataForm.delivery_at" type="date" style="width: 100%;" placeholder="选择交付日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属行业" prop="industry_list">
              <el-select v-model="dataForm.industry_list" multiple filterable :reserve-keyword="false"
                default-first-option :placeholder="$t('all.pleaseSelect', { value: '行业' })" style="width: 100%;">
                <el-option v-for="value in industries" :key="value" :label="value" :value="value" />
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" prop="contact_name">
              <el-input v-model="dataForm.contact_name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contact_phone">
              <el-input v-model="dataForm.contact_phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="contact_email">
              <el-input v-model="dataForm.contact_email" placeholder="请输入联系邮箱"></el-input>
            </el-form-item>
            <el-form-item label="需求描述" prop="description">
              <el-input :rows="5" type="textarea" v-model="dataForm.description"></el-input>
            </el-form-item>
            <el-form-item label="需求预期" prop="expect_usage">
              <el-input :rows="5" type="textarea" v-model="dataForm.expect_usage"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]"
                @click="nextStep">下一步</el-button>
            </el-form-item>
          </template>
          <template v-if="step === 2">
            <el-form-item label="预算" class="append-input" prop="budget">
              <el-input v-model="dataForm.budget" placeholder="请输入预算 0 为面议">
                <template #append>万</template>
              </el-input>
            </el-form-item>
            <el-form-item label="数据准备" prop="data">
              <el-input :rows="5" type="textarea" v-model="dataForm.data"></el-input>
            </el-form-item>
            <el-form-item label="算法准备" prop="algorithm">
              <el-input :rows="5" type="textarea" v-model="dataForm.algorithm"></el-input>
            </el-form-item>
            <el-form-item label="算力准备" prop="evaluation_data">
              <el-input :rows="5" type="textarea" v-model="dataForm.evaluation_data"></el-input>
            </el-form-item>
            <el-form-item label="验收要求" prop="performance_metrics">
              <el-input :rows="5" type="textarea" v-model="dataForm.performance_metrics"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="w-full flex gap-[4px]">
                <el-button
                  class="w-full text-center !h-[48px] !w-[116px] !text-[#344054] !text-[16px] bg-[#FF5005] !rounded-[8px] !border-[1px] "
                  @click="prevStep">上一步</el-button>
                <el-button
                  class="w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]"
                  @click="handleSubmit">提交</el-button>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { currentUserDetail } from '@api/jwt/user'
import { createIssue } from '@api/csrf/issue'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
import { industryList } from "../helpers/utils";

export default {
  data() {
    return {
      industries: industryList,
      org_types: ['企业', '高校', '非营利组织', '社区组织', '事业单位', '研究机构'],
      kinds: [
        {
          label: '技术需求',
          value: 'technical'
        }, {
          label: '场景需求',
          value: 'scene'
        }, {
          label: '数据需求',
          value: 'data'
        }, {
          label: '算力需求',
          value: 'compute'
        }, {
          label: '人才需求',
          value: 'talent'
        }, {
          label: '科研需求',
          value: 'research'
        }
      ],
      step: 1,
      dataForm: {},
      currentUserName: cookies.get('current_user'),
      organizations: [],
      rules: {
        project_name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        kind: [{ required: true, message: '请选择需求类型', trigger: 'blur' }],
        org_path: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        delivery_at: [{ required: true, message: '请选择交付日期', trigger: 'blur' }],
        industry_list: [{ required: true, message: '请选择所属行业', trigger: 'blur' }],
        description: [{ required: true, message: '请输入文章摘要', trigger: 'blur' }],
        budget: [{ required: true, message: '请输入预算', trigger: 'blur' }],
        deploy_type: [{ required: true, message: '请选择部署方式', trigger: 'blur' }],
        contact_name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contact_phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        contact_email: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          { pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9_\.\-])+\.)+([a-zA-Z0-9]{2,4})+$/, message: '请输入正确的邮箱格式', trigger: 'blur' }
        ],
        expect_usage: [{ required: true, message: '请输入需求预期', trigger: 'blur' }],
        data: [{ required: true, message: '请输入数据准备', trigger: 'blur' }],
        algorithm: [{ required: true, message: '请输入算法准备', trigger: 'blur' }],
        evaluation_data: [{ required: true, message: '请输入评估数据准备', trigger: 'blur' }],
        performance_metrics: [{ required: true, message: '请输入验收要求', trigger: 'blur' }]
      }
    }
  },
  methods: {
    nextStep() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.step = 2
        } else {
          return false
        }
      })
    },
    prevStep() {
      this.step = 1
    },
    handleSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.createIssue()
        } else {
          return false
        }
      })
    },
    createIssue() {
      const params = Object.assign({}, this.dataForm)
      const selectedOrg = this.organizations.find(org => org.path === params.org_path)
      params.org_name = selectedOrg.name
      params.org_type = selectedOrg.org_type

      createIssue(params).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '需求提交成功。',
            type: 'success'
          });
          setTimeout(() => {
            window.location.href = `/issues`
          }, 500)
        } else {
          ElMessage.error(res.message)
        }
      })
    }
  },
  mounted() {
    currentUserDetail(this.currentUserName).then(res => {
      this.organizations = res.data.orgs || []
      if (this.organizations.length <= 0) {
        ElMessage.warning('请先创建组织')
        setTimeout(() => {
          window.open('/organizations/new', '_blank')
        }, 1500)
      }
    })
  }
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 24px;
}

.el-form-item__label {
  margin-bottom: 6px;
}

:deep(.el-input__wrapper) {
  border-radius: 8px;
}

:deep(.append-input .el-input__wrapper) {
  border-radius: 8px 0 0 8px;
}

:deep(.el-input-group__append, .el-input-group__prepend) {
  background: transparent;
  color: #344054;
}

:deep(.el-textarea__inner) {
  border-radius: 8px;
}

:deep(.el-upload--picture-card) {
  border: 0px;
}

:deep(.hide .el-upload.el-upload--picture-card) {
  display: none;
}
</style>