import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "top-0 right-0 md:relative md:right-0 flex gap-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ApplicationPage = _resolveComponent("ApplicationPage")
  const _component_InitializeGuide = _resolveComponent("InitializeGuide")
  const _component_StoppedPage = _resolveComponent("StoppedPage")
  const _component_BuildAndErrorPage = _resolveComponent("BuildAndErrorPage")
  const _component_EndpointPage = _resolveComponent("EndpointPage")
  const _component_EndpointLogs = _resolveComponent("EndpointLogs")
  const _component_BillingDetail = _resolveComponent("BillingDetail")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.repoType !== 'endpoint')
        ? (_openBlock(), _createBlock($setup["RepoClone"], {
            key: 0,
            showAddToCollections: $setup.showAddToCollections,
            repoType: $props.repoType,
            httpCloneUrl: $props.repoDetail.repository?.http_clone_url || '',
            sshCloneUrl: $props.repoDetail.repository?.ssh_clone_url || '',
            userName: $props.userName,
            namespacePath: $props.path,
            admin: $props.admin,
            repo: $props.repoDetail,
            enableEndpoint: $props.repoDetail.enable_inference,
            enableFinetune: $props.repoDetail.enable_finetune
          }, null, 8 /* PROPS */, ["showAddToCollections", "repoType", "httpCloneUrl", "sshCloneUrl", "userName", "namespacePath", "admin", "repo", "enableEndpoint", "enableFinetune"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode($setup["TabContainer"], {
      "default-tab": $props.defaultTab,
      settingsVisibility: $props.settingsVisibility,
      repoType: $props.repoType,
      sdk: $props.sdk,
      repo: $props.repoDetail,
      onTabChange: $setup.tabChange
    }, _createSlots({
      summary: _withCtx(() => [
        ($props.repoType === 'space' && $props.repoDetail.path === 'leaderboard/SuperClueRanking')
          ? (_openBlock(), _createBlock(_component_ApplicationPage, {
              key: 0,
              appEndpoint: "https://www.superclueai.com/"
            }))
          : ($props.repoType === 'space' && $props.appStatus === 'NoAppFile')
            ? (_openBlock(), _createBlock(_component_InitializeGuide, {
                key: 1,
                "http-clone-url": $props.repoDetail.repository?.http_clone_url || '',
                "ssh-clone-url": $props.repoDetail.repository?.ssh_clone_url || '',
                sdk: $props.sdk,
                "user-name": $props.userName
              }, null, 8 /* PROPS */, ["http-clone-url", "ssh-clone-url", "sdk", "user-name"]))
            : ($props.repoType === 'space' && $props.appStatus === 'Running')
              ? (_openBlock(), _createBlock(_component_ApplicationPage, {
                  key: 2,
                  appEndpoint: $props.appEndpoint
                }, null, 8 /* PROPS */, ["appEndpoint"]))
              : ($props.repoType === 'space' && ($props.appStatus === 'Stopped' || $props.appStatus === 'Sleeping'))
                ? (_openBlock(), _createBlock(_component_StoppedPage, {
                    key: 3,
                    appStatus: $props.appStatus,
                    canWrite: $props.canWrite,
                    path: $props.path
                  }, null, 8 /* PROPS */, ["appStatus", "canWrite", "path"]))
                : ($props.repoType === 'space')
                  ? (_openBlock(), _createBlock(_component_BuildAndErrorPage, {
                      key: 4,
                      appStatus: $props.appStatus,
                      canWrite: $props.canWrite,
                      onShowSpaceLogs: $setup.showSpaceLogs
                    }, null, 8 /* PROPS */, ["appStatus", "canWrite"]))
                  : ($props.repoType === 'endpoint')
                    ? (_openBlock(), _createBlock(_component_EndpointPage, {
                        key: 5,
                        appEndpoint: $props.appEndpoint,
                        appStatus: $props.appStatus,
                        modelId: $props.modelId,
                        private: $props.private,
                        endpointReplica: $props.endpointReplica,
                        clusterId: $props.clusterId,
                        sku: $props.sku,
                        replicaList: $props.replicaList
                      }, null, 8 /* PROPS */, ["appEndpoint", "appStatus", "modelId", "private", "endpointReplica", "clusterId", "sku", "replicaList"]))
                    : (_openBlock(), _createBlock($setup["RepoSummary"], {
                        key: 6,
                        "repo-type": $props.repoType,
                        "namespace-path": $props.path,
                        "download-count": $props.repoDetail.downloads,
                        currentBranch: $props.currentBranch,
                        "widget-type": $props.repoDetail.widget_type,
                        license: $props.repoDetail.license
                      }, null, 8 /* PROPS */, ["repo-type", "namespace-path", "download-count", "currentBranch", "widget-type", "license"]))
      ]),
      community: _withCtx(() => [
        _createVNode($setup["CommunityPage"], {
          repoType: $props.repoType,
          repoPath: $props.repoDetail.path
        }, null, 8 /* PROPS */, ["repoType", "repoPath"])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      ($props.actionName === 'blob')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["Blob"], {
                branches: _ctx.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.path,
                "can-write": $props.canWrite
              }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write"])
            ]),
            key: "0"
          }
        : undefined,
      ($props.actionName === 'new_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["NewFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path,
                originalCodeContent: ""
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "1"
          }
        : undefined,
      ($props.actionName === 'edit_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["EditFile"], {
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path
              }, null, 8 /* PROPS */, ["current-branch", "current-path", "repo-name", "namespace-path"])
            ]),
            key: "2"
          }
        : undefined,
      ($props.actionName === 'upload_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["UploadFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.repoDetail.name,
                "namespace-path": $props.path
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "3"
          }
        : undefined,
      ($props.actionName === 'commits')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoCommits"], {
                branches: _ctx.branches,
                currentBranch: $props.currentBranch,
                namespacePath: $props.path,
                repoType: $props.repoType
              }, null, 8 /* PROPS */, ["branches", "currentBranch", "namespacePath", "repoType"])
            ]),
            key: "4"
          }
        : undefined,
      ($props.actionName === 'commit')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoCommit"], {
                namespacePath: $props.path,
                repoType: $props.repoType,
                commitId: $props.commitId
              }, null, 8 /* PROPS */, ["namespacePath", "repoType", "commitId"])
            ]),
            key: "5"
          }
        : undefined,
      ($props.actionName === 'files')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["RepoFiles"], {
                branches: _ctx.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.path,
                "can-write": $props.canWrite,
                "repo-type": $props.repoType
              }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write", "repo-type"])
            ]),
            key: "6"
          }
        : undefined,
      ($props.repoType === 'endpoint' && $props.actionName === 'logs')
        ? {
            name: "logs",
            fn: _withCtx(() => [
              _createVNode(_component_EndpointLogs, {
                instances: $props.repoDetail.instances,
                modelId: $props.repoDetail.model_id,
                deployId: $props.repoDetail.deploy_id
              }, null, 8 /* PROPS */, ["instances", "modelId", "deployId"])
            ]),
            key: "7"
          }
        : undefined,
      ($props.settingsVisibility)
        ? {
            name: "billing",
            fn: _withCtx(() => [
              _createVNode(_component_BillingDetail, {
                type: $props.repoType,
                instanceName: $props.repoDetail.svc_name
              }, null, 8 /* PROPS */, ["type", "instanceName"])
            ]),
            key: "8"
          }
        : undefined,
      ($props.settingsVisibility)
        ? {
            name: "settings",
            fn: _withCtx(() => [
              ($props.repoType === 'model')
                ? (_openBlock(), _createBlock($setup["ModelSettings"], {
                    key: 0,
                    path: $props.path,
                    "model-nickname": $props.repoDetail.nickname,
                    "model-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch,
                    "tag-list": $setup.tagList,
                    tags: $props.tags
                  }, null, 8 /* PROPS */, ["path", "model-nickname", "model-desc", "default_branch", "tag-list", "tags"]))
                : _createCommentVNode("v-if", true),
              ($props.repoType === 'dataset')
                ? (_openBlock(), _createBlock($setup["DatasetSettings"], {
                    key: 1,
                    path: $props.path,
                    "dataset-nickname": $props.repoDetail.nickname,
                    "dataset-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch,
                    "tag-list": $setup.tagList,
                    tags: $props.tags
                  }, null, 8 /* PROPS */, ["path", "dataset-nickname", "dataset-desc", "default_branch", "tag-list", "tags"]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" <application-space-settings\r\n          v-if=\"repoType === 'space'\"\r\n          :path=\"path\"\r\n          :application-space-nickname=\"repoDetail.nickname\"\r\n          :application-space-desc=\"repoDetail.description\"\r\n          :default_branch=\"repoDetail.default_branch\"\r\n          :appStatus=\"appStatus\"\r\n          :cloudResource=\"repoDetail.sku || repoDetail.hardware\"\r\n          @showSpaceLogs=\"showSpaceLogs\"\r\n          :coverImage=\"repoDetail.cover_image_url\"\r\n          :isAdmin=\"admin\"\r\n        /> "),
              ($props.repoType === 'code')
                ? (_openBlock(), _createBlock($setup["CodeSettings"], {
                    key: 2,
                    path: $props.path,
                    "code-nickname": $props.repoDetail.nickname,
                    "code-desc": $props.repoDetail.description,
                    default_branch: $props.repoDetail.default_branch
                  }, null, 8 /* PROPS */, ["path", "code-nickname", "code-desc", "default_branch"]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" <EndpointSettings\r\n          v-if=\"repoType === 'endpoint'\"\r\n          :endpointName=\"endpointName\"\r\n          :endpointId=\"endpointId\"\r\n          :appStatus=\"appStatus\"\r\n          :modelId=\"modelId\"\r\n          :userName=\"userName\"\r\n          :cloudResource=\"repoDetail.sku || repoDetail.hardware\"\r\n          :framework=\"repoDetail.runtime_framework\"\r\n          :maxReplica=\"repoDetail.max_replica\"\r\n          :minReplica=\"repoDetail.min_replica\"\r\n          :clusterId=\"repoDetail.cluster_id\"\r\n        /> ")
            ]),
            key: "9"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["default-tab", "settingsVisibility", "repoType", "sdk", "repo"])
  ]))
}