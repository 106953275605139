<template>
  <!-- 公告组件 -->
  <!-- <Announcement /> -->

  <!-- banner -->
  <div class="w-full">
    <banner :banners="banners" />
  </div>

  <!-- 仓库组件 -->
  <div class="max-w-[1440px] m-auto flex bg-[#edf1f9]">
    <div class="w-full px-[40px] pt-[40px] pb-[53px] md:px-[16px]">
      <Repository />
    </div>
  </div>

  <!-- 供需对接 -->
  <div class="max-w-[1440px] m-auto flex bg-[#f8f9fb]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <SupplyDemand />
    </div>
  </div>

  <!-- 应用合集 -->
  <div class="max-w-[1440px] m-auto flex bg-[#EEEFF2]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <ApplicationCollection />
    </div>
  </div>

  <!-- 评测系统 -->
  <div class="max-w-[1440px] m-auto flex bg-[#F5F6F9]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <ModelSystem />
    </div>
  </div>

  <!-- 安全治理 -->
  <div class="max-w-[1440px] m-auto flex bg-[#eeeff2]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <ContentGovernance />
    </div>
  </div>

  <!-- 知识宝库 -->
  <div class="max-w-[1440px] m-auto flex bg-[#FAFAFA]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <KnowledgeBase />
    </div>
  </div>

  <!-- 算力资源系统 -->
  <div class="max-w-[1440px] m-auto flex bg-[#f2f6fc]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <ComputingResourceSystem />
    </div>
  </div>

  <!-- 智算中心分布 -->
  <div class="max-w-[1440px] m-auto flex bg-[#f2f6fc]">
    <div class="w-full px-[40px] py-[40px] md:px-[16px]">
      <ComputingScheduling />
    </div>
  </div>

  <!-- 平台最新数据 -->
  <div class="max-w-[1440px] m-auto flex bg-[url('images/xty_home/tech_stat_bg.png')] bg-cover">
    <div class="w-full px-[40px] pt-[40px] pb-[120px] md:px-[16px]">
      <TechStats :tech_stats="tech_stats" />
    </div>
  </div>

  <!-- 发起成员 -->
<!--  <div class="max-w-[1440px] m-auto bg-[url('images/xty_home/partner_bg.png')] bg-cover">-->
<!--    <div class="m-auto flex">-->
<!--      <div class="w-full px-[40px] py-[40px] md:px-[16px]">-->
<!--        <partner />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <!-- 合作伙伴计划 -->
  <!-- <div class="max-w-[1440px] m-auto flex">
    <div class="w-full px-[64px] md:px-[16px]">
      <div class="py-[60px]">
        <orgSection/>
      </div>
    </div>
  </div> -->

  <!-- 将国产算力变为 MaaS服务 Section -->
  <!-- <div class="max-w-[1440px] m-auto flex">
    <div class="w-full px-[64px] md:px-[16px]">
      <div class="py-[96px] md:py-[64px]">
        <h2 class="text-[36px] font-[500] leading-[44px] text-[#101828] text-center md:w-[343px] mb-[64px] m-auto">MaaS服务——降低开发门槛</h2>
        <div class="text-[20px] text-[#475467] leading-[30px] font-[300] mt-[20px]">
          在不具备硬件设备或专业技能的情况下快速使用人工智能模型，同时为开发者和企业提供便捷灵活、可扩展的模型训推和应用开发环境
        </div>
        <div class="mt-[64px] md:hidden">
          <img src="/images/xty_home/framework_of_pc.svg" alt="">
        </div>
        <div class="mt-[32px] hidden md:flex justify-center">
          <img src="/images/xty_home/framework_of_mobile.svg" alt="">
        </div>
      </div>
    </div>
  </div> -->
   

</template>

<script>
import banner from './banner.vue'
import TechStats from './TechStats.vue'
import Repository from './Repository.vue'
import SupplyDemand from './SupplyDemand.vue'
import ApplicationCollection from './ApplicationCollection.vue'
import KnowledgeBase from './KnowledgeBase.vue'

import ComputingScheduling from './ComputingScheduling.vue'
import ComputingResourceSystem from './ComputingResourceSystem.vue'
import orgSection from './orgSection.vue'
import ContentGovernance from './ContentGovernance.vue'
import ModelSystem from './ModelSystem.vue'
import partner from './partner.vue'
import { Application } from '@hotwired/stimulus'
import Announcement from './Announcement.vue'

export default {
  components: {
    banner,
    Announcement,
    Repository,
    SupplyDemand,
    ApplicationCollection,
    KnowledgeBase,

    TechStats,
    ComputingScheduling,
    ComputingResourceSystem,
    orgSection,
    ContentGovernance,
    ModelSystem,
    partner
  },
  props: {
    banners: {
      type: Object,
      default: () => {
        return []
      }
    },
    tech_stats: {
      type: Object,
      default: () => {
        return []
      }
    }
  },
  
}
</script>
