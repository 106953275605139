import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-[20x] font-[500] mb-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('admin.emailSending.title')), 1 /* TEXT */),
    _createVNode(_component_el_form, {
      ref: "dataFormRef",
      model: $setup.dataForm,
      rules: $setup.rules,
      "label-position": "top"
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" 邮件 group 选择 "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('admin.emailSending.mailGroupSelect'),
          class: "w-[260px]",
          prop: "group"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_autocomplete, {
              clearable: "",
              modelValue: $setup.dataForm.group,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dataForm.group) = $event)),
              "fetch-suggestions": $setup.fetchEmailGroups
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" 邮件模版输入 "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('admin.emailSending.mailTemplateInput'),
          prop: "template"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.dataForm.template,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dataForm.template) = $event)),
              rows: 20,
              type: "textarea",
              placeholder: "Provide email template here"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, { class: "my-[40px]" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, { onClick: $setup.sendGroupMail }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.emailSending.send')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model"])
  ]))
}