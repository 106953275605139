<template>
  <div class="mt-3">
    <el-input
      v-model="localContent"
      type="textarea"
      :rows="2"
      :placeholder="placeholder"
      :maxlength="maxLength"
      show-word-limit
    />
    <div class="flex justify-end gap-2 mt-2">
      <el-button 
        size="small" 
        @click="$emit('cancel')"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        class="!bg-[#FF5005] !border-[#FF5005]"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ submitText }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentEditor',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '写下你的评论...'
    },
    submitText: {
      type: String,
      default: '发表'
    },
    loading: Boolean,
    maxLength: {
      type: Number,
      default: 1000
    }
  },
  emits: ['update:modelValue', 'submit', 'cancel'],
  computed: {
    localContent: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    handleSubmit() {
      if (!this.localContent.trim()) {
        ElMessage.warning('请输入内容')
        return
      }
      this.$emit('submit', this.localContent)
    }
  }
}
</script>

<style scoped>
:deep(.el-input__wrapper) {
  background-color: white !important;
}
</style>