import without_user_request from './without_user_request'
import request from './request'

// 公开的组织搜索
export function organizationSearch(query) {
  return without_user_request({
    url: `/api/v1/organizations`,
    method: 'get',
    params: query
  })
}

// 获取组织详情
export function organizationDetail(path) {
  return request({
    url: `/api/v1/organization/${path}`,
    method: 'get',
  })
}


