export const landingPage = {
  "hero": {
    "headTitle": "让大模型赋能每个人",
    "headDesc": "大模型驱动全民，推动技术加速创新。",
  },
  banner: {
    title: '让大模型 赋能 每一个人',
    subtitle: '大模型驱动全民，推动技术加速创新。',
    joinButton: '加入社区'
  },
  hotTrend: {
    subtitle: '热门趋势',
    title: '本周传神社区热门',
    desc: '了解体验最新传神社区开源模型、应用空间、数据集',
    models: '模型',
    spaces: '空间',
    datasets: '数据',
    loadMore: '查看更多'
  },
  value: {
    title1: '加强开源大模型的',
    title2: '商业应用',
    subtitle: 'CAICT 将帮助您找到最合适、最经济、和活跃的开源大模型'
  },
  "joinus": {
    "JoinUS": "加入我们",
    "join1": "加入我们",
    "join2": "我们正在寻找优秀的人才",
    "join3": "我们是一支分布在全国的分布式远程团队，打造中国版的 HuggingFace+优质社区，加入我们吧",
  },
  "company": {
    "ContactUs": "联系我们",
    "ContactTeam": "与我们的团队交流",
    "Welcome": "我们欢迎您的到访！请联系我们。",
    "Beijing": "北京",
    "BeijingSite": "海淀区 知春路23号 量子银座大厦 11F CAICT",
    "ShangHai": "上海",
    "ShangHaiSite": "徐汇区 龙台路180号 国际传媒港F2 5F CAICT",
    "Desc1": "北京开放传神科技有限公司（CAICT）成立于2023年，是一家致力于大模型生态社区建设，汇集人工智能行业上下游企业链共同为大模型在垂直行业的应用提供解决方案和工具平台的公司。",
    "Desc2": "CAICT的‘传神’开放社区基于自主研发的算力算法平台和应用开发工具平台，为用户提供一站式的大模型应用构建服务，帮助用户高效且低成本地创建个性化的大模型应用。公司成功实现了在金融、政务、制造业等领域的项目落地，并积极与生态合作伙伴共同探索在医疗、工业、媒体等更多领域的落地应用。目前CAICT已完成由国信中数、联想创投数千万元的天使轮融资。",
    "timestamp1": "2023年10月",
    "timestamp2": "2023年10月",
    "timestamp3": "2024年1月",
    "timestamp4": "2024年2月",
    "timestamp5": "2024年3月",
    "content1": "在上海成立上海传之神科技有限公司，成立上海公司，且入驻上海徐汇模速空间",
    "content2": "CAICT 首次发布 StarNet（星展）大模型平台及“传神” 开放创新生态社区",
    "content3-1": "CAICT 发布 StarNet（星展）Beta 版，加快形成新质生产力",
    "content3-2": "CAICT 推出开源、可信的大模型资产管理平台 CSGHub",
    "content4-1": "CAICT 推出高效部署LLM推理服务的开源框架 llm-inference",
    "content4-2": "CAICT 推出开源代码大模型 caict-starcoder, caict-codellama",
    "content5": "CAICT 推出用于 LLM 训练及微调的开源框架 llm-finetune",
  }
}
