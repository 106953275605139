import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "search-and-button-container mb-4 mt-6" }
const _hoisted_3 = { class: "inner-container" }
const _hoisted_4 = { class: "search-container" }
const _hoisted_5 = { class: "web-top-menu" }
const _hoisted_6 = { class: "web-top-menu-inner" }
const _hoisted_7 = {
  class: "flex justify-center space-x-8 mb-6 border-b-2 text-lg py-4",
  id: "pills-tab",
  role: "tablist"
}
const _hoisted_8 = ["id", "aria-controls", "aria-selected", "onClick"]
const _hoisted_9 = {
  class: "main-content",
  id: "pills-tabContent"
}
const _hoisted_10 = { class: "text-xl font-bold mb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WebItem = _resolveComponent("WebItem")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.searchKeyword) = $event)),
            type: "text",
            placeholder: "搜索AI工具...",
            class: "w-full pl-10 pr-4 py-2 rounded-full border focus:outline-none focus:ring-2 focus:ring-[#418fff] focus:bg-gray-100 hover:bg-gray-100 transition-all duration-300 ease-in-out shadow-lg",
            onInput: _cache[1] || (_cache[1] = (...args) => ($options.handleSearch && $options.handleSearch(...args)))
          }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
            [_vModelText, $data.searchKeyword]
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" }, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 24 24",
              "stroke-width": "1.5",
              stroke: "currentColor",
              class: "w-5 h-5"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              })
            ])
          ], -1 /* HOISTED */))
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("a", {
          href: "https://u0ddt94fk3b.feishu.cn/share/base/form/shrcnLB9wIuL47emhQNRO87Hn1c",
          target: "_blank",
          rel: "noopener noreferrer",
          class: "apply-button px-4 py-2 bg-[#FF5005] text-white rounded-full hover:bg-[#e64600] transition-all duration-300 ease-in-out whitespace-nowrap"
        }, " 申请添加AI工具 ", -1 /* HOISTED */))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedItems, (group, idx) => {
            return (_openBlock(), _createElementBlock("li", {
              key: idx,
              class: "webnav-item",
              role: "presentation"
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(["web-nav-link focus:outline-none rounded-full px-4 py-2 transition-all duration-300 ease-in-out", {
                    'bg-[#418fff] text-white': $data.currentSection === group.appclass && !$data.isSearching,
                    'text-gray-700 hover:bg-[#4190ffa4] hover:text-white': $data.currentSection !== group.appclass || $data.isSearching,
                }]),
                id: 'pills-' + group.appclass + '-tab',
                type: "button",
                role: "tab",
                "aria-controls": 'pills-' + group.appclass,
                "aria-selected": $data.currentSection === group.appclass && !$data.isSearching,
                onClick: $event => ($options.handleTabClick(group.appclass))
              }, [
                _createElementVNode("span", null, _toDisplayString(group.appclass), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_8)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            key: $data.isSearching ? 'search' : $data.currentSection,
            class: "tab-pane",
            role: "tabpanel",
            tabindex: "0"
          }, [
            ($data.isSearching)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.filteredGroups, (group) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: group.appclass
                  }, [
                    _createElementVNode("h3", _hoisted_10, _toDisplayString(group.appclass), 1 /* TEXT */),
                    _createVNode(_component_WebItem, {
                      items: group.items
                    }, null, 8 /* PROPS */, ["items"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              : ($options.currentGroup)
                ? (_openBlock(), _createBlock(_component_WebItem, {
                    key: 1,
                    items: $options.currentGroup
                  }, null, 8 /* PROPS */, ["items"]))
                : _createCommentVNode("v-if", true)
          ]))
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}