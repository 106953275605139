import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-[300px] py-8 md:px-5" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center flex-wrap gap-4" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "flex items-center gap-2 my-4" }
const _hoisted_6 = { class: "rounded border border-[#EBEEF5] px-4" }
const _hoisted_7 = { class: "mb-2 flex items-center" }
const _hoisted_8 = ["href", "title"]
const _hoisted_9 = { class: "flex items-center text-sm" }
const _hoisted_10 = { class: "flex items-center mr-2" }
const _hoisted_11 = { class: "text-[#606266] text-sm" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_button_group = _resolveComponent("el-button-group")
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["BranchDropdown"], {
          onChangeBranch: $setup.changeBranch,
          "current-branch": $props.currentBranch,
          branches: $props.branches
        }, null, 8 /* PROPS */, ["current-branch", "branches"]),
        _createVNode(_component_el_breadcrumb, { separator: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_breadcrumb_item, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: `/${$props.repoType}s/${$props.namespacePath}/files/${$props.currentBranch}`
                }, _toDisplayString($props.namespacePath.split('/')[1]), 9 /* TEXT, PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_SvgIcon, { name: "commits" }),
      _createTextVNode(" " + _toDisplayString($setup.t('all.commits')) + " ", 1 /* TEXT */),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-1 border-b border-[#DCDFE6]" }, null, -1 /* HOISTED */))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commits, (commit) => {
        return (_openBlock(), _createElementBlock("div", {
          key: commit.id,
          class: "py-4 border-b border-[#DCDFE6] last-of-type:border-none text-[#344054]"
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", {
              href: `/${$props.repoType}s/${$props.namespacePath}/commit/${commit.id}`,
              class: "truncate",
              title: commit.message
            }, _toDisplayString(commit.message), 9 /* TEXT, PROPS */, _hoisted_8),
            _createVNode(_component_el_button_group, { class: "ml-2 min-w-[107px]" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  plain: "",
                  type: "default",
                  size: "small",
                  class: "!cursor-default hover:!text-[#344054] hover:!border-[#dcdfe6]"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(commit.id.slice(0, 7)), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_el_button, {
                  type: "default",
                  size: "small",
                  onClick: $event => ($setup.copyCommitId(commit.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SvgIcon, {
                      name: "copy",
                      class: "w-3 h-3"
                    })
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_avatar, {
                icon: $setup.UserFilled,
                size: 24,
                class: "mr-2"
              }, null, 8 /* PROPS */, ["icon"]),
              _createElementVNode("span", null, _toDisplayString(commit.committer_name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_11, _toDisplayString($setup.format($setup.beiJingTimeParser(commit.committer_date), _ctx.locale == 'en' ? 'en_US' : 'zh_CN')), 1 /* TEXT */)
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode($setup["CsgPagination"], {
      perPage: $setup.perPage,
      currentPage: $setup.currentPage,
      onCurrentChange: $setup.loadMoreCommits,
      total: $setup.totalCommits
    }, null, 8 /* PROPS */, ["perPage", "currentPage", "total"])
  ]))
}