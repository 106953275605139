import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "17",
  height: "16",
  viewBox: "0 0 17 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "Frame" }, [
      _createElementVNode("path", {
        id: "Vector",
        d: "M3.64486 8.33333C3.64486 8.54227 3.9522 8.9056 4.66517 9.26207C5.58756 9.72327 6.89604 10 8.31152 10C9.72699 10 11.0355 9.72327 11.9579 9.26207C12.6709 8.9056 12.9782 8.54227 12.9782 8.33333V6.8858C11.8782 7.56547 10.1964 8 8.31152 8C6.42668 8 4.74483 7.56547 3.64486 6.8858V8.33333ZM12.9782 10.2191C11.8782 10.8988 10.1964 11.3333 8.31152 11.3333C6.42668 11.3333 4.74483 10.8988 3.64486 10.2191V11.6667C3.64486 11.8756 3.9522 12.2389 4.66517 12.5954C5.58756 13.0566 6.89604 13.3333 8.31152 13.3333C9.72699 13.3333 11.0355 13.0566 11.9579 12.5954C12.6709 12.2389 12.9782 11.8756 12.9782 11.6667V10.2191ZM2.31152 11.6667V5C2.31152 3.34315 4.99782 2 8.31152 2C11.6253 2 14.3115 3.34315 14.3115 5V11.6667C14.3115 13.3235 11.6253 14.6667 8.31152 14.6667C4.99782 14.6667 2.31152 13.3235 2.31152 11.6667ZM8.31152 6.66667C9.72699 6.66667 11.0355 6.38995 11.9579 5.92875C12.6709 5.57227 12.9782 5.20895 12.9782 5C12.9782 4.79105 12.6709 4.42773 11.9579 4.07125C11.0355 3.61005 9.72699 3.33333 8.31152 3.33333C6.89604 3.33333 5.58756 3.61005 4.66517 4.07125C3.9522 4.42773 3.64486 4.79105 3.64486 5C3.64486 5.20895 3.9522 5.57227 4.66517 5.92875C5.58756 6.38995 6.89604 6.66667 8.31152 6.66667Z",
        fill: "black",
        "fill-opacity": "0.38"
      })
    ], -1 /* HOISTED */)
  ])))
}