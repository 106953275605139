<template>
  <div class="vote-buttons flex flex-col items-center mr-3">
    <button 
      class="vote-button"
      :class="{ active: userVote === 'upvote' }"
      @click="handleVote('upvote')"
    >
      <el-icon><CaretTop /></el-icon>
    </button>
    <span class="vote-count" :class="{ 
      'text-[#FF5005]': votesCount > 0,
      'text-[#86909C]': votesCount === 0,
      'text-[#F56C6C]': votesCount < 0
    }">
      {{ votesCount }}
    </span>
    <button 
      class="vote-button"
      :class="{ active: userVote === 'downvote' }"
      @click="handleVote('downvote')"
    >
      <el-icon><CaretBottom /></el-icon>
    </button>
  </div>
</template>

<script>
import { CaretTop, CaretBottom } from '@element-plus/icons-vue'

export default {
  name: 'VoteButtons',
  components: {
    CaretTop,
    CaretBottom
  },
  props: {
    votesCount: {
      type: Number,
      default: 0
    },
    userVote: {
      type: String,
      default: null
    }
  },
  methods: {
    handleVote(type) {
      const voteType = this.userVote === type ? 'cancel' : type
      this.$emit('vote', voteType)
    }
  }
}
</script>

<style scoped>
.vote-buttons {
  min-width: 40px;
}

.vote-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #86909C;
  transition: all 0.2s;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vote-button:hover {
  color: #FF5005;
}

.vote-button.active {
  color: #FF5005;
}

.vote-count {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0;
}

.el-icon {
  font-size: inherit;
}
</style>