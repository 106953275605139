import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }
const _hoisted_2 = { class: "grow flex flex-col gap-[24px] px-6 py-10 border-l min-h-[calc(100vh-153px)]" }
const _hoisted_3 = {
  key: 0,
  class: "mt-[16px] rounded-sm w-full bg-[#F0F3FF] py-[9px] px-[16px] text-[#4D6AD6]"
}
const _hoisted_4 = { class: "mb-[16px]" }
const _hoisted_5 = { class: "text-[#303133] text-[14px] mb-[8px]" }
const _hoisted_6 = { class: "text-[#303133] text-[14px] mb-[8px]" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Menu"], {
      class: "max-w-[411px] md:mb-[24px]",
      name: $props.name
    }, null, 8 /* PROPS */, ["name"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "text-[#303133] text-[20px] font-[600]" }, "SSH Keys", -1 /* HOISTED */)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => ($setup.centerDialogVisible = true)),
        class: "rounded-[4px] border bg-[#FFF] py-[5px] px-[16px] text-[#606266] text-[14px] font-[500] mt-[32px] w-[160px]"
      }, _toDisplayString(_ctx.$t('sshKey.addSshKey')), 1 /* TEXT */),
      ($setup.theSshKeys.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('sshKey.noKeyTips')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.theSshKeys, (sshkey) => {
        return (_openBlock(), _createBlock($setup["SshKeyCard"], {
          "profile-name": $props.name,
          "ssh-key-name": sshkey.name,
          "ssh-key": sshkey.content,
          "ssh-key-id": sshkey.id,
          "create-time": sshkey.created_at
        }, null, 8 /* PROPS */, ["profile-name", "ssh-key-name", "ssh-key", "ssh-key-id", "create-time"]))
      }), 256 /* UNKEYED_FRAGMENT */)),
      _createVNode(_component_el_dialog, {
        modelValue: $setup.centerDialogVisible,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.centerDialogVisible) = $event)),
        title: _ctx.$t('sshKey.addSshKey'),
        width: "30%",
        class: "dialogWidth",
        style: {"border-radius":"0.5rem"},
        left: ""
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_el_button, {
              onClick: _cache[3] || (_cache[3] = $event => ($setup.centerDialogVisible = false))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Cancel")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.submitSshKey
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('all.add')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            model: $setup.formData,
            rules: $setup.formRules,
            ref: "formRef"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, [
                  _createTextVNode(_toDisplayString(_ctx.$t('sshKey.sshKeyName')) + " ", 1 /* TEXT */),
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-red-400" }, "*", -1 /* HOISTED */))
                ]),
                _createVNode(_component_el_input, {
                  modelValue: $setup.theSshKeyName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.theSshKeyName) = $event)),
                  placeholder: "Key",
                  maxlength: "20"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t('sshKey.sshKeyContent')), 1 /* TEXT */),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-red-400" }, "*", -1 /* HOISTED */))
                ]),
                _createVNode(_component_el_form_item, { prop: "theSshKey" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $setup.formData.theSshKey,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.formData.theSshKey) = $event)),
                      rows: 6,
                      type: "textarea",
                      placeholder: "Starts with 'ssh-rsa', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384', 'ecdsa-sha2-nistp521', 'ssh-ed25519', 'sk-ecdsa-sha2-nistp256@openssh.com', or 'sk-ssh-ed25519@openssh.com'"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model", "rules"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "title"])
    ])
  ]))
}