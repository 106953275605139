<template>
  <span>由 
    <template v-if="ownerUrl">
      <a :href="ownerUrl" class="text-[#FF5005] cursor-pointer">{{ ownerName }}</a> 
    </template>
    <template v-else>
      <span class="text-[#FF5005] cursor-pointer">{{ ownerName }}</span> 
    </template>
    提供
  </span>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
    >
      <path d="M0.5 0V8" stroke="#DCDFE6" />
    </svg>
  </span>
  <span>更新时间：{{ repo.updated_at && repo.updated_at.substring(0, 10) }}</span>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
    >
      <path d="M0.5 0V8" stroke="#DCDFE6" />
    </svg>
  </span>
  <span>下载量：{{ repo.downloads }}</span>
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { organizationDetail } from '../../../../api/jwt/organization'
  import { userDetail } from '../../../../api/jwt/user'

  const props = defineProps({
    repo: Object,
    isDetail: Boolean
  });

  const ownerUrl = ref('')
  const ownerName = ref('')

  const setOwnerInfo = () => {
    if (!props.repo) return
    // 列表页的repo没有namespace
    if (!props.repo.namespace) {
      if (!props.repo.path) return
      ownerUrl.value = null
      ownerName.value = props.repo.path.split('/')[0]
      return
    }
    if (!props.repo.namespace.Path) return
    if (props.repo.namespace.Type == "user") {
      ownerUrl.value = `/profile/${props.repo.namespace.Path}`
      ownerName.value = props.repo?.user?.username
      // if (ownerName.value && props.isDetail) {
      //   resetOwnerNameForUser(ownerName.value)
      // }
    } else {
      ownerUrl.value = `/organizations/${props.repo?.namespace?.Path}`
      ownerName.value = props.repo?.namespace?.Path
      // if (ownerName.value && props.isDetail) {
      //   resetOwnerNameForOrganization(ownerName.value)
      // }
    }

  }

  const resetOwnerNameForUser = (username) => {
    if (!username) return
    userDetail(username).then(res => {
      ownerName.value = res.data.nickname || username
    })
  }

  const resetOwnerNameForOrganization = (orgName) => {
    if (!orgName) return
    
    organizationDetail(orgName).then(res => {
      ownerName.value = res.data.name || orgName
    })
  }

  onMounted(() => {
    setOwnerInfo()
  })

  watch(() => props.repo, () => {
    console.log('props.repo', props.repo)
    if (!!props.repo) {
      setOwnerInfo()
    }
  })

</script>