<template>
  <div class="flex flex-col gap-[32px] bg-[#F4F5FE] pt-[24px] pb-[40px] px-[32px] md:px-[10px]">
    <div class="flex flex-col max-w-[1440px] gap-[24px]">
      <div class="flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]">
        <div class="flex items-center gap-[8px] text-[14px] font-[400] leading-[20px]">
          <SvgIcon width="20" height="20" name="xty_home" />
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#475467]">企业需求</div>
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#344054]">技术需求</div>
        </div>

        <div class="flex gap-[8px] font-[500] items-center">
          <a href="/issues" class="flex items-center">
            <el-icon :size="24"><Back /></el-icon>
          </a>
          <div class="text-[#101828] text-[30px] font-[500] leading-[30px]">
            {{ issue.project_name }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-[24px] md:flex-col">
      <!-- 左侧 -->
      <div class="w-[503px] flex flex-col gap-[24px] md:w-full">
        <!-- issue 基础信息 -->
        <div class="bg-white p-[32px] rounded-[8px]">
          <div class="text-[14px] font-[400] leading-[22px] flex flex-col gap-[8px]">
            <div class="">
              <span class="text-[#475467]">企业名称：</span>
              <span class="text-[#667085]">
                <a :href="`/organizations/${issue.org_path}`" target="_blank">{{ issue.org_name }}</a>
                <span class="ml-[8px] px-[8px] py-[3px] rounded-[4px] bg-white border border-[var(--Gray-300)] text-[12px] leading-[18px] font-[400] text-[var(--Gray-700)]">{{ issue.org_type }}</span>
              </span>
            </div>
            <div class="">
              <span class="text-[#475467]">产业链：</span>
              <span class="text-[#667085]">{{ issue.industry_list.join('、') }}</span>
            </div>
            <div class="">
              <span class="text-[#475467]">需求类型：</span>
              <span class="text-[#667085]">{{ kindsMap[issue.kind] }}</span>
            </div>
            <div class="flex gap-[8px] items-center">
              <div class="text-[#475467]">联系人：</div>
              <div class="text-[#667085]">{{ issue.extra?.contact_name }}</div>
              <div class="text-[#667085]">|</div>
              <div class="text-[#667085]">
                <a :href="`tel:${issue.extra?.contact_phone}`">{{ issue.extra?.contact_phone }}</a>
              </div>
              <div class="text-[#667085]">|</div>
              <div class="text-[#667085]">
                <a :href="`mailto:${issue.extra?.contact_email}`">{{ issue.extra?.contact_email }}</a>
              </div>
            </div>
          </div>

          <div class="flex md:flex-col gap-[16px]">
            <div class="flex w-[320px] md:w-full hover:bg-[#fbfafa] rounded-[8px] hover:shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[16px] mt-[16px]">
              <img src="/images/xty_images/issue/budget.svg" class="h-[52px] px-[24px]" alt="">
              <div class="flex flex-col">
                <div class="text-[var(--Brand-700)] text-[18px] font-[500] leading-[28px]">
                  <template v-if="issue.budget > 0">
                    <div class="">{{ issue.budget }}万</div>
                  </template>
                  <template v-else>
                    <div class="">面议</div>
                  </template>
                </div>
                <div class="text-[#667085] text-[14px] font-[400] leading-[20px]">
                  预计投入金额
                </div>
              </div>
            </div>
            <div class="flex w-[320px] md:w-full hover:bg-[#fbfafa] rounded-[8px] hover:shadow-[0px_11px_40px_0px_rgba(0,0,0,0.05)] py-[16px] mt-[16px]">
              <img src="/images/xty_images/issue/delivery.svg" class="h-[52px] px-[24px]" alt="">
              <div class="flex flex-col">
                <div class="text-[#182230] text-[16px] font-[500] leading-[24px]">
                  {{ issue.delivery_at ? dayjs(issue.delivery_at).format("YYYY-MM-DD") : '面议' }}
                </div>
                <div class="text-[#667085] text-[14px] font-[400] leading-[20px]">
                  截止时间
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- issue 状态 -->
        <div class="bg-white p-[32px] rounded-[8px]">
          <div class="text-[14px] font-[400] leading-[22px] flex flex-col gap-[8px]">
            
            <div class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px]">流程状态</div>
            <div class="flex my-[16px]">
              <!-- 进程状态轴 -->
              <template v-for="(status, key) in statusMap" :key="key">
                <div class="flex flex-col gap-[12px] w-[136px] md:w-[116px]">
                  <div class="flex items-center relative left-[57px] md:left-[47px]">
                    <template v-if="statusMap[issue.status]?.value > status.value">
                      <img src="/images/xty_images/issue/checked.svg" class="h-[24px] w-[24px]" alt="">
                    </template>
                    <template v-else-if="statusMap[issue.status]?.value === status.value">
                      <img src="/images/xty_images/issue/processing.svg" class="h-[24px] w-[24px] rounded-[100px] shadow-[0px_0px_0px_4px_rgba(77,106,214,0.24)]" alt="">
                    </template>
                    <template v-else>
                      <img src="/images/xty_images/issue/pending.svg" class="h-[24px] w-[24px]" alt="">
                    </template>
                    <template v-if="key !== 'completed'">
                      <template v-if="statusMap[issue.status]?.value > status.value">
                        <img src="/images/xty_images/issue/split_primary.svg" class="h-[24px] w-[112px] md:w-[92px]" alt="">
                      </template>
                      <template v-else>
                        <img src="/images/xty_images/issue/split_gray.svg" class="h-[24px] w-[112px] md:w-[92px]" alt="">
                      </template>
                    </template>
                  </div>
                  <div class="flex flex-col items-center">
                    <div :class="`${statusMap[issue.status]?.value === status.value ? 'text-[var(--Brand-700)]' : 'text-[var(--Gray-600)]' } text-[14px] font-[500] leading-[22px]`">{{ status.label }}</div>
                    <div :class="`${statusMap[issue.status]?.value === status.value ? 'text-[var(--Brand-700)]' : 'text-[var(--Gray-600)]' } text-[14px] font-[300] leading-[22px]`" v-if="issue[status.time]">
                      {{ dayjs(issue[status.time]).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <template v-if="issue.status === 'collecting'">
              <a :href="`/issue_plans/new?issue_id=${issue.id}`" class="w-[106px] btn btn-orange flex justify-center items-center px-[20px] py-[9px] text-[14px] font-[500] leading-[22px]">投递方案</a>
            </template>
          </div>
        </div>
      </div>


      <!-- 右侧 -->
      <div class="w-full flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]">
        <IssueInfo :issue="issue" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import dayjs from "dayjs";
</script>
<script>
import { issueDetail } from '../../../../api/csrf/issue'
import IssueInfo from './IssueInfo.vue'

export default {
  data() {
    return {
      issue: {
        industry_list: [],
        extra: {}
      },
      kindsMap: {
        technical: '技术需求',
        scene: '场景需求',
        data: '数据需求',
        compute: '算力需求',
        talent: '人才需求',
        research: '科研需求'
      },
      statusMap: {
        collecting: {
          label: '征集中',
          value: 1,
          time: 'collecting_at'
        },
        connecting: {
          label: '对接中',
          value: 2,
          time: 'connecting_at'
        },
        completed: {
          label: '已完成',
          value: 3,
          time: 'completed_at'
        }
      }
    }
  },
  components: {
    IssueInfo
  },
  mounted() {
    this.fetchIssue()
  },
  methods: {
    fetchIssue() {
      const id = location.pathname.split('/')[2]
      issueDetail(id).then(res => {
        this.issue = res.data
      })
    }
  }
}
</script>
