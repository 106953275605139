import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "max-w-[1440px] m-auto flex bg-[#edf1f9]" }
const _hoisted_3 = { class: "w-full px-[40px] pt-[40px] pb-[53px] md:px-[16px]" }
const _hoisted_4 = { class: "max-w-[1440px] m-auto flex bg-[#f8f9fb]" }
const _hoisted_5 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_6 = { class: "max-w-[1440px] m-auto flex bg-[#EEEFF2]" }
const _hoisted_7 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_8 = { class: "max-w-[1440px] m-auto flex bg-[#F5F6F9]" }
const _hoisted_9 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_10 = { class: "max-w-[1440px] m-auto flex bg-[#eeeff2]" }
const _hoisted_11 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_12 = { class: "max-w-[1440px] m-auto flex bg-[#FAFAFA]" }
const _hoisted_13 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_14 = { class: "max-w-[1440px] m-auto flex bg-[#f2f6fc]" }
const _hoisted_15 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_16 = { class: "max-w-[1440px] m-auto flex bg-[#f2f6fc]" }
const _hoisted_17 = { class: "w-full px-[40px] py-[40px] md:px-[16px]" }
const _hoisted_18 = { class: "max-w-[1440px] m-auto flex bg-[url('images/xty_home/tech_stat_bg.png')] bg-cover" }
const _hoisted_19 = { class: "w-full px-[40px] pt-[40px] pb-[120px] md:px-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_banner = _resolveComponent("banner")
  const _component_Repository = _resolveComponent("Repository")
  const _component_SupplyDemand = _resolveComponent("SupplyDemand")
  const _component_ApplicationCollection = _resolveComponent("ApplicationCollection")
  const _component_ModelSystem = _resolveComponent("ModelSystem")
  const _component_ContentGovernance = _resolveComponent("ContentGovernance")
  const _component_KnowledgeBase = _resolveComponent("KnowledgeBase")
  const _component_ComputingResourceSystem = _resolveComponent("ComputingResourceSystem")
  const _component_ComputingScheduling = _resolveComponent("ComputingScheduling")
  const _component_TechStats = _resolveComponent("TechStats")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" 公告组件 "),
    _createCommentVNode(" <Announcement /> "),
    _createCommentVNode(" banner "),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_banner, { banners: $props.banners }, null, 8 /* PROPS */, ["banners"])
    ]),
    _createCommentVNode(" 仓库组件 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Repository)
      ])
    ]),
    _createCommentVNode(" 供需对接 "),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SupplyDemand)
      ])
    ]),
    _createCommentVNode(" 应用合集 "),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ApplicationCollection)
      ])
    ]),
    _createCommentVNode(" 评测系统 "),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ModelSystem)
      ])
    ]),
    _createCommentVNode(" 安全治理 "),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ContentGovernance)
      ])
    ]),
    _createCommentVNode(" 知识宝库 "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_KnowledgeBase)
      ])
    ]),
    _createCommentVNode(" 算力资源系统 "),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_ComputingResourceSystem)
      ])
    ]),
    _createCommentVNode(" 智算中心分布 "),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_ComputingScheduling)
      ])
    ]),
    _createCommentVNode(" 平台最新数据 "),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_TechStats, { tech_stats: $props.tech_stats }, null, 8 /* PROPS */, ["tech_stats"])
      ])
    ]),
    _createCommentVNode(" 发起成员 "),
    _createCommentVNode("  <div class=\"max-w-[1440px] m-auto bg-[url('images/xty_home/partner_bg.png')] bg-cover\">"),
    _createCommentVNode("    <div class=\"m-auto flex\">"),
    _createCommentVNode("      <div class=\"w-full px-[40px] py-[40px] md:px-[16px]\">"),
    _createCommentVNode("        <partner />"),
    _createCommentVNode("      </div>"),
    _createCommentVNode("    </div>"),
    _createCommentVNode("  </div>"),
    _createCommentVNode(" 合作伙伴计划 "),
    _createCommentVNode(" <div class=\"max-w-[1440px] m-auto flex\">\r\n    <div class=\"w-full px-[64px] md:px-[16px]\">\r\n      <div class=\"py-[60px]\">\r\n        <orgSection/>\r\n      </div>\r\n    </div>\r\n  </div> "),
    _createCommentVNode(" 将国产算力变为 MaaS服务 Section "),
    _createCommentVNode(" <div class=\"max-w-[1440px] m-auto flex\">\r\n    <div class=\"w-full px-[64px] md:px-[16px]\">\r\n      <div class=\"py-[96px] md:py-[64px]\">\r\n        <h2 class=\"text-[36px] font-[500] leading-[44px] text-[#101828] text-center md:w-[343px] mb-[64px] m-auto\">MaaS服务——降低开发门槛</h2>\r\n        <div class=\"text-[20px] text-[#475467] leading-[30px] font-[300] mt-[20px]\">\r\n          在不具备硬件设备或专业技能的情况下快速使用人工智能模型，同时为开发者和企业提供便捷灵活、可扩展的模型训推和应用开发环境\r\n        </div>\r\n        <div class=\"mt-[64px] md:hidden\">\r\n          <img src=\"/images/xty_home/framework_of_pc.svg\" alt=\"\">\r\n        </div>\r\n        <div class=\"mt-[32px] hidden md:flex justify-center\">\r\n          <img src=\"/images/xty_home/framework_of_mobile.svg\" alt=\"\">\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div> ")
  ], 64 /* STABLE_FRAGMENT */))
}