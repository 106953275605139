import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "whitespace-normal text-[#475467] font-light" }
const _hoisted_2 = { class: "whitespace-normal text-[#475467] font-light" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")

  return (_openBlock(), _createBlock(_component_el_radio_group, {
    modelValue: $setup.visibility,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.visibility) = $event)),
    class: "!block",
    onChange: _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:value', $event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_radio, {
        class: "w-full !border-[1px] mr-0 mb-[32px] !rounded-xl !h-auto !items-start !p-4",
        label: "public",
        size: "large",
        border: "",
        disabled: !$props.canPublic
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.t('all.public')) + " ", 1 /* TEXT */),
          _createElementVNode("p", _hoisted_1, _toDisplayString($setup.props.publicDesc), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]),
      _createVNode(_component_el_radio, {
        class: "w-full !border-[1px] mr-0 !rounded-xl !h-auto !items-start !p-4",
        label: "private",
        size: "large",
        border: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.t('all.private')) + " ", 1 /* TEXT */),
          _createElementVNode("p", _hoisted_2, _toDisplayString($setup.props.privateDesc), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}