import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[1200px] mx-auto mt-8 px-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_doc_content = _resolveComponent("doc-content")
  const _component_Comments = _resolveComponent("Comments")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_doc_content, {
      doc: $props.doc,
      "can-edit": $setup.canEdit($props.doc),
      "show-actions": true
    }, null, 8 /* PROPS */, ["doc", "can-edit"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Comments, {
        "doc-uuid": $props.doc.uuid,
        "current-user": $props.currentUser
      }, null, 8 /* PROPS */, ["doc-uuid", "current-user"])
    ])
  ]))
}