<template>
  <div class="relative">
    <el-avatar :size="size" :src="avatar">
    </el-avatar>
    <template v-if="flag == 'blue_v'">
      <img src="/images/xty_images/header/flag/blue_v.svg" :class="flagClass" alt="">
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      typo: {
        type: String,
        default: "sm"
      },
      avatar: String,
      flag: String,
    },
    data() {
      return {
        size: '',
        flagClass: '',
      };
    },
    mounted() {
      this.renderAvatar()
    },
    methods: {
      renderAvatar() {
        if (this.typo === "sm") {
          this.size = 40;
          this.flagClass = 'absolute h-[12px] w-[12px] right-0 bottom-[1.5px] object-cover';
        } else {
          this.size = 120;
          this.flagClass = 'absolute h-[24px] w-[24px] left-[90px] bottom-[10px]';
        }
      }
    }
  }
</script>