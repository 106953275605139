import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex min-h-screen bg-white md:block" }
const _hoisted_2 = { class: "hidden md:block md:fixed md:top-[60px] md:left-0 md:right-0 md:z-10 md:bg-white md:border-b md:border-gray-200" }
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "flex items-center justify-between gap-4" }
const _hoisted_5 = { class: "h-full overflow-y-auto bg-white" }
const _hoisted_6 = { class: "p-4 border-b border-gray-200" }
const _hoisted_7 = { class: "p-4" }
const _hoisted_8 = { class: "w-[280px] border-r border-gray-200 p-4 md:hidden" }
const _hoisted_9 = { class: "mb-4 relative" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "flex-1 p-8 md:pt-[144px] md:px-4" }
const _hoisted_12 = { class: "mt-8" }
const _hoisted_13 = {
  key: 1,
  class: "text-center text-gray-500 mt-20"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Search = _resolveComponent("Search")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_Menu = _resolveComponent("Menu")
  const _component_el_button = _resolveComponent("el-button")
  const _component_doc_list = _resolveComponent("doc-list")
  const _component_el_drawer = _resolveComponent("el-drawer")
  const _component_doc_content = _resolveComponent("doc-content")
  const _component_Comments = _resolveComponent("Comments")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 移动端头部 "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_input, {
            modelValue: $setup.searchKeyword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.searchKeyword) = $event)),
            placeholder: "搜索文档...",
            clearable: "",
            onClear: $setup.handleSearch,
            onInput: $setup.handleSearch,
            class: "flex-1"
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "text-gray-400" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Search)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "onClear", "onInput"]),
          _createVNode(_component_el_button, {
            class: "!p-2 flex-shrink-0",
            onClick: _cache[1] || (_cache[1] = $event => ($setup.showSidebar = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "text-gray-500" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Menu)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _createCommentVNode(" 移动端侧边栏抽屉 "),
    _createVNode(_component_el_drawer, {
      modelValue: $setup.showSidebar,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.showSidebar) = $event)),
      direction: "left",
      size: "85%",
      "with-header": false,
      class: "md:block hidden"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-lg font-medium mb-4" }, "文档中心", -1 /* HOISTED */)),
            ($props.admin)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "primary",
                  class: "w-full !bg-[#FF5005] !border-[#FF5005] !text-white",
                  onClick: $setup.handleCreate
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" 创建文档 ")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" 分类导航 "),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_doc_list, {
              "grouped-docs": $setup.groupedDocs,
              "selected-doc": $setup.selectedDoc,
              "expanded-categories": $setup.expandedCategories,
              "search-keyword": $setup.searchKeyword,
              onSelectDoc: $setup.selectDoc,
              onToggleCategory: $setup.toggleCategory
            }, null, 8 /* PROPS */, ["grouped-docs", "selected-doc", "expanded-categories", "search-keyword", "onSelectDoc", "onToggleCategory"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(" 左侧导航栏（桌面端） "),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_el_input, {
          modelValue: $setup.searchKeyword,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.searchKeyword) = $event)),
          placeholder: "搜索文档...",
          clearable: "",
          onClear: $setup.handleSearch,
          onInput: $setup.handleSearch
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "text-gray-400" }, {
              default: _withCtx(() => [
                _createVNode(_component_Search)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "onClear", "onInput"])
      ]),
      _createCommentVNode(" 创建按钮 "),
      _createElementVNode("div", _hoisted_10, [
        ($props.admin)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              class: "w-full !bg-[#FF5005] !border-[#FF5005]",
              onClick: $setup.handleCreate
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" 创建文档 ")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_doc_list, {
        "grouped-docs": $setup.groupedDocs,
        "selected-doc": $setup.selectedDoc,
        "expanded-categories": $setup.expandedCategories,
        "search-keyword": $setup.searchKeyword,
        onSelectDoc: $setup.selectDoc,
        onToggleCategory: $setup.toggleCategory
      }, null, 8 /* PROPS */, ["grouped-docs", "selected-doc", "expanded-categories", "search-keyword", "onSelectDoc", "onToggleCategory"])
    ]),
    _createCommentVNode(" 右侧内容区 "),
    _createElementVNode("div", _hoisted_11, [
      ($setup.selectedDoc)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(), _createBlock(_component_doc_content, {
              key: $setup.selectedDoc?.uuid,
              doc: $setup.selectedDoc,
              "can-edit": $setup.canEdit($setup.selectedDoc),
              "show-actions": true
            }, null, 8 /* PROPS */, ["doc", "can-edit"])),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(), _createBlock(_component_Comments, {
                key: $setup.selectedDoc.uuid,
                "doc-uuid": $setup.selectedDoc.uuid,
                "current-user": $props.currentUser
              }, null, 8 /* PROPS */, ["doc-uuid", "current-user"]))
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, " 请选择要查看的文档 "))
    ])
  ]))
}