import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white min-h-[calc(100vh-129px)]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Menu"], {
      class: "max-w-[411px] md:mb-[24px]",
      nickName: $setup.organization.nickname,
      homepage: $setup.organization.homepage,
      name: $setup.organization.name,
      logo: $setup.organization.logo
    }, null, 8 /* PROPS */, ["nickName", "homepage", "name", "logo"]),
    ($props.action === 'edit')
      ? (_openBlock(), _createBlock($setup["OrganizationEdit"], {
          key: 0,
          class: "grow py-[24px]",
          organizationRaw: $setup.organization,
          onUpdateOrganization: $setup.updateOrganization
        }, null, 8 /* PROPS */, ["organizationRaw"]))
      : _createCommentVNode("v-if", true),
    ($props.action === 'members')
      ? (_openBlock(), _createBlock($setup["OrganizationMembers"], {
          key: 1,
          class: "grow py-[24px]",
          role: $setup.role,
          organizationRaw: $setup.organization
        }, null, 8 /* PROPS */, ["role", "organizationRaw"]))
      : _createCommentVNode("v-if", true)
  ]))
}