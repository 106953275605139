<template>
  <div class="max-w-[1440px] m-auto flex">
    <div class="w-full md:px-[16px] px-[32px] pt-[24px]">
      <div class="grid grid-cols-2 md:grid-cols-1 gap-[24px]">
        <div class="flex px-[16px] py-[12px] flex-col justify-center gap-[8px] rounded-[4px] border border-[1px] border-[#E6E6E6] shadow" :key="space.id" v-for="space in spaces">
          <div class="flex justify-between items-center">
            <div class="text-[14px] font-[500] leading-[20px]">
              {{ space.title }}
            </div>
            <div class="flex gap-[8px] items-center">
              <div class="text-[#475467] text-[14px] font-[400] leading-[24px]">{{ statusMap[space.status].text }}</div>
              <div class="h-[12px] w-[12px] rounded-full bg-[#17B26A]"></div>
            </div>
          </div>
          <div class="text-[12px] text-[#00000061] font-[400] leading-[18px]">
            {{ formatTime(space.updated_at) }} 更新
          </div>
          <a :href="`/spaces/${space.id}`" class="h-[147px] rounded-[8px] bg-[#000000] w-[full] justify-center items-start flex flex-col px-[40px] gap-[8px] relative z-20">
            <div class="text-white text-[20px] font-[500] leading-[28px] z-30">{{ space.title }}</div>
            <div class="text-white text-[16px] font-[500] leading-[28px] z-30">{{ space.desc }}</div>
            <div class="absolute left-0 top-0 h-[147px] w-full z-0">
              <img :src="space.cover_image" alt="" class="h-full" style="inline-size: -webkit-fill-available;">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  export default {
    props: {
      spaces: {
        type: Object,
        default: () => {return {}}
      },
    },
    data() {
      return {
        statusMap: {
          running: {
            text: "运行中",
            color: "#17B26A",
          },
          stopped: {
            text: "停止",
            color: "#17B26A",
          }
        }
      }
    },
    methods: {
      formatTime(time) {
        return dayjs(time).format("YYYY.MM.DD")
      }
    }
  }
</script>
<style scoped lang="less">

</style>