import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex md:px-5 md:flex-col-reverse min-h-[calc(100vh-341px)]" }
const _hoisted_2 = { class: "max-w-[60%] sm:max-w-[100%] pt-4 pb-10 pr-5 sm:pr-0 break-words flex-1 border-t border-[#EBEEF5] md:border-t-0" }
const _hoisted_3 = { class: "w-[40%] sm:w-[100%] border-l border-[#EBEEF5] md:border-l-0 md:border-b md:w-full md:pl-0" }
const _hoisted_4 = { class: "p-[16px]" }
const _hoisted_5 = { class: "text-[#606266] text-base font-medium leading-[22px] md:pl-0" }
const _hoisted_6 = { class: "text-[#303133] text-base font-semibold leading-6 mt-1 md:pl-0" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col gap-[16px] border-t border-[#EBEEF5] p-[16px]"
}
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "flex gap-[8px]" }
const _hoisted_10 = { class: "flex gap-[4px] px-[8px] py-[4px] border rounded-[16px]" }
const _hoisted_11 = { class: "text-[14px] leading-[20px] text-[#667085]" }
const _hoisted_12 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.loading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 0,
            class: "mt-4",
            rows: 5,
            animated: ""
          }))
        : _createCommentVNode("v-if", true),
      ($setup.previewData.data)
        ? (_openBlock(), _createBlock($setup["ParquetViewer"], {
            key: 1,
            previewData: $setup.previewData.data
          }, null, 8 /* PROPS */, ["previewData"]))
        : _createCommentVNode("v-if", true),
      (!$setup.loading)
        ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
            key: 2,
            content: $setup.readmeContent,
            setDefaultText: true
          }, null, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('all.downloadCount')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, _toDisplayString($props.downloadCount), 1 /* TEXT */)
      ]),
      _createCommentVNode(" todo 暂时隐藏 license 信息 "),
      (false && $props.repoType == 'model' && $setup.licenseTagInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SvgIcon, { name: "license" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "ml-[8px] text-[16px] leading-[24px] text-[#344054]" }, "License", -1 /* HOISTED */))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_SvgIcon, {
                  name: "license",
                  width: "15",
                  height: "15"
                }),
                _createElementVNode("p", _hoisted_11, "License: " + _toDisplayString($setup.licenseTagInfo.name), 1 /* TEXT */)
              ]),
              ($setup.licenseTagInfo.url)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: $setup.licenseTagInfo.url,
                    target: "_blank",
                    class: "flex w-[30px] h-[30px] border rounded-[8px] justify-center items-center"
                  }, [
                    _createVNode(_component_SvgIcon, { name: "top_right_arrow" })
                  ], 8 /* PROPS */, _hoisted_12))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["text-[16px] leading-[24px] text-[#344054]", $setup.showMoreLicenseDesc ? 'overflow-hidden text-ellipsis line-clamp-2 text-left': ''])
            }, _toDisplayString($setup.locale == 'zh' ? $setup.licenseTagInfo.desc: $setup.licenseTagInfo.desc_en), 3 /* TEXT, CLASS */),
            ($setup.showMoreLicenseDesc)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = $event => ($setup.moreLicenseDesc = true)),
                  class: "text-[12px] leading-[16px] text-[#223B99] cursor-pointer"
                }, _toDisplayString(_ctx.$t('all.moreDesc')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" <TestEndpoint\r\n        v-if=\"widgetType === 'generation' && endpoint?.status === 'Running'\"\r\n        :appEndpoint=\"appEndpoint\"\r\n        :modelId=\"namespacePath\"\r\n      /> "),
      _createCommentVNode(" <SpaceRelationsCard v-if=\"relations['spaces'] && relations['spaces'].length !== 0\"\r\n                          :namespacePath=\"namespacePath\"\r\n                          :spaces=\"relations['spaces']\"\r\n      /> "),
      ($setup.relations['codes'] && $setup.relations['codes'].length !== 0)
        ? (_openBlock(), _createBlock($setup["CodeRelationsCard"], {
            key: 1,
            namespacePath: $props.namespacePath,
            codes: $setup.relations['codes']
          }, null, 8 /* PROPS */, ["namespacePath", "codes"]))
        : _createCommentVNode("v-if", true),
      ($setup.relations['datasets'] && $setup.relations['datasets'].length !== 0)
        ? (_openBlock(), _createBlock($setup["DatasetRelationsCard"], {
            key: 2,
            namespacePath: $props.namespacePath,
            datasets: $setup.relations['datasets']
          }, null, 8 /* PROPS */, ["namespacePath", "datasets"]))
        : _createCommentVNode("v-if", true),
      ($setup.relations['models'] && $setup.relations['models'].length !== 0)
        ? (_openBlock(), _createBlock($setup["ModelRelationsCard"], {
            key: 3,
            namespacePath: $props.namespacePath,
            models: $setup.relations['models']
          }, null, 8 /* PROPS */, ["namespacePath", "models"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}