import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "text-[48px] md:text-[36px] font-[500] leading-[60px] md:leading-[44px]" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "flex flex-col gap-[20px]" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "text-[18px] font-[300] leading-[28px]" }
const _hoisted_8 = { class: "flex gap-[32px] justify-end md:justify-start" }
const _hoisted_9 = {
  key: 0,
  class: "flex justify-between items-center md:w-full"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "max-w-[1440px] w-[1440px] ml-[-64px] md:ml-0 z-10 h-[545px] md:h-full"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleCheck = _resolveComponent("CircleCheck")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_Back = _resolveComponent("Back")
  const _component_Right = _resolveComponent("Right")
  const _component_RotateImage = _resolveComponent("RotateImage")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (banner, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", {
          class: _normalizeClass(`w-[1440px] md:w-full px-[112px] h-[545px] md:h-[unset] ${banner.banner_type == 'full_image' ? 'md:!py-0' : 'bg-[#272286] '} md:h-[100%] md:px-[0px] mx-auto text-white flex justify-center gap-[64px] md:gap-[0px] md:flex-col ${index == $data.bannerIndex ? 'show-animation py-[96px] md:py-[64px]' : 'hidden-animation'}`)
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(`flex flex-col gap-[48px] w-[576px] select-none md:w-full relative  ${banner.banner_type == 'full_image' ? 'hidden' : ''}`)
            }, [
              (banner.banner_type != 'full_image')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "p-[16px] rounded-[16px] w-[fit-content] md:hidden" }, [
                      _createCommentVNode(" <img :src=\"banner.icon_url\" class=\"w-[64px] h-[64px]\" alt=\"\"> ")
                    ], -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_3, [
                      (banner.link_to)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: banner.link_to
                          }, _toDisplayString(banner.title), 9 /* TEXT, PROPS */, _hoisted_4))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(banner.title), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banner.tasks, (task) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex gap-[12px]",
                          key: task
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_el_icon, { size: 28 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CircleCheck)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(task), 1 /* TEXT */)
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */),
            ($data.items.length >= 2)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(`absolute md:relative bottom-0 ${banner.banner_type == 'full_image' ? 'right-[-50vw]' : 'right-0'}  z-20`)
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: "border border-[1px] border-[#EAECF0] rounded-full w-[56px] h-[56px] flex justify-center items-center cursor-pointer hover:bg-[#EAECF0] hover:text-[#272286] transition duration-300",
                      onClick: _cache[0] || (_cache[0] = (...args) => ($options.prevBanner && $options.prevBanner(...args)))
                    }, [
                      _createVNode(_component_el_icon, { size: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Back)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createElementVNode("div", {
                      class: "border border-[1px] border-[#EAECF0] rounded-full w-[56px] h-[56px] flex justify-center items-center cursor-pointer hover:bg-[#EAECF0] hover:text-[#272286] transition duration-300",
                      onClick: _cache[1] || (_cache[1] = (...args) => ($options.nextBanner && $options.nextBanner(...args)))
                    }, [
                      _createVNode(_component_el_icon, { size: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Right)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ])
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" 这里要隐藏，否则图片容器的高度可以点击 "),
          (index == $data.bannerIndex)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (banner.banner_type == 'image')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (banner.link_to)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: banner.link_to
                          }, [
                            _createElementVNode("img", {
                              src: banner.image_url,
                              class: "w-[576px] md:w-full rounded-[24px]",
                              alt: ""
                            }, null, 8 /* PROPS */, _hoisted_11)
                          ], 8 /* PROPS */, _hoisted_10))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: banner.image_url,
                            class: "w-[576px] md:w-full rounded-[24px]",
                            alt: ""
                          }, null, 8 /* PROPS */, _hoisted_12))
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                (banner.banner_type == 'rotate')
                  ? (_openBlock(), _createBlock(_component_RotateImage, {
                      key: 1,
                      image: banner.image_url,
                      icon: banner.icon_url
                    }, null, 8 /* PROPS */, ["image", "icon"]))
                  : _createCommentVNode("v-if", true),
                (banner.banner_type == 'full_image')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (banner.link_to)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: banner.link_to,
                            class: "max-w-[1440px] w-[1440px] ml-[-64px] md:ml-0 z-10 h-[545px] md:h-full"
                          }, [
                            _createElementVNode("img", {
                              src: banner.image_url,
                              class: "w-full md:w-full md:hidden h-[545px]",
                              alt: ""
                            }, null, 8 /* PROPS */, _hoisted_14),
                            _createElementVNode("img", {
                              src: banner.mobile_image_url,
                              class: "w-full hidden md:block",
                              alt: ""
                            }, null, 8 /* PROPS */, _hoisted_15)
                          ], 8 /* PROPS */, _hoisted_13))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("img", {
                              src: banner.image_url,
                              class: "w-full md:w-full md:hidden h-[545px]",
                              alt: ""
                            }, null, 8 /* PROPS */, _hoisted_17),
                            _createElementVNode("img", {
                              src: banner.mobile_image_url,
                              class: "w-full hidden md:block",
                              alt: ""
                            }, null, 8 /* PROPS */, _hoisted_18)
                          ]))
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}