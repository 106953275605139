export const organization = {
  orgSetting: "Organization Settings",
  orgInfo: "Organization Information",
  orgNameSpace: "Organization Namespace",
  orgSpaceTips: "Once created, the namespace cannot be changed.",
  orgNickName: "Organization Nickname",
  orgAvatar: "Organization Avatar",
  orgType: "Organization Type",
  orgHomepage: "Organization Homepage",
  orgTeam: "Team Members",
  model: "Model",
  dataset: "Dataset",
  space: "Space",
  code: "Code",
  orgMembers: "Organization members",
  create:"Create",
  members: {
    title: "Member List",
    description: "Manage your organization members and their account permissions",
    last_login_at: "Last Login Time",
    role: "Role",
    username: "Username",
    editRole: "Edit Role",
    confirm: "Confirm",
    cancel: "Cancel",
    deleteSuccess: "member removed",
    editRoleSuccess: "member updated",
    deleteConfirmTitle: `Are you sure you want to remove user {username} from organization {orgName}`,
  },
  invite: {
    title: "Invite",
    inviteNew: "Invite New Member",
    inviteDesc: "You are inviting a new member to the organization",
    addSuccess: "Member added successfully",
  },
  newOrganization: {
    title: "Create New Organization",
    createOrg: "Create Organization",
    createSuccess: "Organization created successfully",
    inputSpaceNamePls: "Please enter the organization namespace",
  },
  edit: {
    role:"Modify User Role",
    roleTips:"Learn about the differences in permissions for different roles by accessing the documentation",
    updateSuccess: "organization updated successfully",
  },
}