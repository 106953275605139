import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "comment-tree" }
const _hoisted_2 = {
  key: 0,
  class: "replies-list",
  style: { marginLeft: `${20}px` }
}
const _hoisted_3 = {
  key: 1,
  class: "load-more-replies text-center mt-2 mb-4",
  style: { marginLeft: `${20}px` }
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_comment_item = _resolveComponent("comment-item")
  const _component_comment_tree = _resolveComponent("comment-tree", true)
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 当前评论 "),
    _createVNode(_component_comment_item, {
      comment: $props.comment,
      "user-details": $props.userDetails,
      "current-user": $props.currentUser,
      editing: $props.editingId === $props.comment.id,
      replying: $props.replyingId === $props.comment.id,
      loading: $props.loading,
      size: $options.getAvatarSize($props.comment.depth),
      "highlighted-id": $props.highlightedId,
      "parent-preview": $options.getParentPreview($props.comment),
      onVote: _cache[0] || (_cache[0] = $event => (_ctx.$emit('vote', $event))),
      onReply: _cache[1] || (_cache[1] = $event => (_ctx.$emit('reply', $event))),
      onEdit: _cache[2] || (_cache[2] = $event => (_ctx.$emit('edit', $event))),
      onDelete: _cache[3] || (_cache[3] = $event => (_ctx.$emit('delete', $event))),
      onEditSubmit: _cache[4] || (_cache[4] = $event => (_ctx.$emit('edit-submit', $event))),
      onEditCancel: _cache[5] || (_cache[5] = $event => (_ctx.$emit('edit-cancel'))),
      onReplySubmit: _cache[6] || (_cache[6] = $event => (_ctx.$emit('reply-submit', $event))),
      onReplyCancel: _cache[7] || (_cache[7] = $event => (_ctx.$emit('reply-cancel'))),
      onProfileClick: _cache[8] || (_cache[8] = $event => (_ctx.$emit('profile-click', $event))),
      onParentClick: _cache[9] || (_cache[9] = $event => (_ctx.$emit('parent-click', $event)))
    }, null, 8 /* PROPS */, ["comment", "user-details", "current-user", "editing", "replying", "loading", "size", "highlighted-id", "parent-preview"]),
    _createCommentVNode(" 回复列表 "),
    ($props.comment.replies && $props.comment.replies.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.comment.replies, (reply) => {
            return (_openBlock(), _createBlock(_component_comment_tree, {
              key: reply.id,
              comment: reply,
              "user-details": $props.userDetails,
              "current-user": $props.currentUser,
              "editing-id": $props.editingId,
              "replying-id": $props.replyingId,
              loading: $props.loading,
              "loading-replies": $props.loadingReplies,
              "highlighted-id": $props.highlightedId,
              onVote: _cache[10] || (_cache[10] = $event => (_ctx.$emit('vote', $event))),
              onReply: _cache[11] || (_cache[11] = $event => (_ctx.$emit('reply', $event))),
              onEdit: _cache[12] || (_cache[12] = $event => (_ctx.$emit('edit', $event))),
              onDelete: _cache[13] || (_cache[13] = $event => (_ctx.$emit('delete', $event))),
              onEditSubmit: _cache[14] || (_cache[14] = $event => (_ctx.$emit('edit-submit', $event))),
              onEditCancel: _cache[15] || (_cache[15] = $event => (_ctx.$emit('edit-cancel'))),
              onReplySubmit: _cache[16] || (_cache[16] = $event => (_ctx.$emit('reply-submit', $event))),
              onReplyCancel: _cache[17] || (_cache[17] = $event => (_ctx.$emit('reply-cancel'))),
              onProfileClick: _cache[18] || (_cache[18] = $event => (_ctx.$emit('profile-click', $event))),
              onParentClick: _cache[19] || (_cache[19] = $event => (_ctx.$emit('parent-click', $event))),
              onLoadMore: _cache[20] || (_cache[20] = $event => (_ctx.$emit('load-more', $event)))
            }, null, 8 /* PROPS */, ["comment", "user-details", "current-user", "editing-id", "replying-id", "loading", "loading-replies", "highlighted-id"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" 加载更多回复按钮 "),
    ($props.comment.has_more_replies)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_button, {
            type: "text",
            size: "small",
            loading: $props.loadingReplies[$props.comment.id],
            onClick: _cache[21] || (_cache[21] = $event => (_ctx.$emit('load-more', $props.comment.id)))
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode(" 加载更多回复 ")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}