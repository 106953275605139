import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-[16px] border-t border-[#EBEEF5]" }
const _hoisted_2 = { class: "flex items-center gap-[8px]" }
const _hoisted_3 = { class: "font-[500] text-[#344054] text-[16px] leading-[24px]" }
const _hoisted_4 = { class: "text-[#344054]" }
const _hoisted_5 = { class: "mt-[16px] flex flex-col gap-[12px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createVNode(_component_SvgIcon, {
        name: "codes",
        width: "18px",
        height: "18px"
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('codes.codesUsedToTrain')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_4, _toDisplayString($props.namespacePath), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.codes, (code) => {
        return (_openBlock(), _createBlock($setup["RepoItem"], {
          repo: code,
          repoType: "code",
          cardType: "relations"
        }, null, 8 /* PROPS */, ["repo"]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}