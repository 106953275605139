import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Blob"], {
    content: $props.content,
    "last-commit": $props.lastCommit,
    branches: $props.branches,
    "current-branch": $props.currentBranch,
    "current-path": $props.currentPath,
    "namespace-path": $props.namespacePath,
    onChangeBranch: $setup.changeBranch
  }, null, 8 /* PROPS */, ["content", "last-commit", "branches", "current-branch", "current-path", "namespace-path"]))
}