<template>
  <div class="flex items-center gap-4">
    <span 
      v-if="canReply"
      class="text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]"
      @click="$emit('reply')"
    >
      回复
    </span>
    <template v-if="isOwner">
      <span 
        class="text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]"
        @click="$emit('edit')"
      >
        编辑
      </span>
      <span 
        class="text-[12px] text-[#86909C] cursor-pointer hover:text-[#FF5005]"
        @click="handleDelete"
      >
        删除
      </span>
    </template>
  </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'

export default {
  name: 'CommentActions',
  props: {
    comment: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOwner() {
      return this.comment.user_name === this.currentUser.name
    },
    canReply() {
      return this.comment.depth < 5
    }
  },
  methods: {
    async handleDelete() {
      try {
        await ElMessageBox.confirm('确定要删除这条评论吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.$emit('delete')
      } catch (error) {
        // 用户取消删除
      }
    }
  }
}
</script>