import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    width: $props.width,
    height: $props.height,
    src: `/images/${$props.path}/${$props.name}.svg`,
    onerror: "this.style.display='none'"
  }, null, 8 /* PROPS */, _hoisted_1))
}