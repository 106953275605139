<template>
  <div class="page-container">
    <div class="search-and-button-container mb-4 mt-6">
      <div class="inner-container">
        <div class="search-container">
          <input
            v-model="searchKeyword"
            type="text"
            placeholder="搜索AI工具..."
            class="w-full pl-10 pr-4 py-2 rounded-full border focus:outline-none focus:ring-2 focus:ring-[#418fff] focus:bg-gray-100 hover:bg-gray-100 transition-all duration-300 ease-in-out shadow-lg"
            @input="handleSearch"
          />
          <div class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </div>
        </div>
        <a
          href="https://u0ddt94fk3b.feishu.cn/share/base/form/shrcnLB9wIuL47emhQNRO87Hn1c"
          target="_blank"
          rel="noopener noreferrer"
          class="apply-button px-4 py-2 bg-[#FF5005] text-white rounded-full hover:bg-[#e64600] transition-all duration-300 ease-in-out whitespace-nowrap"
        >
          申请添加AI工具
        </a>
      </div>
    </div>

    <div class="web-top-menu">
      <div class="web-top-menu-inner">
        <ul
            class="flex justify-center space-x-8 mb-6 border-b-2 text-lg py-4"
            id="pills-tab"
            role="tablist"
        >
          <li
              v-for="(group, idx) in groupedItems"
              :key="idx"
              class="webnav-item"
              role="presentation"
          >
            <button
                class="web-nav-link focus:outline-none rounded-full px-4 py-2 transition-all duration-300 ease-in-out"
                :class="{
                    'bg-[#418fff] text-white': currentSection === group.appclass && !isSearching,
                    'text-gray-700 hover:bg-[#4190ffa4] hover:text-white': currentSection !== group.appclass || isSearching,
                }"
                :id="'pills-' + group.appclass + '-tab'"
                type="button"
                role="tab"
                :aria-controls="'pills-' + group.appclass"
                :aria-selected="currentSection === group.appclass && !isSearching"
                @click="handleTabClick(group.appclass)"
            >
              <span>{{ group.appclass }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-content" id="pills-tabContent">
      <transition name="fade" mode="out-in">
        <div
            :key="isSearching ? 'search' : currentSection"
            class="tab-pane"
            role="tabpanel"
            tabindex="0"
        >
          <template v-if="isSearching">
            <div v-for="group in filteredGroups" :key="group.appclass">
              <h3 class="text-xl font-bold mb-4">{{ group.appclass }}</h3>
              <WebItem :items="group.items" />
            </div>
          </template>
          <WebItem v-else-if="currentGroup" :items="currentGroup" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import WebItem from "./webnav/components/WebItem.vue";

export default {
  name: "WebNav",
  components: {
    WebItem,
  },
  props: {
    webnavItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      items: [],
      currentSection: "",
      searchKeyword: "",
      isSearching: false,
      groupOrder: {
        "AI社区": 1,
        "AI办公": 2,
        "AI写作": 3,
        "AI对话": 4,
        "AI音频": 5,
        "AI视频": 6,
        "AI编程": 7,
      }
    };
  },
  computed: {
    groupedItems() {
      const groups = {};
      const unknownClassOrder = new Set();
      
      this.items.forEach(item => {
        if (!groups[item.appclass]) {
          groups[item.appclass] = [];
          if (!this.groupOrder[item.appclass]) {
            unknownClassOrder.add(item.appclass);
          }
        }
        groups[item.appclass].push(item);
      });
      
      const unknownClasses = Array.from(unknownClassOrder);
      const unknownOrder = unknownClasses.reduce((acc, className, index) => {
        acc[className] = 1000 + index;
        return acc;
      }, {});
      
      return Object.entries(groups)
        .map(([appclass, items]) => ({
          appclass,
          items,
          order: this.groupOrder[appclass] || unknownOrder[appclass] || 999
        }))
        .sort((a, b) => a.order - b.order);
    },
    currentGroup() {
      const group = this.groupedItems.find(group => group.appclass === this.currentSection);
      return group ? group.items : null;
    },
    filteredGroups() {
      if (!this.searchKeyword.trim()) {
        return this.groupedItems;
      }
      const keyword = this.searchKeyword.toLowerCase().trim();
      return this.groupedItems.map(group => ({
        ...group,
        items: group.items.filter(item => 
          item.title.toLowerCase().includes(keyword) ||
          item.desc.toLowerCase().includes(keyword)
        )
      })).filter(group => group.items.length > 0);
    },
  },
  methods: {
    handleSearch() {
      this.isSearching = this.searchKeyword.trim() !== '';
    },
    handleTabClick(appclass) {
      this.currentSection = appclass;
      this.searchKeyword = '';
      this.isSearching = false;
    },
  },
  watch: {
    webnavItems: {
      handler(val) {
        this.items = val;
        if (this.items.length > 0 && !this.currentSection) {
          const firstGroup = this.groupedItems[0];
          this.currentSection = firstGroup ? firstGroup.appclass : "";
        }
      },
      immediate: true
    }
  },
  created() {
    this.items = this.webnavItems;
    if (this.items.length > 0) {
      const firstGroup = this.groupedItems[0];
      this.currentSection = firstGroup ? firstGroup.appclass : "";
    }
  },
};
</script>

<style scoped>
.web-nav-link {
  position: relative;
  overflow: hidden;
}

.web-nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.5px;
  background-color: #87bfff;
  transition: all 0.3s ease-in-out;
}

.web-nav-link:hover::after,
.web-nav-link[aria-selected="true"]::after {
  width: 100%;
  left: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.search-container {
  max-width: 600px; 
  margin: 0 auto;
  margin-top: 1.5rem;
}

.search-and-button-container {
  max-width: 1000px; /* 增加容器宽度以容纳搜索栏和按钮 */
  margin: 0 auto;
  padding: 0 1rem;
}

.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* 添加间距 */
}

.search-container {
  width: 600px; /* 保持搜索栏宽度不变 */
  position: relative;
}

.apply-button {
  flex-shrink: 0; /* 防止按钮缩小 */
  position: absolute;
  right: 0;
}

@media (max-width: 1020px) {
  .search-and-button-container {
    padding: 0 1rem;
  }

  .inner-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    width: 100%;
  }

  .apply-button {
    position: static;
    margin-top: 1rem;
    text-align: center;
  }
}
</style>