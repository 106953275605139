import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "weight-adjuster" }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "flex items-center gap-4" }
const _hoisted_4 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_5 = { class: "grid gap-4" }
const _hoisted_6 = { class: "w-32" }
const _hoisted_7 = { class: "w-12 text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_el_slider = _resolveComponent("el-slider")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { class: "mb-4" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-bold" }, "请滑动滑块修改权重", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass({'text-red-500': $setup.totalWeight > 100, 'text-green-500': $setup.totalWeight === 100})
            }, " 总权重: " + _toDisplayString($setup.totalWeight) + "% ", 3 /* TEXT, CLASS */),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.applyWeights,
              disabled: $setup.totalWeight !== 100
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" 应用权重 ")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick", "disabled"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        ($setup.totalWeight !== 100)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_el_alert, {
                type: $setup.totalWeight > 100 ? 'warning' : 'info',
                title: $setup.weightAlertMessage,
                "show-icon": ""
              }, null, 8 /* PROPS */, ["type", "title"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentWeights, (weight, field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: field,
              class: "flex items-center gap-4"
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString($setup.getFieldLabel(field)), 1 /* TEXT */),
              _createVNode(_component_el_slider, {
                modelValue: $setup.currentWeights[field],
                "onUpdate:modelValue": $event => (($setup.currentWeights[field]) = $event),
                min: 0,
                max: 100,
                step: 0.5,
                class: "flex-1",
                onChange: $setup.handleWeightChange
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(weight) + "%", 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}