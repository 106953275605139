<template>
  <div class="flex min-h-screen bg-white md:block">
    <!-- 移动端头部 -->
    <div class="hidden md:block md:fixed md:top-[60px] md:left-0 md:right-0 md:z-10 md:bg-white md:border-b md:border-gray-200">
      <div class="p-4">
        <div class="flex items-center justify-between gap-4">
          <el-input
            v-model="searchKeyword"
            placeholder="搜索文档..."
            clearable
            @clear="handleSearch"
            @input="handleSearch"
            class="flex-1"
          >
            <template #prefix>
              <el-icon class="text-gray-400"><Search /></el-icon>
            </template>
          </el-input>
          <el-button
            class="!p-2 flex-shrink-0"
            @click="showSidebar = true"
          >
            <el-icon class="text-gray-500"><Menu /></el-icon>
          </el-button>
        </div>
      </div>
    </div>

    <!-- 移动端侧边栏抽屉 -->
    <el-drawer
      v-model="showSidebar"
      direction="left"
      size="85%"
      :with-header="false"
      class="md:block hidden"
    >
      <div class="h-full overflow-y-auto bg-white">
        <div class="p-4 border-b border-gray-200">
          <div class="text-lg font-medium mb-4">文档中心</div>
          <el-button
            v-if="admin"
            type="primary"
            class="w-full !bg-[#FF5005] !border-[#FF5005] !text-white"
            @click="handleCreate"
          >
            创建文档
          </el-button>
        </div>
        
        <!-- 分类导航 -->
        <div class="p-4">
          <doc-list
            :grouped-docs="groupedDocs"
            :selected-doc="selectedDoc"
            :expanded-categories="expandedCategories"
            :search-keyword="searchKeyword"
            @select-doc="selectDoc"
            @toggle-category="toggleCategory"
          />
        </div>
      </div>
    </el-drawer>

    <!-- 左侧导航栏（桌面端） -->
    <div class="w-[280px] border-r border-gray-200 p-4 md:hidden">
      <div class="mb-4 relative">
        <el-input
          v-model="searchKeyword"
          placeholder="搜索文档..."
          clearable
          @clear="handleSearch"
          @input="handleSearch"
        >
          <template #prefix>
            <el-icon class="text-gray-400"><Search /></el-icon>
          </template>
        </el-input>
      </div>
      
      <!-- 创建按钮 -->
      <div class="mb-4">
        <el-button
          v-if="admin"
          type="primary"
          class="w-full !bg-[#FF5005] !border-[#FF5005]"
          @click="handleCreate"
        >
          创建文档
        </el-button>
      </div>
      
      <doc-list
        :grouped-docs="groupedDocs"
        :selected-doc="selectedDoc"
        :expanded-categories="expandedCategories"
        :search-keyword="searchKeyword"
        @select-doc="selectDoc"
        @toggle-category="toggleCategory"
      />
    </div>

    <!-- 右侧内容区 -->
    <div class="flex-1 p-8 md:pt-[144px] md:px-4">
      <template v-if="selectedDoc">
        <doc-content
          :key="selectedDoc?.uuid"
          :doc="selectedDoc"
          :can-edit="canEdit(selectedDoc)"
          :show-actions="true"
        />
        <div class="mt-8">
          <Comments 
            :key="selectedDoc.uuid"
            :doc-uuid="selectedDoc.uuid"
            :current-user="currentUser"
          />
        </div>
      </template>
      <template v-else>
        <div class="text-center text-gray-500 mt-20">
          请选择要查看的文档
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Search, ArrowDown, Menu, Plus, View, ChatLineSquare } from '@element-plus/icons-vue'
import DocContent from './components/DocContent.vue'
import Comments from './components/Comments.vue'
import DocList from './components/DocList.vue'
import { docTypes, typeOrder, themeColors } from './constants/docTypes'

export default {
  components: {
    Search,
    ArrowDown,
    Menu,
    Plus,
    View,
    ChatLineSquare,
    DocContent,
    Comments,
    DocList
  },
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    docs: {
      type: Array,
      required: true
    },
    currentUser: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  setup(props) {
    const docs = ref(props.docs)
    const selectedDoc = ref(null)
    const searchKeyword = ref('')
    const expandedCategories = ref({})
    const showSidebar = ref(false)
    
    const groupedDocs = ref({})

    const toggleCategory = (type) => {
      expandedCategories.value[type] = !expandedCategories.value[type]
    }

    const groupDocsByType = () => {
      const filteredDocs = docs.value.filter(doc => {
        if (props.admin) {
          return true
        }
        return true
      })

      groupedDocs.value = typeOrder.reduce((acc, type) => {
        acc[type] = []
        return acc
      }, {})

      filteredDocs.forEach(doc => {
        const type = doc.doc_type || 'other'
        if (typeOrder.includes(type)) {
          groupedDocs.value[type].push(doc)
        } else {
          groupedDocs.value.other.push(doc)
        }
      })

      typeOrder.forEach(type => {
        if (expandedCategories.value[type] === undefined) {
          expandedCategories.value[type] = false
        }
      })
    }

    const selectDoc = (doc) => {
      selectedDoc.value = doc
      if (doc.doc_type) {
        expandedCategories.value[doc.doc_type] = true
      }
      
      const newUrl = `/docs?uuid=${doc.uuid}`
      window.history.pushState({ uuid: doc.uuid }, '', newUrl)
      showSidebar.value = false
    }

    const initSelectedDoc = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const uuid = urlParams.get('uuid')
      
      if (uuid) {
        const doc = docs.value.find(d => d.uuid === uuid)
        if (doc) {
          selectDoc(doc)
        } else {
          ElMessage({
            message: '文档不存在或已被删除',
            type: 'warning',
            duration: 3000
          })
          window.history.replaceState(null, '', '/docs')
        }
      }
    }

    const handlePopState = (event) => {
      const urlParams = new URLSearchParams(window.location.search)
      const uuid = urlParams.get('uuid')
      
      if (uuid) {
        const doc = docs.value.find(d => d.uuid === uuid)
        if (doc) {
          selectDoc(doc)
        } else {
          ElMessage({
            message: '文档不存在或已被删除',
            type: 'warning',
            duration: 3000
          })
          selectedDoc.value = null
          window.history.replaceState(null, '', '/docs')
        }
      } else {
        selectedDoc.value = null
      }
    }

    const handleSearch = () => {
      if (!searchKeyword.value) {
        groupDocsByType()
        // 重置所有分类的展开状态
        typeOrder.forEach(type => {
          expandedCategories.value[type] = false
        })
        return
      }

      const filteredDocs = docs.value.filter(doc => {
        const matchesSearch = doc.title.toLowerCase().includes(searchKeyword.value.toLowerCase())
        if (props.admin) {
          return matchesSearch
        }
        return matchesSearch
      })
      
      // 重新组织分类数据，保持顺序
      const newGroupedDocs = typeOrder.reduce((acc, type) => {
        acc[type] = []
        return acc
      }, {})

      filteredDocs.forEach(doc => {
        const type = doc.doc_type || 'other'
        if (newGroupedDocs[type]) {
          newGroupedDocs[type].push(doc)
        } else {
          newGroupedDocs.other.push(doc)
        }
      })

      groupedDocs.value = newGroupedDocs

      typeOrder.forEach(type => {
        expandedCategories.value[type] = newGroupedDocs[type].length > 0
      })

      // 如果只有一个搜索结果，自动选中该文档
      const allResults = Object.values(newGroupedDocs).flat()
      if (allResults.length === 1) {
        selectDoc(allResults[0])
      }

      // 如果没有搜索结果，显示提示
      if (allResults.length === 0) {
        ElMessage({
          message: '未找到相关文档',
          type: 'info'
        })
      }
    }

    // 添加防抖函数
    const debounce = (fn, delay) => {
      let timer = null
      return function (...args) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          fn.apply(this, args)
        }, delay)
      }
    }

    // 使用防抖处理搜索
    const debouncedSearch = debounce(handleSearch, 300)

    const handleCreate = () => {
      window.location.href = '/docs/new'
    }

    const handleEdit = (doc) => {
      window.location.href = `/docs/${doc.uuid}/edit`
    }

    const formatDate = (dateString) => {
      if (!dateString) return ''
      
      try {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
          return ''
        }
        
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        
        return `${year}-${month}-${day}`
      } catch (error) {
        console.error('Date parsing error:', error)
        return ''
      }
    }

    const canEdit = (doc) => {
      if (props.admin) return true
      if (!props.currentUser) return false
      return doc.user_id === props.currentUser.id
    }

    const getCardClasses = (doc) => {
      const type = doc.doc_type || 'other'
      const theme = themeColors[type]
      
      if (selectedDoc.value?.uuid !== doc.uuid) {
        return [
          'border-gray-200',
          theme.hoverBg,
          'hover:border-gray-300',
          'hover:shadow-[0_4px_8px_rgba(0,0,0,0.05)]'
        ]
      }
      
      return [
        theme.bg,
        theme.border,
        'border-[1.5px]',
        'shadow-[0_0_0_2px_rgba(0,0,0,0.1)]'
      ]
    }

    const getTextColorClass = (type) => {
      return themeColors[type || 'other'].text
    }

    onMounted(() => {
      groupDocsByType()
      initSelectedDoc()
      window.addEventListener('popstate', handlePopState)
    })

    onUnmounted(() => {
      window.removeEventListener('popstate', handlePopState)
    })

    return {
      docs,
      selectedDoc,
      searchKeyword,
      docTypes,
      groupedDocs,
      expandedCategories,
      selectDoc,
      handleSearch: debouncedSearch,
      handleCreate,
      handleEdit,
      formatDate,
      canEdit,
      toggleCategory,
      getCardClasses,
      getTextColorClass,
      typeOrder,
      showSidebar,
    }
  }
}
</script>

<style scoped>
:deep(.prose) {
  max-width: none;
}

:deep(.prose img) {
  margin: 0 auto;
}

:deep(.el-input__wrapper) {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 2px rgba(255, 80, 5, 0.1) !important;
  border-color: #FF5005 !important;
}

.doc-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-width: 1px;
  transition: all 0.2s ease-in-out;
}

.doc-card:hover {
  transform: translateY(-1px);
}

.doc-card:active {
  transform: translateY(0);
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

:deep(.el-button:hover) {
  background-color: #ff6a2b !important;
  border-color: #ff6a2b !important;
}

:deep(.el-button:active) {
  transform: scale(0.98);
}

:deep(.el-input__clear) {
  color: #999;
}

:deep(.el-input__clear:hover) {
  color: #666;
}

:deep(.el-drawer) {
  @apply !rounded-r-2xl;
}

:deep(.el-drawer__body) {
  @apply p-0;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  :deep(.el-input__wrapper) {
    @apply !shadow-none !border !border-gray-200 !rounded-lg;
  }
  
  :deep(.el-input__wrapper:hover) {
    @apply !border-gray-300;
  }
  
  :deep(.el-input__wrapper.is-focus) {
    @apply !border-[#FF5005] !shadow-[0_0_0_2px_rgba(255,80,5,0.1)];
  }
}

@media (max-width: 768px) {
  .doc-card {
    @apply !shadow-none !border-b !rounded-none !p-4;
  }
  
  .doc-card:last-child {
    @apply !border-b-0;
  }
  
  .doc-card:hover {
    @apply !transform-none;
  }
}

.md\:fixed {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .md\:top-\[60px\] {
    top: 60px;
  }
  
  .md\:pt-\[144px\] {
    padding-top: 144px;
  }
}

:deep(.el-icon) {
  font-size: 14px;
}
</style>
