import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex md:flex-col gap-[20px] w-full" }
const _hoisted_3 = { class: "rounded-[20px_20px_0_0] bg-cover text-[20px] px-[30px] font-[400] py-[32px] leading-[30px] opacity-90 bg-[url('images/xty_home/model_system_card_bg.svg')] relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-[24px] px-[30px] py-[32px]" }
const _hoisted_6 = { class: "flex gap-[16px] flex-col" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text-[18px] font-[400] leading-[23px] opacity-80" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-[28px] font-[600] leading-[28px] text-center md:w-[343px] mb-[20px] m-auto" }, [
      _createTextVNode(" 模型评测 "),
      _createElementVNode("span", { class: "ml-[8px] px-[8px] absolute rounded-[8px] bg-[#E3EDF8] text-[#678ECE] text-[12px] font-[500]" }, " 即将上线 ")
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[20px] font-[300] leading-[30px] text-[#475467] mb-[64px] text-center w-[758px] md:w-full" }, " 赋能技术创新、帮助模型选型、防范安全风险 ", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cards, (card) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "rounded-[20px] w-full bg-white",
            key: card.title
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(card.title) + " ", 1 /* TEXT */),
              _createElementVNode("img", {
                src: card.header_icon,
                class: "w-[140px] h-[140px] absolute right-[0px] top-[-40px]",
                alt: ""
              }, null, 8 /* PROPS */, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.tasks, (task) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex gap-[12px] items-center",
                    key: task.icon
                  }, [
                    _createElementVNode("img", {
                      src: task.icon,
                      class: "w-[32px] h-[32px]",
                      alt: ""
                    }, null, 8 /* PROPS */, _hoisted_7),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(task.text), 1 /* TEXT */)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}