import request from './request'

/** 案例 开始 */
// 搜索案例
export function caseList(query) {
  return request({
    url: `/internal_api/cases`,
    method: 'get',
    params: query
  })
}

// 查看案例
export function caseDetail(id) {
  return request({
    url: `/internal_api/cases/${id}`,
    method: 'get',
  })
}

// 创建案例
export function caseCreate(data) {
  return request({
    url: `/internal_api/cases`,
    method: 'post',
    data: data
  })
}
/** 案例 结束 */
