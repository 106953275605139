import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center mb-[5px] sm:w-auto gap-[8px]" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-[8px]"
}
const _hoisted_5 = { class: "flex items-center gap-[8px] text-xs text-[#00000061]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: `/models/${$props.model.path}`,
    class: "basis-1/2 md:basis-full max-w-[532px] md:max-w-full md:max-w-full flex flex-col xty-model-card md:bg-white bg-unset"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        title: $props.model.path,
        class: "model-path max-w-[60%] text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap"
      }, _toDisplayString($props.model.name), 9 /* TEXT, PROPS */, _hoisted_3),
      ($props.model.repository?.tags?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass(`text-[12px] px-[8px] py-[3px] flex gap-[4px] font-[400] rounded-[4px] bg-[${$setup.colorMap($setup.displayTag().category)}30] text-[${$setup.colorMap($setup.displayTag().category)}]`)
            }, _toDisplayString($setup.displayTag().name), 3 /* TEXT, CLASS */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["ShortInfo"], {
        supplier: $setup.props.model.path.split('/')[0],
        updated_at: $props.model.updated_at,
        downloads: $props.model.downloads
      }, null, 8 /* PROPS */, ["supplier", "updated_at", "downloads"])
    ])
  ], 8 /* PROPS */, _hoisted_1))
}