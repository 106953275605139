<template>
  <div class="flex flex-col gap-[20px]">
    <div class="flex flex-col gap-[8px] w-full">
      <div class="text-[14px] font-[500] leading-[20px] text-[#223B99]">
        {{ salon.city }}
        •
        {{ formatTime(salon.event_at) }}
      </div>
      <a
        :href="salon.link"
        target="_blank"
        class="text-[18px] font-[500] leading-[28px] text-[#101828] flex gap-[16px] items-start self-stretch justify-between"
      >
        <div class="overflow-hidden overflow-ellipsis line-clamp-2">
          {{ salon.title }}
        </div>
        <div class="w-[24px] mt-[3px]">
          <el-icon :size="24"><TopRight /></el-icon>
        </div>
      </a>
      <div class="text-[16px] font-[300] leading-[24px] text-[#475467] line-clamp">
        主办单位：{{ salon.organizer }}
      </div>
    </div>
    <div class="flex gap-[8px]">
      <template :key="tag" v-for="tag in salon.salon_tags">
        <div :class="`rounded-full border-[1px] px-[10px] text-[14px] font-[400] leading-[20px] ${getTheme(tag)}`">
          {{ tag }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import dayjs from "dayjs";
  import { stringMod } from "../helpers/utils";

  export default {
    props: {
      salon: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        themes: {
          0: "border-[#99AFFF] bg-[#CED8FF] text-[#223B99]",
          1: "border-[#C7D7FE] bg-[#EEF4FF] text-[#3538CD]",
          2: "border-[#FCCEEE] bg-[#F8F9FC] text-[#C11574]",
          3: "border-[#D5D9EB] bg-[#F0F9FF] text-[#363F72]",
          4: "border-[#B9E6FE] bg-[#F0F9FF] text-[#026AA2]",
          5: "border-[#F9DBAF] bg-[#FEF6EE] text-[#B93815]",

        }
      }
    },
    methods: {
      formatTime(time) {
        try {
          return dayjs(time).format("HH:mm a YYYY.M.D")
        } catch (error) {
          console.log(error)
        }
      },
      getTheme(tag) {
        const mod = stringMod(tag, 6)
        return this.themes[mod]
      }
    }
  }
</script>