import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full bg-[#FCFCFD] pt-9 pb-[60px] xl:px-10 md:px-0 md:pb-6 md:h-auto"
}
const _hoisted_2 = { class: "mx-auto max-w-[1280px]" }
const _hoisted_3 = { class: "flex items-center text-[14px] font-[400] gap-[8px] leading-[20px] text-[#00000061] mt-[10px] md:px-5" }
const _hoisted_4 = { class: "mx-auto max-w-[1280px] mt-[-40px] xl:px-10 md:px-0 md:mt-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.repo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["Breadcrumb"], {
              referActionUrl: `/${$props.repoType}s`,
              referActionName: _ctx.$t(`${$props.repoType}s.list`),
              currentActionName: _ctx.$t(`${$props.repoType}s.detail`)
            }, null, 8 /* PROPS */, ["referActionUrl", "referActionName", "currentActionName"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["ShortInfo"], {
                repo: $setup.repo,
                isDetail: true
              }, null, 8 /* PROPS */, ["repo"])
            ]),
            _createVNode($setup["RepoHeader"], {
              license: $setup.repo.license,
              name: $setup.repo.name,
              nickname: $setup.repo.nickname,
              desc: $setup.repo.description,
              path: `${$props.namespace}/${$props.repoName}`,
              tags: $setup.tags,
              avatar: _ctx.avatar,
              "owner-url": $setup.ownerUrl,
              repoId: $setup.repo.repository_id,
              totalLikes: $setup.repo.likes,
              hasLike: $setup.repo.user_likes,
              repoType: $props.repoType,
              baseModel: $setup.repo.base_model
            }, null, 8 /* PROPS */, ["license", "name", "nickname", "desc", "path", "tags", "avatar", "owner-url", "repoId", "totalLikes", "hasLike", "repoType", "baseModel"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["RepoTabs"], {
        "local-repo-id": _ctx.localRepoId,
        "repo-detail": $setup.repo,
        "current-branch": $props.currentBranch,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $setup.repo.can_manage,
        "can-write": $setup.repo.can_write,
        tags: $setup.tags,
        "tag-list": _ctx.tagList,
        userName: $props.userName,
        commitId: $props.commitId,
        repoType: $props.repoType,
        admin: $props.admin,
        path: `${$props.namespace}/${$props.repoName}`
      }, null, 8 /* PROPS */, ["local-repo-id", "repo-detail", "current-branch", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write", "tags", "tag-list", "userName", "commitId", "repoType", "admin", "path"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}