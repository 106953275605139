import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative w-[460px] h-[220px] overflow-hidden rounded-[0_0_100px_0] bg-[#1B185FB3]" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $props.image,
      class: "w-[800px] h-[800px] rounded-[24px] absolute left-[-290px] top-[-290px] animation-rotate-60",
      alt: ""
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("img", {
      src: $props.icon,
      class: "w-[100px] h-[100px] rounded-[24px] absolute left-[56px] top-[56px]",
      alt: ""
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}