import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      type: "border-card",
      modelValue: $setup.activeName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.activeName) = $event)),
      onTabChange: $setup.changeTab,
      class: "rounded"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "编辑",
          name: "edit"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Codemirror"], {
              modelValue: $setup.codeContent,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.codeContent) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_tab_pane, {
          label: "预览",
          class: "p-4",
          name: "preview"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", { innerHTML: $setup.previewDiff }, null, 8 /* PROPS */, _hoisted_1)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}