import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = {
  key: 1,
  class: "mr-3",
  style: {"min-width":"40px"}
}
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "flex gap-3" }
const _hoisted_6 = { class: "flex-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vote_buttons = _resolveComponent("vote-buttons")
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_comment_header = _resolveComponent("comment-header")
  const _component_comment_actions = _resolveComponent("comment-actions")
  const _component_comment_editor = _resolveComponent("comment-editor")

  return (_openBlock(), _createElementBlock("div", {
    id: `comment-${$props.comment.id}`,
    class: _normalizeClass(["comment-item mb-6 p-4 rounded-lg transition-all duration-200", [
      $options.isRoot ? 'bg-white border border-gray-100 hover:border-gray-200 shadow-sm' : 
      'bg-[#F7F8FA] hover:bg-[#F0F2F5]',
      { 'highlight-animation': $data.isHighlighted }
    ]]),
    style: _normalizeStyle($options.computedStyle)
  }, [
    _createElementVNode("div", _hoisted_2, [
      (!$props.comment.deleted)
        ? (_openBlock(), _createBlock(_component_vote_buttons, {
            key: 0,
            "votes-count": $props.comment.votes_count,
            "user-vote": $props.comment.user_vote,
            onVote: $options.handleVote
          }, null, 8 /* PROPS */, ["votes-count", "user-vote", "onVote"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_avatar, {
            src: $props.userDetails[$props.comment.user_name]?.avatar,
            size: $props.size,
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('profile-click', $props.comment.user_name)))
          }, null, 8 /* PROPS */, ["src", "size"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_comment_header, {
              comment: $props.comment,
              "user-details": $props.userDetails,
              "parent-preview": $props.parentPreview,
              onProfileClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('profile-click', $event))),
              onParentClick: _cache[2] || (_cache[2] = $event => (_ctx.$emit('parent-click', $event)))
            }, null, 8 /* PROPS */, ["comment", "user-details", "parent-preview"]),
            _createElementVNode("div", {
              class: _normalizeClass(["text-[14px] mb-2", { 'text-[#86909C] italic': $props.comment.deleted, 'text-[#1D2129]': !$props.comment.deleted }])
            }, _toDisplayString($props.comment.deleted ? '该评论已删除' : $props.comment.content), 3 /* TEXT, CLASS */),
            (!$props.comment.deleted)
              ? (_openBlock(), _createBlock(_component_comment_actions, {
                  key: 0,
                  comment: $props.comment,
                  "current-user": $props.currentUser,
                  onReply: _cache[3] || (_cache[3] = $event => (_ctx.$emit('reply', $props.comment))),
                  onEdit: _cache[4] || (_cache[4] = $event => (_ctx.$emit('edit', $props.comment))),
                  onDelete: _cache[5] || (_cache[5] = $event => (_ctx.$emit('delete', $props.comment.id)))
                }, null, 8 /* PROPS */, ["comment", "current-user"]))
              : _createCommentVNode("v-if", true),
            ($props.editing)
              ? (_openBlock(), _createBlock(_component_comment_editor, {
                  key: 1,
                  modelValue: $data.editContent,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.editContent) = $event)),
                  loading: $props.loading,
                  onCancel: _cache[7] || (_cache[7] = $event => (_ctx.$emit('edit-cancel'))),
                  onSubmit: _cache[8] || (_cache[8] = $event => (_ctx.$emit('edit-submit', { id: $props.comment.id, content: $data.editContent })))
                }, null, 8 /* PROPS */, ["modelValue", "loading"]))
              : _createCommentVNode("v-if", true),
            ($props.replying)
              ? (_openBlock(), _createBlock(_component_comment_editor, {
                  key: 2,
                  modelValue: $data.replyContent,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.replyContent) = $event)),
                  loading: $props.loading,
                  placeholder: $options.replyPlaceholder,
                  onCancel: _cache[10] || (_cache[10] = $event => (_ctx.$emit('reply-cancel'))),
                  onSubmit: _cache[11] || (_cache[11] = $event => (_ctx.$emit('reply-submit', { parentId: $props.comment.id, content: $data.replyContent })))
                }, null, 8 /* PROPS */, ["modelValue", "loading", "placeholder"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ])
  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}