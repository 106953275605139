import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`rounded-[16px] border-[2px] px-[36px] py-[60px] relative flex flex-col gap-[30px] ${$options.getTheme($props.firstItem.label).borderColor}`),
    style: {"width":"auto"}
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`absolute bg-white left-[30px] top-[-16px] rounded-[8px] px-[16px] text-[20px] font-[400] leading-[30px] border-[2px] ${$options.getTheme($props.firstItem.label).borderColor}`)
    }, _toDisplayString($props.secondItem.label), 3 /* TEXT, CLASS */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.range, (times) => {
      return (_openBlock(), _createElementBlock("div", {
        key: times,
        class: "flex flex-nowrap gap-[30px]"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.secondItem.items, (thirdItem, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: thirdItem.logo
          }, [
            (index % this.thirdLevelRowCount === times)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: thirdItem.link_to
                }, [
                  _createElementVNode("img", {
                    src: thirdItem.logo,
                    alt: "",
                    class: "h-[54px] w-[172px]"
                  }, null, 8 /* PROPS */, _hoisted_2)
                ], 8 /* PROPS */, _hoisted_1))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}