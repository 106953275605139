import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[20px]" }
const _hoisted_2 = { class: "flex flex-col gap-[8px] w-full" }
const _hoisted_3 = { class: "text-[14px] font-[500] leading-[20px] text-[#223B99]" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "overflow-hidden overflow-ellipsis line-clamp-2" }
const _hoisted_6 = { class: "w-[24px] mt-[3px]" }
const _hoisted_7 = { class: "text-[16px] font-[300] leading-[24px] text-[#475467] line-clamp" }
const _hoisted_8 = { class: "flex gap-[8px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopRight = _resolveComponent("TopRight")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.salon.city) + " • " + _toDisplayString($options.formatTime($props.salon.event_at)), 1 /* TEXT */),
      _createElementVNode("a", {
        href: $props.salon.link,
        target: "_blank",
        class: "text-[18px] font-[500] leading-[28px] text-[#101828] flex gap-[16px] items-start self-stretch justify-between"
      }, [
        _createElementVNode("div", _hoisted_5, _toDisplayString($props.salon.title), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_icon, { size: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_TopRight)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 8 /* PROPS */, _hoisted_4),
      _createElementVNode("div", _hoisted_7, " 主办单位：" + _toDisplayString($props.salon.organizer), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.salon.salon_tags, (tag) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tag,
          class: _normalizeClass(`rounded-full border-[1px] px-[10px] text-[14px] font-[400] leading-[20px] ${$options.getTheme(tag)}`)
        }, _toDisplayString(tag), 3 /* TEXT, CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}