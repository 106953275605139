import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "17",
  height: "16",
  viewBox: "0 0 17 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "Frame" }, [
      _createElementVNode("path", {
        id: "Vector",
        d: "M3.3116 5.1024V10.8983L8.3116 13.793L13.3116 10.8983V5.1024L8.3116 2.20766L3.3116 5.1024ZM8.3116 0.666992L14.6449 4.33366V11.667L8.3116 15.3337L1.97827 11.667V4.33366L8.3116 0.666992ZM4.64424 6.64743L7.64494 8.38466V11.7503H8.97827V8.38466L11.9789 6.64743L11.3109 5.49353L8.3116 7.22999L5.3123 5.49353L4.64424 6.64743Z",
        fill: "black",
        "fill-opacity": "0.38"
      })
    ], -1 /* HOISTED */)
  ])))
}