import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-[60px] bg-[#1C7A6C]" }
const _hoisted_2 = { class: "max-w-[1280px] m-auto h-full flex justify-between items-center" }
const _hoisted_3 = { class: "text-[#D1DDD0]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-[700] text-[#D1DDD0]" }, " CAICT Admin Pannel ", -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        href: "/",
        target: "_blank",
        class: "font-[500] text-[#D1DDD0] underline"
      }, " Open Site ", -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.userName) + " ( " + _toDisplayString($props.userRoles) + " )", 1 /* TEXT */)
    ])
  ]))
}