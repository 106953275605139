import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-[14px] text-[#344054] cursor-pointer flex items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`px-[8px] py-[6px] bg-[##ebddc114] border border-r-0 border-[#D0D5DD] rounded-l-[6px] ${$props.active ? 'bg-[#ffa80130] border-[#ffa80130]' : ''}`)
    }, [
      _createElementVNode("img", {
        src: '/images/tags/' + $props.tag.name + '.svg',
        class: _normalizeClass(`w-[14px] h-[14px] ${$props.active ? 'active' : ''}`),
        alt: "",
        onerror: "this.style.display='none'"
      }, null, 10 /* CLASS, PROPS */, _hoisted_2)
    ], 2 /* CLASS */),
    _createElementVNode("p", {
      class: _normalizeClass(["h-[28px] pl-[4px] pr-[8px] py-[3px] border border-l-0 border-[#D0D5DD] rounded-r-[6px]", { 'bg-[#ffa80130]': $props.active, 'text-[#ffa801]': $props.active, 'border-[#ffa80130]': $props.active }]),
      onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('handleTagClick', $props.tag)))
    }, _toDisplayString($setup.tagName), 3 /* TEXT, CLASS */)
  ]))
}