import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#F4F5FE] pt-[24px] pb-[40px]" }
const _hoisted_2 = { class: "flex flex-col max-w-[1440px] mx-[32px] gap-[24px] md:mx-[10px]" }
const _hoisted_3 = { class: "flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]" }
const _hoisted_4 = { class: "flex items-center gap-[8px] text-[14px] font-[400] leading-[20px]" }
const _hoisted_5 = { class: "flex gap-[8px] font-[500] items-center" }
const _hoisted_6 = {
  href: "/cases",
  class: "flex items-center"
}
const _hoisted_7 = { class: "text-[#101828] text-[30px] font-[500] leading-[30px]" }
const _hoisted_8 = { class: "my-[12px] text-[14px] font-[400] leading-[22px] flex md:flex-col gap-[32px] md:gap-[8px]" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "text-[#667085]" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "text-[#667085]" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "text-[#667085]" }
const _hoisted_15 = { class: "flex w-full md:w-full rounded-[8px] py-[20px]" }
const _hoisted_16 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_Back = _resolveComponent("Back")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "xty_home"
          }),
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "slash"
          }),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[#475467]" }, "案例展示", -1 /* HOISTED */)),
          _createVNode(_component_SvgIcon, {
            width: "20",
            height: "20",
            name: "slash"
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-[#344054]" }, "案例详情", -1 /* HOISTED */))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createVNode(_component_el_icon, { size: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_Back)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString($data.item.title), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-[#475467] font-[600]" }, "企业名称：", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_10, _toDisplayString($data.item.org_name), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-[#475467] font-[600]" }, "所属行业：", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_12, _toDisplayString($data.item.industry_list.join('、')), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-[#475467] font-[600]" }, "案例类型：", -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_14, _toDisplayString($data.item.kind), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", {
            class: "ck-content",
            innerHTML: $data.item.content
          }, null, 8 /* PROPS */, _hoisted_16)
        ])
      ])
    ])
  ]))
}