<template>
  <div class="bg-[#F4F5FE] pt-[24px] pb-[40px]">
    <div class="flex flex-col max-w-[1440px] mx-[32px] gap-[24px] md:mx-[10px]">
      <div class="flex flex-col w-full bg-white p-[32px] rounded-[8px] gap-[20px] md:px-[16px]">
        <div class="flex items-center gap-[8px] text-[14px] font-[400] leading-[20px]">
          <SvgIcon width="20" height="20" name="xty_home" />
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#475467]">案例展示</div>
          <SvgIcon width="20" height="20" name="slash" />
          <div class="text-[#344054]">案例详情</div>
        </div>

        <div class="flex gap-[8px] font-[500] items-center">
          <a href="/cases" class="flex items-center">
            <el-icon :size="24">
              <Back />
            </el-icon>
          </a>
          <div class="text-[#101828] text-[30px] font-[500] leading-[30px]">
            {{ item.title }}
          </div>
        </div>

        <div class="my-[12px] text-[14px] font-[400] leading-[22px] flex md:flex-col gap-[32px] md:gap-[8px]">
          <div class="">
            <span class="text-[#475467] font-[600]">企业名称：</span>
            <span class="text-[#667085]">{{ item.org_name }}</span>
          </div>
          <div class="">
            <span class="text-[#475467] font-[600]">所属行业：</span>
            <span class="text-[#667085]">{{ item.industry_list.join('、') }}</span>
          </div>
          <div class="">
            <span class="text-[#475467] font-[600]">案例类型：</span>
            <span class="text-[#667085]">{{ item.kind }}</span>
          </div>
        </div>

        <div
          class="flex w-full md:w-full rounded-[8px] py-[20px]">
          <div class="ck-content" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseDetail } from '@api/csrf/case'

export default {
  data() {
    return {
      item: {
        industry_list: [],
      }
    }
  },
  mounted() {
    this.fetchCase()
  },
  methods: {
    fetchCase() {
      const id = location.pathname.split('/')[2]
      caseDetail(id).then(res => {
        this.item = res.data
      })
    }
  }
}
</script>