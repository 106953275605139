import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-[30px]" }
const _hoisted_2 = { class: "my-[30px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" visibility "),
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "Visibility", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.model.private,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.model.private) = $event)),
            placeholder: "Select",
            size: "large",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Private",
                value: true
              }),
              _createVNode(_component_el_option, {
                label: "Public",
                value: false
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: _cache[1] || (_cache[1] = $event => ($setup.updateModel({ private: $setup.model.private })))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
    _createCommentVNode(" base_model "),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "text-[18px] font-[500] mb-[8px]" }, "Base Model", -1 /* HOISTED */)),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_autocomplete, {
            clearable: "",
            modelValue: $setup.model.base_model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.model.base_model) = $event)),
            "fetch-suggestions": $setup.fetchBaseModels,
            size: "large"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            size: "small",
            onClick: _cache[3] || (_cache[3] = $event => ($setup.updateModel({ base_model: $setup.model.base_model })))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Update")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ], 64 /* STABLE_FRAGMENT */))
}