import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gradient-to-l from-[#FAFAFA] to-white" }
const _hoisted_2 = { class: "mx-auto max-w-[1440px] pt-[16px] px-[80px] pb-[40px] md:p-[16px]" }
const _hoisted_3 = { class: "mt-[10px]" }
const _hoisted_4 = { class: "mx-auto max-w-[1440px] px-[80px] mt-[-40px] md:px-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Breadcrumb"], {
          referActionUrl: "/datasets",
          referActionName: "数据集列表",
          currentActionName: "数据集详情"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["ResourceHeader"], {
            private: $props.dataset.data.private,
            license: $props.dataset.data.license,
            name: $props.dataset.data.name,
            nickname: $props.dataset.data.nickname,
            desc: $props.dataset.data.description,
            path: $props.dataset.data.path,
            avatar: $props.avatar,
            tags: $props.tags,
            resource: $props.dataset.data,
            "owner-url": $props.ownerUrl
          }, null, 8 /* PROPS */, ["private", "license", "name", "nickname", "desc", "path", "avatar", "tags", "resource", "owner-url"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["DatasetTabs"], {
        blob: $props.blob.data,
        "local-dataset-id": $props.localDatasetId,
        "local-dataset": $props.localDataset,
        "dataset-path": $props.dataset.data.path,
        "dataset-nickname": $props.localDataset.nickname,
        "dataset-desc": $props.localDataset.desc,
        "dataset-default-branch": $props.dataset.data.default_branch,
        "dataset-private": $props.dataset.data.private,
        "dataset-detail": $props.dataset.data,
        "last-commit": $props.lastCommit.data,
        branches: $props.branches.data,
        "current-branch": $props.currentBranch,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $props.settingsVisibility,
        "can-write": $props.canWrite
      }, null, 8 /* PROPS */, ["blob", "local-dataset-id", "local-dataset", "dataset-path", "dataset-nickname", "dataset-desc", "dataset-default-branch", "dataset-private", "dataset-detail", "last-commit", "branches", "current-branch", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}