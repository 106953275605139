import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "mb-5" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_progress = _resolveComponent("el-progress")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('admin.syncSetting.title')), 1 /* TEXT */),
    ($setup.form.default)
      ? (_openBlock(), _createBlock(_component_el_alert, {
          key: 0,
          title: _ctx.$t('admin.syncSetting.defaultTokenAlert'),
          type: "warning",
          "show-icon": "",
          class: "!mb-5"
        }, null, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_el_form, {
      model: $setup.form,
      "label-width": "auto",
      "label-position": "left",
      style: {"max-width":"400px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "Token" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.form.token,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.token) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('admin.syncSetting.concurrent')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $setup.form.concurrent_count,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.form.concurrent_count) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.concurrentCounts, (count) => {
                  return _createVNode(_component_el_option, {
                    key: count,
                    label: count,
                    value: count
                  }, null, 8 /* PROPS */, ["label", "value"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('admin.syncSetting.bandwidth')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $setup.form.max_bandwidth,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.form.max_bandwidth) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.bandwidthCounts, (count) => {
                  return _createVNode(_component_el_option, {
                    key: count,
                    label: count,
                    value: count
                  }, null, 8 /* PROPS */, ["label", "value"])
                }), 64 /* STABLE_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.onSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t($setup.submitKey)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model"]),
    _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "my-8" }, null, -1 /* HOISTED */)),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('admin.syncSetting.queues')), 1 /* TEXT */),
    _createVNode(_component_el_table, {
      data: $setup.queues,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "path",
          label: "Path"
        }),
        _createVNode(_component_el_table_column, {
          prop: "repo_type",
          label: "Type",
          width: "100"
        }),
        _createVNode(_component_el_table_column, { label: "Progress" }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_progress, {
              percentage: scope.row.progress
            }, null, 8 /* PROPS */, ["percentage"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_table_column, {
          prop: "sync_status",
          label: "Status"
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data"]),
    _createVNode(_component_el_pagination, {
      "current-page": $setup.page,
      "onUpdate:currentPage": _cache[3] || (_cache[3] = $event => (($setup.page) = $event)),
      "page-size": $setup.per,
      layout: "prev, pager, next",
      total: $setup.total,
      onCurrentChange: $setup.fetchSyncRecords,
      class: "my-[52px] flex justify-center"
    }, null, 8 /* PROPS */, ["current-page", "page-size", "total"])
  ]))
}