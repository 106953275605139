import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "comments-section" }
const _hoisted_2 = { class: "sort-section mb-4 flex items-center gap-4" }
const _hoisted_3 = {
  key: 1,
  class: "text-center text-[#86909C] py-4 bg-gray-50 rounded-lg"
}
const _hoisted_4 = { class: "comments-list" }
const _hoisted_5 = {
  key: 1,
  class: "text-center text-[#86909C] py-8"
}
const _hoisted_6 = {
  key: 2,
  class: "flex justify-center mt-6"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_comment_editor = _resolveComponent("comment-editor")
  const _component_comment_tree = _resolveComponent("comment-tree")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" 评论排序 "),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-[14px] text-[#86909C]" }, "排序方式：", -1 /* HOISTED */)),
      _createVNode(_component_el_radio_group, {
        modelValue: $data.sortBy,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.sortBy) = $event)),
        size: "small",
        onChange: $options.handleSortChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_button, { label: "hot" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("热门")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_radio_button, { label: "new" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("最新")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_radio_button, { label: "old" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("最早")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "onChange"])
    ]),
    _createCommentVNode(" 评论输入框 "),
    ($options.canComment)
      ? (_openBlock(), _createBlock(_component_comment_editor, {
          key: 0,
          modelValue: $data.newComment,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.newComment) = $event)),
          loading: $data.submitting,
          "submit-text": "发表评论",
          onSubmit: $options.submitComment
        }, null, 8 /* PROPS */, ["modelValue", "loading", "onSubmit"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[7] || (_cache[7] = [
          _createTextVNode(" 请"),
          _createElementVNode("a", {
            href: "/login",
            class: "text-[#FF5005] hover:text-[#ff6a2b]"
          }, "登录", -1 /* HOISTED */),
          _createTextVNode("后发表评论 ")
        ]))),
    _createCommentVNode(" 评论列表 "),
    _createElementVNode("div", _hoisted_4, [
      ($data.comments.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($data.comments, (comment) => {
            return (_openBlock(), _createElementBlock("div", {
              key: comment.id
            }, [
              _createCommentVNode(" 渲染评论树 "),
              _createVNode(_component_comment_tree, {
                comment: comment,
                "user-details": $data.userDetails,
                "current-user": $props.currentUser,
                "editing-id": $data.editingCommentId,
                "replying-id": $data.replyToId,
                loading: $data.submitting,
                "loading-replies": $data.loadingReplies,
                "highlighted-id": $data.highlightedCommentId,
                onVote: $options.handleVote,
                onReply: $options.showReplyInput,
                onEdit: $options.showEditInput,
                onDelete: $options.deleteComment,
                onEditSubmit: $options.submitEdit,
                onEditCancel: $options.cancelEdit,
                onReplySubmit: $options.submitReply,
                onReplyCancel: $options.cancelReply,
                onProfileClick: $options.goToProfile,
                onParentClick: $options.scrollToComment,
                onLoadMore: $options.loadMoreReplies
              }, null, 8 /* PROPS */, ["comment", "user-details", "current-user", "editing-id", "replying-id", "loading", "loading-replies", "highlighted-id", "onVote", "onReply", "onEdit", "onDelete", "onEditSubmit", "onEditCancel", "onReplySubmit", "onReplyCancel", "onProfileClick", "onParentClick", "onLoadMore"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, " 暂无评论，快来发表第一条评论吧 ")),
      _createCommentVNode(" 分页 "),
      ($data.totalPages > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_pagination, {
              "current-page": $data.currentPage,
              "onUpdate:currentPage": _cache[2] || (_cache[2] = $event => (($data.currentPage) = $event)),
              "page-size": $data.perPage,
              total: $data.totalCount,
              onCurrentChange: $options.handlePageChange,
              layout: "prev, pager, next",
              background: ""
            }, null, 8 /* PROPS */, ["current-page", "page-size", "total", "onCurrentChange"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}