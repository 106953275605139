import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex items-center absolute top-0 right-0 md:relative md:pb-4 z-10 md:hidden" }
const _hoisted_2 = { class: "ml-2 text-[#606266] text-sm font-medium" }
const _hoisted_3 = { class: "text-[#a0a1a7] p-2" }
const _hoisted_4 = { class: "text-[#a0a1a7] p-2" }
const _hoisted_5 = { class: "rounded-t border-t border-x border-[#EBEEF5] mt-4" }
const _hoisted_6 = { class: "flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]" }
const _hoisted_7 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }
const _hoisted_8 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.dialogLibraryVisible = true))
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none"
      }, [
        _createElementVNode("path", {
          d: "M4.6665 3.5L1.1665 7L4.6665 10.5M9.33317 3.5L12.8332 7L9.33317 10.5",
          stroke: "#606266",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        })
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, "Use in " + _toDisplayString($props.resource) + " library", 1 /* TEXT */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, false]
    ]),
    _createVNode(_component_el_tooltip, {
      effect: "dark",
      content: "请不要使用迅雷下载，推荐使用BT下载器，如：Deluge、qBittorrent、Vuze等",
      placement: "top"
    }, {
      default: _withCtx(() => [
        (!!$setup.ptMetadata?.metadata?.data?.stuts == 1)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]",
              onClick: $setup.downloadPtLink
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                class: "w-[16px] h-[16px]",
                src: "/images/xty_images/common/magnet.svg",
                alt: ""
              }, null, -1 /* HOISTED */),
              _createElementVNode("div", { class: "ml-2 text-[#606266] text-sm font-medium" }, "BT+ 下载", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true),
        (!!$props.btLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]",
              onClick: $setup.downloadBtLink,
              target: "_blank"
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("img", {
                class: "w-[16px] h-[16px]",
                src: "/images/xty_images/common/magnet.svg",
                alt: ""
              }, null, -1 /* HOISTED */),
              _createElementVNode("div", { class: "ml-2 text-[#606266] text-sm font-medium" }, "BT 下载", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    (!!$setup.ptMetadata?.metadata?.data?.stuts == 1 || !!$props.btLink)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          effect: "dark",
          content: "请在【文件和版本】中检查是否存在权重文件，如不存在，请尝试BT/BT+下载方式",
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]",
              onClick: _cache[1] || (_cache[1] = $event => ($setup.cloneRepositoryVisible = true))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none"
              }, [
                _createElementVNode("rect", {
                  opacity: "0.12",
                  x: "1.3335",
                  y: "1.3335",
                  width: "13.3333",
                  height: "13.3333",
                  rx: "3.33333",
                  fill: "#2F384C"
                }),
                _createElementVNode("path", {
                  d: "M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z",
                  stroke: "#2F384C",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1 /* HOISTED */),
              _createElementVNode("div", { class: "ml-2 text-[#606266] text-sm font-medium" }, "Git 下载", -1 /* HOISTED */)
            ]))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogLibraryVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.dialogLibraryVisible) = $event)),
      title: "Dataset library is unknown",
      class: "md:!w-[80%]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, "# Type of " + _toDisplayString($props.resource) + "/library unknown.", 1 /* TEXT */),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-[#a0a1a7] p-2" }, "# Feel free to open a Pull request ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, "# for integration of the " + _toDisplayString($props.resource) + " hub", 1 /* TEXT */),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-[#a0a1a7] p-2" }, "# into the corresponding library =)", -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.cloneRepositoryVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.cloneRepositoryVisible) = $event)),
      title: "",
      class: "md:!w-[80%]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[11] || (_cache[11] = _createElementVNode("svg", {
              class: "mr-1",
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none"
            }, [
              _createElementVNode("rect", {
                opacity: "0.12",
                x: "1.3335",
                y: "1.3335",
                width: "13.3333",
                height: "13.3333",
                rx: "3.33333",
                fill: "#2F384C"
              }),
              _createElementVNode("path", {
                d: "M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z",
                stroke: "#2F384C",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1 /* HOISTED */)),
            _createTextVNode(" 下载" + _toDisplayString($setup.resourceMap[$props.resource]), 1 /* TEXT */)
          ])
        ]),
        _createVNode(_component_el_tabs, {
          modelValue: $setup.activeCloneType,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.activeCloneType) = $event)),
          class: "border border-[#EBEEF5] mb-8 clone-tabs"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "HTTPS",
              name: "https"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-[#909399]" }, "# 在下载前请确认您安装了git-lfs (https://git-lfs.com)", -1 /* HOISTED */)),
                  _cache[13] || (_cache[13] = _createElementVNode("div", null, "git lfs install", -1 /* HOISTED */)),
                  _createElementVNode("div", null, "git clone " + _toDisplayString($props.httpCloneUrl), 1 /* TEXT */),
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-[#909399]" }, "# 如果您不想下载LFS文件的内容，请在环境变量中添加", -1 /* HOISTED */)),
                  _cache[15] || (_cache[15] = _createElementVNode("div", null, "GIT_LFS_SKIP_SMUDGE=1", -1 /* HOISTED */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_tab_pane, {
              label: "SSH",
              name: "ssh"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-[#909399]" }, "# 在下载前请确认您安装了git-lfs (https://git-lfs.com)", -1 /* HOISTED */)),
                  _cache[17] || (_cache[17] = _createElementVNode("div", null, "git lfs install", -1 /* HOISTED */)),
                  _createElementVNode("div", null, "git clone " + _toDisplayString($props.sshCloneUrl), 1 /* TEXT */),
                  _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-[#909399]" }, "# 如果您不想下载LFS文件的内容，请在环境变量中添加", -1 /* HOISTED */)),
                  _cache[19] || (_cache[19] = _createElementVNode("div", null, "GIT_LFS_SKIP_SMUDGE=1", -1 /* HOISTED */))
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}