import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "px-[12px] py-[12px] rounded-full bg-[#ACBDFF] border-[8px] border-[#CED8FF]" }
const _hoisted_3 = { class: "flex justify-center flex-col m-auto w-full relative" }
const _hoisted_4 = { class: "text-[18px] leading-[28px] text-[#101828]" }
const _hoisted_5 = { class: "text-[14px] leading-[20px] text-[#475467] font-light mb-5" }
const _hoisted_6 = { class: "flex flex-col w-full" }
const _hoisted_7 = { class: "dialog-footer flex justify-between" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: _ctx.$t('organization.members.editRole'),
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dialogVisible) = $event)),
    width: "350",
    style: { borderRadius: '10px' },
    onClose: $options.handleClose
  }, {
    header: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SvgIcon, { name: "edit_org_member_role" })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: "/images/invite_bg.png",
          class: "w-[200px] absolute top-1 left-1"
        }, null, -1 /* HOISTED */))
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_button, {
          class: "flex-1 mr-3 text-gray-700",
          size: "large",
          onClick: $options.handleClose
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('organization.members.cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_el_button, {
          class: "flex-1",
          size: "large",
          color: "#3250BD",
          type: "primary",
          loading: $data.loading,
          onClick: _cache[1] || (_cache[1] = $event => ($options.submitForm('form')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('organization.members.confirm')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('organization.edit.role')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('organization.edit.roleTips')), 1 /* TEXT */),
        _createVNode(_component_el_form, {
          model: $data.dataForm,
          rules: $data.rules,
          class: "w-full",
          ref: "form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "role" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('organization.members.role')), 1 /* TEXT */),
                  _createVNode(_component_el_select, {
                    modelValue: $data.dataForm.role,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.role) = $event)),
                    size: "large",
                    placeholder: this.$t('all.select')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.roleMappings, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8 /* PROPS */, ["label", "value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue", "placeholder"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "modelValue", "onClose"]))
}