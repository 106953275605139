import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[24px] px-6 py-10 border-l" }
const _hoisted_2 = { class: "font-semibold text-[20px] leading-[28px]" }
const _hoisted_3 = { class: "flex gap-[12px] fileInput" }
const _hoisted_4 = { class: "flex items-center gap-[4px] mb-[8px]" }
const _hoisted_5 = { class: "text-gray-500 text-[12px] italic pt-1" }
const _hoisted_6 = { class: "flex items-center gap-[4px] mb-[8px]" }
const _hoisted_7 = { class: "flex flex-col gap-[8px]" }
const _hoisted_8 = {
  key: 0,
  class: "flex gap-[13px] max-w-[400px] items-center mt-[16px]"
}
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { class: "w-[88px] h-[40px] text-[#98A2B3] flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200" }
const _hoisted_11 = {
  key: 0,
  class: "rounded-md border border-gray-200 bg-gray-200 shadow-sm px-[14px] py-[8px] font-[500] text-[#98A2B3] text-[14px] leading-[20px]"
}
const _hoisted_12 = { class: "flex items-center gap-[4px] mb-[8px]" }
const _hoisted_13 = { class: "flex items-center gap-[4px] mb-[8px]" }
const _hoisted_14 = { class: "flex items-center gap-[4px] mb-[8px]" }
const _hoisted_15 = { class: "flex items-center gap-[4px] mb-[8px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.canChangeUsername === 'true')
      ? (_openBlock(), _createBlock(_component_el_alert, {
          key: 0,
          title: _ctx.$t('profile.edit.renameUsername'),
          center: "",
          "show-icon": "",
          type: "warning"
        }, null, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('profile.edit.title')), 1 /* TEXT */),
    _createCommentVNode(" avatar "),
    _createElementVNode("div", null, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex items-center gap-[4px] mb-[8px]" }, "用户头像", -1 /* HOISTED */)),
      _createVNode(_component_el_avatar, {
        size: 120,
        src: $setup.profileData.avatar
      }, null, 8 /* PROPS */, ["src"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          ref: "fileInput",
          type: "file",
          class: "hidden",
          onChange: $setup.uploadAvatar
        }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
        _createElementVNode("div", {
          onClick: $setup.uploadImage,
          class: "text-[14px] border border-[#DCDFE6] px-[20px] py-[9px] leading-[22px] text-center rounded-[8px] text-white cursor-pointer bg-[#409EFF]"
        }, _toDisplayString(_ctx.$t('profile.edit.uploadAvatar')), 1 /* TEXT */),
        _createElementVNode("div", {
          onClick: $setup.removeImage,
          class: "text-[14px] border border-[#DCDFE6] px-[20px] py-[9px] leading-[22px] text-center rounded-[8px] text-[#606266] cursor-pointer bg-white"
        }, _toDisplayString(_ctx.$t('profile.edit.removeAvatar')), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" name "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('all.userName')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.profileData.username) = $event)),
        disabled: $setup.canChangeUsername !== 'true',
        placeholder: this.$t('all.userName')
      }, null, 8 /* PROPS */, ["modelValue", "disabled", "placeholder"]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('rule.nameRule')), 1 /* TEXT */)
    ]),
    _createCommentVNode(" phone "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('all.phone')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_input, {
          class: "max-w-[600px]",
          modelValue: $setup.profileData.phone,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.profileData.phone) = $event)),
          ref: "phoneInputElement",
          onChange: $setup.resetPhoneStatus,
          disabled: !$setup.updatePhoneEnabled,
          placeholder: _ctx.$t('all.phone')
        }, null, 8 /* PROPS */, ["modelValue", "disabled", "placeholder"]),
        ($setup.updatePhoneEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createCommentVNode(" sms code input "),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('profile.edit.smsCode')), 1 /* TEXT */),
                _withDirectives(_createElementVNode("input", {
                  class: "w-[117px] px-[12px] py-[8px] h-[40px] rounded-r-md border bg-white",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.inputSmsCode) = $event))
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, $setup.inputSmsCode]
                ])
              ]),
              _createCommentVNode(" countdown "),
              ($setup.inCountDown)
                ? (_openBlock(), _createElementBlock("button", _hoisted_11, _toDisplayString($setup.countdown) + "s " + _toDisplayString(_ctx.$t('profile.edit.retrySendSms')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" send sms "),
              _createCommentVNode(" <button v-else\r\n                  @click=\"sendSmsCode\"\r\n                  class=\"rounded-md border border-[#409EFF] bg-[#409EFF] shadow-sm px-[14px] py-[8px] font-[500] text-white text-[14px] leading-[20px]\"\r\n          >\r\n            {{ $t('profile.edit.sendSms') }}\r\n          </button> ")
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" <el-button v-else\r\n                  class=\"w-[120px]\"\r\n                  @click=\"enableUpdatePhone\"\r\n        >\r\n          {{ $t('profile.edit.updatePhone') }}\r\n        </el-button> ")
      ])
    ]),
    _createCommentVNode(" email "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_12, [
        _createTextVNode(_toDisplayString(_ctx.$t('all.email')), 1 /* TEXT */),
        _cache[10] || (_cache[10] = _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "6",
          height: "6",
          viewBox: "0 0 6 6",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M2.21714 5.21809L3.35474 3.85009L4.49234 5.21809L5.12594 4.75729L4.20434 3.25969L5.77394 2.61169L5.52914 1.87729L3.88754 2.26609L3.74354 0.538086H2.96594L2.82194 2.28049L1.18034 1.87729L0.921143 2.61169L2.49074 3.25969L1.58354 4.75729L2.21714 5.21809Z",
            fill: "#F56C6C"
          })
        ], -1 /* HOISTED */))
      ]),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.profileData.email) = $event)),
        placeholder: _ctx.$t('all.email')
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createCommentVNode(" nickname "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('all.nickName')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.nickname,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.profileData.nickname) = $event)),
        placeholder: this.$t('all.nickName')
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createCommentVNode(" bilibili "),
    _createElementVNode("div", null, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex items-center gap-[4px] mb-[8px]" }, " BiliBili账号 ", -1 /* HOISTED */)),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.bilibili,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.profileData.bilibili) = $event)),
        placeholder: "https://space.bilibili.com/00000000"
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createCommentVNode(" weibo "),
    _createElementVNode("div", null, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex items-center gap-[4px] mb-[8px]" }, " 微博账号 ", -1 /* HOISTED */)),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.weibo,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.profileData.weibo) = $event)),
        placeholder: "https://weibo.com/u/0000000000"
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createCommentVNode(" homepage "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('all.homepage')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.homepage,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.profileData.homepage) = $event)),
        placeholder: this.$t('all.homepage')
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createCommentVNode(" bio "),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('all.bio')), 1 /* TEXT */),
      _createVNode(_component_el_input, {
        class: "max-w-[600px]",
        modelValue: $setup.profileData.bio,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($setup.profileData.bio) = $event)),
        clearable: "",
        type: "textarea",
        autosize: { minRows: 8, maxRows: 30 },
        placeholder: this.$t('all.bio')
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", {
      onClick: $setup.confirmUpdateProfile,
      class: "w-[111px] text-[14px] px-[16px] py-[5px] leading-[22px] text-center rounded-[8px] text-white cursor-pointer btn-orange"
    }, _toDisplayString(_ctx.$t('all.save')), 1 /* TEXT */)
  ]))
}