<template>
  <div v-for="type in typeOrder" :key="type" class="mb-4">
    <template v-if="groupedDocs[type]?.length > 0">
      <div 
        class="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-50"
        @click="toggleCategory(type)"
      >
        <div class="text-[16px] font-medium text-gray-900">
          {{ docTypes[type] }}
          <span 
            class="ml-1 text-gray-400 text-sm"
            :class="{ 'text-[#FF5005]': searchKeyword && (groupedDocs[type]?.length || 0) > 0 }"
          >({{ groupedDocs[type]?.length || 0 }})</span>
        </div>
        <el-icon 
          class="text-gray-400 transition-transform duration-300" 
          :class="{ 
            'rotate-180': expandedCategories[type],
            'text-[#FF5005]': searchKeyword && (groupedDocs[type]?.length || 0) > 0 
          }"
        >
          <ArrowDown />
        </el-icon>
      </div>
      <div 
        v-show="expandedCategories[type]"
        class="space-y-2 mt-2"
      >
        <div 
          v-for="doc in (groupedDocs[type] || [])" 
          :key="doc.uuid" 
          @click="selectDoc(doc)"
          class="doc-card ml-2"
          :class="[
            'p-3 rounded-lg cursor-pointer text-[14px]',
            'border transition-all duration-200',
            getCardClasses(doc)
          ]"
        >
          <div class="flex items-center justify-between mb-1">
            <span :class="[
              'font-medium line-clamp-1 text-gray-900',
              selectedDoc?.uuid === doc.uuid ? getTextColorClass(doc.doc_type) : ''
            ]">{{ doc.title }}</span>
            <div class="flex items-center gap-2 flex-shrink-0">
              <span 
                v-if="doc.status !== 'published'" 
                class="px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-600"
              >草稿</span>
            </div>
          </div>
          <div class="flex items-center justify-between text-xs text-gray-500">
            <span>更新于 {{ formatDate(doc.updated_at) }}</span>
            <div class="flex items-center gap-3">
              <span class="flex items-center gap-1">
                <el-icon><View /></el-icon>
                {{ doc.views_count || 0 }}
              </span>
              <span class="flex items-center gap-1">
                <el-icon><ChatLineSquare /></el-icon>
                {{ doc.comments_count || 0 }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ArrowDown, ChatLineSquare, View } from '@element-plus/icons-vue'
import { docTypes, typeOrder, themeColors } from '../constants/docTypes'
import dayjs from 'dayjs'

export default {
  name: 'DocList',
  components: {
    ArrowDown,
    ChatLineSquare,
    View
  },
  props: {
    groupedDocs: {
      type: Object,
      required: true
    },
    selectedDoc: {
      type: Object,
      default: null
    },
    expandedCategories: {
      type: Object,
      required: true
    },
    searchKeyword: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      docTypes,
      typeOrder
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    selectDoc(doc) {
      this.$emit('select-doc', doc)
    },
    toggleCategory(type) {
      this.$emit('toggle-category', type)
    },
    getCardClasses(doc) {
      const type = doc.doc_type || 'other'
      const theme = themeColors[type]
      
      if (this.selectedDoc?.uuid !== doc.uuid) {
        return [
          'border-gray-200',
          theme.hoverBg,
          'hover:border-gray-300',
          'hover:shadow-[0_4px_8px_rgba(0,0,0,0.05)]'
        ]
      }
      
      return [
        theme.bg,
        theme.border,
        'border-[1.5px]',
        'shadow-[0_0_0_2px_rgba(0,0,0,0.1)]'
      ]
    },
    getTextColorClass(type) {
      return themeColors[type || 'other'].text
    }
  }
}
</script> 

<style scoped>
:deep(.el-icon) {
  font-size: 14px;
}
</style> 