<template>
  <div class="comment-tree">
    <!-- 当前评论 -->
    <comment-item
      :comment="comment"
      :user-details="userDetails"
      :current-user="currentUser"
      :editing="editingId === comment.id"
      :replying="replyingId === comment.id"
      :loading="loading"
      :size="getAvatarSize(comment.depth)"
      :highlighted-id="highlightedId"
      :parent-preview="getParentPreview(comment)"
      @vote="$emit('vote', $event)"
      @reply="$emit('reply', $event)"
      @edit="$emit('edit', $event)"
      @delete="$emit('delete', $event)"
      @edit-submit="$emit('edit-submit', $event)"
      @edit-cancel="$emit('edit-cancel')"
      @reply-submit="$emit('reply-submit', $event)"
      @reply-cancel="$emit('reply-cancel')"
      @profile-click="$emit('profile-click', $event)"
      @parent-click="$emit('parent-click', $event)"
    />

    <!-- 回复列表 -->
    <div 
      v-if="comment.replies && comment.replies.length > 0"
      class="replies-list"
      :style="{ marginLeft: `${20}px` }"
    >
      <comment-tree
        v-for="reply in comment.replies"
        :key="reply.id"
        :comment="reply"
        :user-details="userDetails"
        :current-user="currentUser"
        :editing-id="editingId"
        :replying-id="replyingId"
        :loading="loading"
        :loading-replies="loadingReplies"
        :highlighted-id="highlightedId"
        @vote="$emit('vote', $event)"
        @reply="$emit('reply', $event)"
        @edit="$emit('edit', $event)"
        @delete="$emit('delete', $event)"
        @edit-submit="$emit('edit-submit', $event)"
        @edit-cancel="$emit('edit-cancel')"
        @reply-submit="$emit('reply-submit', $event)"
        @reply-cancel="$emit('reply-cancel')"
        @profile-click="$emit('profile-click', $event)"
        @parent-click="$emit('parent-click', $event)"
        @load-more="$emit('load-more', $event)"
      />
    </div>

    <!-- 加载更多回复按钮 -->
    <div 
      v-if="comment.has_more_replies" 
      class="load-more-replies text-center mt-2 mb-4"
      :style="{ marginLeft: `${20}px` }"
    >
      <el-button 
        type="text" 
        size="small"
        :loading="loadingReplies[comment.id]"
        @click="$emit('load-more', comment.id)"
      >
        加载更多回复
      </el-button>
    </div>
  </div>
</template>

<script>
import CommentItem from './CommentItem.vue'

export default {
  name: 'CommentTree',
  components: {
    CommentItem
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    userDetails: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    editingId: [String, Number],
    replyingId: [String, Number],
    loading: Boolean,
    loadingReplies: {
      type: Object,
      default: () => ({})
    },
    highlightedId: {
      type: [String, Number],
      default: null
    }
  },
  methods: {
    getAvatarSize(depth) {
      // 随着深度增加，头像尺寸逐渐减小
      // const sizes = [40, 36, 32, 28, 24]
      // return sizes[Math.min(depth, sizes.length - 1)]
      return 40
    },
    getParentPreview(comment) {
      if (!comment.parent_id) return '原评论'
      const parentComment = this.findParentComment(comment.parent_id)
      if (!parentComment) return '原评论'
      return parentComment.deleted ? '该评论已删除' : parentComment.content
    },
    findParentComment(parentId) {
      // 在当前评论树中查找父评论
      if (this.comment.id === parentId) return this.comment
      if (this.comment.replies) {
        for (const reply of this.comment.replies) {
          if (reply.id === parentId) return reply
        }
      }
      return null
    }
  }
}
</script>

<style scoped>
.comment-tree {
  margin-bottom: 1rem;
}

.replies-list {
  margin-top: 1rem;
}
</style>
