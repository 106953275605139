import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex md:block max-w-[1440px] m-auto min-h-[calc(100vh-153px)] md:min-h-0 ml-[32px] md:ml-0" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-[334px] flex-col bg-gradient-to-l from-[#FAFAFA] to-white border-r border-[#F2F2F2] text-[12px] h-full pb-[20px]"
}
const _hoisted_3 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex gap-[8px] flex-wrap" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "w-full"
}
const _hoisted_10 = { class: "border-b border-[#F2F2F2] px-[16px] py-[12px] text-[#000000E0]" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 2,
  class: "flex flex-wrap gap-[5px]"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "pr-[20px] pt-[32px] md:px-[20px] md:pt-[0px] w-full" }
const _hoisted_18 = { class: "flex md:flex-col justify-between items-center px-[16px]" }
const _hoisted_19 = { class: "text-lg font-normal text-[#101828] flex items-center gap-2" }
const _hoisted_20 = { class: "text-gray-400 text-[16px] italic" }
const _hoisted_21 = { class: "flex justify-end gap-[16px] items-center mt-[16px] md:flex-col md:pl-0 md:mr-0" }
const _hoisted_22 = { class: "md:w-full" }
const _hoisted_23 = { class: "md:mt-[16px] md:w-full flex justify-end" }
const _hoisted_24 = {
  key: 0,
  href: `/cases/new`,
  class: "btn btn-orange flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]"
}
const _hoisted_25 = { class: "grid grid-cols-2 md:grid-cols-1 gap-[16px] px-[16px] py-[12px] md:px-[0px] mb-[60px]" }
const _hoisted_26 = ["href"]
const _hoisted_27 = { class: "flex flex-col gap-[10px] border-[#E6E6E6] border-[1px] rounded-[4px] p-[16px] shadow-[0px_0px_0px_0px_rgba(0,0,0,0.05)] cursor-pointer xty-model-card" }
const _hoisted_28 = { class: "flex gap-[16px] justify-between text-[18px] font-[500] leading-[28px]" }
const _hoisted_29 = { class: "text-[rgba(0, 0, 0, 0.88)] overflow-ellipsis line-clamp line-clamp-1 max-w-[70%]" }
const _hoisted_30 = { class: "flex items-center gap-[8px] text-[#667085] text-[12px] leading-[18px] font-[400]" }
const _hoisted_31 = { class: "flex items-center gap-[4px]" }
const _hoisted_32 = {
  key: 0,
  class: "text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD]"
}
const _hoisted_33 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_34 = { class: "text-[#667085]" }
const _hoisted_35 = { class: "flex gap-[8px]" }
const _hoisted_36 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_37 = { class: "text-[#667085] w-[135px] inline-block" }
const _hoisted_38 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400]" }
const _hoisted_39 = { class: "text-[#667085]" }
const _hoisted_40 = { class: "flex gap-[8px]" }
const _hoisted_41 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1" }
const _hoisted_42 = { class: "text-[#667085] w-[135px] inline-block" }
const _hoisted_43 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1" }
const _hoisted_44 = { class: "text-[#667085]" }
const _hoisted_45 = { class: "text-[#475467] text-[14px] leading-[22px] font-[400] overflow-ellipsis line-clamp-1" }
const _hoisted_46 = { class: "text-[#667085]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_drawer = _resolveComponent("el-drawer")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$data.mobileScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createCommentVNode(" 案例类型 "),
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "案例类型", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kinds, (kind) => {
              return (_openBlock(), _createElementBlock("span", {
                key: kind,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${_ctx.activeKind === kind ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleKindClick(kind))
              }, _toDisplayString(kind), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" 组织类型 "),
          _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "组织类型", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.org_types, (org) => {
              return (_openBlock(), _createElementBlock("span", {
                key: org,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${_ctx.activeOrgType === org ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleOrgTypeClick(org))
              }, _toDisplayString(org), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" 行业 "),
          _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "text-[#00000099] font-[500] text-[12px] py-[16px]" }, "行业", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tags, (tag) => {
              return (_openBlock(), _createElementBlock("span", {
                key: tag,
                class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD] ${$data.activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                onClick: $event => ($options.handleTagClick(tag))
              }, _toDisplayString(tag), 11 /* TEXT, CLASS, PROPS */, _hoisted_8))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'Kind' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[0] || (_cache[0] = $event => {this.drawer = true; this.activeNavItem = 'Kind'})
            }, " 案例类型 ", 2 /* CLASS */),
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'OrgType' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[1] || (_cache[1] = $event => {this.drawer = true; this.activeNavItem = 'OrgType'})
            }, " 组织类型 ", 2 /* CLASS */),
            _createElementVNode("span", {
              class: _normalizeClass(`${$data.activeNavItem === 'Industry' ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`),
              onClick: _cache[2] || (_cache[2] = $event => {this.drawer = true; this.activeNavItem = 'Industry'})
            }, " 行业 ", 2 /* CLASS */)
          ]),
          _createVNode(_component_el_drawer, {
            modelValue: $data.drawer,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.drawer) = $event)),
            direction: "btt",
            "with-header": false
          }, {
            default: _withCtx(() => [
              ($data.activeNavItem === 'Kind')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kinds, (kind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: kind,
                        class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${_ctx.activeKind === kind ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                        onClick: $event => ($options.handleKindClick(kind))
                      }, _toDisplayString(kind), 11 /* TEXT, CLASS, PROPS */, _hoisted_12))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : ($data.activeNavItem === 'OrgType')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.org_types, (org) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: org,
                          class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${_ctx.activeOrgType === org ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                          onClick: $event => ($options.handleOrgTypeClick(org))
                        }, _toDisplayString(org), 11 /* TEXT, CLASS, PROPS */, _hoisted_14))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  : _createCommentVNode("v-if", true),
              ($data.activeNavItem === 'Industry')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tags, (tag) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: tag,
                        class: _normalizeClass(`text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD] ${$data.activeTag === tag ? 'bg-[#ffa80130] border-[#ffa80130] text-[#ffa801]' : ''}`),
                        onClick: $event => ($options.handleTagClick(tag))
                      }, _toDisplayString(tag), 11 /* TEXT, CLASS, PROPS */, _hoisted_16))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ])),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("h3", _hoisted_19, [
          _createVNode(_component_SvgIcon, {
            name: "case",
            width: "18",
            height: "18"
          }),
          _createElementVNode("span", null, [
            _cache[8] || (_cache[8] = _createTextVNode(" 案例展示 ")),
            _createElementVNode("span", _hoisted_20, _toDisplayString($data.total), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_el_input, {
              modelValue: $data.keywords,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.keywords) = $event)),
              class: "w-[320px] md:w-full",
              size: "large",
              placeholder: "搜索关键字",
              "prefix-icon": $setup.Search,
              onChange: $options.fetchCases
            }, null, 8 /* PROPS */, ["modelValue", "prefix-icon", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_23, [
            ($data.canCreateCase)
              ? (_openBlock(), _createElementBlock("a", _hoisted_24, " 上传案例 "))
              : (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 1,
                  class: "box-item",
                  effect: "dark",
                  content: "请先登录并注册组织后再上传案例",
                  placement: "top-start"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("a", {
                      href: `/cases/new`,
                      class: "btn bg-[var(--Gray-4)] text-[--Base-White] flex justify-center items-center px-[24px] py-[8px] text-[14px] font-[400] leading-[20px]"
                    }, " 上传案例 ", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cases, (item) => {
          return (_openBlock(), _createElementBlock("a", {
            key: item.id,
            href: `/cases/${item.id}`
          }, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(item.title), 1 /* TEXT */),
                (item.build_status)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(`text-[14px] px-[4px] rounded-[4px] cursor-pointer ${$options.getThemeClass(item.build_status)}`)
                    }, _toDisplayString($data.build_status_map[item.build_status]), 3 /* TEXT, CLASS */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  (item.org_type)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(item.org_type), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.industry_list, (tag) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: tag,
                      class: "text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer border border-[#D0D5DD]"
                    }, _toDisplayString(tag), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */)),
                  (item.report_status)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(`text-[14px] px-[8px] py-[3px] rounded-[6px] cursor-pointer ${$options.getThemeClass(item.report_status)}`)
                      }, _toDisplayString($data.report_status_map[item.report_status]), 3 /* TEXT, CLASS */))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-[#475467]" }, "牵头单位：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_34, _toDisplayString(item.org_name), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-[#475467]" }, "发布时间：", -1 /* HOISTED */)),
                  _createElementVNode("span", _hoisted_37, _toDisplayString($setup.dayjs(item.updated_at).format("YYYY-MM-DD")), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-[#475467]" }, "案例类型：", -1 /* HOISTED */)),
                  _createElementVNode("span", _hoisted_39, _toDisplayString(item.kind), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-[#475467]" }, "底座模型：", -1 /* HOISTED */)),
                  _createElementVNode("span", _hoisted_42, _toDisplayString(item.extra.base_model), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-[#475467]" }, "模型类型：", -1 /* HOISTED */)),
                  _createElementVNode("span", _hoisted_44, _toDisplayString($data.model_kind_map[item.model_kind]), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-[#475467]" }, "案例介绍：", -1 /* HOISTED */)),
                _createElementVNode("span", _hoisted_46, _toDisplayString(item.description), 1 /* TEXT */)
              ])
            ])
          ], 8 /* PROPS */, _hoisted_26))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createVNode($setup["CsgPagination"], {
        perPage: $data.perPage,
        currentPage: $data.currentPage,
        onCurrentChange: $options.fetchCases,
        total: $data.total
      }, null, 8 /* PROPS */, ["perPage", "currentPage", "onCurrentChange", "total"])
    ])
  ]))
}