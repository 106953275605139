import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "flex justify-end gap-2 mt-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      modelValue: $options.localContent,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.localContent) = $event)),
      type: "textarea",
      rows: 2,
      placeholder: $props.placeholder,
      maxlength: $props.maxLength,
      "show-word-limit": ""
    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "maxlength"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        size: "small",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('cancel')))
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" 取消 ")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_button, {
        type: "primary",
        size: "small",
        class: "!bg-[#FF5005] !border-[#FF5005]",
        loading: $props.loading,
        onClick: $options.handleSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.submitText), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "onClick"])
    ])
  ]))
}