<template>
  <div class="bg-white">
    <div class="max-w-[1440px] m-auto flex md:px-[16px] px-[32px]">
      <div class="flex md:flex-col w-full">
        <!-- 左侧标签选择模块 -->
        <template v-if="!mobileScreen">
          <!-- PC 端 -->
          <div class="flex pt-[24px] pr-[24px] w-[310px] flex-col gap-[16px] border-r border-r-[1px] border-[#DCDFE6] mb-[40px]">
            <!-- 组织类型 -->
            <div class="flex flex-col gap-[8px]">
              <div class="text-[12px] font-[400] leading-[20px] text-[#909399]">
                {{ $t("organization.collection.org_type") }}
              </div>
              <div class="flex gap-[8px] flex-wrap">
                <div 
                  :class="`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${query.org_type == org_type ? 'active-org-tag-btn' : ''}`"
                  v-for="org_type in org_types" 
                  :key="org_type"
                  @click="handleOrgTypeChange(org_type)"
                >
                  {{ org_type }}
                </div>
              </div>
            </div>
            <!-- 组织行业 -->
            <div class="flex flex-col gap-[8px]">
              <div class="text-[12px] font-[400] leading-[20px] text-[#909399]">
                {{ $t("organization.collection.industry") }}
              </div>
              <div class="flex gap-[8px] flex-wrap">
                <div 
                  :class="`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${query.industry == industry ? 'active-org-tag-btn' : ''}`"
                  v-for="industry in industries" 
                  :key="industry"
                  @click="handleIndustryChange(industry)"
                >
                  <img :src="`/images/xty_images/organization/collection/${industry}.svg`" alt="">
                  {{ industry }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- 右侧内容模块 -->
        <div class="grow flex flex-col mb-[40px]">
          <div class="flex md:flex-col justify-between items-center pl-[24px]">
            <h3 class="text-lg font-normal text-[#101828] flex items-center gap-2">
              <SvgIcon
                name="organization"
                width="18"
                height="18"
              />
              <span>
                企业卡片
                <span class="text-gray-400 text-[16px] italic">
                  {{ totalCount }}
                </span>
              </span>
            </h3>
            <div class="flex justify-end gap-[16px] items-center mt-[16px] md:flex-col md:pl-0 md:mr-0">
              <!-- 搜索框 -->
              <div class="px-[16px] md:px-0 md:w-[222px]">
                <div class="px-[16px] md:px-0 search-model-input md:border-0 py-[12px] border-[#F2F2F2] css-custom-input">
                  <el-input 
                    v-model="query.search"
                    :prefix-icon="Search"
                    @change="handleKeywordChange"
                    type="text" 
                    :placeholder="$t('organization.collection.search_placeholder')"
                  />
                </div>
              </div>
              <!-- 新建组织 -->
              <div>
                <a
                  href="/organizations/new"
                  class="btn-orange px-[24px] py-[8px] rounded-[4px] text-[14px] font-[400] leading-[20px] text-[#FFFFFFE6] cursor-pointer"
                >
                  {{ $t("organization.collection.create_organization") }}
                </a>
              </div>
            </div>
          
            <div class="md:border-b md:pb-[12px]" v-if="mobileScreen">
              <template>
                <span
                  :class="`${org_type_drawer ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
                  @click="org_type_drawer = true"
                >
                组织类型
                </span>
                <span
                  :class="`${industry_drawer ? ' active-btn' : ''} mr-[4px] h-[22px] font-[400] px-[12px] py-[4px] cursor-pointer hover-active-btn`"
                  @click="industry_drawer = true"
                >
                组织行业
                </span>
                <!-- 组织类型 -->
                <el-drawer v-model="org_type_drawer" direction="btt" :with-header="false">
                  <div class="flex flex-col gap-[8px]">
                    <div class="text-[12px] font-[400] leading-[20px] text-[#909399]">
                      {{ $t("organization.collection.org_type") }}
                    </div>
                    <div class="flex gap-[8px] flex-wrap">
                      <div 
                        :class="`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${query.org_type == org_type ? 'active-org-tag-btn' : ''}`"
                        v-for="org_type in org_types" 
                        :key="org_type"
                        @click="handleOrgTypeChange(org_type)"
                      >
                        {{ org_type }}
                      </div>
                    </div>
                  </div>
                </el-drawer>
                <!-- 组织行业 -->
                <el-drawer v-model="industry_drawer" direction="btt" :with-header="false">
                  <div class="text-[12px] font-[400] leading-[20px] text-[#909399]">
                      {{ $t("organization.collection.industry") }}
                    </div>
                    <div class="flex gap-[8px] flex-wrap">
                      <div 
                        :class="`flex gap-[4px] border border-[1px] border-[#D0D5DD] px-[8px] py-[3px] rounded-[4px] text-[#344054] text-[12px] font-[400] leading-[18px] cursor-pointer ${query.industry == industry ? 'active-org-tag-btn' : ''}`"
                        v-for="industry in industries" 
                        :key="industry"
                        @click="handleIndustryChange(industry)"
                      >
                        <img :src="`/images/xty_images/organization/collection/${industry}.svg`" alt="">
                        {{ industry }}
                      </div>
                    </div>
                </el-drawer>
                <!-- 手机端 -->
              </template>
            </div>

          </div>
          <!-- 组织列表 -->
          <div class="grid grid-cols-2 md:grid-cols-1 gap-[16px] pl-[24px] md:pl-0 py-[24px]">
            <template v-for="item in items" :key="item.id">
              <OrganizationCard :item="item" />
            </template>
          </div>

          <div class="pt-[20px] flex justify-center">
            <CsgPagination :perPage="query.per" :currentPage="query.page" @currentChange="fetchItem" :total="totalCount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { Search } from '@element-plus/icons-vue'
</script>
<script>
  import { organizationSearch } from '../../../../api/jwt/organization'
  import OrganizationCard from './OrganizationCard.vue';
  import CsgPagination from '../shared/CsgPagination.vue'
  export default {
    props: {
      industries: {
        type: Array,
      },
      org_types: {
        type: Array,
      },
    },
    components: {
      OrganizationCard,
      CsgPagination,
    },
    data() {
      return {
        items: [],
        totalCount: 0,
        org_type_drawer: false,
        industry_drawer: false,
        query: {
          industry: "",
          org_type: "",
          page: 1,
          search: "",
          per: 10,
        },
        mobileScreen: window.innerWidth < 768,
      };
    },
    mounted() {
      this.fetchItem();
      window.addEventListener("resize", this.adapterScreenWidth);
    },
    methods: {
      adapterScreenWidth() {
        this.mobileScreen = window.innerWidth < 768;
      },
      handleIndustryChange(industry) {
        if (this.query.industry == industry) {
          this.query.industry = "";
        } else {
          this.query.industry = industry;
        }
        this.fetchItem();
      },
      handleOrgTypeChange(org_type) {
        if (this.query.org_type == org_type) {
          this.query.org_type = "";
        } else {
          this.query.org_type = org_type;
        }
        this.fetchItem();
      },
      handleKeywordChange() {
        this.fetchItem();
      },
      fetchItem(page) {
        organizationSearch({
          page: page ? page : this.query.page,
          per: this.query.per,
          search: this.query.search,
          industry: this.query.industry,
          org_type: this.query.org_type,
        }).then((res) => {
          this.items = res.data.data;
          this.totalCount = res.data.total;
        });
      }
    },
  };
</script>
