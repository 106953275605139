import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "m-auto w-[583px] md:w-full md:px-[20px]" }
const _hoisted_3 = { class: "py-[96px] text-center" }
const _hoisted_4 = { class: "flex gap-[8px] w-full my-[16px]" }
const _hoisted_5 = { class: "w-full flex gap-[4px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-[20px] font-[500] leading-[30px]" }, " 发布需求 ", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${$data.step === 1 ? 'issue-start-active-bar' : 'issue-start-inactive-bar'}`),
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.prevStep && $options.prevStep(...args)))
          }, " 基本信息 ", 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(`flex justify-center w-full cursor-pointer py-[8px] text-[16px] bg-round ${$data.step === 1 ? 'issue-end-inactive-bar' : 'issue-end-active-bar'}`),
            onClick: _cache[1] || (_cache[1] = (...args) => ($options.nextStep && $options.nextStep(...args)))
          }, " 预算与要求 ", 2 /* CLASS */)
        ]),
        _createVNode(_component_el_form, {
          ref: "dataForm",
          model: $data.dataForm,
          rules: $data.rules,
          "label-position": "top",
          class: "text-left"
        }, {
          default: _withCtx(() => [
            ($data.step === 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_form_item, {
                    label: "项目名称",
                    prop: "project_name"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $data.dataForm.project_name,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.project_name) = $event)),
                        placeholder: "请输入项目名称"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "需求类型",
                    prop: "kind"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $data.dataForm.kind,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.dataForm.kind) = $event)),
                        placeholder: "选择需求类型"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kinds, (kind) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: kind.value,
                              label: kind.label,
                              value: kind.value
                            }, null, 8 /* PROPS */, ["label", "value"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "组织名称",
                    prop: "org_path"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $data.dataForm.org_path,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.dataForm.org_path) = $event)),
                        placeholder: "选择组织"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.organizations, (org) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: org.path,
                              label: org.name,
                              value: org.path
                            }, null, 8 /* PROPS */, ["label", "value"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "交付日期",
                    prop: "delivery_at"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_date_picker, {
                        modelValue: $data.dataForm.delivery_at,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.dataForm.delivery_at) = $event)),
                        type: "date",
                        style: {"width":"100%"},
                        placeholder: "选择交付日期"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "所属行业",
                    prop: "industry_list"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $data.dataForm.industry_list,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.dataForm.industry_list) = $event)),
                        multiple: "",
                        filterable: "",
                        "reserve-keyword": false,
                        "default-first-option": "",
                        placeholder: _ctx.$t('all.pleaseSelect', { value: '行业' }),
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.industries, (value) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: value,
                              label: value,
                              value: value
                            }, null, 8 /* PROPS */, ["label", "value"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue", "placeholder"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "联系人",
                    prop: "contact_name"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $data.dataForm.contact_name,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.dataForm.contact_name) = $event)),
                        placeholder: "请输入联系人"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "联系电话",
                    prop: "contact_phone"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $data.dataForm.contact_phone,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.dataForm.contact_phone) = $event)),
                        placeholder: "请输入联系电话"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "联系邮箱",
                    prop: "contact_email"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $data.dataForm.contact_email,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.dataForm.contact_email) = $event)),
                        placeholder: "请输入联系邮箱"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "需求描述",
                    prop: "description"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.description,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.dataForm.description) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "需求预期",
                    prop: "expect_usage"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.expect_usage,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.dataForm.expect_usage) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        class: "w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]",
                        onClick: $options.nextStep
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode("下一步")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            ($data.step === 2)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_el_form_item, {
                    label: "预算",
                    class: "append-input",
                    prop: "budget"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $data.dataForm.budget,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.dataForm.budget) = $event)),
                        placeholder: "请输入预算 0 为面议"
                      }, {
                        append: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("万")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "数据准备",
                    prop: "data"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.data,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($data.dataForm.data) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "算法准备",
                    prop: "algorithm"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.algorithm,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.dataForm.algorithm) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "算力准备",
                    prop: "evaluation_data"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.evaluation_data,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.dataForm.evaluation_data) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "验收要求",
                    prop: "performance_metrics"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        rows: 5,
                        type: "textarea",
                        modelValue: $data.dataForm.performance_metrics,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.dataForm.performance_metrics) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_button, {
                          class: "w-full text-center !h-[48px] !w-[116px] !text-[#344054] !text-[16px] bg-[#FF5005] !rounded-[8px] !border-[1px]",
                          onClick: $options.prevStep
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("上一步")
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"]),
                        _createVNode(_component_el_button, {
                          class: "w-full text-center !h-[48px] !text-[16px] !text-white !bg-[#FF5005] btn-orange !rounded-[8px] !border-[1px] !border-[#FF5005]",
                          onClick: $options.handleSubmit
                        }, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("提交")
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["onClick"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ])
  ]))
}