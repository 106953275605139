import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_upload_filled = _resolveComponent("upload-filled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      model: $setup.dataForm,
      "label-position": "top",
      "label-width": "auto",
      style: {"max-width":"600px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $setup.dataForm.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dataForm.name) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, { label: "Upload Email List (Only CSV supported)" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_upload, {
              "file-list": $setup.dataForm.emailList,
              "onUpdate:fileList": _cache[1] || (_cache[1] = $event => (($setup.dataForm.emailList) = $event)),
              class: "upload-demo w-[600px]",
              "auto-upload": false,
              drag: "",
              limit: 1,
              accept: "text/csv"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_upload_filled)
                  ]),
                  _: 1 /* STABLE */
                }),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "el-upload__text" }, [
                  _createTextVNode(" Drop file here or "),
                  _createElementVNode("em", null, "click to upload")
                ], -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["file-list"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.createEmailGroup
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Create")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model"]),
    _createCommentVNode(" email group list "),
    _createVNode(_component_el_table, {
      stripe: true,
      data: $setup.emailGroups,
      style: {"width":"100%"},
      class: "mt-[40px]"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "Name"
        }),
        _createVNode(_component_el_table_column, {
          prop: "email_list",
          label: "EmailList"
        }),
        _createVNode(_component_el_table_column, {
          prop: "created_at",
          label: "Created At"
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data"]),
    _createVNode(_component_el_pagination, {
      "current-page": $setup.currentPage,
      "onUpdate:currentPage": _cache[2] || (_cache[2] = $event => (($setup.currentPage) = $event)),
      "page-size": $setup.per,
      layout: "prev, pager, next",
      total: $setup.total,
      onCurrentChange: $setup.refreshEmailGroups,
      class: "my-[52px] flex justify-center"
    }, null, 8 /* PROPS */, ["current-page", "page-size", "total"])
  ], 64 /* STABLE_FRAGMENT */))
}