<template>
  <div class="text-[28px] font-[600] leading-[28px] text-center">
    平台最新数据
  </div>
  <div class="flex gap-[32px] flex-col pt-[60px]">
    <div class="flex md:flex-col gap-[32px] px-[64px] rounded-[16px] justify-between">
      <div class="w-[248px] pb-[27px] border-b border-[#7caef8] md:w-full flex flex-col gap-[4px]" v-for="item in tech_stats.slice(0, 4)" :key="item.name">
        <div class="text-[36px] font-[400] text-center">
          {{ item.value }}
        </div>
        <div class="text-[14px] font-[400] text-center">
          {{ item.label }}
        </div>
      </div>
    </div>

    <div class="flex md:flex-col gap-[32px] px-[64px] rounded-[16px] justify-between">
      <div class="w-[340px] pb-[27px] border-b border-[#7caef8] md:w-full flex flex-col gap-[4px]" v-for="item in tech_stats.slice(-3)" :key="item.name">
        <div class="text-[36px] font-[400] text-center">
          {{ item.value }}
        </div>
        <div class="text-[14px] font-[400] text-center">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      tech_stats: {
        type: Object,
        default: () => {
          return []
        }
      }
    },
  }
</script>
