import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "text-[16px] font-medium text-gray-900" }
const _hoisted_3 = { class: "space-y-2 mt-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex items-center justify-between mb-1" }
const _hoisted_6 = { class: "flex items-center gap-2 flex-shrink-0" }
const _hoisted_7 = {
  key: 0,
  class: "px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-600"
}
const _hoisted_8 = { class: "flex items-center justify-between text-xs text-gray-500" }
const _hoisted_9 = { class: "flex items-center gap-3" }
const _hoisted_10 = { class: "flex items-center gap-1" }
const _hoisted_11 = { class: "flex items-center gap-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowDown = _resolveComponent("ArrowDown")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_View = _resolveComponent("View")
  const _component_ChatLineSquare = _resolveComponent("ChatLineSquare")

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.typeOrder, (type) => {
    return (_openBlock(), _createElementBlock("div", {
      key: type,
      class: "mb-4"
    }, [
      ($props.groupedDocs[type]?.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: "flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-50",
              onClick: $event => ($options.toggleCategory(type))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createTextVNode(_toDisplayString($data.docTypes[type]) + " ", 1 /* TEXT */),
                _createElementVNode("span", {
                  class: _normalizeClass(["ml-1 text-gray-400 text-sm", { 'text-[#FF5005]': $props.searchKeyword && ($props.groupedDocs[type]?.length || 0) > 0 }])
                }, "(" + _toDisplayString($props.groupedDocs[type]?.length || 0) + ")", 3 /* TEXT, CLASS */)
              ]),
              _createVNode(_component_el_icon, {
                class: _normalizeClass(["text-gray-400 transition-transform duration-300", { 
            'rotate-180': $props.expandedCategories[type],
            'text-[#FF5005]': $props.searchKeyword && ($props.groupedDocs[type]?.length || 0) > 0 
          }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ArrowDown)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])
            ], 8 /* PROPS */, _hoisted_1),
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(($props.groupedDocs[type] || []), (doc) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: doc.uuid,
                  onClick: $event => ($options.selectDoc(doc)),
                  class: _normalizeClass(["doc-card ml-2", [
            'p-3 rounded-lg cursor-pointer text-[14px]',
            'border transition-all duration-200',
            $options.getCardClasses(doc)
          ]])
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", {
                      class: _normalizeClass([
              'font-medium line-clamp-1 text-gray-900',
              $props.selectedDoc?.uuid === doc.uuid ? $options.getTextColorClass(doc.doc_type) : ''
            ])
                    }, _toDisplayString(doc.title), 3 /* TEXT, CLASS */),
                    _createElementVNode("div", _hoisted_6, [
                      (doc.status !== 'published')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "草稿"))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", null, "更新于 " + _toDisplayString($options.formatDate(doc.updated_at)), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_View)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createTextVNode(" " + _toDisplayString(doc.views_count || 0), 1 /* TEXT */)
                      ]),
                      _createElementVNode("span", _hoisted_11, [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ChatLineSquare)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createTextVNode(" " + _toDisplayString(doc.comments_count || 0), 1 /* TEXT */)
                      ])
                    ])
                  ])
                ], 10 /* CLASS, PROPS */, _hoisted_4))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */), [
              [_vShow, $props.expandedCategories[type]]
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}