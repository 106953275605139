<template>
  <div>
    <el-card>
      <template #header>
        <div class="card-header">
          <span>Code Detail</span>
        </div>
      </template>
      <ul class="max-w-[480px]">
        <li class="flex justify-between mb-4">
          <label>Name</label>
          <p>{{ code.name }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Owner</label>
          <p>{{ route.params.namespace }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Creator</label>
          <p>{{ code.user?.username || '' }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Visibility</label>
          <p>{{ code.private == false ? 'public' : 'private' }}</p>
        </li>
        <li class="flex justify-between mb-4">
          <label>Created At</label>
          <p>{{ dayjs(code.created_at).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </li>
      </ul>
      <template #footer>
        <div class="flex gap-[16px]">
          <div class="card-footer">
            <el-button @click="redirectCodeEdit" class="w-[100px]">编辑</el-button>
          </div>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { useRoute } from 'vue-router'
  import dayjs from "dayjs"
  import { ElMessage } from 'element-plus'
  import useFetchApi from '../../../packs/useFetchApi'

  const route = useRoute()
  const code = ref({})

  const fetchCode = async () => {
    const { data, error } = await useFetchApi(`/codes/${route.params.namespace}/${route.params.name}`).json()
    if (data.value) {
      const res_json = data.value
      code.value = res_json.data
    } else {
      ElMessage.error(error.value.msg || 'Failed to fetch code')
    }
  }

  const redirectCodeEdit = () => {
    window.location.href = `/new_admin/codes/${route.params.namespace}/${route.params.name}/edit`
  }

  onMounted(() => {
    fetchCode()
  })
</script>