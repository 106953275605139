<template>
  <div class="w-[full] px-[90px] overflow-x-scroll m-auto py-[94px] flex flex-col gap-[24px]">
    <template v-for="times in range" :key="times">
      <div class="flex flex-nowrap gap-[24px]">
        <template v-for="(firstItem, index) in this.data.bulletins" :key="firstItem.label">
          <template v-if="index % this.data.firstLevelRowCount === times">
            <FirstLevel
              :firstItem="firstItem"
              :secondLevelRowCount="this.data.secondLevelRowCount"
              :thirdLevelRowCount="this.data.thirdLevelRowCount"
            >
            </FirstLevel>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import FirstLevel from './FirstLevel.vue'
  export default {
    components: {
      FirstLevel
    },
    props: {
      data: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      range() {
        return Array.from({ length: this.data.firstLevelRowCount }, (v, k) => k);
      }
    }
  }
</script>