import request from './request'

// 获取当前用户的详情数据
export function currentUserDetail(username) {
  return request({
    url: `/api/v1/user/${username}`,
    method: 'get',
  })
}

// 获取用户详情
export function userDetail(username) {
  return request({
    url: `/api/v1/user/${username}`,
    method: 'get',
  })
}
