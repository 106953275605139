import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4 absolute top-0 right-0 md:relative md:pl-5 md:pb-4 z-10 md:hidden" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { class: "rounded-t border-t border-x border-[#EBEEF5] mt-4" }
const _hoisted_5 = { class: "flex items-center px-3 py-2 bg-[#F5F7FA] text-[#303133]" }
const _hoisted_6 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }
const _hoisted_7 = { class: "flex gap-[8px] text-[14px] leading-[20px] text-[#667085]" }
const _hoisted_8 = {
  key: 0,
  class: "mb-[4px]"
}
const _hoisted_9 = { class: "text-[#909399]" }
const _hoisted_10 = { class: "text-[#909399]" }
const _hoisted_11 = { class: "flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all" }
const _hoisted_12 = { class: "flex gap-[8px] text-[14px] leading-[20px] text-[#667085] mb-[8px]" }
const _hoisted_13 = { class: "text-[#909399]" }
const _hoisted_14 = { class: "text-[#909399]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_arrow_down = _resolveComponent("arrow-down")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!$setup.ptMetadata?.metadata?.data?.stuts == 1)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] mx-[16px] cursor-pointer hover:bg-[#F9FAFB]",
          onClick: $setup.downloadBtLink
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            class: "w-[16px] h-[16px]",
            src: "/images/xty_images/common/magnet.svg",
            alt: ""
          }, null, -1 /* HOISTED */),
          _createElementVNode("div", { class: "ml-2 text-[#606266] text-sm font-medium" }, "BT 下载", -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" multi-source sync button "),
    ($setup.showSyncButton)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "default",
          class: "!rounded-lg",
          disabled: $setup.syncInprogress,
          onClick: $setup.handleSyncRepo
        }, {
          default: _withCtx(() => [
            _createVNode($setup["SvgIcon"], {
              name: "sync",
              class: "mr-2"
            }),
            _createTextVNode(" " + _toDisplayString($setup.syncInprogress ? _ctx.$t("repo.source.syncing") : _ctx.$t("repo.source.syncButton")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" endpoint deploy button "),
    _createCommentVNode(" <DeployDropdown\r\n      v-if=\"isLoggedIn && repoType === 'model' && enableEndpoint && !!httpCloneUrl\"\r\n      :modelId=\"namespacePath\"\r\n    /> "),
    (!$setup.isLoggedIn && $props.repoType === 'model' && $props.enableEndpoint && !!$setup.httpCloneUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "default",
            class: "!rounded-lg",
            onClick: $setup.toLoginPage
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("all.deploy")) + " ", 1 /* TEXT */),
              _createVNode(_component_el_icon, { class: "ml-1 el-icon--right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_down)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" finetune deploy button "),
    ($props.repoType === 'model' && $props.enableFinetune && !!$setup.httpCloneUrl)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "flex px-[12px] py-[5px] mr-4 justify-center items-center gap-1 rounded-lg bg-[#FFF] border border-[#D0D5DD] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer",
          onClick: $setup.handleButtonClick
        }, [
          _createVNode($setup["SvgIcon"], {
            name: "model_finetune_create",
            class: "mr-1"
          }),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('finetune.title')), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" repo download clone button "),
    (!!$setup.httpCloneUrl)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "rounded border border-[#DCDFE6] flex items-center justify-center px-4 py-[5px] cursor-pointer hover:bg-[#F9FAFB]",
          onClick: _cache[0] || (_cache[0] = $event => ($setup.cloneRepositoryVisible = true))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("svg", {
            class: "mr-1",
            xmlns: "http://www.w3.org/2000/svg",
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none"
          }, [
            _createElementVNode("rect", {
              opacity: "0.12",
              x: "1.3335",
              y: "1.3335",
              width: "13.3333",
              height: "13.3333",
              rx: "3.33333",
              fill: "#2F384C"
            }),
            _createElementVNode("path", {
              d: "M6.66683 5.3335L4.00016 8.00016L6.66683 10.6668M9.3335 5.3335L12.0002 8.00016L9.3335 10.6668M6.66682 14.6668H9.3335C11.2003 14.6668 12.1338 14.6668 12.8468 14.3035C13.474 13.9839 13.9839 13.474 14.3035 12.8468C14.6668 12.1338 14.6668 11.2003 14.6668 9.3335V6.66683C14.6668 4.79999 14.6668 3.86657 14.3035 3.15353C13.9839 2.52632 13.474 2.01639 12.8468 1.69681C12.1338 1.3335 11.2003 1.3335 9.3335 1.3335H6.66683C4.79999 1.3335 3.86657 1.3335 3.15353 1.69681C2.52632 2.01639 2.01639 2.52632 1.69681 3.15353C1.3335 3.86657 1.3335 4.79999 1.3335 6.66682V9.3335C1.3335 11.2003 1.3335 12.1338 1.69681 12.8468C2.01639 13.474 2.52632 13.9839 3.15353 14.3035C3.86657 14.6668 4.79999 14.6668 6.66682 14.6668Z",
              stroke: "#2F384C",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1 /* HOISTED */),
          _createElementVNode("div", { class: "ml-2 text-[#606266] text-sm font-medium" }, "Git 下载", -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" clone dialog "),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.cloneRepositoryVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.cloneRepositoryVisible) = $event)),
      title: "",
      class: "md:!w-[80%]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["SvgIcon"], {
              name: "clone",
              class: "mr-1"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t($setup.downloadModalKey)), 1 /* TEXT */)
          ])
        ]),
        _createVNode(_component_el_tabs, {
          modelValue: $setup.activeCloneType,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.activeCloneType) = $event)),
          class: "border border-[#EBEEF5] mb-8 clone-tabs"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "HTTPS",
              name: "https"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode($setup["SvgIcon"], {
                      name: "exclamation_point",
                      width: "13",
                      height: "13",
                      class: "cursor-pointer"
                    }),
                    _cache[6] || (_cache[6] = _createTextVNode(" Use ")),
                    _cache[7] || (_cache[7] = _createElementVNode("a", {
                      href: "/settings/git-token",
                      target: "_blank",
                      class: "underline"
                    }, "access token", -1 /* HOISTED */)),
                    _cache[8] || (_cache[8] = _createTextVNode(" as git password/credential "))
                  ]),
                  ($setup.currentUser)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: $setup.useToken,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.useToken) = $event)),
                          label: _ctx.$t('application_spaces.gradioGuide.useToken'),
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "label"])
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_9, "# " + _toDisplayString(_ctx.$t('all.lfsTips')), 1 /* TEXT */),
                  ($setup.useToken)
                    ? (_openBlock(), _createBlock($setup["MarkdownViewer"], {
                        key: 1,
                        content: $setup.httpsCloneCodeWithTokenMarkdown
                      }, null, 8 /* PROPS */, ["content"]))
                    : (_openBlock(), _createBlock($setup["MarkdownViewer"], {
                        key: 2,
                        content: $setup.httpsCloneCodeMarkdown
                      }, null, 8 /* PROPS */, ["content"])),
                  _createElementVNode("div", _hoisted_10, "# " + _toDisplayString(_ctx.$t('all.lfsTips2')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], {
                    content: $setup.getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')
                  }, null, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_tab_pane, {
              label: "SSH",
              name: "ssh"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode($setup["SvgIcon"], {
                      name: "exclamation_point",
                      width: "13",
                      height: "13",
                      class: "cursor-pointer"
                    }),
                    _cache[9] || (_cache[9] = _createElementVNode("a", {
                      href: "/settings/ssh-keys",
                      target: "_blank",
                      class: "underline"
                    }, "Add your SSH public key", -1 /* HOISTED */))
                  ]),
                  _createElementVNode("div", _hoisted_13, "# " + _toDisplayString(_ctx.$t('all.lfsTips')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], { content: $setup.sshCloneCodeMarkdown }, null, 8 /* PROPS */, ["content"]),
                  _createElementVNode("div", _hoisted_14, "# " + _toDisplayString(_ctx.$t('all.lfsTips2')), 1 /* TEXT */),
                  _createVNode($setup["MarkdownViewer"], {
                    content: $setup.getMarkdownCode('  GIT_LFS_SKIP_SMUDGE=1', 'bash')
                  }, null, 8 /* PROPS */, ["content"])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" <el-tab-pane\r\n          v-if=\"repoType == 'model' || repoType == 'dataset'\"\r\n          label=\"SDK\"\r\n          name=\"sdk\"\r\n        >\r\n          <div\r\n            class=\"flex flex-col gap-1 px-3 py-2 border-t border-[#EBEEF5] bg-[#ffffff] text-[#303133] break-all\"\r\n          >\r\n          <div class=\"flex gap-[8px] text-[14px] leading-[20px] text-[#667085]\">\r\n            <SvgIcon name=\"exclamation_point\" width=\"13\" height=\"13\" class=\"cursor-pointer\" />\r\n            Use\r\n            <a href=\"https://github.com/CAICTs/csghub-sdk\" target=\"_blank\" class=\"underline\"> SDK </a>\r\n            to download\r\n            </div>\r\n            <div class=\"text-[#909399] mt-[8px]\"># {{ $t('all.sdkTips') }}</div>\r\n            <markdown-viewer\r\n              :content=\"cmdCloneCodeMarkdown\"\r\n            ></markdown-viewer>\r\n          </div>\r\n        </el-tab-pane> ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}