<template>
  <div class="flex max-w-[1440px] m-auto">
    <div v-if="!mobileScreen">
      <TagSidebar
        :taskTags="props.taskTags"
        :frameworkTags="props.frameworkTags"
        :licenseTags="props.licenseTags"
        @resetTags="resetTags"
        type="model"
      />
    </div>
    <div class="w-full">
      <div class="px-[16px] search-model-input md:border-0 border-b py-[12px] border-[#F2F2F2] css-custom-input">
        <el-input
          v-model="nameFilterInput"
          size="large"
          :prefix-icon="Search"
          placeholder="搜索模型名称"
          @change = "reloadModels"
        />
      </div>
      <div v-if="mobileScreen">
        <MobileTagSidebar
          :taskTags="props.taskTags"
          :frameworkTags="props.frameworkTags"
          :licenseTags="props.licenseTags"
          @resetTags="resetTags"
          type="model"
        />
      </div>
      <div class="flex w-full flex-wrap justify-between md:gap-[12px] gap-[16px] px-[16px] py-[12px] md:bg-[#F7F7F7] bg-unset">
        <model-item v-for="model in modelsData" :model="model" />
      </div>
      <div v-show="totalModels > perPage">
        <el-pagination background
                      v-model:current-page="currentPage"
                      :default-current-page=1
                      :total="totalModels"
                      :page-size="16"
                      layout="prev, pager, next"
                      @update:current-page="reloadModels"
                      class="my-[52px] flex justify-center"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref, inject } from 'vue';
  import { Search } from '@element-plus/icons-vue'
  import { ElInput, ElMessage } from 'element-plus'
  import ModelItem from './ModelItem.vue'
  import TagSidebar from '../tags/TagSidebar.vue';
  import MobileTagSidebar from '../tags/MobileTagSidebar.vue';

  const csghubServer = inject('csghubServer')

  const props = defineProps({
    taskTags: String,
    frameworkTags: String,
    licenseTags: String
  })

  const nameFilterInput = ref('')
  const sortSelection = ref('recently_update')
  const currentPage = ref(1)
  const perPage = ref(16)
  const totalModels = ref(0)
  const taskTag = ref('')
  const frameworkTag = ref('')
  const licenseTag = ref('')
  const modelsData = ref(Array)
  const drawer = ref(false)
  const sortOptions = [
                        {
                          value: 'recently_update',
                          label: '最近更新'
                        },
                        {
                          value: 'trending',
                          label: '热门'
                        },
                        {
                          value: 'most_download',
                          label: '最多下载'
                        },
                        {
                          value: 'most_favorite',
                          label: '最多喜欢'
                        },
                      ]

  const mobileScreen = ref(
    window.innerWidth < 768
  );

  const adapterScreenWidth = () => {
    mobileScreen.value = window.innerWidth < 768;
  };

  const onWindowResize = onMounted(() => {
    window.addEventListener("resize", adapterScreenWidth);
  });
  const resetTags = (task, framework, license) => {
    taskTag.value = task
    frameworkTag.value = framework
    licenseTag.value = license
    reloadModels()
  }

  const reloadModels = (childCurrent) => {
    let url = `${csghubServer}/api/v1/${props.repoType}s`
    url = url + `?page=${childCurrent ? childCurrent : currentPage.value}`
    url = url + `&per=${perPage.value}`
    url = url + `&search=${nameFilterInput.value}`
    url = url + `&sort=${sortSelection.value}`
    url = url + `&task_tag=${taskTag.value}`
    url = url + `&framework_tag=${frameworkTag.value}`
    url = url + `&language_tag=${languageTag.value}`
    url = url + `&license_tag=${licenseTag.value}`
    url = url + `&source=${sourceSelection.value === 'all' ? '' : sourceSelection.value}`

    loadModels(url)
  }

  async function loadModels(url) {
    const response = await fetch(url);

    if (!response.ok) {
      ElMessage({
        message: "加载模型数据报错",
        type: 'warning'
      })
    } else {
      response.json().then((data) => {
        modelsData.value = data['models']
        totalModels.value = data['total']
      })
    }
  }

  onMounted(() => {
    reloadModels()
  })
</script>