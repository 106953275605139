<template>
  <div 
    :id="`comment-${comment.id}`" 
    class="comment-item mb-6 p-4 rounded-lg transition-all duration-200"
    :class="[
      isRoot ? 'bg-white border border-gray-100 hover:border-gray-200 shadow-sm' : 
      'bg-[#F7F8FA] hover:bg-[#F0F2F5]',
      { 'highlight-animation': isHighlighted }
    ]"
    :style="computedStyle"
  >
    <div class="flex">
      <vote-buttons 
        v-if="!comment.deleted"
        :votes-count="comment.votes_count"
        :user-vote="comment.user_vote"
        @vote="handleVote"
      />
      <div v-else class="mr-3" style="min-width: 40px"></div>

      <div class="flex-1">
        <div class="flex gap-3">
          <el-avatar 
            :src="userDetails[comment.user_name]?.avatar" 
            :size="size" 
            class="cursor-pointer"
            @click="$emit('profile-click', comment.user_name)"
          />
          <div class="flex-1">
            <comment-header
              :comment="comment"
              :user-details="userDetails"
              :parent-preview="parentPreview"
              @profile-click="$emit('profile-click', $event)"
              @parent-click="$emit('parent-click', $event)"
            />
            
            <div class="text-[14px] mb-2" :class="{ 'text-[#86909C] italic': comment.deleted, 'text-[#1D2129]': !comment.deleted }">
              {{ comment.deleted ? '该评论已删除' : comment.content }}
            </div>
            
            <comment-actions
              v-if="!comment.deleted"
              :comment="comment"
              :current-user="currentUser"
              @reply="$emit('reply', comment)"
              @edit="$emit('edit', comment)"
              @delete="$emit('delete', comment.id)"
            />

            <comment-editor
              v-if="editing"
              v-model="editContent"
              :loading="loading"
              @cancel="$emit('edit-cancel')"
              @submit="$emit('edit-submit', { id: comment.id, content: editContent })"
            />

            <comment-editor
              v-if="replying"
              v-model="replyContent"
              :loading="loading"
              :placeholder="replyPlaceholder"
              @cancel="$emit('reply-cancel')"
              @submit="$emit('reply-submit', { parentId: comment.id, content: replyContent })"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VoteButtons from './VoteButtons.vue'
import CommentHeader from './CommentHeader.vue'
import CommentActions from './CommentActions.vue'
import CommentEditor from './CommentEditor.vue'

export default {
  name: 'CommentItem',
  components: {
    VoteButtons,
    CommentHeader,
    CommentActions,
    CommentEditor
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    userDetails: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    editing: Boolean,
    replying: Boolean,
    loading: Boolean,
    size: {
      type: Number,
      default: 40
    },
    highlightedId: {
      type: [Number, String],
      default: null
    },
    parentPreview: {
      type: String,
      default: '原评论'
    }
  },
  data() {
    return {
      editContent: this.comment.content,
      replyContent: '',
      isHighlighted: false
    }
  },
  computed: {
    isRoot() {
      return !this.comment.parent_id
    },
    computedStyle() {
      if (this.isRoot) return {}
      const marginLeft = Math.min(this.comment.depth, 5) * 20
      return { marginLeft: `${marginLeft}px` }
    },
    replyPlaceholder() {
      return `回复 @${this.comment.user_name}`
    }
  },
  watch: {
    highlightedId: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.toString() === this.comment.id.toString()) {
          this.highlight()
        }
      }
    }
  },
  methods: {
    handleVote(type) {
      this.$emit('vote', { id: this.comment.id, type })
    },
    highlight() {
      this.isHighlighted = true
      setTimeout(() => {
        this.isHighlighted = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
.comment-item {
  position: relative;
}

.comment-item:hover {
  transform: translateY(-1px);
  transition: all 0.2s ease-in-out;
}

@keyframes highlight {
  0% {
    background-color: rgba(255, 80, 5, 0.1);
    border-color: #FF5005;
  }
  50% {
    background-color: rgba(255, 80, 5, 0.2);
    border-color: #FF5005;
  }
  100% {
    background-color: transparent;
    border-color: transparent;
  }
}

.highlight-animation {
  animation: highlight 2s ease-out;
  border: 1px solid transparent;
}
</style>