import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: "/images/xty_home/computing_scheduling_of_pc.png",
      alt: "",
      class: "w-full md:hidden"
    }, null, -1 /* HOISTED */),
    _createElementVNode("img", {
      src: "/images/xty_home/computing_scheduling_of_mobile.png",
      alt: "",
      class: "w-full hidden md:flex"
    }, null, -1 /* HOISTED */)
  ])))
}