import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 z-50 flex items-center justify-center"
}
const _hoisted_2 = { class: "relative bg-white rounded-lg p-6 max-w-md w-full mx-4" }
const _hoisted_3 = { class: "flex justify-end mt-6" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.openAnnouncement && $options.openAnnouncement(...args))),
      class: "fixed bottom-6 right-6 z-50 flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 hover:scale-105 animate-bounce-gentle group"
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("svg", {
        class: "w-5 h-5 animate-pulse",
        fill: "none",
        stroke: "currentColor",
        viewBox: "0 0 24 24"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
          d: "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        })
      ], -1 /* HOISTED */),
      _createTextVNode(" 系统公告 "),
      _createElementVNode("span", { class: "absolute -top-10 right-0 bg-gray-800 text-white text-sm px-3 py-1 rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200" }, " 遇到页面问题？点击这里查看解决方案 ", -1 /* HOISTED */)
    ]), 512 /* NEED_PATCH */), [
      [_vShow, !$data.isOpen]
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "transition duration-300 ease-out",
      "enter-from-class": "transform scale-95 opacity-0",
      "enter-to-class": "transform scale-100 opacity-100",
      "leave-active-class": "transition duration-200 ease-in",
      "leave-from-class": "transform scale-100 opacity-100",
      "leave-to-class": "transform scale-95 opacity-0"
    }, {
      default: _withCtx(() => [
        ($data.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createCommentVNode(" 背景遮罩 "),
              _createVNode(_Transition, {
                "enter-active-class": "transition-opacity duration-300",
                "enter-from-class": "opacity-0",
                "enter-to-class": "opacity-100",
                "leave-active-class": "transition-opacity duration-200",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "absolute inset-0 bg-black bg-opacity-50",
                    onClick: _cache[1] || (_cache[1] = (...args) => ($options.closeAnnouncement && $options.closeAnnouncement(...args)))
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", _hoisted_2, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-xl font-medium mb-4" }, "系统公告", -1 /* HOISTED */)),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-gray-600" }, [
                  _createElementVNode("p", { class: "mb-4" }, "尊敬的用户，欢迎来到鲸智社区。如遇到页面无法正常显示，请尝试清除本站Cookie："),
                  _createElementVNode("div", { class: "bg-gray-50 p-4 rounded-lg space-y-4" }, [
                    _createElementVNode("div", { class: "flex items-start gap-2" }, [
                      _createElementVNode("div", { class: "bg-blue-100 rounded-full p-2 flex-shrink-0" }, [
                        _createElementVNode("span", { class: "text-blue-600 font-bold" }, "1")
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(" 按键盘快捷键： "),
                        _createElementVNode("div", { class: "mt-1 p-2 bg-white rounded border border-gray-200" }, [
                          _createElementVNode("span", { class: "font-medium" }, "Windows:"),
                          _createTextVNode(" Ctrl + Shift + Delete"),
                          _createElementVNode("br"),
                          _createElementVNode("span", { class: "font-medium" }, "Mac:"),
                          _createTextVNode(" Command + Shift + Delete ")
                        ])
                      ])
                    ]),
                    _createElementVNode("div", { class: "flex items-center gap-2" }, [
                      _createElementVNode("div", { class: "bg-blue-100 rounded-full p-2 flex-shrink-0" }, [
                        _createElementVNode("span", { class: "text-blue-600 font-bold" }, "2")
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(" 在弹出的清除浏览数据窗口中： "),
                        _createElementVNode("ul", { class: "mt-1 ml-4 list-disc text-gray-500" }, [
                          _createElementVNode("li", null, "时间范围选择\"过去1小时\""),
                          _createElementVNode("li", null, "只勾选\"Cookie及其他网站数据\"")
                        ])
                      ])
                    ]),
                    _createElementVNode("div", { class: "flex items-center gap-2" }, [
                      _createElementVNode("div", { class: "bg-blue-100 rounded-full p-2 flex-shrink-0" }, [
                        _createElementVNode("span", { class: "text-blue-600 font-bold" }, "3")
                      ]),
                      _createElementVNode("div", null, " 点击\"清除数据\"按钮 ")
                    ]),
                    _createElementVNode("div", { class: "flex items-center gap-2" }, [
                      _createElementVNode("div", { class: "bg-blue-100 rounded-full p-2 flex-shrink-0" }, [
                        _createElementVNode("span", { class: "text-blue-600 font-bold" }, "4")
                      ]),
                      _createElementVNode("div", null, " 刷新页面即可 ")
                    ])
                  ])
                ], -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", {
                    onClick: _cache[2] || (_cache[2] = (...args) => ($options.closeAnnouncement && $options.closeAnnouncement(...args))),
                    class: "px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                  }, " 我知道了 ")
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}