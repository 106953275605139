export const docTypes = {
  guide: '使用指南',
  faq: '常见问题',
  partner: '伙伴入驻',
  article: '鲸智动态',
  other: '其他'
}

export const typeOrder = ['guide', 'faq', 'partner', 'article', 'other']

export const themeColors = {
  guide: {
    border: 'border-emerald-500',
    bg: 'bg-emerald-50',
    text: 'text-emerald-600',
    hoverBg: 'hover:bg-emerald-50/50',
    hoverBorder: 'hover:border-emerald-500/50'
  },
  faq: {
    border: 'border-[#FF5005]',
    bg: 'bg-orange-50',
    text: 'text-[#FF5005]',
    hoverBg: 'hover:bg-orange-50/50',
    hoverBorder: 'hover:border-[#FF5005]/50'
  },
  partner: {
    border: 'border-purple-500',
    bg: 'bg-purple-50',
    text: 'text-purple-600',
    hoverBg: 'hover:bg-purple-50/50',
    hoverBorder: 'hover:border-purple-500/50'
  },
  article: {
    border: 'border-cyan-500',
    bg: 'bg-cyan-50',
    text: 'text-cyan-600',
    hoverBg: 'hover:bg-cyan-50/50',
    hoverBorder: 'hover:border-cyan-500/50'
  },
  other: {
    border: 'border-blue-500',
    bg: 'bg-blue-50',
    text: 'text-blue-600',
    hoverBg: 'hover:bg-blue-50/50',
    hoverBorder: 'hover:border-blue-500/50'
  }
} 