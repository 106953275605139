import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container w-full" }
const _hoisted_2 = {
  class: "editor-container editor-container_classic-editor",
  ref: "editorContainerElement"
}
const _hoisted_3 = { class: "editor-container__editor" }
const _hoisted_4 = { ref: "editorElement" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Ckeditor = _resolveComponent("Ckeditor")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          ($data.isLayoutReady)
            ? (_openBlock(), _createBlock(_component_Ckeditor, {
                key: 0,
                modelValue: $data.config.initialData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.config.initialData) = $event)),
                editor: $data.editor,
                config: $data.config,
                onInput: $options.inputOnChange,
                onReady: $options.onEditorReady
              }, null, 8 /* PROPS */, ["modelValue", "editor", "config", "onInput", "onReady"]))
            : _createCommentVNode("v-if", true)
        ], 512 /* NEED_PATCH */)
      ])
    ], 512 /* NEED_PATCH */)
  ]))
}