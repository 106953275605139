import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vote-buttons flex flex-col items-center mr-3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CaretTop = _resolveComponent("CaretTop")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_CaretBottom = _resolveComponent("CaretBottom")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["vote-button", { active: $props.userVote === 'upvote' }]),
      onClick: _cache[0] || (_cache[0] = $event => ($options.handleVote('upvote')))
    }, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_CaretTop)
        ]),
        _: 1 /* STABLE */
      })
    ], 2 /* CLASS */),
    _createElementVNode("span", {
      class: _normalizeClass(["vote-count", { 
      'text-[#FF5005]': $props.votesCount > 0,
      'text-[#86909C]': $props.votesCount === 0,
      'text-[#F56C6C]': $props.votesCount < 0
    }])
    }, _toDisplayString($props.votesCount), 3 /* TEXT, CLASS */),
    _createElementVNode("button", {
      class: _normalizeClass(["vote-button", { active: $props.userVote === 'downvote' }]),
      onClick: _cache[1] || (_cache[1] = $event => ($options.handleVote('downvote')))
    }, [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_component_CaretBottom)
        ]),
        _: 1 /* STABLE */
      })
    ], 2 /* CLASS */)
  ]))
}