import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")

  return (_openBlock(), _createBlock(_component_el_table, {
    data: $setup.tableData,
    border: "",
    stripe: "",
    "max-height": "420",
    onRowClick: $setup.toggleRow,
    class: "w-full rounded-md mb-4",
    "row-class-name": "row-item-clamp cursor-pointer",
    "cell-class-name": "!align-top"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.previewData.columns, (column) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: column,
          prop: column,
          label: column,
          "min-width": "180"
        }, null, 8 /* PROPS */, ["prop", "label"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"]))
}