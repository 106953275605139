import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.statusType === 'build')
      ? (_openBlock(), _createBlock($setup["BuildStatus"], {
          key: 0,
          statusName: $setup.appStatusDisplayName
        }, null, 8 /* PROPS */, ["statusName"]))
      : _createCommentVNode("v-if", true),
    ($setup.statusType === 'success')
      ? (_openBlock(), _createBlock($setup["SuccessStatus"], {
          key: 1,
          statusName: $setup.appStatusDisplayName
        }, null, 8 /* PROPS */, ["statusName"]))
      : _createCommentVNode("v-if", true),
    ($setup.statusType === 'warning')
      ? (_openBlock(), _createBlock($setup["WarningStatus"], {
          key: 2,
          statusName: $setup.appStatusDisplayName
        }, null, 8 /* PROPS */, ["statusName"]))
      : _createCommentVNode("v-if", true),
    ($setup.statusType === 'error')
      ? (_openBlock(), _createBlock($setup["ErrorStatus"], {
          key: 3,
          statusName: $setup.appStatusDisplayName
        }, null, 8 /* PROPS */, ["statusName"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}