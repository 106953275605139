<template>
  <div :class="`rounded-[16px] border-[2px] px-[36px] py-[60px] relative flex flex-col gap-[32px] ${getTheme(firstItem.label).bg} ${getTheme(firstItem.label).borderColor}`" style="width: auto;">
    <div :class="`absolute bg-white ${getTheme(firstItem.label).borderColor} left-[24px] top-[-16px] rounded-[8px] px-[16px] text-[20px] font-[400] leading-[30px] border-[2px]`">
      {{ firstItem.label }}
    </div>
    <template v-for="times in range" :key="times">
      <div class="flex flex-nowrap gap-[32px]">
        <template v-for="(secondItem, index) in firstItem.subs" :key="secondItem.label">
          <template v-if="index % this.secondLevelRowCount === times">
            <SecondLevel :firstItem="firstItem" :secondItem="secondItem" :thirdLevelRowCount="this.thirdLevelRowCount"></SecondLevel>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import { stringMod } from "../helpers/utils";
  import SecondLevel from './SecondLevel.vue'

  export default {
    props: {
      firstItem: {
        type: Object,
        default: () => ({})
      },
      secondLevelRowCount: {
        type: Number,
        default: 3
      },
      thirdLevelRowCount: {
        type: Number,
        default: 4
      }
    },
    components: {
      SecondLevel
    },
    data() {
      return {
        themes: {
          0: {
            "borderColor": "border-[#FDB022]",
            "bg": "bg-[#FFFCF5]"
          },
          1: {
            "borderColor": "border-[#5271E3]",
            "bg": "bg-[#F0F3FF]"
          },
          2: {
            "borderColor": "border-[#47CD89]",
            "bg": "bg-[#F6FEF9]"
          },
          3: {
            "borderColor": "border-[#9DA4AE]",
            "bg": "bg-[#FCFCFD]"
          },
          4: {
            "borderColor": "border-[#A48AFB]",
            "bg": "bg-[#FBFAFF]"
          },
          5: {
            "borderColor": "border-[#F97066]",
            "bg": "bg-[#FFFBFA]"
          },
        }
      }
    },
    methods: {
      getTheme(str) {
        const mod = stringMod(str, 6)
        return this.themes[mod]
      }
    },
    computed: {
      range() {
        return Array.from({ length: this.secondLevelRowCount }, (v, k) => k);
      }
    }
  }
</script>