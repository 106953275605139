import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-[8px] md:px-1 px-4 justify-center items-center" }
const _hoisted_2 = { class: "block not-italic md:hidden" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none"
    }, [
      _createElementVNode("path", {
        d: "M15.8332 9.99935H4.1665M4.1665 9.99935L9.99984 15.8327M4.1665 9.99935L9.99984 4.16602",
        stroke: "#344054",
        "stroke-width": "1.67",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      })
    ], -1 /* HOISTED */)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('all.previous')), 1 /* TEXT */)
  ]))
}