import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-[480px]" }
const _hoisted_2 = { class: "flex justify-between mb-4" }
const _hoisted_3 = { class: "flex justify-between mb-4" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "flex justify-between mb-4" }
const _hoisted_6 = { class: "flex justify-between mb-4" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between mb-4"
}
const _hoisted_8 = { class: "flex justify-between mb-4" }
const _hoisted_9 = {
  key: 1,
  class: "flex justify-between mb-4"
}
const _hoisted_10 = {
  key: 2,
  class: "flex justify-between mb-4"
}
const _hoisted_11 = { class: "flex gap-[16px]" }
const _hoisted_12 = { class: "card-footer" }
const _hoisted_13 = { class: "card-footer" }
const _hoisted_14 = { class: "dialog-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, null, {
      header: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("span", null, "Model Detail")
        ], -1 /* HOISTED */)
      ])),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_button, {
              onClick: $setup.redirectModelEdit,
              class: "w-[100px]"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("编辑")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = $event => ($setup.showWeightDialog = true)),
              class: "w-[100px]"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("设置权重")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_13, [
            ($setup.canSync)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  onClick: $setup.syncModelMirror,
                  class: "w-[100px]"
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("同步")
                  ])),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _cache[5] || (_cache[5] = _createElementVNode("label", null, "Name", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.model.name), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("label", null, "Owner", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.route.params.namespace), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("label", null, "Creator", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.model.user?.username || ''), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("label", null, "Visibility", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.model.private == false ? 'public' : 'private'), 1 /* TEXT */)
          ]),
          _createElementVNode("li", _hoisted_6, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "Weight", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.model.recom_op_weight || 1), 1 /* TEXT */)
          ]),
          ($setup.sourceUrl)
            ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Origin", -1 /* HOISTED */)),
                _createElementVNode("p", null, _toDisplayString($setup.sourceUrl), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("li", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Created At", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString($setup.dayjs($setup.model.created_at).format('YYYY-MM-DD HH:mm:ss')), 1 /* TEXT */)
          ]),
          ($setup.lastUpdatedAt)
            ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                _cache[12] || (_cache[12] = _createElementVNode("label", null, "Last Updated At", -1 /* HOISTED */)),
                _createElementVNode("p", null, _toDisplayString($setup.dayjs($setup.lastUpdatedAt).format('YYYY-MM-DD HH:mm:ss')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          ($setup.lastMessage)
            ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                _cache[13] || (_cache[13] = _createElementVNode("label", null, "Last Message", -1 /* HOISTED */)),
                _createElementVNode("p", null, _toDisplayString($setup.lastMessage), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.showWeightDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.showWeightDialog) = $event)),
      title: "设置模型权重",
      width: "30%",
      class: "dialogWidth",
      style: {"border-radius":"0.5rem"},
      left: ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_14, [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = $event => ($setup.showWeightDialog = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.updateWeight
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: $setup.weight,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.weight) = $event)),
          type: "number",
          placeholder: "请输入权重"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}