import request from './request'

/** 需求 开始 */
// 搜索需求
export function issueList(query) {
  return request({
    url: `/internal_api/issues`,
    method: 'get',
    params: query
  })
}

// 查看需求
export function issueDetail(uuid) {
  return request({
    url: `/internal_api/issues/${uuid}`,
    method: 'get',
  })
}

// 创建需求
export function createIssue(data) {
  return request({
    url: `/internal_api/issues`,
    method: 'post',
    data
  })
}
/** 需求 结束 */

/** 方案 开始 */
// 创建方案
export function createIssuePlan(data) {
  return request({
    url: `/internal_api/issue_plans`,
    method: 'post',
    data
  })
}