<template>
  <div class="bg-gradient-to-l from-[#FAFAFA] to-white">
    <div class="mx-auto max-w-[1440px] pt-[16px] px-[80px] pb-[40px] md:p-[16px]">
      <breadcrumb 
        referActionUrl="/datasets"
        referActionName="数据集列表"
        currentActionName="数据集详情"
      />
      <div class="mt-[10px]">
        <resource-header
          :private="dataset.data.private"
          :license="dataset.data.license"
          :name="dataset.data.name"
          :nickname="dataset.data.nickname"
          :desc="dataset.data.description"
          :path="dataset.data.path"
          :avatar="avatar"
          :tags="tags"
          :resource="dataset.data"
          :owner-url="ownerUrl"
        />
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-[1440px] px-[80px] mt-[-40px] md:px-[16px]">
    <dataset-tabs
      :blob="blob.data"
      :local-dataset-id="localDatasetId"
      :local-dataset="localDataset"
      :dataset-path="dataset.data.path"
      :dataset-nickname="localDataset.nickname"
      :dataset-desc="localDataset.desc"
      :dataset-default-branch="dataset.data.default_branch"
      :dataset-private="dataset.data.private"
      :dataset-detail="dataset.data"
      :last-commit="lastCommit.data"
      :branches="branches.data"
      :current-branch="currentBranch"
      :current-path="currentPath"
      :default-tab="defaultTab"
      :actionName="actionName"
      :settingsVisibility="settingsVisibility"
      :can-write="canWrite"
    />
  </div>
</template>

<script setup>
import ResourceHeader from '../shared/ResourceHeader.vue';
import Breadcrumb from '../Breadcrumb.vue'
import DatasetTabs from './DatasetTabs.vue';

const props = defineProps({
  dataset: Object,
  localDataset: Object,
  files: Object,
  lastCommit: Object,
  branches: Object,
  localDatasetId: String,
  currentBranch: String,
  currentPath: String,
  defaultTab: String,
  blob: Object,
  actionName: String,
  avatar: String,
  settingsVisibility: Boolean,
  tags: Object,
  ownerUrl: String,
  canWrite: Boolean
})
</script>

<style scoped>
body {
  background: #fff !important;
}
</style>
